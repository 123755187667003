import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../../services/redux/actions/app-actions";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";

import {changeComment, deleteComment} from "./api";
import CommentField from "../../../components/UI/CommentField";
import {Person} from "@material-ui/icons";
import {MODALS} from "../../../constants";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  comment_icon: {
    color: "#fff",
    background: "#9194A9",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30
  },
  comment_info: {
    display: "flex",
    alignItems: "center"
  },
  comment_fio: {
    fontSize: 14,
    fontWeight: 600,
    color: "#000",
    marginRight: 10
  },
  comment_date: {
    fontSize: 12,
    color: "#9194A9"
  },
  comment_controls: {
    display: "flex",
    alignItems: "center",

    "& p": {
      margin: "0 10px 0 0"
    }
  },
  comment_btn: {
    fontSize: 12,
    fontWeight: 500,
    color: "#9194A9",
    textDecoration: "underline",
    cursor: "pointer",

    "&:hover": {
      color: "#000"
    }
  }
});

const SingleComment = ({
                         crm_deal_comment,
                         crm_deal_comment_id,
                         crm_deal_id,
                         is_owner,
                         setComments,
                         fio,
                         rowversion
                       }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {addDefaultSnack, addCloseSnack} = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [comment, setComment] = useState(crm_deal_comment);

  const handleClickAway = () => {
    setIsEdit(false);
    setComment("");
  };

  const handleClick = () => {
    setLoading(true)
    changeComment(crm_deal_id, crm_deal_comment_id, {comments: comment})
      .then(res => {
        setLoading(false)
        setComments(res);
        setIsEdit(false);
        addDefaultSnack("Комментарий успешно изменен");
      })
      .catch(err => {
        setLoading(false)
        addCloseSnack(err.response.data.error);
      });
  };

  const handleEditClick = () => {
    setComment(crm_deal_comment);
    setIsEdit(true);
  };

  const handleDelete = () => {
    deleteComment(crm_deal_id, crm_deal_comment_id, {comments: comment})
      .then(res => {
        setComments(res);
        addDefaultSnack("Комментарий успешно удален");
        dispatch(setShowModal(false));
      })
      .catch(err => {
        addCloseSnack(err.response.data.error);
      });
  };

  const handleDeleteClick = () => {
    dispatch(
      setShowModal(true, MODALS.confirmDelete, {
        title: "Удаление комментария",
        handleDelete
      })
    );
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="45px 1fr"
      alignItems="flex-start"
      mt={1.7}
    >
      <Box className={classes.comment_icon}>
        <Person/>
      </Box>
      {isEdit ? (
        <CommentField
          value={comment}
          loading={loading}
          handleClickAway={handleClickAway}
          handleClick={handleClick}
          handleChange={e => setComment(e.target.value)}
          handleClear={handleClickAway}
        />
      ) : (
        <Box width="100%">
          <Box className={classes.comment_info}>
            <Typography className={classes.comment_fio}>{fio}</Typography>
            <Typography className={classes.comment_date}>
              {rowversion}
            </Typography>
          </Box>
          <Box mb={1}>{crm_deal_comment}</Box>
          {is_owner && <Box className={classes.comment_controls}>
            <Typography
              className={classes.comment_btn}
              onClick={handleEditClick}
            >
              Изменить
            </Typography>
            <Typography
              className={classes.comment_btn}
              onClick={handleDeleteClick}
            >
              Удалить
            </Typography>
          </Box>}
        </Box>
      )}
    </Box>
  );
};

export default SingleComment;
