import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, FormControl, InputLabel, NativeSelect} from "@material-ui/core";

import CustomDatePicker from "../../../components/UI/DatePicker";
import {getSaleDeptId} from "../../salesDeptPlan/api";
import {getCrmStatuses} from "../../deals/view/api";
import AutoSuggest from "../../../components/UI/AutoSuggest";
import cookiesService from "../../../services/cookiesService";

const useStyles = makeStyles({
  filters: {
    display: "flex",
    gap: 20,
  },
  date_picker: {
    maxWidth: 200,

    "& label": {
      fontWeight: 400,
    },

    "& button": {
      padding: 0,
    },
  },
});

const cookiesServiceInstance = cookiesService.getService();

const Filters = ({state, setState}) => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const classes = useStyles();
  const [saleDept, setSaleDept] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    getSaleDeptId().then((res) => {
      setSaleDept(res);
    });
  }, []);

  useEffect(() => {
    getCrmStatuses().then((res) => {
      setStatuses(res);
    });
  }, []);

  const handleChange = (e, v, name) => {
    if (v) {
      setState({...state, [name]: v});
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value ? e.target.value : null,
      });
    }
  };

  const handleClearDate = (name) => {
    setState({...state, [name]: null});
  };

  return (
    <Box className={classes.filters}>
      {position_code !== "SALE_HEAD" ? (
        <Box minWidth={200}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sd-select" shrink>
              ОП
            </InputLabel>
            <NativeSelect
              onChange={handleChange}
              inputProps={{
                name: "sale_department_id",
                id: "sd-select",
              }}
            >
              <option value="">Все ОП</option>
              {saleDept.map((el) => {
                return (
                  <option
                    value={el.sale_department_id}
                    key={el.sale_department_id}
                  >
                    {el.sale_department_name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
      ) : (
        ""
      )}

      <Box minWidth={250}>
        <AutoSuggest
          label="Статусы"
          placeholder="Выберите"
          name="crm_status_ids"
          value={state.crm_status_ids}
          isMultiple
          onChange={handleChange}
          list={statuses}
          showKey="crm_status_name"
          valueKey="crm_status_id"
        />
      </Box>
      <Box className={classes.date_picker}>
        <CustomDatePicker
          name="date_begin"
          label="Дата с"
          placeholder="Введите дату"
          handleChange={handleChange}
          value={state.date_begin}
          handleClearDate={handleClearDate}
        />
      </Box>
      <Box className={classes.date_picker}>
        <CustomDatePicker
          name="date_end"
          label="Дата по"
          placeholder="Введите дату"
          handleChange={handleChange}
          value={state.date_end}
          handleClearDate={handleClearDate}
        />
      </Box>
    </Box>
  );
};

export default Filters;
