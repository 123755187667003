import InputText from "components/Form/InputText";
import ProgressR from "components/Form/ProgressR";
import CustomButton from "components/UI/Button";
import {Formik} from "formik";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {changeBankData} from "../../../pages/deals/view/api";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {setModalData, setShowModal} from "../../../services/redux/actions/app-actions";

export const validate = Yup.object().shape({
  account_iik: Yup.string()
    .nullable()
    .required("Обязательное поле"),
  account_bik: Yup.string()
    .nullable()
    .required("Обязательное поле"),
  account_bin: Yup.string()
    .nullable()
    .required("Обязательное поле")
});

export default function BankDataModal({ client_id }) {
  const [loading, ] = useState(false);
  const {addDefaultSnack } = useCustomSnackbar();
  const { bankData } = useSelector(state => state.appState.modalData);
  const dispatch = useDispatch();

  const postData = values => {
    changeBankData(client_id, values)
      .then(res => {
        dispatch(setModalData({ bankData: res }));
        dispatch(setShowModal(false));
        addDefaultSnack("Реквизиты банка успешно сохранены");
      })
      .catch(() => {});
  };

  return (
    <ProgressR width="300px" loading={loading}>
      <Formik
        enableReinitialize
        initialValues={bankData?.bank_details || {}}
        validationSchema={validate}
        validateOnChange={false}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          postData(values, resetForm, setSubmitting);
        }}
      >
        {props => {
          let {
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting
          } = props;

          return (
            <form className="my-class">
              <ProgressR
                width="340px"
                minHeight="50px"
                loading={isSubmitting}
              />
              <InputText
                name="account_iik"
                label="ИИК"
                value={values.account_iik}
                error={errors.account_iik}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputText
                name="account_bik"
                label="БИК"
                value={values.account_bik}
                error={errors.account_bik}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputText
                name="account_bin"
                label="БИН"
                value={values.account_bin}
                error={errors.account_bin}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputText
                name="account_bank_name"
                label="Наименование банка"
                value={values.account_bank_name}
                error={errors.account_bank_name}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <div className="d-flex justify-content-end">
                <CustomButton btnText="Сохранить" handleClick={handleSubmit} />
              </div>
            </form>
          );
        }}
      </Formik>
    </ProgressR>
  );
}
