import InputPhone from "components/Form/InputPhone";
import InputText from "components/Form/InputText";
import ProgressR from "components/Form/ProgressR";
import CustomButton from "components/UI/Button";
import {Formik} from "formik";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setModalData, setShowModal} from "../../../services/redux/actions/app-actions";
import * as Yup from "yup";
import {changeContactData} from "../../../pages/deals/view/api";
import {useSnackbar} from "notistack";
import CheckboxR from "../../Form/CheckboxR";

export const validate = Yup.object().shape({
  contact_fio: Yup.string().required("Обязательное поле"),
  phone: Yup.string().required("Обязательное поле")
});

export default function ContactDataModal({ client_id }) {
  const [loading,] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const postData = values => {
    changeContactData(client_id, {
      ...values,
      phone: values.phone
    })
      .then(res => {
        dispatch(setModalData({ contactData: res }));
        dispatch(setShowModal(false));
        enqueueSnackbar("Контактные данные успешно сохранены", {
          variant: "success"
        });
      })
      .catch(err => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  };

  return (
    <ProgressR width="300px" loading={loading}>
      <Formik
        enableReinitialize
        initialValues={{}}
        validationSchema={validate}
        validateOnChange={false}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          postData(values, resetForm, setSubmitting);
        }}
      >
        {props => {
          let {
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting
          } = props;

          return (
            <form>
              <ProgressR
                width="340px"
                minHeight="50px"
                loading={isSubmitting}
              />
              <InputText
                name="contact_fio"
                label="ФИО"
                value={values.contact_fio}
                error={errors.contact_fio}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputPhone
                name="phone"
                label="Телефон"
                value={values.phone}
                error={errors.phone}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <div>
                <CheckboxR
                  name="is_contact"
                  label="Контактное лицо"
                  onChange={handleChange}
                  checked={values.is_contact}
                />
              </div>
              <div className="d-flex justify-content-end">
                <CustomButton btnText="Сохранить" handleClick={handleSubmit} />
              </div>
            </form>
          );
        }}
      </Formik>
    </ProgressR>
  );
}
