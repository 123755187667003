import React, { useEffect, useState } from "react";

import { useStyles } from "../../../components/modal/modals/PaymentModal";
import { Box, TableCell, Typography } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHeadComp from "./TableHeadComp";
import TableBody from "@material-ui/core/TableBody";

import { getTranches } from "./api";
import EmptyOrShow from "../../../components/EmptyOrShow";
import TableRow from "@material-ui/core/TableRow";
import {formatSum} from "../../../utils";

const tranchesTitle = [
  "ID",
  "Сумма",
  "По условию договора",
  "Дата ожидаемой оплаты",
  "Комментарии",
  "Кол-во просроченных дней"
];

export default function Tranches({ crmDealId }) {
  const classes = useStyles();
  const [tranchesData, setTranchesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const paymentAmountArray = tranchesData.map(el => {
    return el.payment_amount;
  });

  const totalPaymentAmount = paymentAmountArray.reduce((a, b) => a + b, 0);

  useEffect(() => {
    setLoading(true);
    getTranches(crmDealId)
      .then(res => {
        setTranchesData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crmDealId]);

  return (
    <>
      <Typography className={classes.main_title}>Список траншей</Typography>
      <Box className={classes.block}>
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table aria-label="a dense table" className={classes.table}>
              <TableHeadComp data={tranchesTitle} />
              <TableBody>
                {tranchesData?.map(el => (
                  <TableRow key={el.client_request_tranche_id}>
                    <TableCell align="center">
                      {el.client_request_tranche_id}
                    </TableCell>
                    <TableCell align="center">
                      {formatSum(el.payment_amount)}
                    </TableCell>
                    <TableCell align="center">
                      {el.is_agreement_change_text}
                    </TableCell>
                    <TableCell align="center">{el.payment_date}</TableCell>
                    <TableCell align="center" style={{ whiteSpace: "normal" }}>
                      {el.comments}
                    </TableCell>
                    <TableCell align="center">{el.days_overdue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
      <Box className={classes.total_amount}>
        Общая сумма траншей: {formatSum(totalPaymentAmount)} тг
      </Box>
    </>
  );
}
