import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";

import styleConstants from "../../../constants/styleConstants";
import CustomButton from "../../UI/Button";
import InputText from "../../Form/InputText";
import PaymentRowComp from "../../../pages/deals/view/PaymentRowComp";
import {addPayments, getDealStatuses, getPayments} from "../../../pages/deals/view/api";
import {format} from "date-fns";
import {useDispatch, useSelector} from "react-redux";
import EmptyOrShow from "../../EmptyOrShow";
import {setBtnStatuses, setStatusInfo} from "../../../services/redux/actions/app-actions";
import Tranches from "../../../pages/deals/view/Tranches";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {formatSum} from "../../../utils";

export const useStyles = makeStyles({
  payment_wrapper: {
    width: "1270px",
    maxWidth: "90vw",
    maxHeight: "80vh",
    overflow: "auto",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",
    paddingRight: 10,

    "&::-webkit-scrollbar": {
      width: 5
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  main_title: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    marginBottom: 10
  },
  block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4

    // "&:not(:last-child)": {
    //   marginBottom: 25
    // }
  },
  table_container: {
    "&::-webkit-scrollbar": {
      width: 7,
      height: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table: {
    "& td": {
      padding: "5px !important",
      whiteSpace: "nowrap"
    }
  },
  inputs_block: {
    backgroundColor: "#fff",
    border: "1px solid #D2D3D9",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gap: "25px",
    alignItems: "flex-end",
    marginBottom: 10,
    padding: "10px 15px"
  },
  input_block: {
    marginBottom: "0 !important",

    "& div": {
      marginBottom: "0 !important"
    },

    "& label": {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "15px",
      color: "#9194A9",
      margin: 0
    },

    "& input": {
      fontSize: 14
    }
  },
  checkbox_block: {
    "& label": {
      marginBottom: "0 !important"
    }
  },
  btn_add: {
    "& button": {
      whiteSpace: "nowrap"
    }
  },
  total_amount: {
    fontWeight: 600,
    margin: "10px 0 25px 0"
  }
});

const paymentsTitle = [
  "ID",
  "Сумма",
  "Дата платежа",
  "Номер счета",
  "Комментарии",
  "Статус",
  "Тип",
  "Номер транзакции",
  "Ссылка для оплаты",
  "Действия"
];
const checkboxes = ["is_payed", "is_last"];

export default function PaymentModal({ crm_deal_id, btnStatuses }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { client_type_id } = useSelector(
    state => state.appState.dealCreateData
  );
  const [value, setValue] = useState({
    client_type_id
  });
  const [paymentData, setPaymentData] = useState([]);
  const { addDefaultSnack } = useCustomSnackbar();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPayments(crm_deal_id)
      .then(res => {
        setPaymentData(res.payments);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crm_deal_id]);

  const handleChange = e => {
    let targetValue;

    if (checkboxes.includes(e.target.name)) {
      targetValue = +e.target.checked;
    } else {
      targetValue = e.target.value;
    }
    setValue({
      ...value,
      id: Date.now(),
      [e.target.name]: targetValue
    });
  };

  const handleAdd = async () => {
    try {
      const resp = await addPayments(crm_deal_id, {
        ...value,
        payment_amount: parseInt(value.payment_amount),
        payment_date: value["payment_date"]
          ? format(new Date(value["payment_date"]), "dd.MM.yyyy")
          : null
      });
      setPaymentData(resp.payments);
      dispatch(
        setBtnStatuses({ ...btnStatuses, payments_btn: resp.payments_btn })
      );
      setValue({
        payment_amount: "",
        payment_date: "",
        comments: "",
        is_payed: false,
        is_last: false
      });
      addDefaultSnack();
      try {
        const statusInfo = await getDealStatuses(crm_deal_id);
        dispatch(setStatusInfo(statusInfo));
      } catch (err) {}
    } catch (err) {}
  };

  const paymentAmountArray = paymentData.map(el => {
    return el.payment_amount;
  });

  const total = paymentAmountArray.reduce((a, b) => a + b, 0);

  return (
    <Box className={classes.payment_wrapper}>
      <Tranches crmDealId={crm_deal_id} />
      <Box>
        <Typography className={classes.main_title}>Список платежей</Typography>
        <Box className={classes.inputs_block}>
          <InputText
            name="payment_date"
            type="date"
            label="Дата платежа"
            value={value["payment_date"]}
            onChange={handleChange}
            shrink={true}
            className={classes.input_block}
          />
          <InputText
            name="payment_amount"
            type="number"
            label="Сумма платежа"
            value={value["payment_amount"]}
            onChange={handleChange}
            className={classes.input_block}
          />
          <InputText
            name="comments"
            label="Комментарии"
            value={value["comments"]}
            onChange={handleChange}
            className={classes.input_block}
          />
          <Box className={classes.btn_add}>
            <CustomButton
              btnText={"Добавить платеж"}
              bgColor={styleConstants.primaryColor}
              onClick={handleAdd}
            />
          </Box>
        </Box>
        <Box className={classes.block}>
          <EmptyOrShow loading={loading}>
            <TableContainer className={classes.table_container}>
              <Table aria-label="a dense table" className={classes.table}>
                <TableHeadComp data={paymentsTitle} />
                <TableBody>
                  {paymentData?.map(el => (
                    <PaymentRowComp
                      key={el.client_request_payment_id}
                      {...el}
                      setPaymentData={setPaymentData}
                      crmDealId={crm_deal_id}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </EmptyOrShow>
        </Box>
        <Box className={classes.total_amount}>
          Общая сумма платежей: {formatSum(total)} тг
        </Box>
      </Box>
    </Box>
  );
}
