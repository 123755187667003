import React, {useCallback, useRef} from "react";
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";

import {useVirtual} from "react-virtual";
import {matchSorter} from "match-sorter";
import clsx from "clsx";

import styleConstants from "../../../constants/styleConstants";
import {FaUserTie, RiVipCrown2Fill} from "react-icons/all";
import {Box} from "@material-ui/core";
import {ShowPhone} from "../../../components/helpers/showPhone";

export default function ClientsRow({search, clients}) {
  const classes = useStyles();
  const parentRef = useRef();

  const sortedClients = matchSorter(clients, search, {
    keys: [
      "client_id",
      "client_name",
      "email",
      "phone_number",
      "client_iin",
      "deal_cnt",
    ],
  });

  const rowVirtualizer = useVirtual({
    size: sortedClients.length,
    parentRef,
    estimateSize: useCallback(() => 50, []),
  });

  return (
    <>
      <Box ref={parentRef} className={classes.list}>
        <Box
          className={classes.list_inner}
          style={{
            height: `${rowVirtualizer.totalSize}px`,
          }}
        >
          {rowVirtualizer.virtualItems.map((item) => {
            const el = search ? sortedClients[item.index] : clients[item.index];
            return (
              <ClientRowItem
                key={item.index} item={item} el={el}/>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

const ClientRowItem = ({item, el}) => {
  const classes = useStyles();

  return <Box
    className={classes.list_row}
    style={{
      transform: `translateY(${item.start}px)`,
      height: `${item.size}px`,
    }}
  >
    <Box className={classes.list_cell} textAlign="left">
      {el.client_id}
    </Box>
    <Box className={classes.list_cell} textAlign="left">
      <Link to={`client/${el.client_id}`}>{el.client_name}</Link>
    </Box>
    <Box className={classes.list_cell} textAlign="center">
      <ShowPhone body={{client_id: el.client_id}}/>
    </Box>
    <Box className={classes.list_cell} textAlign="left">
      {el.email}
    </Box>
    <Box className={classes.list_cell} textAlign="right">
      {el.client_iin}
    </Box>
    <Box className={clsx(classes.list_cell, classes.client_status)}>
      {el.is_vip ? (
        <span className={classes.vip_status}>
                      <RiVipCrown2Fill/>
                    </span>
      ) : null}
      {el.is_investor ? (
        <span className={classes.investor_status}>
                      <FaUserTie/>
                    </span>
      ) : null}
    </Box>
    <Box className={classes.list_cell} textAlign="left">
      {el.deal_cnt === 0 ? "" : el.deal_cnt}
    </Box>
    <Box className={classes.list_cell} textAlign="left">
      {el.is_active ? "Да" : "Нет"}
    </Box>
  </Box>
}


const useStyles = makeStyles({
  list: {
    backgroundColor: "#fff",
    height: "calc(100vh - 190px)",
    width: "100%",
    overflow: "auto",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: 7,
      height: 5,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius,
    },
  },
  list_inner: {
    width: "100%",
    position: "relative",
  },
  list_row: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 200px 1fr 200px 150px 110px 110px",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    "&:not(:last-child)": {
      borderBottom: "1px solid #E2E3ED",
    },
  },
  list_cell: {
    fontSize: 14,
    lineHeight: "17px",
    width: "100%",
    padding: 10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  client_status: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& span": {
      width: 25,
      height: 25,
      margin: "0 5px",
    },

    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  vip_status: {
    color: "#EFC761",
  },
  investor_status: {
    color: "#AA82D3",
  },
});