import React from "react";

import { TableCell, TableRow } from "@material-ui/core";
import NumberFormat from "react-number-format";

const TableRowComp = ({ discount_name, type_text, cnt }) => {
  return (
    <TableRow>
      <TableCell>{discount_name}</TableCell>
      <TableCell>{type_text}</TableCell>

      <TableCell>
        <NumberFormat value={cnt} displayType="text" thousandSeparator={" "} />
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
