import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Button} from "@material-ui/core";
import clsx from "clsx";
import {setDealCreateData, setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
  wrapper: {
    width: 800
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10
  },
  button: {
    width: "100%",
    height: 38,
    padding: 10,
    borderRadius: 4,
    marginBottom: 10
  },
  change: {
    color: "#fff",
    backgroundColor: "#77bf6c",

    "&:hover": {
      backgroundColor: "#77bf6c"
    }
  },
  cancel: {
    borderColor: "#6191EF",
    color: "#6191EF"
  }
});

const ChangeDataModal = ({ sale_department_id, changeData, copyState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.wrapper}>
      <h1>В сделке имеются незавершенные задачи, завершить?</h1>
      <Box className={classes.buttons}>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.cancel)}
          onClick={() => {
            dispatch(
              setDealCreateData({
                ...copyState,
                sale_department_id: copyState?.sale_department_id
              })
            );
            dispatch(setShowModal(false));
          }}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.change)}
          onClick={() => {
            changeData(sale_department_id, true);
            dispatch(setShowModal(false));
          }}
        >
          Завершить
        </Button>
      </Box>
    </Box>
  );
};

export default ChangeDataModal;
