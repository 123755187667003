import React, { useState } from "react";
import InputText from "../../Form/InputText";
import CustomButton from "../../UI/Button";
import styleConstants from "../../../constants/styleConstants";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  inputs_block: {
    display: "grid",

    "& input": {
      width: 323
    }
  }
});

function AddClientModal() {
  const classes = useStyles();
  const [value, setValue] = useState({});

  const handleChange = (e, v, name) => {
    setValue({
      ...value,
      [name ? name : e.target.name]: v ? v : e.target.value
    });
  };

  const handleAdd = () => {
  };

  return (
    <Box>
      <Box className={classes.inputs_block}>
        <InputText
          name="name"
          label="ФИО"
          fullWidth={false}
          value={value["name"]}
          onChange={handleChange}
        />
        <InputText
          name="phone"
          label="Телефон"
          type="number"
          fullWidth={false}
          value={value["phone"]}
          onChange={handleChange}
        />
        <InputText
          name="iin"
          label="ИИН"
          type="number"
          fullWidth={false}
          value={value["iin"]}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <CustomButton
          btnText={"Добавить"}
          bgColor={styleConstants.primaryColor}
          onClick={handleAdd}
        />
      </Box>
    </Box>
  );
}

export default AddClientModal;
