import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    width: props => props.width + "%",
    opacity: props =>
      props.isDays && ((props.rowIndex === props.dayIndex && 1) || 0)
  },
  horizontal_item: {
    backgroundColor: props => props.bg,
    height: 10,
    border: "1px solid #E2E3ED"
  },
  horizontal_day: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "1px",
    color: "#0E1432",
    textAlign: "right",
    whiteSpace: "nowrap"
  }
});

const bgColors = [
  "#FAFAFE",
  "#ECECFF",
  "#A8B2FF",
  "#CB9CFF",
  "#FFE971",
  "#E9FAF5",
  "#FFEFF4"
];
export const anotherColors = [
  "#6191EF",
  "#F9C283",
  "#AA6767",
  "#96DFF6",
  "#A396F6",
  "#e534eb",
  "#34ebd5",
  "#eb4c34",
  "#3471eb",
  "#99cf67",
  "#C4C4C4"
];

const HorizontalChartItem = ({
  width,
  size,
  rowIndex,
  dayIndex,
  day,
  isDays = true,
  convertedHours = []
}) => {
  const classes = useStyles({
    bg: isDays ? bgColors[dayIndex] : anotherColors[dayIndex],
    width: size && (size / 100) * width,
    rowIndex,
    dayIndex,
    isDays
  });
  return (
    <Box className={classes.item}>
      {isDays ? (
        <Box className={classes.horizontal_day}>
          {day}д {convertedHours[dayIndex]}ч
        </Box>
      ) : null}
      <Box className={classes.horizontal_item} />
    </Box>
  );
};

const HorizontalChart = ({
  days = [],
  rowIndex = null,
  isDays = true,
  convertedHours = []
}) => {
  const containerRef = useRef();
  const [width, setWidth] = useState(null);
  useEffect(() => {
    const blockWidth = containerRef.current
      ?.getBoundingClientRect()
      .width.toFixed(0);
    setWidth(blockWidth);
  }, []);

  return (
    <Box width="100%" display="flex" ref={containerRef} alignItems="flex-end">
      {days?.map((day, i) => (
        <HorizontalChartItem
          key={i}
          width={day}
          size={width}
          bg={"red"}
          rowIndex={rowIndex}
          dayIndex={i}
          day={day}
          isDays={isDays}
          convertedHours={convertedHours}
        />
      ))}
    </Box>
  );
};

export default HorizontalChart;
