import React, {useCallback, useEffect, useState} from "react";

import {useHuntersStyles} from "./index";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NumberFormat from "react-number-format";
import {getPlans} from "./api";
import Progress from "components/UI/Progress";
import EmptyOrShow from "../../components/EmptyOrShow";
import Search from "../../components/UI/Search";

export const HuntersAccordion = ({filters}) => {
  const classes = useHuntersStyles();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stateSum, setStateSum] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchChange = useCallback(() => {
    if (!search) return setFilteredData(data)
    setFilteredData(filterData(data, search));
  }, [search, data])

  useEffect(() => {
    const timeout = setTimeout(() => handleSearchChange(), 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [handleSearchChange]);

  useEffect(() => {
    setStateSum(calSum(filteredData));
  }, [filteredData]);

  useEffect(() => {
    setLoading(true);
    getPlans(filters)
      .then(res => {
        setData(res);
        setFilteredData(res);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  return (
    <Box
      sx={{marginBottom: "20px", border: "1px solid #ccc", borderRadius: 4}}
    >
      <Accordion defaultExpanded style={{borderRadius: 4, boxShadow: "none"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>План/Факт продаж</Typography>
        </AccordionSummary>
        <EmptyOrShow loading={loading}>
          <AccordionDetails style={{display: "block"}}>
            <div style={{marginBottom: "25px", width: "250px"}}>
              <Search
                placeholder={"Поиск"}
                handleChange={handleSearch}
                value={search}
              />
            </div>

            <TableContainer
              className={classes.table_container}
              style={{maxHeight: 500}}
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className={classes.table_head}>
                  <TableRow>
                    {headTitle.map(el => {
                      return (
                        <TableCell
                          key={el}
                          style={{color: "black", fontWeight: "bold"}}
                        >
                          {el}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.table_body}>
                  {filteredData.map((e, i) => {
                    return (
                      <TableRow
                        key={i}
                        className={!e.with_plan ? classes.table_item_false : ""}
                      >
                        <TableCell align="left">
                          {e.sale_department_name}
                        </TableCell>
                        <TableCell align="left">
                          <NumberFormat
                            value={e?.sd_month_plan_sum}
                            displayType="text"
                            thousandSeparator={" "}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <NumberFormat
                            value={e?.sd_month_fact_sum}
                            displayType="text"
                            thousandSeparator={" "}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Progress
                            progress={e.percentage}
                            color={e.percentage_color}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <NumberFormat
                            value={e?.crm_deals_cnt}
                            displayType="text"
                            thousandSeparator={" "}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <NumberFormat
                            value={e?.big_cnt}
                            displayType="text"
                            thousandSeparator={" "}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <NumberFormat
                            value={e?.other_cnt}
                            displayType="text"
                            thousandSeparator={" "}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow className={classes.table_footer}>
                    <TableCell style={{fontWeight: "bold"}}>Итого</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.sd_month_plan_sum}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.sd_month_fact_sum}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.crm_deals_cnt}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.big_cnt}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.other_cnt}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </EmptyOrShow>
      </Accordion>
    </Box>
  );
}
const headTitle = [
  "Отдел продаж",
  "План",
  "Факт",
  "% выполнения",
  "Кол-во продаж",
  "BIG",
  "Внешка"
];

const calSum = (filteredData) => {
  return {
    big_cnt: filteredData?.reduce((a, b) => a + b.big_cnt, 0),
    crm_deals_cnt: filteredData?.reduce((a, b) => a + b.crm_deals_cnt, 0),
    other_cnt: filteredData?.reduce((a, b) => a + b.other_cnt, 0),
    sd_month_fact_sum: filteredData?.reduce((a, b) => a + b.sd_month_fact_sum, 0),
    sd_month_plan_sum: filteredData?.reduce((a, b) => a + b.sd_month_plan_sum, 0)
  }
}

const filterData = (data, search) => {
  return data.filter(el =>
    el?.sale_department_name
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.sd_month_plan_sum
      .toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.sd_month_fact_sum
      .toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()))
}