import React, {useEffect, useState} from 'react'

const LOGO_FILE_NAME = process.env.REACT_APP_LOGO_FILE_NAME || 'crmLogo.svg';
const LOGO_SMALL_FILE_NAME = process.env.REACT_APP_LOGO_SMALL_FILE_NAME || 'smartremontLogo.svg';

export const Logo = ({size = 'large', alt, className = ''}) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const getImage = async () => {
      if (size === 'small') {
        await import(`assets/images/logo/${LOGO_SMALL_FILE_NAME}`).then((module) => {
          setImageSrc(module.default);
        });
      } else  {
        await import(`assets/images/logo/${LOGO_FILE_NAME}`).then((module) => {
          setImageSrc(module.default);
        });
      }
    }
    getImage()
  }, [size])

  return (
    <img src={imageSrc} alt={alt} className={className}/>
  );
};