import React, {useEffect, useState} from "react";
import {Box, FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import {getSaleDeptId} from "pages/salesDeptPlan/api";
import {monthList, yearList} from "../../utils/dates";
import cookiesService from "../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();

const Filters = ({state, handleChange}) => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const [saleDept, setSaleDept] = useState([]);

  useEffect(() => {
    getSaleDeptId().then(res => {
      setSaleDept(res);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: "20px",
        padding: "20px 0"
      }}
    >
      <Box sx={{minWidth: 200}}>
        <FormControl fullWidth>
          <InputLabel htmlFor="year-select" shrink>
            Год
          </InputLabel>
          <NativeSelect
            value={state.year}
            onChange={handleChange}
            inputProps={{
              name: "year",
              id: "year-select"
            }}
          >
            {yearList.map(el => {
              return (
                <option value={el.year} key={el.id}>
                  {el.year}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box sx={{minWidth: 200}}>
        <FormControl fullWidth>
          <InputLabel htmlFor="month-select" shrink>
            Месяц
          </InputLabel>
          <NativeSelect
            value={state.month}
            onChange={handleChange}
            inputProps={{
              name: "month",
              id: "month-select"
            }}
          >
            {monthList.map(el => {
              return (
                <option value={el.id} key={el.id}>
                  {el.month}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </Box>
      {position_code === "SALE_HEAD_HEAD" ||
      position_code === "SALE_AUDITOR" ? (
        <FormControl style={{minWidth: "200px"}}>
          <InputLabel htmlFor="select-sd" shrink>
            ОП
          </InputLabel>
          <NativeSelect
            onChange={handleChange}
            inputProps={{
              name: "sale_department_id",
              id: "select-sd"
            }}
          >
            <option value="">Все ОП</option>
            {saleDept.map(el => {
              return (
                <option
                  value={el.sale_department_id}
                  key={el.sale_department_id}
                >
                  {el.sale_department_name}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      ) : null}
    </Box>
  );
};

export default Filters;
