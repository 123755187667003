import instance from "./index";
import cookiesService from "../services/cookiesService";

export const checkAuth = () => {
  const cookiesServiceInstance = cookiesService.getService();
  const token = cookiesServiceInstance.getAccessToken();
  return instance(true, null, {
    showToast: false,
    throwError: true
  }).post(`/auth/api/token/verify/`, { token });
};
