import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
	wrapper: {
		maxWidth: 550,
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 10
	},
	button: {
		width: "100%",
		height: 38,
		padding: 10,
		borderRadius: 4,
		marginBottom: 10
	},
	confirm: {
		color: "#fff",
		backgroundColor: "#6191ef"
	},
	cancel: {
		borderColor: "#6191EF",
		color: "#6191EF"
	}
});

export default function CheckDocument({handleCancel = () => {}, handleAccept = () => {}}) {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<h1>Клиент с таким ИИН уже существует. Подтянуть данные?</h1>
			<Box className={classes.buttons}>
				<Button
					variant="outlined"
					className={clsx(classes.button, classes.cancel)}
					onClick={handleCancel}
				>
					Отмена
				</Button>
				<Button
					variant="contained"
					color="primary"
					className={clsx(classes.button, classes.confirm)}
					onClick={handleAccept}
				>
					Подтвердить
				</Button>
			</Box>
		</Box>
	);
};