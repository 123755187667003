import React, {useEffect} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {SnackbarProvider} from "notistack";
import "./assets/css/_base.scss";
import "./assets/css/_media.scss";
import "./assets/css/fonts.css";
import "react-phone-number-input/style.css";
import {Routes} from "./Routes";
import configureStore from "./services/redux/configureStore";

import CssBaseline from "@material-ui/core/CssBaseline";
import {ModalWrapper} from "components/modal";
import {SnackbarUtilsConfigurator} from "./components/helpers/snackbar";
import {DispatchComponent} from "./api/helpers";
import {BrowserRouter, useHistory} from "react-router-dom";
import {initialize} from "./services/redux/actions/app-actions";
import {LinearProgress} from "@material-ui/core";

const store = configureStore();

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <DispatchComponent/>
        <AppWrapped/>
      </BrowserRouter>
    </Provider>
  );
};

const AppWrapped = () => {
  const dispatch = useDispatch();
  const {init} = useSelector(state => state.appState);
  const history = useHistory();

  useEffect(() => {
    dispatch(initialize(history.push));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!init) return <LinearProgress color="primary"/>
  return <SnackbarProvider
    maxSnack={3}
    autoHideDuration={3000}
    iconVariant={{
      error: () => <span/>
    }}
  >
    <SnackbarUtilsConfigurator/>
    <CssBaseline/>
    <Routes/>
    <ModalWrapper/>
  </SnackbarProvider>
}
