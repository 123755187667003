import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

//Анимация
import {motion} from "framer-motion";

//Стили
import {ThemeProvider} from "@material-ui/styles";
import MuiTheme from "./theme";

// Страницы
import {PAGES} from "./constants";

import {LogInPage} from "./pages";

import {LeftSidebar} from "./components/sidebar";
import resetPassword from "./pages/login/resetPassword";
import {Content} from "./components/content";

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1.01
  }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.4
};

export const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from="/" to={PAGES.deals}/>
        <Route path={[PAGES.login]}>
          <Switch>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Route path={PAGES.login} component={LogInPage}/>
            </motion.div>
          </Switch>
        </Route>
        <Route path={[PAGES.resetPassword]}>
          <Switch>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Route path={PAGES.resetPassword} component={resetPassword}/>
            </motion.div>
          </Switch>
        </Route>

        <LeftSidebar>
          <Switch>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              className="h-100"
              variants={pageVariants}
            >
              <Content/>
            </motion.div>
          </Switch>
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  );
};
