import { Checkbox, FormControlLabel, withStyles, Box } from "@material-ui/core";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/all";

const CheckboxStyled = withStyles({
  root: {
    color: "#6191EF !important"
  }
})(Checkbox);

const CheckboxGreenStyled = withStyles({
  root: {
    color: "#77BF6C !important"
  }
})(Checkbox);

const FormControlLabelStyled = withStyles({
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#C2C4CE"
  }
})(FormControlLabel);

const FormControlLabelActionStyled = withStyles({
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#6191EF"
  }
})(FormControlLabel);

const FormControlLabelPurpleStyled = withStyles({
  root: {
    backgroundColor: "#E4E7FF",
    borderRadius: "4px",
    padding: 5,
    height: "max-content"
  },
  label: {
    width: "100%",
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  }
})(FormControlLabel);

const FormControlLabelGreyStyled = withStyles({
  root: {
    backgroundColor: "#F5F5F7",
    borderRadius: "4px",
    padding: 5,
    height: "max-content"
  },
  label: {
    width: "100%",
    fontSize: 12,
    fontWeight: 600,
    color: "#9194A9"
  }
})(FormControlLabel);

export default function CheckboxR({
  value,
  name,
  label,
  color = "default",
  onChange,
  disabled,
  action = null,
  colorType = "default",
  title = "",
  allowHint = false
}) {
  const LabelTypes = {
    purple: FormControlLabelPurpleStyled,
    grey: FormControlLabelGreyStyled,
    action: FormControlLabelActionStyled,
    default: FormControlLabelStyled
  };

  const CheckboxTypes = {
    green: CheckboxGreenStyled,
    default: CheckboxStyled
  };

  let FormControl = LabelTypes[colorType] || LabelTypes.default;

  let CheckboxWrapper = CheckboxTypes.default;
  if (action) {
    CheckboxWrapper = CheckboxTypes.green;
    FormControl = LabelTypes.action;
  }
  if (colorType !== "default") {
    CheckboxWrapper = CheckboxTypes.green;
  }

  return (
    <FormControl
      style={{ width: "100%" }}
      control={
        <CheckboxWrapper
          checked={value}
          onChange={onChange}
          name={name}
          color={color}
          inputProps={{ "aria-label": label }}
          disabled={disabled}
        />
      }
      label={
        allowHint ? (
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            component="span"
            width="100%"
          >
            <span style={{ width: "80%" }}>{label}</span>
            <AiOutlineQuestionCircle
              style={{ marginRight: 5, width: 20, height: 20 }}
              title={title}
            />
          </Box>
        ) : (
          label
        )
      }
    />
  );
}
