import cookiesService from "../../cookiesService";
import { PAGES } from "../../../constants";
import { handleError } from "../../../api/helpers";
import { checkAuth } from "../../../api/api_app";
import { getRequestTypes } from "pages/reports/api";

export const SET_INIT = "app:setInit";
export const SET_AUTH = "app:setAuth";
export const SET_USER_DATA = "app:setUserData";
export const SHOW_POPUP = "app:showPopUp";
export const SHOW_MODAL = "app:showModal";
export const SET_DEAL_CREATE_DATA = "app:setDealCreateData";
export const SET_MODAL_DATA = "app:setModalData";
export const SET_MAIN_CITY = "app:setMainCity";
export const TOGGLE_SIDEBAR = "app:setOpenSidebar";
export const SET_TASKS = "app:setTasks";
export const SET_CHECKLISTS = "app:setChecklists";
export const SET_REQUEST_TYPES = "app:setRequestTypes";
export const SET_STATUS_INFO = "app:setStatusInfo";
export const SET_BTN_STATUSES = "app:setBtnStatuses";
export const TOGGLE_TASKS_PAGE = "app:toggleTasksPage";
export const REFRESH_STATE = "app:refreshState";
export const SET_ACTIVE_HUNTER = "app:setActiveHunter";
export const SET_HUNTERS_LIST = "app:setHuntersList";
export const SET_HUNTERS_PLAN_PENALTY = "app:setHuntersPlanPenalty";
export const SET_COEFF_LIST = "app:setCoeffList";
export const SET_DEAL_COUNT = "app:setDealCount";
export const SET_DEAL_DOCS = "app:setDealDocs";

export const setInitialize = (init = true) => {
  return {
    type: SET_INIT,
    payload: {
      init,
    },
  };
};

export const setAuth = (isUserAuthenticated = true) => {
  return {
    type: SET_AUTH,
    payload: {
      isUserAuthenticated,
    },
  };
};

export const setUserData = (userData) => {
  return {
    type: SET_USER_DATA,
    payload: { userData },
  };
};

export const showPopUp = (show = false, type = "", data = {}, ...props) => {
  return {
    type: SHOW_POPUP,
    payload: {
      show,
      type,
      data,
      ...props,
    },
  };
};

export const setShowModal = (show = false, type = "", data = {}) => {
  return {
    type: SHOW_MODAL,
    payload: { show, type, data },
  };
};

export const setDealCreateData = (data, updatedData) => {
  return {
    type: SET_DEAL_CREATE_DATA,
    payload: { data, updatedData },
  };
};
export const setRequestTypes = (payload) => {
  return {
    type: SET_REQUEST_TYPES,
    payload,
  };
};

export const setModalData = (payload) => {
  return {
    type: SET_MODAL_DATA,
    payload,
  };
};

export const setMainCity = (payload) => {
  return {
    type: SET_MAIN_CITY,
    payload,
  };
};

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
  };
};

export const setTasks = (payload) => {
  return {
    type: SET_TASKS,
    payload,
  };
};

export const setChecklists = (payload) => {
  return {
    type: SET_CHECKLISTS,
    payload,
  };
};

export const setStatusInfo = (payload) => {
  return {
    type: SET_STATUS_INFO,
    payload,
  };
};
export const setBtnStatuses = (payload) => {
  return {
    type: SET_BTN_STATUSES,
    payload,
  };
};
export const toggleTasksPage = (payload) => {
  return {
    type: TOGGLE_TASKS_PAGE,
    payload,
  };
};
export const refreshState = (payload) => {
  return {
    type: REFRESH_STATE,
    payload,
  };
};
export const setActiveHunter = (payload) => {
  return {
    type: SET_ACTIVE_HUNTER,
    payload,
  };
};
export const setHuntersList = (payload) => {
  return {
    type: SET_HUNTERS_LIST,
    payload,
  };
};
export const setHuntersPlanPenalty = (payload) => {
  return {
    type: SET_HUNTERS_PLAN_PENALTY,
    payload,
  };
};
export const setCoeffList = (payload) => {
  return {
    type: SET_COEFF_LIST,
    payload,
  };
};
export const setDealCount = (payload) => {
  return {
    type: SET_DEAL_COUNT,
    payload,
  };
};
export const setDealDocs = (payload) => {
  return {
    type: SET_DEAL_DOCS,
    payload,
  };
};

export const getRequestTypesData = () => async (dispatch, getState) => {
  const requestTypes = getState().appState;
  if (requestTypes?.length) return;
  const res = await getRequestTypes();
  dispatch(setRequestTypes(res || []));
};

export const logout = (cookiesService, redirect) => async (dispatch) => {
  dispatch(setUserData(null));
  dispatch(setAuth(false));
  cookiesService.clearToken();
  redirect(PAGES.login);
};

export const initialize = (redirect) => async (dispatch) => {
  const cookiesServiceInstance = cookiesService.getService();
  try {
    if (!cookiesServiceInstance.isCredentialsExist()) {
      await redirect(PAGES.login);
      return dispatch(setInitialize());
    }
    await checkAuth();
    dispatch(setAuth(true));
  } catch (e) {
    if (e?.response?.status === 401) {
      return await handleError(e);
    } else {
      await redirect(PAGES.login);
      cookiesServiceInstance.clearToken();
    }
  }
  dispatch(setInitialize());
};
