import React, {useState} from "react";
import InputText from "../../Form/InputText";
import {Box} from "@material-ui/core";
import {ResidentsTable} from "./ResidentsModal";
import {getResidentsTableByIin} from "../../../pages/deals/view/api";
import EmptyOrShow from "../../EmptyOrShow";

const CheckIin = () => {
  const [iin, setIin] = useState("");
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    getResidentsTableByIin(iin)
      .then(res => {
        setResidents(res);
      })
      .catch(() => {
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box minWidth={350}>
      <form onSubmit={handleSubmit}>
        <InputText
          value={iin}
          onChange={e => setIin(e.target.value)}
          label={"ИИН"}
          placeholder={"Введите ИИН и нажмите Enter"}
        />
      </form>
      <EmptyOrShow loading={loading}>
        {residents.length ? (
          <ResidentsTable residents={residents} isReadOnly={true}/>
        ) : null}
      </EmptyOrShow>
    </Box>
  );
};

export default CheckIin;
