import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";
import DealsList from "./DealsList";
import {getBgColor} from "../../../utils";

const DealsCol = ({
                    deals,
                    crm_status_name,
                    el,
                    crm_status_id,
                    search,
                    fetchDeals
                  }) => {
  const props = {
    backgroundColor: getBgColor(crm_status_id)
  };
  const classes = useStyles(props);
  const [sortedDeals, setSortedDeals] = useState(deals)

  useEffect(() => {
    let delayDebounceFn
    if (!!search?.trim() && deals?.length) {
      delayDebounceFn = setTimeout(() => {
        const sortedDeals = deals?.filter(item => item.client_fio?.toLowerCase().includes(search.toLowerCase())
          || String(item.crm_deal_id).includes(search.toLowerCase())
          || item.resident_name?.toLowerCase().includes(search.toLowerCase())
          || item.city_name?.toLowerCase().includes(search.toLowerCase())
          || item.origin_name?.toLowerCase().includes(search.toLowerCase())
          || item.cc_task_text?.toLowerCase().includes(search.toLowerCase()))
        setSortedDeals(sortedDeals)
      }, 500)
    } else {
      setSortedDeals(deals)
    }
    return () => clearTimeout(delayDebounceFn)
  }, [search, deals])

  return (
    <Box className={classes.col}>
      <Box className={classes.col_title_wrapper}>
        <Typography className={classes.col_title}>{crm_status_name}</Typography>
        <Typography className={classes.col_desc}>{sortedDeals?.length || 0}</Typography>
      </Box>
      <Box className={classes.dealsList}>
        <DealsList
          {...el}
          crmStatusId={crm_status_id}
          sortedDeals={sortedDeals}
          fetchDeals={fetchDeals}
        />
      </Box>
    </Box>
  );
};

export default DealsCol;

const useStyles = makeStyles({
  col: {
    width: 290,
    backgroundColor: "#fff",
    borderRadius: styleConstants.borderRadius,
    border: styleConstants.border,
    "&:not(:last-child)": {
      marginRight: 10
    }
  },
  col_title_wrapper: {
    fontSize: 14,
    backgroundColor: props => props.backgroundColor,
    display: "flex",
    padding: 10
  },
  col_title: {
    fontWeight: 600,
    color: "#72768F",
    marginRight: 5
  },
  col_desc: {
    fontWeight: 600,
    color: styleConstants.darkGray
  },
  dealsList: {
    padding: 10
  }
});


