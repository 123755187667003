import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setDealCreateData, setModalData, setShowModal} from "../../../services/redux/actions/app-actions";

import {Box, Button, Table, TableBody, TableContainer} from "@material-ui/core";

import {getBankData, getContactData, getDocData} from "../../deals/view/api";
import useClientApi from "./api";
import {useSnackbar} from "notistack";
import clsx from "clsx";

import {MODALS} from "../../../constants";
import InputText from "../../../components/Form/InputText";
import {PlusIcon} from "../../../components/UI/Icons";
import {FaUserTie, RiVipCrown2Fill} from "react-icons/all";
import TableHeadComp from "../../deals/view/TableHeadComp";
import ContactDataRowComp from "../../deals/view/ContactDataRowComp";
import {useStyles} from "../../deals/view/DealHeadForm";
import globalUseStyles from "../../../components/globalStyles";
import cookiesService from "../../../services/cookiesService";
import {ShowPhone} from "../../../components/helpers/showPhone";
import InputPhone from "../../../components/Form/InputPhone";

const contactTitle = ["ФИО", "Телефон", "Тип", "Действия"];
const cookiesServiceInstance = cookiesService.getService();
export default function ClientInfo() {
  const {getClientInfo} = useClientApi();
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {...values} = useSelector(state => state.appState.dealCreateData);
  const [showPhone, setShowPhone] = useState(false)

  const {docData, bankData, contactData} = useSelector(
    state => state.appState.modalData
  );
  const {position_code} = cookiesServiceInstance.getUserData();

  useEffect(() => {
    getClientInfo(params.id).then(res => {
      dispatch(setDealCreateData({...values, ...res.data}));
    });
  }, [params.id, dispatch]);

  useEffect(() => {
    Promise.all([
      getDocData(values.client_id, values.crm_deal_id),
      getBankData(params.id),
      getContactData(params.id)
    ])
      .then(res => {
        dispatch(
          setModalData({
            docData: res[0] || {},
            bankData: res[1],
            contactData: res[2]
          })
        );
      })
      .catch(err => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  }, [dispatch, params.id, values?.client_id, values?.crm_deal_id]);

  const handleChange = e => {
    dispatch(
      setDealCreateData({
        [e.target.name]: e.target.value ? e.target.value : ""
      })
    );
  };
  const handleClickPhone = async (e, phone_number) => {
    if (!phone_number) return
    dispatch(setDealCreateData({phone_number}));
    setShowPhone(true)
  }

  return (
    <Box className={classes.client_info} style={{marginBottom: 20}}>
      <Box className={classes.info_item}>
        <h4>Личная информация</h4>
        <Box className={classes.personal_info}>
          <Box className={classes.personal_info_item}>
            <InputText
              name="client_fio"
              label="ФИО"
              value={values?.client_fio || ""}
              onChange={handleChange}
              fullWidth={false}
            />
            <InputText
              name="iin"
              label="ИИН"
              type="number"
              value={values?.iin || ""}
              onChange={handleChange}
              fullWidth={false}
            />
            <InputText
              name="address"
              label="Адрес проживания"
              value={values?.address || ""}
              onChange={handleChange}
              fullWidth={false}
            />
            <button
              className={clsx(classes.deals_btn, {
                [globalClasses.btn_green]: docData?.all_filled === true && values?.client_type_id !== null,
                [globalClasses.btn_grey]:
                Boolean(docData?.all_filled) === false || values?.client_type_id === null,
                [globalClasses.btn_disable]: !values.client_id
              })}
              style={{width: 170}}
              disabled={values?.client_type_id === null}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.docData, {
                    title: "Данные документа",
                    client_id: values.client_id,
                    crm_deal_id: values.crm_deal_id
                  })
                )
              }
            >
              Паспортные данные
            </button>
          </Box>
          <Box className={classes.personal_info_item}>
            <InputText
              name="email"
              label="Email"
              type="email"
              value={values?.email || ""}
              onChange={handleChange}
              fullWidth={false}
            />
            <Box className={classes.number_block}>
              {
                showPhone
                  ? <InputPhone
                    name="phone_number"
                    type="number"
                    label="Телефон"
                    value={values?.phone_number || ""}
                    onChange={handleChange}
                    fullWidth={false}
                  />
                  : !!values.client_id && <ShowPhone onClick={handleClickPhone} body={{client_id: values.client_id}}/>
              }
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.info_item}>
        <h4>Дополнительная информация</h4>
        <Box className={classes.additional_info}>
          <Box className={classes.additional_info_item}>
            <Box className={classes.additional_info_title}>
              <RiVipCrown2Fill
                name="is_vip"
                size={24}
                color={values?.is_vip ? "#EFC761" : "#dadada"}
                style={{
                  cursor: "pointer",
                  pointerEvents: position_code === "SALE_HEAD" ? "auto" : "none"
                }}
                onClick={() =>
                  handleChange({
                    target: {
                      name: "is_vip",
                      value: !values?.is_vip
                    }
                  })
                }
              />
              VIP клиент
            </Box>
            <Box className={classes.additional_info_title}>
              <FaUserTie
                size={24}
                color={values.is_investor ? "#AA82D3" : "#dadada"}
              />
              Инвестор
            </Box>
            <button
              className={clsx(classes.deals_btn, {
                [globalClasses.btn_green]: bankData?.all_filled === true,
                [globalClasses.btn_grey]:
                Boolean(bankData?.all_filled) === false,
                [globalClasses.btn_disable]: !values.client_id
              })}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.bankData, {
                    title: "Реквизиты банка",
                    client_id: params.id
                  })
                )
              }
            >
              Реквизиты банка
            </button>
          </Box>
          <Box className={classes.additional_info_item}>
            <Button
              className={`${classes.btn_contact} ${
                !values?.client_id ? "pointer-none" : ""
              }`}
              endIcon={<PlusIcon/>}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.contactData, {
                    title: "Контактное лицо",
                    client_id: params.id
                  })
                )
              }
            >
              Контактное лицо
            </Button>
            {contactData?.phones.length ? (
              <Box className={classes.table_wrapper}>
                <TableContainer className={classes.table_container}>
                  <Table aria-label="a dense table" className={classes.table}>
                    <TableHeadComp data={contactTitle}/>
                    <TableBody>
                      {contactData?.phones.map(el => {
                        return (
                          !!values.client_id && <ContactDataRowComp
                            key={el.client_phone_id}
                            {...el} clientId={values.client_id}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
