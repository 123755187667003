import React from "react";
import { Box, Typography } from "@material-ui/core";
import checklist from "../../../assets/images/checklist.svg";
import { useStyles } from "../../tasks/view/Task";

export default function Task({ title, date, taskId, is_asap }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.taskLeftBlock}
      style={{ width: "100%", padding: "20px 0" }}
    >
      <Box className={classes.taskLeftBlock_icon}>
        <Box className={classes.taskLeftBlock_icon_inner}>
          <img src={checklist} width={20} height={20} alt="icon" />
        </Box>
      </Box>
      <Box className={classes.taskLeftBlock_info}>
        <Typography
          className={classes.taskLeftBlock_title}
          style={{ color: is_asap ? "#FF4754" : "#000" }}
        >
          №{taskId} {title}
        </Typography>
        <Typography className={classes.taskLeftBlock_date}>{date}</Typography>
      </Box>
      {/*<Typography className={classes.taskLeftBlock_time}>2 часа</Typography>*/}
    </Box>
  );
}
