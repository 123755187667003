import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../../services/redux/actions/app-actions";

import {Box, FormControl, InputLabel, NativeSelect} from "@material-ui/core";

import {addHunter, getHuntersListForHead} from "../../../pages/huntersPlan/api";
import CustomButton from "../../UI/Button";
import {monthList, yearList} from "../../../utils/dates";
import NumberFormatInput from "../../UI/NumberFormatInput";
import {getSaleDeptId} from "../../../pages/salesDeptPlan/api";
import AutoSuggest from "../../UI/AutoSuggest";
import cookiesService from "../../../services/cookiesService";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {getHunters} from "../../../pages/deals/view/api";

const cookiesServiceInstance = cookiesService.getService()
const AddHunter = ({state, setHuntersPlan}) => {
  const {position_code, sale_department_id} = cookiesServiceInstance.getUserData();
  const dispatch = useDispatch();
  const [huntersArr, setHuntersArr] = useState([]);
  const [addHunterState, setAddHunterState] = useState({
    employee_id: null,
    month: null,
    year: null,
    hunter_salary: null,
    hunter_month_plan_sum: null,
    sale_department_id: sale_department_id ? sale_department_id : null
  });
  const [saleDept, setSaleDept] = useState([]);
  const {addDefaultSnack} = useCustomSnackbar();

  useEffect(() => {
    getSaleDeptId().then(res => {
      setSaleDept(res);
    });
  }, []);

  const handleChange = e => {
    setAddHunterState({
      ...addHunterState,
      [e.target.name]: +e.target.value
    });

    if (position_code === "SALE_HEAD_HEAD") {
      if (e.target.name === "sale_department_id") {
        getHuntersListForHead(e.target.value).then(res => {
          setHuntersArr(res);
        });
      }
    }
  };

  useEffect(() => {
    if (sale_department_id) {
      getHunters(sale_department_id).then(res => {
        setHuntersArr(res);
      });
    }
  }, [sale_department_id]);

  const handleAdd = () => {
    addHunter(state.year, state.month, addHunterState)
      .then(res => {
        setHuntersPlan(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно изменен");
      })
      .catch(() => {
      });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{margin: "20px 0"}}>
        <Box sx={{minWidth: 300}}>
          <FormControl fullWidth disabled={sale_department_id}>
            <InputLabel htmlFor="select-sd" shrink={sale_department_id}>
              ОП
            </InputLabel>
            <NativeSelect
              onChange={handleChange}
              value={sale_department_id}
              inputProps={{
                name: "sale_department_id",
                id: "select-sd"
              }}
            >
              <option value=""/>
              {saleDept.map(el => {
                return (
                  <option
                    value={el.sale_department_id}
                    key={el.sale_department_id}
                  >
                    {el.sale_department_name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
        <Box sx={{minWidth: 300}}>
          <AutoSuggest
            label="Хантер"
            name="employee_id"
            list={huntersArr}
            valueKey="employee_id"
            showKey="fio"
            onChange={handleChange}
            disabled={!huntersArr.length}
          />
        </Box>
        <Box
          sx={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px"}}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="month-select">Месяц</InputLabel>
            <NativeSelect
              value={addHunterState.month}
              onChange={handleChange}
              inputProps={{
                name: "month",
                id: "month-select"
              }}
            >
              <option aria-label="" value=""/>
              {monthList.map(el => {
                return (
                  <option value={el.id} key={el.id}>
                    {el.month}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="select-year">Год</InputLabel>
            <NativeSelect
              value={addHunterState.year}
              onChange={handleChange}
              inputProps={{
                name: "year",
                id: "select-year"
              }}
            >
              <option aria-label="" value=""/>
              {yearList.map(el => {
                return (
                  <option value={el.year} key={el.id}>
                    {el.year}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
        <Box sx={{display: "grid"}}>
          <NumberFormatInput
            name="hunter_salary"
            label="Оклад"
            value={addHunterState.hunter_salary || ""}
            handleChange={handleChange}
          />
          <NumberFormatInput
            name="hunter_month_plan_sum"
            label="План"
            value={addHunterState.hunter_month_plan_sum || ""}
            handleChange={handleChange}
          />
        </Box>
      </Box>
      <Box sx={{width: 200, marginLeft: "auto"}}>
        <CustomButton btnText="Добавить" handleClick={handleAdd}/>
      </Box>
    </Box>
  );
};

export default AddHunter;
