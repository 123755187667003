import React from "react";
import {LinearProgress} from "@material-ui/core";

export default function ProgressR({
  isConstructor = null,
  loading = true,
  show = true,
  width = "100%",
  minHeight = "unset",
  children,
}) {
  function render() {
    return (
      <>
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <>{show ? <>{children}</> : null}</>
        )}
      </>
    );
  }

  const props = {};
  if (isConstructor) props.className = "constructor-wrapper p-b-0";

  return (
    <div
      {...props}
      style={{ minWidth: width, minHeight: loading ? minHeight : "unset" }}
    >
      {render()}
    </div>
  );
}
