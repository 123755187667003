import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDealCreateData} from "../../../services/redux/actions/app-actions";

import {Box} from "@material-ui/core";

import ClientInfo from "./ClientInfo";
import ClientAccordion from "./ClientAccordion";
import ClientTabs from "./ClientTabs";
import {useStyles} from "../../deals/view/index";
import clsx from "clsx";
import {Link} from "react-router-dom";
import CustomButton from "../../../components/UI/Button";

export default function Client() {
  const classes = useStyles();
  const [, setLoading] = useState(false);
  const { isOpenSidebar } = useSelector(state => state.appState);

  const dispatch = useDispatch();

  const handleChange = e => {
    dispatch(
      setDealCreateData({
        [e.target.name]: e.target.value ? e.target.value : ""
      })
    );
  };

  return (
    <Box className={classes.deal_outer} style={{ padding: "45px 0 0 0" }}>
      <Box
        className={
          !isOpenSidebar
            ? clsx(classes.closeSidebar, classes.deal_head)
            : clsx(classes.openSidebar, classes.deal_head)
        }
        style={{ boxShadow: "none" }}
      >
        <Box className={classes.deal_head_inner}>
          <h3>Карточка клиента</h3>
          <Box className={classes.actions}>
            <Link to="/clients">
              <CustomButton
                btnText={"Вернуться в список клиентов"}
                variant="outlined"
              />
            </Link>
          </Box>
        </Box>
      </Box>
      <ClientAccordion>
        <ClientInfo handleChange={handleChange} />
      </ClientAccordion>
      <ClientTabs setClientLoading={setLoading} />
    </Box>
  );
}
