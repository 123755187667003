import axios from "axios";
import {API_URL} from "../constants";
import toast from "../components/helpers/snackbar";
import {handleError} from "./helpers";
import cookiesService from "../services/cookiesService";

export let source;

const cookiesServiceInstance = cookiesService.getService();
const instance = (
  authorization = true,
  url = null,
  propsOptions = {}
) => {
  const options = {
    throwError: false, showToast: true, ...propsOptions
  }
  const {showToast, throwError} = options;

  const CancelToken = axios.CancelToken;
  source = CancelToken.source();

  const axiosInstance = axios.create({
    baseURL: url || API_URL,
    cancelToken: options.cancelToken || source.token
  });
  if (authorization) {
    axiosInstance.interceptors.request.use(
      config => {
        const token = cookiesServiceInstance.getAccessToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (axios.isCancel(error)) return
      if (showToast) {
        const message = error?.response?.data?.error;
        const defaultMessage = error.response?.status
          ? `${error.response?.status}, ${error?.response?.statusText}`
          : "Ошибка";
        toast.error(message || defaultMessage);
      }
      handleError(error, source.cancel);
      return throwError
        ? Promise.reject(error)
        : Promise.reject("Что-то пошло не так!");
    }
  );
  return axiosInstance;
};

export default instance;
