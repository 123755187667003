import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {IconButton, InputLabel} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import {makeStyles} from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3
  }
});

const CustomDatePicker = ({
  label,
  name,
  placeholder,
  value,
  handleChange = () => {},
  handleClearDate
}) => {
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <KeyboardDatePicker
          disableToolbar
          name={name}
          invalidDateMessage="Некорректная дата"
          autoOk
          variant="inline"
          format="dd.MM.yyyy"
          id="date-picker-inline"
          placeholder={placeholder}
          inputValue={value}
          value={null}
          fullWidth
          onChange={(...rest) => handleChange(...rest, name)}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => handleClearDate(name)}
                disabled={!value}
              >
                <ClearIcon />
              </IconButton>
            )
          }}
          InputAdornmentProps={{
            position: "start"
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default CustomDatePicker;
