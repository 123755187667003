import CustomButton from "../../../components/UI/Button";
import React, {useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {setShowModal, setStatusInfo, setTasks} from "../../../services/redux/actions/app-actions";
import {MODALS} from "../../../constants";
import {endDealTask, getDealStatuses} from "../../deals/view/api";

export const EndTaskButton = ({
                                is_question,
                                crm_task_type_name,
                                crm_deal_id,
                                date_complete,
                                crm_deal_task_id,
                                is_complete,
                                dealMode = false, afterEndTask
                              }) => {
  const dispatch = useDispatch()
  const {addDefaultSnack} = useCustomSnackbar()
  const [loading, setLoading] = useState(false)

  const handleEndTask = () => {
    dispatch(
      setShowModal(true, MODALS.endTask, {
        title: `Завершение задачи: ${crm_task_type_name}`,
        deal_id: crm_deal_id, task_id: crm_deal_task_id, afterEndTask, dealMode
      })
    );
  };
  const onEndTask = () => {
    setLoading(true)
    endDealTask(crm_deal_id, crm_deal_task_id, {is_complete: true})
      .then(res => {
        setLoading(false)
        addDefaultSnack("Задача успешно завершена");
        if(afterEndTask)
          afterEndTask()
        if(dealMode) {
          dispatch(setTasks(res));
          getDealStatuses(crm_deal_id).then(res => {
            dispatch(setStatusInfo(res));
          });
        }
      })
      .catch(() => {
        setLoading(false)
      });
  };

  const btnText = useMemo(() => {
    if (dealMode) return is_complete ? `Выполнено ${date_complete}` : "Завершить"
    return "Завершить"
  }, [is_complete, dealMode, date_complete])

  return <CustomButton
    handleClick={is_question ? handleEndTask : onEndTask}
    btnText={btnText} loading={loading}
    disabled={is_complete}
  />
}