import React from "react";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import SearchIcon from '@material-ui/icons/Search';

const iconList = {
  WorkOutlineOutlinedIcon,
  AssignmentOutlinedIcon,
  PeopleAltOutlinedIcon,
  AssessmentOutlinedIcon,
  EventNoteOutlinedIcon,
  TrendingUpOutlinedIcon,
  FolderOutlinedIcon,
  BarChartOutlinedIcon,
  LoyaltyIcon,
  DescriptionOutlined,
  SearchIcon,
};

const IconComponent = ({ name, ...props }) => {
  const Icon = iconList[name];
  return <Icon {...props} />;
};

export default IconComponent;
