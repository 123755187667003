import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import ProgressR from "components/Form/ProgressR";
import {isEmpty} from "components/helpers";
import CustomButton from "components/UI/Button";
import {MODALS, PAGES} from "../../../constants";
import {useSnackbar} from "notistack";
import {setDealCreateData, setShowModal,} from "../../../services/redux/actions/app-actions";
import {
  createDeal,
  getBigEmployees,
  getCities,
  getClientTypes,
  getCrmStatuses,
  getFormInfo,
  getOrigins,
  getRemontKeys,
  getRemontTypes,
  getResidents,
  getSaleDepart,
  getSalePoints,
  updateDeal,
} from "./api";
import DealHeadForm from "./DealHeadForm";
import DealLeftForm from "./DealLeftForm";
import DealRightForm from "./DealRightForm";
import ClientsChoice from "./ClientsChoice";
import ClientDocs from "./ClientDocs";
import DealStatus from "./DealStatus";
import HistoryDrawer from "./HistoryDrawer";
import DealComments from "./DealComments";
import Layout from "../../../components/layout";
import {format} from "date-fns";

import {makeStyles} from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import {Box, Button} from "@material-ui/core";
import clsx from "clsx";
import {CaretRight} from "react-iconly";
import cookiesService from "../../../services/cookiesService";
import {getHuntersData} from "../services";

export const useStyles = makeStyles((theme) => ({
  deal_outer: {
    backgroundColor: "#E5E5E5",
  },
  deal_inner: {
    padding: "50px 11px 11px",
  },
  deal_inner_client: {
    padding: "0 11px 11px",
  },
  deal_head: {
    backgroundColor: "#fff",
    boxShadow: "0 5px 10px rgba(108, 108, 108, 0.1)",
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    transition: "all 0.2s linear",
    zIndex: 100,

    "& h3": {
      color: "#6191ef",
      textTransform: "uppercase",
    },
  },
  deal_head_inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 45,
    padding: "0 30px",
  },
  openSidebar: {
    width: "calc(100% - 330px)",
  },
  closeSidebar: {
    width: "calc(100% - 73px)",
  },
  deal_number: {
    display: "flex",
    alignItems: "center",

    "& h3": {
      margin: 0,
    },
  },
  deal_status: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#72768F",
    marginLeft: 20,
  },
  actions: {
    display: "flex",
    alignItems: "flex-start",
    gap: 20,

    "& a": {
      textDecoration: "none",
      height: 30,

      "& button": {
        color: "#6191ef",
        backgroundColor: "transparent",
        borderColor: "#6191ef",
        borderRadius: 4,
        textTransform: "none",

        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },

    "& button": {
      width: "auto",
      height: 30,
      textTransform: "none",
    },
  },
  main_title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    color: "#494C62",
    textTransform: "uppercase",
    margin: "10px 0 10px 20px",
  },
  btn_history_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5px 0",
  },
  btn_history: {
    fontWeight: 600,
    borderRadius: 4,
    backgroundColor: "#ffffff",
    color: "#6191EF",

    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  btn_freedom: {
    fontWeight: 600,
    color: "#fff",
    backgroundColor: "#9194a9 !important",
    borderRadius: 4,
    marginRight: 5,

    "& hover": {
      backgroundColor: "#9194a9 !important",
    },

    "& a": {
      color: "#fff",
      textDecoration: "none",
      display: "flex",
      gap: "5px",
    },
  },
  btn_repair: {
    fontWeight: 600,
    color: "#fff",
    borderRadius: 4,
    marginRight: 5,
  },
  deal_body: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "1fr",
    gap: "10px",

    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: "1fr",
    },
  },
  deal_body_left: {
    backgroundColor: "#fff",
    border: "1px solid #e2e3ed",
    borderRadius: 4,
    width: "100%",
    height: "100%",
    flex: 1,
    padding: 20,

    "& .MuiFormControl-root": {
      width: 250,
    },
  },
  deal_body_right: {
    display: "grid",
    gap: "10px",
  },
  deal_footer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: 10,
    marginTop: 10,

    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.down(850)]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

const cookiesServiceInstance = cookiesService.getService();
export default function DealView({
                                   crm_deal_id = null,
                                   isClient = false,
                                   setBtnsDisable = () => {
                                   },
                                   fetchTabs = () => {
                                   },
                                 }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const {enqueueSnackbar} = useSnackbar();

  const {
    dealCreateData,
    isOpenSidebar,
    btnStatuses,
    statusInfo,
    activeHunter,
  } = useSelector((state) => state.appState);

  const {position_code, employee_id} = cookiesServiceInstance.getUserData();
  const {remont_btn} = btnStatuses;

  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessDeal, setAccessDeal] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return history.listen(() => {
      window.location.reload();
    });
  }, [history]);

  const getResidentsData = (city_id) => {
    if (city_id) {
      getResidents({city_id}).then((residents) =>
        setData({...data, residents})
      );
    }
  };

  const getData = useCallback(() => {
    setLoading(true);
    setBtnsDisable(true);
    Promise.all([
      getFormInfo(crm_deal_id, isClient ? 1 : 0),
      getCities(),
      getCrmStatuses(),
      getOrigins(0),
      getClientTypes(),
      getRemontTypes(),
      getRemontKeys(),
      getBigEmployees(),
      getSaleDepart(),
    ])
      .then(
        ([
           formInfo,
           cities,
           crmStatuses,
           origins,
           clientTypes,
           remontTypes,
           remontKeys,
           bigEmployees,
           saleDepart,
         ]) => {
          if (formInfo) {
            dispatch(setDealCreateData(formInfo, formInfo));
            dispatch(getHuntersData(formInfo?.sale_department_id))

            Promise.all([
              getResidents({city_id: formInfo.city_id}),
              getSalePoints(formInfo?.sale_department_id),
            ]).then(([residents, salePoints]) => {
              setData({
                formInfo,
                cities,
                crmStatuses,
                origins,
                clientTypes,
                remontTypes,
                residents,
                remontKeys,
                bigEmployees,
                salePoints,
                saleDepart,
              });
            });
          } else {
            setData({
              formInfo,
              cities,
              crmStatuses,
              origins,
              clientTypes,
              remontTypes,
              remontKeys,
              bigEmployees,
              saleDepart,
            });
          }
        }
      )
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
        setBtnsDisable(false);
      });
  }, [crm_deal_id, isClient, dispatch])

  useEffect(() => {
    getData();
  }, [getData]);

  const validateData = (d = {}) => {
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!d.email?.match(emailRegex)) {
      return "Неправильный email";
    }
    return null;
  };

  const postData = () => {
    if (dealCreateData.email && validateData(dealCreateData)) {
      enqueueSnackbar(validateData(dealCreateData), {
        variant: "error",
      });
      return;
    }

    if (crm_deal_id) {
      let min_date_change = 0;
      let min_remont_date_begin = dealCreateData?.min_remont_date_begin
        ? dealCreateData?.min_remont_date_begin
        : null;
      let remont_begin_id = dealCreateData?.remont_begin_id
        ? dealCreateData?.remont_begin_id
        : null;
      if (
        (data.formInfo.min_remont_date_begin &&
          data.formInfo.min_remont_date_begin !== min_remont_date_begin) ||
        (data.formInfo.remont_begin_id &&
          data.formInfo.remont_begin_id !== remont_begin_id)
      ) {
        if (
          !window.confirm(
            `Была изменена минимальная дата начала ремонта, в связи с этим сбросятся все рассрочки/скидки/надбавки/акции. Действительно хотите произвести данное действие?`
          )
        ) {
          return false;
        }
        min_date_change = 1;
      }
      if (data.client_type_id !==4 && dealCreateData.client_type_id === 4) {
        if (
          !window.confirm(
            `Тип клиента был изменен на "Юридическое лицо", в связи с этим сбросятся все рассрочки/скидки/надбавки/акции. Действительно хотите произвести данное действие?`
          )
        ) {
          return false;
        }
      }
      setLoading(true);
      updateDeal(crm_deal_id, isClient ? 1 : 0, {
        ...dealCreateData,
        prop_date: dealCreateData?.prop_date
          ? format(new Date(dealCreateData?.prop_date), "dd.MM.yyyy")
          : null,
        min_remont_date_begin: dealCreateData?.min_remont_date_begin
          ? format(
            new Date(dealCreateData?.min_remont_date_begin),
            "dd.MM.yyyy"
          )
          : null,
        big_employee_id: dealCreateData?.big_employee_id
          ? dealCreateData?.big_employee_id
          : null,
        min_date_change,
      })
        .then((res) => {
          if (!isEmpty(res)) {
            dispatch(setDealCreateData(res, res));
            setData((prev) => ({...prev, formInfo: res}));
            enqueueSnackbar("Сделка успешно изменена!", {
              variant: "success",
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      createDeal(isClient ? 1 : 0, {
        ...dealCreateData,
        big_employee_id: dealCreateData?.big_employee_id
          ? dealCreateData?.big_employee_id
          : null,
      })
        .then((res) => {
          if (!isEmpty(res)) {
            enqueueSnackbar("Сделка успешно создана!", {
              variant: "success",
            });
            if (!isClient) {
              history.push(PAGES.dealView + `/${res.crm_deal_id}`);
            } else {
              fetchTabs(true);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const disable = (crm_status_id) => {
    if (
      crm_status_id === 1 &&
      (position_code === "SELLER" || position_code === "SALE_AUDITOR")
    ) {
      return `${globalClasses.disable_block}`;
    } else if (crm_status_id === 2 && position_code === "SALE_AUDITOR") {
      return `${globalClasses.disable_block}`;
    } else if (
      crm_status_id === 3 &&
      (position_code === "CALL_CENTER" || position_code === "SALE_AUDITOR")
    ) {
      return `${globalClasses.disable_block}`;
    } else if (crm_status_id === 4 && position_code === "CALL_CENTER") {
      return `${globalClasses.disable_block}`;
    } else if (
      crm_status_id === 5 &&
      (position_code === "CALL_CENTER" || position_code === "SALE_AUDITOR")
    ) {
      return `${globalClasses.disable_block}`;
    } else if (
      crm_status_id === 6 &&
      (position_code === "CALL_CENTER" || position_code === "SALE_AUDITOR")
    ) {
      return `${globalClasses.disable_block}`;
    } else if (
      crm_status_id === 7 &&
      (position_code === "SELLER" || position_code === "SALE_AUDITOR")
    ) {
      return `${globalClasses.disable_block}`;
    } else if (
      position_code === "CALL_CENTER_HEAD" &&
      crm_status_id > 2 &&
      crm_status_id < 8
    ) {
      return `${globalClasses.disable_block}`;
    } else if (!accessDeal && activeHunter?.employee_id) {
      return `${globalClasses.disable_block}`;
    } else {
      return "";
    }
  };

  const handleAboutRepair = () => {
    dispatch(
      setShowModal(true, MODALS.aboutRepair, {
        title: "Информация по ремонту",
        id: crm_deal_id,
      })
    );
  };

  const getLabel = () => {
    const currStatus = statusInfo?.statuses?.find(
      (el) => el.is_current === true
    );
    if (currStatus)
      return currStatus["crm_status_name"];
    return "Загрузка";
  };

  useEffect(() => {
    if (
      position_code === "SELLER" &&
      employee_id !== activeHunter?.employee_id
    ) {
      setAccessDeal(false);
    } else {
      setAccessDeal(true);
    }
  }, [activeHunter.employee_id, employee_id, position_code]);

  if (!dealCreateData?.is_access && dealCreateData?.is_access !== undefined) {
    return (
      <Box
        sx={{
          fontSize: "50px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Нет Доступа
      </Box>
    );
  }

  return (
    <Layout title={crm_deal_id ? `Сделка №${crm_deal_id}` : "Создание сделки"}>
      <ProgressR loading={!isClient ? loading : false}>
        <Box
          className={clsx(
            classes.deal_outer,
            disable(dealCreateData.crm_status_id)
          )}
        >
          <Box
            className={
              !isOpenSidebar
                ? clsx(classes.closeSidebar, classes.deal_head)
                : clsx(classes.openSidebar, classes.deal_head)
            }
          >
            <Box className={classes.deal_head_inner}>
              {crm_deal_id ? (
                <Box className={classes.deal_number}>
                  {isClient ? (
                    <h3>Карточка клиента</h3>
                  ) : (
                    <h3>сделка №{crm_deal_id}</h3>
                  )}
                  {isClient ? null : (
                    <Box className={classes.deal_status}>{getLabel()}</Box>
                  )}
                </Box>
              ) : (
                <h3>{isClient ? "Карточка клиента" : "Создание сделки"}</h3>
              )}
              <Box className={classes.actions}>
                <Link to={isClient ? "/clients" : "/deals"}>
                  <CustomButton
                    btnText={
                      isClient
                        ? "Вернуться в список клиентов"
                        : "Вернуться в сделки"
                    }
                    variant="outlined"
                  />
                </Link>
                <CustomButton btnText={"Сохранить"} handleClick={postData}/>
              </Box>
            </Box>
          </Box>
          <ProgressR loading={loading}>
            <Box
              className={
                isClient ? classes.deal_inner_client : classes.deal_inner
              }
            >
              {isClient ? null : (
                <Box>
                  <h4 className={classes.main_title}>Информация о клиенте</h4>
                  <DealHeadForm data={data} crm_deal_id ={crm_deal_id} isClient={isClient}/>
                </Box>
              )}
              <Box className={classes.btn_history_wrapper}>
                <h4 className={classes.main_title} style={{flex: 1}}>
                  Данные сделки
                </h4>
                {dealCreateData?.is_show_freedom_btn ? (
                  <Button className={classes.btn_freedom}>
                    <a href={dealCreateData?.freedom_url} target="_blank" rel="noopener noreferrer">
                      Freedom запрос <CaretRight set="light"/>
                    </a>
                  </Button>
                ) : null}
                <Button
                  className={classes.btn_repair}
                  style={{
                    backgroundColor: remont_btn ? "#77bf6c" : "#9194a9",
                  }}
                  onClick={handleAboutRepair}
                >
                  Ремонт
                </Button>
                <Button
                  className={classes.btn_history}
                  onClick={() => setOpen(true)}
                  style={{
                    zIndex: position_code === "CALL_CENTER_HEAD" ? 100 : 0,
                  }}
                >
                  История
                </Button>
              </Box>
              <Box className={classes.deal_body}>
                <Box className={classes.deal_body_left}>
                  <DealLeftForm
                    isClient={isClient}
                    data={data}
                    setData={setData}
                    getResidentsData={getResidentsData}
                  />
                </Box>
                <Box className={classes.deal_body_right}>
                  <ClientsChoice/>
                  <ClientDocs/>
                </Box>
              </Box>
              <Box className={classes.deal_footer}>
                <DealStatus/>
                <DealComments/>
                <DealRightForm/>
              </Box>
            </Box>
          </ProgressR>
        </Box>
        <HistoryDrawer
          open={open}
          crm_deal_id={crm_deal_id}
          handleClose={handleClose}
        />
      </ProgressR>
    </Layout>
  );
}
