import React from "react";
import styles from "./LoaderData.module.scss";

export const LoaderData = ({ small, white = false, allWidth = true }) => {
  return (
    <div className={`${styles.loaderData__ldsRing_wrapper} ${allWidth ? styles.loaderData__ldsRing_wrapperAllWidth : ''}`}>
      <div
        className={`${styles.loaderData__ldsRing} ${
          small ? styles.loaderData__ldsRingSmall : ""
        }`}
      >
        <RingItem small={small} white={white} />
        <RingItem small={small} white={white} />
        <RingItem small={small} white={white} />
        <RingItem small={small} white={white} />
      </div>
    </div>
  );
};

const RingItem = ({ small, white }) => (
  <div
    className={`${styles.loaderData__ldsRing_item} ${
      small ? styles.loaderData__ldsRing_itemSmall : ""
    } ${white ? styles.loaderData__ldsRing_itemWhite : ""}`}
  ></div>
);
