import React from "react";
import {TextField, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

export default function InputText({
                                    name = "",
                                    type = "text",
                                    label = undefined,
                                    placeholder = "",
                                    value = "",
                                    onChange,
                                    maxLength = null,
                                    readOnly = false,
                                    required = false,
                                    numberText = false,
                                    margin = true,
                                    className = "",
                                    error = "",
                                    inputComponent = null,
                                    variant = "standard",
                                    shrink = true,
                                    isWhite = null,
                                    fullWidth = true,
                                    disabled
                                  }) {
  const classes = useStyles({margin})
  let inputProps = inputComponent ? {...inputComponent} : {},
    inputLabelProps = shrink ? {shrink} : {};

  const handleChange = (e) => {
    const defaultObject = {target: {name, value}}
    const {target: {value: newValue}} = e

    if(numberText && isNaN(newValue)) return onChange(defaultObject)
    if(maxLength !== null && typeof maxLength === "number") {
      if(newValue?.length > maxLength)
        return onChange(defaultObject)
      return onChange(e)
    }
    return onChange(e)
  }

  let props = {
    fullWidth: fullWidth,
    id: name,
    name: name,
    type: type,
    className: clsx(classes.input, className),
    label: label,
    value: value,
    required,
    onChange: handleChange,
    variant: variant,
    placeholder: placeholder ? placeholder : "",
    InputProps: {
      readOnly: readOnly,
      ...inputProps
    },
    InputLabelProps: {
      ...inputLabelProps
    },
    helperText: error,
    disabled: disabled,
    error: !!error
  };

  if (isWhite) return <WhiteTextField {...props} />;
  else return <TextField {...props} />;
}

const useStyles = makeStyles({
  input: {
    marginBottom: props => props.margin ? "20px" : '0 !important'
  }
})

const WhiteTextField = withStyles({
  root: {
    color: "#fff !important",
    "& label": {
      color: "#fff !important"
    },
    "& label.Mui-focused": {
      color: "#fff !important"
    },
    "& .MuiInputBase-input": {
      color: "#fff !important"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important"
      },
      "&:hover fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important"
      },
      "&.Mui-focused fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important"
      }
    }
  },
  input: {
    color: "#fff !important",
    "&:focus": {
      color: "#fff !important",
      borderColor: "#fff !important"
    },
    "&$focused": {
      color: "#fff !important",
      borderColor: "#fff !important"
    }
  }
})(TextField);