import React, {useEffect, useState} from "react";
import {setCoeffList, setShowModal} from "../../services/redux/actions/app-actions";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import {MODALS} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {Delete} from "react-iconly";
import {deleteCoefficient, getCoefficients, getCoefficientTypes} from "./api";
import Layout from "../../components/layout";
import Filters from "./Filters";
import styleConstants from "../../constants/styleConstants";

import EmptyOrShow from "../../components/EmptyOrShow";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 11,
    backgroundColor: "#383838",
    padding: "5px 10px"
  }
}))(Tooltip);

const Coefficient = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addDefaultSnack } = useCustomSnackbar();

  const { coeffList } = useSelector(state => state.appState);
  const [dataTypes, setDataTypes] = useState([]);
  const [state, setState] = useState({
    coeff: 1,
    sale_department_id: 0
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.sale_department_id) {
      setLoading(true)
      getCoefficients(state)
        .then(res => {
          dispatch(setCoeffList(res));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [state, dispatch]);

  useEffect(() => {
    getCoefficientTypes().then(res => {
      setDataTypes(res);
      setState(prev => ({ ...prev, sale_department_id: res[0].sale_koef_type_id }));
    });
  }, []);
  const selectedDate = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const deleteCoeff = val => {
    setLoading(true);
    deleteCoefficient(val, state.coeff)
      .then(() => {
        addDefaultSnack("Коэффициент успешно удален");
        dispatch(setShowModal(false));
        getCoefficients(state)
          .then(res => {
            dispatch(setCoeffList(res));
          })
          .catch(() => {
          });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout title="Коэффициенты">
      <Box sx={{ padding: "45px 20px 20px" }}>
        <Box sx={{ display: "flex", alighItems: "flex-end" }}>
          <Filters
            handleChange={selectedDate}
            state={dataTypes}
            type={state.coeff}
          />
        </Box>
        <Box className={classes.table_wrapper}>
          <EmptyOrShow loading={loading}>
            <TableContainer className={classes.table_container}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className={classes.table_head}>
                  <TableRow>
                    {headTitle.map(el => {
                      return <TableCell key={el}>{el}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.table_body}>
                  {coeffList.map(e => {
                    return (
                      <TableRow key={e.sale_koef_id}>
                        <TableCell align="left">{e.sale_koef_id}</TableCell>
                        <TableCell align="left">
                          {e.percents_from} - {e.percents_to}
                        </TableCell>
                        <TableCell align="left">
                          <Box
                            component="div"
                            style={{
                              background: e.color_code,
                              width: e.color_code ? "50px" : "auto",
                              minHeight: "20px",
                              borderRadius: "4px"
                            }}
                          >
                            {e.color_code ? "" : "Цвет не выбран"}
                          </Box>
                        </TableCell>
                        <TableCell align="left">{e.value}</TableCell>
                        <TableCell align="left">
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <CustomTooltip title="Изменение коэффицииента">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  dispatch(
                                    setShowModal(
                                      true,
                                      MODALS.changeCoeffModal,
                                      {
                                        title: "Изменение",
                                        state: e,
                                        type: state.coeff,
                                        saleDept: dataTypes,
                                        department: state.sale_department_id,
                                      }
                                    )
                                  )
                                }
                              >
                                <EditIcon style={{ color: "#6191EF" }} />
                              </IconButton>
                            </CustomTooltip>
                            <CustomTooltip title="Удаление коэффициента">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  dispatch(
                                    setShowModal(true, MODALS.confirmDelete, {
                                      title: "Удаление коэффициента",
                                      handleDelete: () =>
                                        deleteCoeff(e.sale_koef_id)
                                    })
                                  );
                                }}
                              >
                                <Delete
                                  set="light"
                                  style={{ color: "#FF6262" }}
                                />
                              </IconButton>
                            </CustomTooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </EmptyOrShow>
        </Box>
      </Box>
    </Layout>
  );
};

export default Coefficient;
const headTitle = [
  "ID коэффициента",
  "Диапазон",
  "Цвет",
  "Значение",
  "Действия"
];
const useStyles = makeStyles({
  table_wrapper: {},
  table_container: {
    border: "1px solid #f0f2f6",
    height: "calc(100vh - 150px)",
    background: "white",
    "&::-webkit-scrollbar": {
      width: 8
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table_head: {
    background: "white",
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      background: "white"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "1px solid #ccc !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important"
    }
  }
});
