import React, {useEffect, useState} from "react";
import {Box, Radio, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import CustomButton from "../../UI/Button";
import {useStyles} from "./PresetChoiceModal";
import {getResidentsTable, saveResidentsTable} from "../../../pages/deals/view/api";
import EmptyOrShow from "../../EmptyOrShow";
import {setDealCreateData, setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../helpers";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const headTitles = [
  "",
  "Обьект_Наименование",
  "Помещение_Наименование",
  "Площадь",
  "Чистовая"
];

const readOnlyHeader = [
  "Обьект_Наименование",
  "Помещение_Наименование",
  "Площадь",
  "Чистовая"
];

const ResidentsRow = ({
  resident,
  selected,
  setSelected,
  index,
  isReadOnly
}) => {
  const classes = useStyles();
  const handleChange = e => {
    if(e.target.value === null) setSelected(null)
    setSelected(Number(e.target.value));
  };

  return (
    <TableRow>
      {isReadOnly ? null : (
        <TableCell align="center">
          <Radio
            color="primary"
            checked={selected === index}
            onChange={handleChange}
            value={index}
            className={classes.radio}
            classes={{
              checked: classes.checked
            }}
          />
        </TableCell>
      )}
      <TableCell align="center">{resident["Объект_Наименование"]}</TableCell>
      <TableCell align="center">{resident["Помещение_Наименование"]}</TableCell>
      <TableCell align="center">{resident["Площадь"]}</TableCell>
      <TableCell align="center">{resident["Чистовая"]}</TableCell>
    </TableRow>
  );
};

export const ResidentsTable = ({
  residents,
  selected,
  setSelected,
  isReadOnly = false
}) => {
  return (
    <TableContainer style={{ maxHeight: "60vh" }}>
      <Table>
        <TableHeadComp data={isReadOnly ? readOnlyHeader : headTitles} />
        <TableBody>
          {residents?.map((el, i) => (
            <ResidentsRow
              key={i}
              resident={el}
              selected={selected}
              setSelected={setSelected}
              index={i}
              isReadOnly={isReadOnly}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ResidentsModal = ({ client_id, crm_deal_id, dealCreateData }) => {
  const { addDefaultSnack } = useCustomSnackbar();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    const params = {
      crm_deal_id,
      json_data: residents,
      num: selected === null ? null : selected + 1
    };
    setLoading(true);
    saveResidentsTable(params)
      .then(res => {
        dispatch(setShowModal(false));
        dispatch(setDealCreateData({ ...dealCreateData, json_1c: res }));
        addDefaultSnack();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (client_id) {
      getResidentsTable(client_id)
        .then(res => {
          setResidents(res);
          if (!isEmpty(dealCreateData?.json_1c)) {
            const selectedResident = res?.find(
              (el) =>
                el["Помещение_ГУИД"] ===
                dealCreateData?.json_1c["Помещение_ГУИД"]
            );
            const index = res.indexOf(selectedResident)
            setSelected(index < 0 ? null : index);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [client_id, dealCreateData]);

  return (
    <EmptyOrShow loading={loading}>
      <ResidentsTable
        residents={residents}
        setSelected={setSelected}
        selected={selected}
      />
      {residents?.length ? (
        <Box marginLeft="auto" width={180}>
          <CustomButton
            btnText="Сохранить"
            handleClick={handleClick}
            disabled={loading}
          />
        </Box>
      ) : null}
      <Guid data={dealCreateData?.json_1c} />
    </EmptyOrShow>
  );
};

const Guid = ({ data }) => {
  const prettify = () => {
    const arr = [];
    if (!isEmpty(data)) {
      for (const item in data) {
        arr.push(`${item}: ${data[item]}`);
      }
    }
    return arr;
  };
  const newData = prettify();
  return (
    <ul style={{ listStyle: "none", padding: 0 }}>
      {newData?.map((el, i) => (
        <li key={i}>{el}</li>
      ))}
    </ul>
  );
};

export default ResidentsModal;
