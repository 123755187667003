import {
  SET_AUTH,
  SET_DEAL_CREATE_DATA,
  SET_USER_DATA,
  SHOW_MODAL,
  SHOW_POPUP,
  SET_MODAL_DATA,
  SET_MAIN_CITY,
  TOGGLE_SIDEBAR,
  SET_TASKS,
  SET_CHECKLISTS,
  SET_STATUS_INFO,
  SET_BTN_STATUSES,
  TOGGLE_TASKS_PAGE,
  REFRESH_STATE,
  SET_ACTIVE_HUNTER,
  SET_HUNTERS_LIST,
  SET_HUNTERS_PLAN_PENALTY,
  SET_COEFF_LIST,
  SET_DEAL_COUNT,
  SET_DEAL_DOCS,
  SET_INIT,
  SET_REQUEST_TYPES,
} from "../actions/app-actions";
import _ from "lodash";

const initialState = {
  init: false,
  isUserAuthenticated: false,
  popup: {
    show: false,
    type: "",
    data: {},
  },
  userData: {},
  modal: {
    show: false,
    type: "",
    data: {},
  },
  dealCreateData: {},
  modalData: {},
  cityId: null,
  isOpenSidebar: false,
  tasks: [],
  checklists: [],
  statusInfo: {},
  btnStatuses: {},
  updatedDealData: {},
  isArchive: false,
  activeHunter: "",
  huntersList: { id: null, list: [] },
  crmHuntersList: [],
  requestTypes: [],
  huntersPlanPenaltyArr: [],
  coeffList: [],
  dealCount: 0,
  dealDocs: [],
};

export const appReducer = (state = initialState, { type, payload }) => {
  let newState = _.cloneDeep(state);

  switch (type) {
    case SET_INIT:
      newState.init = payload.init;
      return newState;
    case SET_AUTH:
      newState.isUserAuthenticated = payload.isUserAuthenticated;
      return newState;
    case SET_USER_DATA:
      newState.userData = payload.userData;
      return newState;
    case SHOW_POPUP:
      newState.popup = payload;
      return newState;
    case SHOW_MODAL:
      newState.modal = { ...payload };
      return newState;
    case SET_DEAL_CREATE_DATA:
      newState.dealCreateData = { ...newState.dealCreateData, ...payload.data };
      if (payload.updatedData)
        newState.updatedDealData = payload.updatedData || {};
      return newState;
    case SET_MODAL_DATA:
      newState.modalData = { ...newState.modalData, ...payload };
      return newState;
    case SET_MAIN_CITY:
      newState.cityId = payload;
      return newState;
    case TOGGLE_SIDEBAR:
      newState.isOpenSidebar = !newState.isOpenSidebar;
      return newState;
    case SET_TASKS:
      newState.tasks = payload;
      return newState;
    case SET_REQUEST_TYPES:
      newState.requestTypes = payload;
      return newState;
    case SET_CHECKLISTS:
      newState.checklists = payload;
      return newState;
    case SET_STATUS_INFO:
      newState.statusInfo = payload;
      return newState;
    case SET_BTN_STATUSES:
      newState.btnStatuses = payload;
      return newState;
    case TOGGLE_TASKS_PAGE:
      newState.isArchive = payload;
      return newState;
    case REFRESH_STATE:
      newState.dealCreateData = payload;
      newState.btnStatuses = {};
      newState.statusInfo = {};
      newState.tasks = [];
      return newState;
    case SET_ACTIVE_HUNTER:
      newState.activeHunter = payload;
      return newState;
    case SET_HUNTERS_LIST:
      newState.huntersList = payload;
      return newState;
    case SET_HUNTERS_PLAN_PENALTY:
      newState.huntersPlanPenaltyArr = payload;
      return newState;
    case SET_COEFF_LIST:
      newState.coeffList = payload;
      return newState;
    case SET_DEAL_COUNT:
      newState.dealCount = payload;
      return newState;
    case SET_DEAL_DOCS:
      newState.dealDocs = payload;
      return newState;

    default:
      return state;
  }
};
