import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../../services/redux/actions/app-actions";

import {Box, FormControl, InputLabel, NativeSelect, Select} from "@material-ui/core";

import {addMonth} from "../../../pages/salesDeptPlan/api";
import CustomButton from "../../UI/Button";
import {monthList, yearList} from "../../../utils/dates";
import NumberFormatInput from "../../UI/NumberFormatInput";
import cookiesService from "../../../services/cookiesService";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const cookiesServiceInstance = cookiesService.getService();
const AddMonthModal = ({state, setKpiList, saleDept}) => {
  const dispatch = useDispatch();
  const {position_code} = cookiesServiceInstance.getUserData();
  const [addMonthState, setAddMonthState] = useState({
    month: null,
    year: null,
    sale_department_id: null,
    sd_month_plan_sum: null,
    taxes_percentage: null
  });
  const {addDefaultSnack} = useCustomSnackbar();

  const handleChange = e => {
    setAddMonthState({
      ...addMonthState,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = () => {
    addMonth(state.year, addMonthState?.sale_department_id, addMonthState)
      .then(res => {
        setKpiList(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно добавлен");
      })
      .catch(() => {
      });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{margin: "20px 0"}}>
        {position_code === "SALE_HEAD_HEAD" ? (
          <FormControl fullWidth>
            <InputLabel htmlFor="sd-select">ОП</InputLabel>
            <NativeSelect
              value={addMonthState.sale_department_id}
              onChange={handleChange}
              inputProps={{
                name: "sale_department_id",
                id: "sd-select"
              }}
            >
              <option aria-label="" value=""/>
              {saleDept.map(el => {
                return (
                  <option
                    value={el.sale_department_id}
                    key={el.sale_department_id}
                  >
                    {el.sale_department_name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        ) : null}
        <Box
          sx={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px"}}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="select-month">Месяц</InputLabel>
            <Select
              native
              value={addMonthState.month}
              onChange={handleChange}
              inputProps={{
                name: "month",
                id: "select-month"
              }}
            >
              <option aria-label="" value=""/>
              {monthList.map(el => {
                return (
                  <option value={el.monthNumber} key={el.id}>
                    {el.month}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="select-year">Год</InputLabel>
            <Select
              native
              value={addMonthState.year}
              onChange={handleChange}
              inputProps={{
                name: "year",
                id: "select-year"
              }}
            >
              <option aria-label="" value=""/>
              {yearList.map(el => {
                return (
                  <option value={el.year} key={el.id}>
                    {el.year}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <NumberFormatInput
          name="sd_month_plan_sum"
          label="План"
          value={addMonthState.sd_month_plan_sum || ""}
          handleChange={handleChange}
        />
        <NumberFormatInput
          name="taxes_percentage"
          label="Процент налогов"
          value={addMonthState.taxes_percentage || ""}
          handleChange={handleChange}
        />
      </Box>
      <Box sx={{width: 200, marginLeft: "auto"}}>
        <CustomButton btnText="Добавить" handleClick={handleAdd}/>
      </Box>
    </Box>
  );
};

export default AddMonthModal;
