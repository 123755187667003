import React from "react";
import { LinearProgress } from "@material-ui/core";
import InCard from "./InCard";

export default function EmptyOrShow({
  loading = true,
  show = true,
  children,
  inCard = false,
  style = {}
}) {
  function render() {
    return (
      <>
        {loading ? (
          <LinearProgress color="primary" style={style} />
        ) : (
          <>{show ? <>{children}</> : null}</>
        )}
      </>
    );
  }

  if (inCard) {
    return <InCard className="mb-4">{render()}</InCard>;
  } else {
    return <>{render()}</>;
  }
}
