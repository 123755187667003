import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import HorizontalChart from "./HorizontalChart";

const useStyles = makeStyles({
  chart_row: {
    width: "100%",
    height: 50,
    borderBottom: "1px solid #E2E3ED",
    marginBottom: 25
  },
  chart_item: {
    width: "100%"
  },
  single_item: {
    width: props => (props.width ? `calc(${props.width + "%"} - 77px)` : ""),
    backgroundColor: props => props.bg,
    height: 45,
    border: "1px solid #E2E3ED",
    animation: `$test 1s cubic-bezier(.31,.51,.16,.85)`
  },
  "@keyframes test": {
    "0%": {
      width: 0
    },
    "100%": {
      width: props => (props.width ? `calc(${props.width + "%"} - 77px)` : "")
    }
  },
  chart_day: {
    width: "50%"
  },
  chart_percent: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "170%",
    letterSpacing: "1px",
    color: "#72768F",
    marginRight: 12
  },
  chart_row_item: {
    width: "50%"
  }
});

const bgColors = [
  "#FAFAFE",
  "#FAFAFE",
  "#E4E7FF",
  "#F1E9FA",
  "#F9F3D3",
  "#E9FAF5",
  "#FFEFF4"
];
const SingleChart = ({
  size = "",
  days = [],
  rowIndex,
  convertedHours = []
}) => {
  const classes = useStyles({
    width: size,
    bg: bgColors[rowIndex]
  });

  return (
    <Box className={classes.chart_row}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ height: "100%" }}
      >
        <Box className={classes.chart_day}>
          <HorizontalChart
            days={days}
            rowIndex={rowIndex}
            convertedHours={convertedHours}
          />
        </Box>
        <Box
          display="flex"
          position="relative"
          className={classes.chart_row_item}
          justifyContent="flex-end"
        >
          <Box
            className={classes.chart_item}
            display="flex"
            justifyContent="flex-end"
          >
            <Box className={classes.chart_percent}>{size}%</Box>
            <Box className={classes.single_item} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleChart;
