import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { Box, Tooltip } from "@material-ui/core";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}
const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 11,
    backgroundColor: "#383838",
    padding: "5px 10px",
  },
}))(Tooltip);
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


export default function LinearWithValueLabel({
  progress,
  title,
  color,
  tooltip,
}) {
  // const classes = useStyles();
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 7,
      borderRadius: 2,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 2,
      backgroundColor: color,
    },
  }))(LinearProgress);

  return (
    <CustomTooltip title={tooltip && tooltip != 0 ? tooltip : ""}>
      <Box>
        {title ? (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: "black" }}
          >
            {title}
          </Typography>
        ) : (
          ""
        )}
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress
              variant="determinate"
              value={progress > 100 ? 100 : progress}
            />
          </Box>
          <Box minWidth={35}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ whiteSpace: "nowrap" }}
            >{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Box>
      </Box>
    </CustomTooltip>
  );
}
