import { makeStyles } from "@material-ui/core/styles";

const globalUseStyles = makeStyles({
  deals_main_title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    color: "#494C62",
    margin: "0 0 15px 0"
  },
  btn_disable: {
    pointerEvents: "none",
    cursor: "default"
  },
  btn_grey: {
    backgroundColor: "#9194a9 !important"
  },
  btn_green: {
    backgroundColor: "#77bf6c !important",
    color: "#fff",
    fontWeight: 600
  },
  disable_block: {
    // pointerEvents: "none",
    userSelect: "none",
    position: "relative",
    cursor: "not-allowed",

    "&::before": {
      content: '""',
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      borderRadius: 4,
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: "50"
    }
  }
});

export default globalUseStyles;
