import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import EmptyOrShow from "../../EmptyOrShow";
import { FileUpload, PlusDefIcon } from "../Icons";

const useStyles = makeStyles({
  dropzone: {
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: "transparent",
    padding: 5
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194a9",
    margin: "0 0 10px 0"
  },
  content: {
    borderBottom: "1px solid #f0f2f6",
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "100%",
    paddingBottom: 5,
    cursor: "pointer"
  },
  content_title: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194a9",
    display: "flex",
    alignItems: "center",
    flex: 1,
    height: 20,
    margin: 0
  }
});

export default function UploadFile({
  name,
  title = "",
  label = "",
  accept = "",
  onChange,
  files = "",
  children,
  withCard = true,
  multiple = null,
  fileName = "",
  isUploading = false,
  getRootProps,
  getInputProps,
  isDragAccept
}) {
  const classes = useStyles();

  const acceptStyle = {
    borderColor: "#6191EF"
  };

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {})
    }),
    [isDragAccept]
  );

  return (
    <Box {...getRootProps({ style })} className={classes.dropzone}>
      <input
        {...getInputProps()}
        // style={{ width: 0, display: "none" }}
        name={name}
        // id={"upload-file-" + name}
        // type="file"
        // accept={accept}
        onChange={e =>
          onChange({
            target: { name, value: e.target.files }
          })
        }
      />
      <Box className={classes.label}>{title}</Box>
      <Box className={classes.content}>
        <FileUpload width={20} height={20} />
        <Typography className={classes.content_title}>
          {fileName ? fileName : "Загрузите документ"}
        </Typography>
        <PlusDefIcon width={20} height={20} />
      </Box>
      {isUploading ? (
        <Box>
          <EmptyOrShow loading={isUploading} />
        </Box>
      ) : null}
    </Box>
  );
}
