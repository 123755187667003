import React, { useEffect, useState } from "react";
import {
	setShowModal,
	setStatusInfo,
	setTasks
} from "../../../services/redux/actions/app-actions";

import { makeStyles } from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import { Box, Button } from "@material-ui/core";

import { createDealTask, getDealStatuses, getDealTasks } from "./api";
import TaskTabItem from "./TaskTabItem";
import styleConstants from "../../../constants/styleConstants";
import { PlusIcon } from "../../../components/UI/Icons";
import { useDispatch, useSelector } from "react-redux";
import { MODALS } from "../../../constants";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { ReactComponent as EmptyTasks } from "../../../assets/images/icons/emptyTasks.svg";
import EmptyOrShow from "../../../components/EmptyOrShow";

const useStyles = makeStyles(theme => ({
	tasks: {
		backgroundColor: "#fff",
		padding: 10,
		borderRadius: 4,
		width: "100%",
		minWidth: 445,
		height: "100%",
		gridArea: "1 / 4 / 3 / 5",

		"&::-webkit-scrollbar": {
			width: 7
		},

		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#6191EF",
			borderRadius: 4
		},

		[theme.breakpoints.down(1300)]: {
			gridArea: "2 / 2 / 3 / 4"
		},

		[theme.breakpoints.down(850)]: {
			gridArea: "auto"
		}
	},
	tasks_items: {
		width: "100%",
		height: 708,
		overflow: "auto",
		paddingRight: 10,
		scrollbarColor: "#6191EF #fff",
		scrollbarWidth: "thin",

		[theme.breakpoints.down(1300)]: {
			height: 509
		},

		"&::-webkit-scrollbar": {
			width: 7
		},

		"&::-webkit-scrollbar-thumb": {
			backgroundColor: styleConstants.primaryColor,
			borderRadius: styleConstants.borderRadius
		}
	},
	add_task: {
		color: styleConstants.primaryColor,
		fontSize: 14,
		fontWeight: 600
	},
	emptyTasks: {
		fontSize: 16,
		fontWeight: 500,
		color: "#9194A9",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		height: "100%",
		paddingTop: "80px",

		"& svg": {
			width: 150,
			height: 150
		}
	}
}));

const DealRightForm = () => {
	const classes = useStyles();
	const globalClasses = globalUseStyles();
	const { crm_deal_id } = useSelector(state => state.appState.dealCreateData);
	const { tasks } = useSelector(state => state.appState);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const handleSave = params => {
		createDealTask(crm_deal_id, params)
			.then(res => {
				dispatch(setTasks(res));
				enqueueSnackbar("Задача успешно создана", {
					variant: "success"
				});

				getDealStatuses(crm_deal_id)
					.then(res => {
						dispatch(setStatusInfo(res));
					})
					.catch(() => {
					});
				dispatch(setShowModal(false));
			})
			.catch(err => {
				enqueueSnackbar(err.response.data.error, {
					variant: "error"
				});
			});
	};

	const handleAdd = () => {
		dispatch(
			setShowModal(true, MODALS.addTask, {
				title: "Создать новую задачу",
				handleSave
			})
		);
	};

	useEffect(() => {
		if (crm_deal_id) {
			setLoading(true);
			getDealTasks(crm_deal_id).then(res => {
				dispatch(setTasks(res));
			}).finally(() => {
				setLoading(false);
			});
		}
	}, [crm_deal_id, dispatch]);

	return (
		<Box
			className={clsx(classes.tasks, {
				[globalClasses.disable_block]: !crm_deal_id
			})}
		>
			<EmptyOrShow loading={!crm_deal_id ? false : loading}>
				<Box display="flex" justifyContent="space-between" alignItems="baseline">
					<h4 className={globalClasses.deals_main_title}>Текущие задачи</h4>
					<Button
						className={classes.add_task}
						endIcon={<PlusIcon />}
						onClick={handleAdd}
					>
						Добавить задачу
					</Button>
				</Box>
				<Box className={classes.tasks_items}>
					{tasks?.length <= 0 ? (
						<Box className={classes.emptyTasks}>
							<EmptyTasks />
							У вас еще нет задач
						</Box>
					) : (
						<>
							{tasks?.map(el => {
								return (
									<TaskTabItem
										key={el.crm_deal_task_id}
										{...el}
										setTasks={setTasks}
										crm_deal_id={crm_deal_id}
									/>
								);
							})}
						</>
					)}
				</Box>
			</EmptyOrShow>
		</Box>
	);
};

export default DealRightForm;
