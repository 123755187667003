import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { refreshState } from "../../../services/redux/actions/app-actions";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, IconButton, Tab, Tabs } from "@material-ui/core";

import useClientApi from "./api";
import DealView from "../../deals/view";
import clsx from "clsx";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
  tabs_wrapper: {
    color: "#494C62 !important",
    backgroundColor: "#fff !important",
    boxShadow: "unset !important",

    "& .MuiTabs-root": {
      minHeight: 30
    },

    "& .MuiTab-root": {
      fontSize: 12,
      fontWeight: 600,
      minHeight: "auto"
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#6681F7",
      height: 3
    }
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#6681F7"
    },
    "& button:hover": {
      color: "#6681F7"
    }
  },
  tab_panel: {
    padding: "10px 11px"
  },
  disable_tab: {
    pointerEvents: "none"
  },
  tab_content_wrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "10px"
  },
  active_tab: {
    color: "#fff",
    backgroundColor: "#6681f7",

    "& svg": {
      color: "#fff !important"
    }
  },
  tab_count: {
    fontSize: 10,
    color: "#fff",
    backgroundColor: "#FF4754",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    margin: 0
  },
  deal_wrapper: {
    border: "1px solid #6681f7",
    borderRadius: 4,
    height: 35,
    transition: "all 0.3s ease",

    "&:hover": {
      color: "#fff",
      backgroundColor: "#6681f7",

      "& svg": {
        color: "#fff"
      }
    }
  },
  deal: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    height: "100%",
    padding: "0 14px",
    cursor: "pointer"
  },
  new_deal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",

    "& div": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      padding: "0 10px 0 14px",
      cursor: "pointer"
    },

    "& button": {
      color: "#fff",
      marginRight: 10,

      "&:hover": {
        backgroundColor: "#6681f7",
        "& svg": {
          color: "#fff"
        }
      },

      "& svg": {
        color: "#6681f7"
      }
    }
  },
  tab_icon: {
    border: "1px solid #6681f7",
    backgroundColor: "#E5E5E5",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    cursor: "pointer",
    transition: "all 0.3s ease",

    "& svg": {
      color: "#6681f7",
      width: 25,
      height: 25
    },

    "&:hover": {
      backgroundColor: "#6681f7",
      "& svg": {
        color: "#fff"
      }
    }
  }
});

const clientInfoFields = [
  "client_fio",
  "iin",
  "client_id",
  "is_investor",
  "is_vip",
  "phone_number",
  "email"
];

export default function ClientTabs({ setClientLoading }) {
  const { getClientTabs } = useClientApi();
  const params = useParams();
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [tabsData, setTabsData] = useState([]);
  const [dealId, setDealId] = useState(null);
  const [btnsDisable, setBtnsDisable] = useState(true);

  const dispatch = useDispatch();
  const { dealCreateData } = useSelector(state => state.appState);

  const makeBtnsDisable = useCallback((value) => {
    setBtnsDisable(value)
  }, [])

  const fetchTabs = (isNew = false) => {
    getClientTabs(params.id)
      .then(res => {
        setTabsData(res.data);
        if (isNew) {
          setDealId(
            res.data[0].deals[res.data[0].deals.length - 1].crm_deal_id
          );
        } else {
          const crmDealId =
            res.data[value].deals[0] === undefined
              ? ""
              : res.data[value].deals[0].crm_deal_id;
          setDealId(crmDealId);
        }
      })
      .finally(() => {
        setBtnsDisable(false);
      });
  };

  useEffect(() => {
    fetchTabs();
  }, []);

  useEffect(() => {
    if (tabsData.length && value !== 0) {
      const crmDealId =
        tabsData[value]?.deals[0] === undefined
          ? ""
          : tabsData[value]?.deals[0].crm_deal_id;
      setDealId(crmDealId);
    }
    if (tabsData[value]?.deals.length !== 0) {
      setDealId(tabsData[value]?.deals[0].crm_deal_id);
    }
  }, [value]);

  const resetState = () => {
    const obj = {};
    clientInfoFields.forEach(el => {
      obj[el] = dealCreateData[el];
    });

    dispatch(refreshState(obj));
  };

  const handleClick = id => {
    setDealId(id);
    if (!id) {
      resetState();
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className={classes.tab_panel}
      >
        {value === index && (
          <Box
            className={clsx(classes.tab_content_wrapper, {
              [classes.disable_tab]: btnsDisable
            })}
          >
            {children}
          </Box>
        )}
      </Box>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleAddDeal = () => {
    resetState();
    setDealId(null);
    const tabs = [...tabsData];
    tabs[0].deals.push({
      tasks_num: null,
      crm_deal_id: null
    });
    setTabsData(tabs);
  };

  const handleRemoveDeal = () => {
    const tabs = [...tabsData];
    tabs[0].deals.pop();
    setTabsData(tabs);
    setDealId(tabsData[0].deals[0].crm_deal_id);
  };

  return (
    <>
      <AppBar position="static" className={classes.tabs_wrapper}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabs}
        >
          {tabsData.map((el, i) => {
            return (
              <Tab
                key={el.status_code}
                label={`${el.status_name} (${el.deals.length})`}
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabsData.map((el, i) => {
        return (
          <TabPanel value={value} index={i} key={el.status_code}>
            {el.deals.map(deal => {
              return (
                <Box key={deal.crm_deal_id} className={classes.deal_wrapper}>
                  {deal.crm_deal_id ? (
                    <Box
                      className={clsx(classes.deal, {
                        [classes.active_tab]: deal.crm_deal_id === dealId
                      })}
                      onClick={() => {
                        handleClick(deal.crm_deal_id);
                      }}
                    >
                      <Box>{deal.crm_deal_id}</Box>
                      {deal.tasks_num <= 0 ? null : (
                        <p className={classes.tab_count}>{deal.tasks_num}</p>
                      )}
                    </Box>
                  ) : (
                    <Box
                      className={clsx(classes.new_deal, {
                        [classes.active_tab]: deal.crm_deal_id === dealId
                      })}
                    >
                      <Box
                        onClick={() => {
                          handleClick(deal.crm_deal_id);
                        }}
                      >
                        Новая сделка
                      </Box>
                      <IconButton size="small" onClick={handleRemoveDeal}>
                        <ClearIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            })}
            {value === 0 ? (
              <button className={classes.tab_icon} onClick={handleAddDeal}>
                <AddIcon />
              </button>
            ) : null}
          </TabPanel>
        );
      })}
      {tabsData[value]?.deals.length ? (
        <DealView
          isClient={true}
          crm_deal_id={dealId}
          setClientLoading={setClientLoading}
          setBtnsDisable={makeBtnsDisable}
          fetchTabs={fetchTabs}
          setDealId={setDealId}
        />
      ) : null}
    </>
  );
}
