import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";

import {getHuntersReports} from "./api";
import NumberFormat from "react-number-format";
import Progress from "components/UI/Progress";
import Layout from "../../components/layout";
import Filters from "./Filters";
import styleConstants from "../../constants/styleConstants";
import EmptyOrShow from "../../components/EmptyOrShow";
import Search from "../../components/UI/Search";
import cookiesService from "../../services/cookiesService";
import {LOCALSTORAGEFIELDS} from "../../constants";
import {HuntersAccordion} from "./accordion";

const cookiesServiceInstance = cookiesService.getService();
const HuntersKpi = () => {
  const classes = useHuntersStyles();
  const monthFromLocal = localStorage.getItem(LOCALSTORAGEFIELDS.month);
  const {position_code, employee_id} = cookiesServiceInstance.getUserData();

  const [search, setSearch] = useState("");
  const [stateSum, setStateSum] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    year: new Date().getFullYear().toString(),
    month: monthFromLocal
      ? monthFromLocal
      : (new Date().getMonth() + 1).toString(),
    sale_department_id: position_code === "SALE_HEAD_HEAD" ? "" : 1
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGEFIELDS.month, filters.month);
  }, [filters.month]);

  const handleSearchChange = useCallback(() => {
    if (!search) return setFilteredData(data)
    setFilteredData(sortData(data, search));
  }, [search, data])

  useEffect(() => {
    const timeout = setTimeout(() => handleSearchChange(), 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [handleSearchChange]);

  useEffect(() => {
    setStateSum(calcSum(filteredData));
  }, [filteredData]);

  useEffect(() => {
    setLoading(true);
    getHuntersReports(filters)
      .then(res => {
        setData(res);
      })
      .catch(() => {
      })
      .finally(() => setLoading(false));
  }, [filters]);

  const handleChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };
  return (
    <Layout title="KPI хантеров">
      <Box
        sx={{
          padding: "45px 20px 20px"
        }}
      >
        <Filters state={filters} handleChange={handleChange}/>

        {position_code === "SALE_HEAD_HEAD" || position_code === "SALE_HEAD" ? (
          <HuntersAccordion filters={filters}/>
        ) : null}
        <div style={{marginBottom: "25px", width: "250px"}}>
          <Search
            placeholder={"Поиск"}
            handleChange={handleSearch}
            value={search}
          />
        </div>
        <EmptyOrShow loading={loading}>
          <TableContainer
            className={classes.table_container}
            style={
              position_code !== "SALE_HEAD_HEAD" ? {maxHeight: "80vh"} : {}
            }
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className={classes.table_head}>
                <TableRow>
                  {headTitle.map(el => {
                    return (
                      <TableCell key={el} style={{fontWeight: "bold"}}>
                        {el}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table_body}>
                {filteredData.map(e => {
                  return (
                    <TableRow
                      key={e.employee_id}
                      className={!e.with_plan ? classes.table_item_false : ""}
                    >
                      <TableCell align="left">
                        {e.sale_department_name}
                      </TableCell>
                      <TableCell align="left">
                        {e.employee_id === employee_id ||
                        position_code !== "SELLER" ? (
                          <Link to={`/hunter/${e.employee_id}`} target="_blank">
                            {e.fio}
                          </Link>
                        ) : (
                          e.fio
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.hunter_month_plan_sum}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.fact_opt}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.fact_ddu}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Progress
                          progress={e.percentage}
                          title="План"
                          color={e.percentage_color_code}
                        />
                        <Progress
                          progress={e.contract_percentage}
                          title="+ договор"
                          color={e.contract_percentage_color_code}
                          tooltip={
                            <NumberFormat
                              value={e?.fact2_opt}
                              displayType="text"
                              thousandSeparator={" "}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.left_sum}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.bonus_opt}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.penalty_total_sum}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.salary_n_bonuses}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={e?.total}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.table_footer}>
                  <TableCell style={{fontWeight: "bold"}}>Итого</TableCell>
                  <TableCell/>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.hunter_month_plan_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.fact_opt}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.fact_ddu}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell/>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.left_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.bonus_opt}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.penalty_total_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.salary_n_bonuses}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum?.total}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Layout>
  );
};

export default HuntersKpi;

const sortData = (data, search = '') => {
  return data.filter(el => el?.fio?.toLowerCase().includes(search.toLowerCase()) ||
    el?.hunter_month_plan_sum
      ?.toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.left_sum
      ?.toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.total
      ?.toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.fact_ddu
      ?.toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()) ||
    el?.salary_n_bonuses
      ?.toString()
      ?.toLowerCase()
      .includes(search.toLowerCase()))
}

const calcSum = (filteredData) => {
  return {
    bonus_opt: filteredData?.reduce((a, b) => a + b.bonus_opt, 0),
    fact2_opt: filteredData?.reduce((a, b) => a + b.fact2_opt, 0),
    fact_ddu: filteredData?.reduce((a, b) => a + b.fact_ddu, 0),
    fact_opt: filteredData?.reduce((a, b) => a + b.fact_opt, 0),
    hunter_month_plan_sum: filteredData?.reduce(
      (a, b) => a + b.hunter_month_plan_sum,
      0
    ),
    left_sum: filteredData?.reduce((a, b) => a + b.left_sum, 0),
    penalty_total_sum: filteredData?.reduce((a, b) => a + b.penalty_total_sum, 0),
    salary_n_bonuses: filteredData?.reduce((a, b) => a + b.salary_n_bonuses, 0),
    total: filteredData?.reduce((a, b) => a + b.total, 0)
  }
}
const headTitle = [
  "Отдел продаж",
  "Хантер",
  "План",
  "Факт опционалки",
  "Факт ДДУ",
  "% выполнения плана",
  "Остаток плана",
  "Бонус опционалки",
  "Штраф",
  "Бонус + оклад",
  "К выплате (примерно)"
];

export const useHuntersStyles = makeStyles({
  table_container: {
    "@media (max-width: 1024px)": {
      maxHeight: "44vh"
    },
    "@media (min-width: 1440px)": {
      maxHeight: "55vh"
    },

    "@media (min-width: 2000px)": {
      maxHeight: "62vh"
    },
    "@media (min-width: 2200px)": {
      maxHeight: "68vh"
    },
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "#fff",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table_footer: {
    "& td": {
      backgroundColor: "#fff",
      borderTop: "1px solid #f0f2f6 !important",
      fontWeight: "bold",
      color: "black"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  },
  table_item_false: {
    "& td:nth-child(1)": {
      borderLeft: "0.25rem solid rgba(220, 53, 69, 0.5) !important"
    }
  },

  table_head: {
    background: "white",
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      background: "white"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: props => props.hover === false ? '' : "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "1px solid #ccc !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  }
});
