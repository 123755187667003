import {Box} from "@material-ui/core";
import NumberFormat from "react-number-format";
import React, {useEffect, useState} from "react";
import {useNavStyles} from "./Navbar";
import {getKpiInfo} from "./api";
import cookiesService from "../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();
export const SellerSide = () => {
  const classes = useNavStyles();
  const [kpiInfo, setKpiInfo] = useState({});

  const userData = cookiesServiceInstance.getUserData();
  const {position_code} = userData

  useEffect(() => {
    if (position_code === "SELLER")
      getKpiInfo().then(res => {
        setKpiInfo(res);
      });
  }, [position_code]);

  if (position_code !== "SELLER") return null

  return <Box className={classes.kpi_info}>
    <Box className={classes.kpi_info_plan}>
      <p>План:</p>
      <span>
        <NumberFormat
          value={kpiInfo?.plan_sum}
          thousandSeparator={" "}
          displayType="text"
        />
      </span>
    </Box>
    <Box className={classes.kpi_info_percent}>
      <p>Процент:</p>
      <span>
                    <NumberFormat
                      value={kpiInfo?.percentage}
                      thousandSeparator={" "}
                      displayType="text"
                    />
                  </span>
    </Box>
    <Box className={classes.kpi_info_total}>
      <p>Общая сумма:</p>
      <span>
                    <NumberFormat
                      value={kpiInfo?.total}
                      thousandSeparator={" "}
                      displayType="text"
                    />
                  </span>
    </Box>
  </Box>
}