import instance from "api";
import { handleError } from "api/helpers";

export const changeClientInfo = (id, params) => {
  return instance(true)
    .put(`/crm/client/${id}/`, params)
    .then(res => {
      return res?.data;
    })
    .catch((err) => {
      return handleError(err);
    });
};

const useClientApi = () => {
  const getClientInfo = client_id =>
    instance().get(`/crm/client/${client_id}`);
  const getClientTabs = client_id =>
    instance().get(`/crm/client/${client_id}/tabs/`);
  return {
    getClientInfo,
    getClientTabs
  };
};

export default useClientApi;
