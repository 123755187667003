import React from "react";

import { TableCell, TableRow } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {Link} from "react-router-dom";

export const TableDetailRowComp = ({ crm_deal_id, fact_sum, client_fio, hunter_fio, resident_name,
                        flat_num,
                        prop_date }) => {
  return (
    <TableRow>
      <TableCell><Link target={'_blank'} to={`/deal/${crm_deal_id}`}>{crm_deal_id}</Link></TableCell>
      <TableCell>{client_fio}</TableCell>
      <TableCell>{hunter_fio}</TableCell>
      <TableCell>
        <NumberFormat
          value={fact_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell>{resident_name}</TableCell>
      <TableCell>{flat_num}</TableCell>
      <TableCell>{prop_date}</TableCell>
    </TableRow>
  );
};

