import React from "react";

import {TableCell, TableRow, Tooltip} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {withStyles} from "@material-ui/core/styles";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 11,
    backgroundColor: "#383838",
    padding: "5px 10px"
  }
}))(Tooltip);

const TableRowComp = ({
                        crm_deal_id,
                        client_request_main_id,
                        contract_sum,
                        fact_sum,
                        flat_num,
                        resident_name,
                        remont_type_name,
                        prop_number,
                        prop_date,
                        hunter_contract_sum,
                        hunters_cnt,
                        diff,
                        is_contract
                      }) => {
  const isContract = {
    backgroundColor: is_contract ? "#d3d3d36e" : "#fff"
  };

  return (
    <TableRow>
      <TableCell
        style={{
          borderLeft: diff ? "0.25rem solid #ff9800" : "none",
          backgroundColor: is_contract ? "#d3d3d36e" : "#fff"
        }}
      >
        <CustomTooltip title={`Перейти к сделке №${crm_deal_id}`}>
          <a
            href={`/deal/${crm_deal_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {crm_deal_id}
          </a>
        </CustomTooltip>
      </TableCell>
      <TableCell style={isContract}>{client_request_main_id}</TableCell>
      <TableCell style={isContract}>{resident_name}</TableCell>
      <TableCell style={isContract}>{flat_num}</TableCell>
      <TableCell style={isContract}>{remont_type_name}</TableCell>
      <TableCell style={isContract}>{prop_number}</TableCell>
      <TableCell style={isContract}>{prop_date}</TableCell>
      <TableCell style={isContract}>
        <NumberFormat
          value={contract_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell style={isContract}>{hunters_cnt}</TableCell>
      <TableCell style={isContract}>
        <NumberFormat
          value={hunter_contract_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell style={isContract}>
        <NumberFormat
          value={fact_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
