import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={" "}
      isNumericString
    />
  );
});

const NumberFormatInput = ({
  name,
  label,
  value,
  handleChange,
  shrink = false
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      fullWidth
      name={name}
      InputLabelProps={shrink === true ? { shrink: true } : {}}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
      variant="standard"
    />
  );
};

export default NumberFormatInput;
