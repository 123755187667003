const styleConstants = {
    borderRadius:4,
    primaryColor:'#6191EF',
    darkGray:'#9194A9',
    textColor:'#494C62',
    border:'1px solid #E2E3ED',
    iconColor:'#72768F',
    subtitle:'#A5A7B5',
    errorColor:'#e81b1b',
}

export default styleConstants

