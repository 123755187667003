import React, {useEffect, useState} from "react";

import Button from "@material-ui/core/Button";

import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {doLogIn} from "./api";
import {useDispatch, useSelector} from "react-redux";
import {setAuth, setUserData} from "../../services/redux/actions/app-actions";
import {PAGES} from "../../constants";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import DeviceDetector from "device-detector-js";
import {Link} from "react-router-dom";
import {Logo} from "../../components/UI/Logo";
import {Redirect} from "react-router";
import cookiesService from "../../services/cookiesService";
import {LoaderData} from "../../components/loaderData";

export const useLoginStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: 269, // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    height: "auto !important"
  },
  submit: {
    margin: "20px 0 10px",
    background: "linear-gradient(104.82deg, #0C4B86 -17.14%, #E42734 83.14%)",
    height: 50,
    fontSize: 18
  },
  link: {
    color: "rgba(12, 75, 134, 1)",
    fontSize: 16,
    lineHeight: "19px",
    display: "block",
    textAlign: "center",
    marginBottom: 15
  },
  logo: {
    maxWidth: "100%",
    height: "auto"
  },
  forget: {
    color: "#3b4374",
    fontWeight: 700,
    fontSize: "15px",
    marginTop: "5px",
    '&:hover': {
      textDecoration: "none"
    }
  }
}));

const deviceDetector = new DeviceDetector();
const cookiesServiceInstance = cookiesService.getService();
export const LogInPage = ({history}) => {
  const classes = useLoginStyles();
  const dispatch = useDispatch();

  const [loginBody, setLoginBody] = useState({
    login: '',
    password: '',
    is_mobile: false,
    os: '',
    user_agent: ''
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {isUserAuthenticated} = useSelector(state => state.appState);

  const singIn = async () => {
    setLoading(true);
    await doLogIn(loginBody)
      .then(res => {
        setLoading(false);
        if (res) {
          dispatch(setUserData(res.user));
          dispatch(setAuth(true));
          cookiesServiceInstance.setLoginData(res);
          history.push(PAGES.deals);
        }
      })
      .catch(() => {
        setLoading(false);
      })
  };

  useEffect(() => {
    let data = {}
    const device = deviceDetector.parse(window.navigator.userAgent);
    if (device) {
      data.os = device.os.name
      data.user_agent = device.client.name
    }
    data.is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent
    )
    setLoginBody(prev => ({...prev, ...data}))
  }, []);

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  const handleChange = (key, value) => {
    setLoginBody(prev => ({...prev, [key]: value}))
  }
  if (isUserAuthenticated) return <Redirect to={PAGES.main}/>;
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{backgroundColor: "#fff", textAlign: "center"}}
    >
      <div className={classes.paper}>
        <div style={{width: 269, marginBottom: 50}}>
          <Logo alt="" size={'large'} className={classes.logo}/>
        </div>

        <form className={classes.form}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={loginBody.login || ''}
            style={{color: "#818181", fontSize: 18}}
            onChange={e => handleChange('login', e.target?.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type={showPassword ? "text" : "password"}
            id="password"
            value={loginBody.password}
            autoComplete="current-password"
            onChange={e => handleChange('password', e.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{justifyContent: "center"}}
          >
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={singIn}
              disabled={loading}
            >
              {loading ? <LoaderData small/> : 'Войти'}
            </Button>
          </div>
          <Link
            to={{
              pathname: "/reset_password",
              state: {email: loginBody.login}
            }} className={classes.forget}
          >
            Забыли пароль?
          </Link>
        </form>
      </div>
    </Container>
  );
};
