import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import InputText from "components/Form/InputText";
import CustomButton from "components/UI/Button";
import {makeStyles} from "@material-ui/core/styles";
import {setShowModal, setStatusInfo, setTasks} from "../../../services/redux/actions/app-actions";
import {callComplete, getDealStatuses} from "../../../pages/deals/view/api";
import {useSnackbar} from "notistack";
import EmptyOrShow from "../../EmptyOrShow";

const useStyles = makeStyles({
  form: {
    marginTop: "0 !important"
  },
  inputs: {
    // display: "grid",
    // gridTemplateColumns: "repeat(6, 1fr)",
    // gridTemplateRows: "repeat(3, 1fr)",
    // gap: 30
  },
  select_type: {
    gridArea: "1/1/2/7"
  },
  datePicker: {
    gridArea: "2/1/3/4",

    "& button": {
      padding: "2px !important"
    }
  },
  timePicker: {
    gridArea: "2/4/3/7"
  },
  comment: {
    gridArea: "3/1/4/7",

    "& label": {
      fontSize: 15,
      fontWeight: 600,
      color: "#9194A9",
      transform: "unset !important"
    }
  },
  btn_save: {
    marginTop: 5,
    textAlign: "right",

    "& button": {
      marginTop: "0 !important",
      width: "100% !important"
    }
  },
  btn_save_current: {
    "& button": {
      backgroundColor: "rgb(119, 191, 108) !important"
    }
  }
});

export default function CallCenterCheckModal({ initialValues = {} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { statusInfo } = useSelector(state => state.appState);
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const list = statusInfo?.cc_statuses || [];

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const params = {
    crm_deal_id: initialValues.crm_deal_id,
    crm_deal_task_id: initialValues.crm_deal_task_id,
    comment
  };

  const handleEndTaskCc = (params) => {
    setLoading(true)
    callComplete(params.crm_deal_id, params.crm_deal_task_id, { comment: params.comment, crm_status_id: null })
      .then(res => {
        setLoading(false)
        dispatch(setTasks(res));
        enqueueSnackbar("Задача успешно завершена", {
          variant: "success"
        });
        getDealStatuses(params.crm_deal_id).then(res => {
          dispatch(setStatusInfo(res));
        });
        dispatch(setShowModal(false));
      })
      .catch(err => {
        setLoading(false)
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  };

  const handleStatusClick = item => {
    setLoading(true)
    callComplete(params.crm_deal_id, params.crm_deal_task_id, { comment: params.comment, crm_status_id: item.crm_status_id })
      .then(res => {
        setLoading(false)
        dispatch(setTasks(res));
        enqueueSnackbar("Задача успешно завершена", {
          variant: "success"
        });
        getDealStatuses(params.crm_deal_id).then(res => {
          dispatch(setStatusInfo(res));
        });
        dispatch(setShowModal(false));
      })
      .catch(err => {
        setLoading(false)
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  };

  return (
    <EmptyOrShow loading={loading}>
      <form className={classes.form}>
        <div className={classes.inputs}>
          <InputText
            name="comment"
            label="Комментарий"
            placeholder="Введите комментарий"
            value={comment}
            className={classes.comment}
            onChange={handleChange}
          />
        </div>

        {
          list.map(i => {
            return (
              <div className={classes.btn_save} key={i.crm_status_id}>
                <CustomButton disabled={loading}
                  btnText={i.crm_status_name}
                  handleClick={() => handleStatusClick(i)}
                />
              </div>
            );
          })
        }

        <div className={`${classes.btn_save} ${classes.btn_save_current}`}>
          <CustomButton disabled={loading}
            btnText="Завершить и оставить в текущем статусе"
            handleClick={() => handleEndTaskCc(params)}
          />
        </div>
      </form>
    </EmptyOrShow>
  );
}
