import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, TableCell, Typography } from "@material-ui/core";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

import { getTechnics } from "../../../pages/deals/view/api";
import { MAIN_URL } from "../../../constants";
import EmptyOrShow from "../../EmptyOrShow";

const setTechnicsTitle = ["Название набора", "Количество", "Сумма"];
const technicsTitle = ["ID", "Фото", "Наименование", "Количество", "Сумма"];

const useStyles = makeStyles({
  technics: {
    width: 690
  },
  main_title: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    marginBottom: 10
  },
  block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4,

    "&:not(:last-child)": {
      marginBottom: 25
    }
  },
  productName: {
    whiteSpace: "nowrap",
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default"
  },
  table_img: {
    display: "block",
    maxWidth: 100,
    objectFit: "contain"
  }
});

export default function TechnicsModal({ crm_deal_id }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { technique, set } = data;

  useEffect(() => {
    if (crm_deal_id) {
      setLoading(true);
      getTechnics(crm_deal_id)
        .then(res => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [crm_deal_id]);

  const getImgSrc = photo_title_url => {
    if (photo_title_url?.includes("http")) {
      return photo_title_url;
    }
    return MAIN_URL + photo_title_url;
  };

  return (
    <Box className={classes.technics}>
      <EmptyOrShow loading={loading}>
        <Typography className={classes.main_title}>Наборы техники</Typography>
        <Box className={classes.block}>
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHeadComp data={setTechnicsTitle} />
              {technique ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {technique?.product_set_name}
                    </TableCell>
                    <TableCell align="center">
                      {technique?.product_set_cnt}
                    </TableCell>
                    <TableCell align="center">
                      {technique?.product_set_price}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </Box>
        <Typography className={classes.main_title}>Техника</Typography>
        <Box className={classes.block}>
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHeadComp data={technicsTitle} />
              <TableBody>
                {set?.map(el => {
                  return (
                    <TableRow key={el.client_request_id}>
                      <TableCell align="center">
                        {el.client_product_id}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={getImgSrc(el.photo_title_url)}
                          className={classes.table_img}
                          alt="tech_img"
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.productName}
                        title={el.product_name}
                      >
                        {el.product_name}
                      </TableCell>
                      <TableCell align="center">{el.product_cnt}</TableCell>
                      <TableCell align="center">{el.product_price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </EmptyOrShow>
    </Box>
  );
}
