import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapper: {
    width: props => props.width,
    border: props => `1px solid ${props.color}`,
    height: props => props.height,
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  }
});

const CustomIconButton = ({
  color,
  width,
  height,
  children,
  style = {},
  handleClick = () => {}
}) => {
  const props = {
    color,
    width,
    height
  };
  const classes = useStyles(props);
  return (
    <Box className={classes.wrapper} style={style} onClick={handleClick}>
      {children}
    </Box>
  );
};

export default CustomIconButton;
