import React from "react";
import DealView from "../view";
import { useParams } from "react-router";

const DealRoot = () => {
  const params = useParams();
  return <DealView crm_deal_id={params.id} />;
};

export default DealRoot;
