import React, {useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, TextareaAutosize} from "@material-ui/core";
import CustomButton from "../../UI/Button";
import {addPenalty} from "../../../pages/huntersPlan/api";
import {setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import NumberFormatInput from "../../UI/NumberFormatInput";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  comment: {
    color: "#494C62",
    border: "none",
    borderRadius: 4,
    outline: "none",
    resize: "none",
    width: "100%",
    marginBottom: 10,
    padding: 10
  }
});

const AddPenalty = ({id, state, getHuntersFunc, fio}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [penaltyState, setPenaltyState] = useState({
    penalty_sum: null,
    comments: ""
  });
  const {addDefaultSnack} = useCustomSnackbar();

  const handleChange = e => {
    setPenaltyState({...penaltyState, [e.target.name]: e.target.value});
  };

  const handleAdd = () => {
    addPenalty(id, state.year, state.month, penaltyState)
      .then(() => {
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно изменен");
        getHuntersFunc();
      })
      .catch(() => {
      });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{margin: "20px 0"}}>
        <Box sx={{marginBottom: 20}}>Хантер - {fio}</Box>
        <NumberFormatInput
          name="penalty_sum"
          label="Сумма штрафа"
          value={penaltyState.penalty_sum || ""}
          handleChange={handleChange}
        />
        <TextareaAutosize
          name="comments"
          className={classes.comment}
          minRows={5}
          placeholder="Введите комментарий"
          value={penaltyState.comments}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{width: 200, marginLeft: "auto"}}>
        <CustomButton
          btnText="Добавить"
          handleClick={handleAdd}
          disabled={!penaltyState.penalty_sum}
        />
      </Box>
    </Box>
  );
};

export default AddPenalty;
