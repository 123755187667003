import instance from "../../api";

export const doLogIn = data => {
  return instance(false)
    .post(`/auth/login/`, data)
    .then(res => {
      return res.data;
    });
};
export const sendEmail = email => {
  return instance(false)
    .post(`/auth/reset_password/`, { email })
    .then(res => {
      return res;
    });
};
