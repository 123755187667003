import React, {useCallback, useEffect} from "react";
import {IconButton, Modal} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setShowModal} from "../../services/redux/actions/app-actions";
import CloseIcon from "@material-ui/icons/Close";
import {MODALS} from "../../constants";
import BankDataModal from "./modals/BankDataModal";
import DocDataModal from "./modals/DocDataModal";
import ContactDataModal from "./modals/ContactDataModal";
import FormBuilderModal from "./modals/FormBuilderModal";
import PresetChoiceModal from "./modals/PresetChoiceModal";
import AddTaskModal from "./modals/AddTaskModal";
import PaymentModal from "./modals/PaymentModal";
import KeyTransferModal from "./modals/KeyTransferModal";
import AdditionalDocsModal from "./modals/AdditionalDocsModal";
import UploadDocsModal from "./modals/UploadDocsModal";
import TechnicsModal from "./modals/TechnicsModal";
import KitchenModal from "./modals/KitchenModal";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import AddClientModal from "./modals/AddClientModal";
import SalesModal from "./modals/SalesModal";
import TechFeatureModal from "./modals/TechFeatureModal";
import DeniedContract from "./modals/DeniedContract";
import HuntersModal from "./modals/HuntersModal";
import CheckDocument from "./modals/CheckDocument";
import ResidentsModal from "./modals/ResidentsModal";
import CheckIin from "./modals/CheckIinModal";
import AboutRepair from "./modals/AboutRepair";
import ChangeData from "./modals/ChangeDataModal";
import AddMonth from "./modals/addMonthModal";
import ChangeKpi from "./modals/changeKpiModal";
import ChangeSum from "./modals/changeSum";
import AddHunter from "./modals/addHunter";
import PenaltyList from "./modals/penaltyList";
import AddPenalty from "./modals/addPenalty";
import CloseMonth from "./modals/closeMonthModal";
import AddCoeff from "../../pages/coefficient/modals/addCoeffModal";
import ChangeCoeff from "../../pages/coefficient/modals/changeCoeffModal";
import CallCenterCheck from "./modals/CallCenterCheckModal";
import {EndTaskModal} from "./modals/EndTaskModal";

const Modals = {
  [MODALS.bankData]: BankDataModal,
  [MODALS.docData]: DocDataModal,
  [MODALS.contactData]: ContactDataModal,
  [MODALS.formBuilder]: FormBuilderModal,
  [MODALS.addTask]: AddTaskModal,
  [MODALS.payment]: PaymentModal,
  [MODALS.keyTransfer]: KeyTransferModal,
  [MODALS.presetChoice]: PresetChoiceModal,
  [MODALS.additionalDocs]: AdditionalDocsModal,
  [MODALS.uploadDocs]: UploadDocsModal,
  [MODALS.technics]: TechnicsModal,
  [MODALS.kitchen]: KitchenModal,
  [MODALS.confirmDelete]: ConfirmDeleteModal,
  [MODALS.addClient]: AddClientModal,
  [MODALS.endTask]: EndTaskModal,
  [MODALS.sales]: SalesModal,
  [MODALS.techFeature]: TechFeatureModal,
  [MODALS.deniedContract]: DeniedContract,
  [MODALS.huntersModal]: HuntersModal,
  [MODALS.checkDocument]: CheckDocument,
  [MODALS.residentsModal]: ResidentsModal,
  [MODALS.checkIin]: CheckIin,
  [MODALS.aboutRepair]: AboutRepair,
  [MODALS.changeDataModal]: ChangeData,
  [MODALS.addMonthModal]: AddMonth,
  [MODALS.changeKpiModal]: ChangeKpi,
  [MODALS.changeSumModal]: ChangeSum,
  [MODALS.addHunterModal]: AddHunter,
  [MODALS.penaltyListModal]: PenaltyList,
  [MODALS.addPenaltyModal]: AddPenalty,
  [MODALS.closeMonthModal]: CloseMonth,
  [MODALS.changeCoeffModal]: ChangeCoeff,
  [MODALS.addCoeffModal]: AddCoeff,
  [MODALS.CallCenterCheck]: CallCenterCheck,

};

export const ModalWrapper = () => {
  const { show, type, data = {} } = useSelector(
    (state) => state.appState.modal
  );
  const dispatch = useDispatch();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      dispatch(setShowModal(false));
    }
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const ModalInner = Modals[type] || Modals[MODALS.bankData];

  const getClassNames = () => {
    let classes = "";
    if (data.form) classes = classes + "_form";
    if (data.white) classes = classes + "_white";
    if (data.whiteForm) classes = classes + "_white _form";
    if (data.fullWidth) classes = classes + "_fullwidth";
    return classes;
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  const clearInputIin = data?.handleCancel;

  return (
    <div className="modal_r">
      <Modal
        disableScrollLock
        open={show}
        onClose={() => {
          if (data?.clearIin) {
            clearInputIin();
          }
          dispatch(setShowModal(false));
        }}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
      >
        <div className={`modal_r__inner ${getClassNames()}`}>
          <div className="d-flex justify-content-between align-items-center">
            {data?.title && <div className="modal-title">{data?.title}</div>}
            <div className="actions">
              {data.hideClose || (
                <IconButton
                  className="_close"
                  onClick={() => {
                    if (data?.clearIin) {
                      clearInputIin();
                    }
                    dispatch(setShowModal(false));
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </div>
          <ModalInner {...data} />
        </div>
      </Modal>
    </div>
  );
}
