import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setCoeffList, setShowModal} from "../../../services/redux/actions/app-actions";
import {Box, FormControl, InputLabel, NativeSelect,} from "@material-ui/core";
import ColorPicker from "../../../components/UI/ColorPicker";
import CustomButton from "../../../components/UI/Button";
import InputText from "../../../components/Form/InputText";
import {addCoefficient} from "../api";
import cookiesService from "../../../services/cookiesService";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const cookiesServiceInstance = cookiesService.getService();
const AddCoeffModal = ({type, saleDept}) => {
  const dispatch = useDispatch();
  const [changeState, setChangeState] = useState({
    color_code: "",
    percents_from: 0,
    percents_to: 0,
    value: 0,
    sale_department_id: saleDept[0].sale_department_id,
  });
  const {addDefaultSnack} = useCustomSnackbar();
  const {position_code} = cookiesServiceInstance.getUserData();

  const handleAdd = () => {
    addCoefficient(changeState, type)
      .then((res) => {
        dispatch(setCoeffList(res));
        dispatch(setShowModal(false));
        addDefaultSnack("Коэффициент успешно Добавлен");
      })
      .catch(() => {
      });
  };
  const handleChange = (e) => {
    setChangeState({
      ...changeState,
      [e.target.name]: +e.target.value,
    });
  };
  const handleChangeColor = (e) => {
    setChangeState({
      ...changeState,
      color_code: e?.hex,
    });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{display: "grid"}}>
        {position_code === "SALE_HEAD_HEAD" ||
        position_code === "SALE_AUDITOR" ? (
          <FormControl style={{minWidth: "200px"}}>
            <InputLabel htmlFor="select-sd" shrink>
              ОП
            </InputLabel>
            <NativeSelect
              onChange={handleChange}
              inputProps={{
                name: "sale_department_id",
                id: "select-sd",
              }}
            >
              {saleDept.map((el) => {
                return (
                  <option
                    value={el.sale_department_id}
                    key={el.sale_department_id}
                  >
                    {el.sale_department_name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        ) : null}
        <InputText
          name="value"
          label="Значение коэффициента"
          shrink={false}
          type="number"
          value={changeState.value || null}
          onChange={handleChange}
          fullWidth={true}
        />
        <InputText
          name="percents_from"
          label="Диапазон коэффициента от"
          shrink={false}
          type="number"
          value={changeState.percents_from || null}
          onChange={handleChange}
          fullWidth={true}
        />
        <InputText
          name="percents_to"
          label="Диапазон коэффициента до"
          shrink={false}
          value={changeState.percents_to || null}
          onChange={handleChange}
          fullWidth={true}
        />
        <ColorPicker
          comColor={changeState.color_code}
          setComColor={(val) => {
            handleChangeColor(val);
          }}
        />
      </Box>
      <Box sx={{width: 200, marginRight: "auto", marginTop: "20px"}}>
        <CustomButton btnText="Добавить" handleClick={handleAdd}/>
      </Box>
    </Box>
  );
};

export default AddCoeffModal;
