import React from "react";
import {Box, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {activePage, useStyles} from "../";
import clsx from "clsx";
import IconComponent from "../icons";

const LinkComponent = ({
  path,
  Icon,
  page,
  title,
  positionCode,
  child,
  horizontalClass
}) => {
  const location = useLocation();
  const classes = useStyles();
  const { isOpenSidebar } = useSelector(state => state.appState);

  if (!positionCode) return null;

  return (
    <Link
      to={path}
      className={
        isOpenSidebar
          ? classes.sidebar_links
          : clsx(
              classes.sidebar_links,
              horizontalClass
                ? classes.sidebar_links__max
                : classes.sidebar_links__min
            )
      }
      style={child ? { marginLeft: "10px" } : {}}
    >
      <Box>
        <IconComponent
          name={Icon}
          className={
            activePage(page, location)
              ? classes.sidebar_icon__active
              : classes.sidebar_icon
          }
          fontSize={"medium"}
        />
      </Box>
      <Typography
        className={
          activePage(page, location)
            ? clsx(classes.sidebar_text, classes.sidebar_text__active)
            : classes.sidebar_text
        }
      >
        {title}
      </Typography>
    </Link>
  );
};

export default LinkComponent;
