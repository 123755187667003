import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, LinearProgress} from "@material-ui/core";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ruLocale from "@fullcalendar/core/locales/ru";
import {getCalendar} from "../api";
import {useSelector} from "react-redux";
import {removeHashFromUrl} from "../../../utils";
import axios from "axios";

const Calendar = () => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [calendar, setCalendar] = useState([]);
  const { isArchive } = useSelector(state => state.appState);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setIsFetching(true)
    getCalendar({ is_archive: isArchive },{
      cancelToken: source.token,
    }).then(res => {
      setIsFetching(false)
      if(!res) return setIsFetching(true)
      const edited = res?.map(item => ({...item, url: removeHashFromUrl(item.url)}))
      const newCalendar = edited?.map(el => ({
        ...el,
        backgroundColor: el?.is_overdue ? "#FF4754" : "#6191EF"
      }));
      setCalendar(newCalendar);
    }).catch(() => {
      setIsFetching(false)
    });

    return () => {
      source.cancel('Component unmounted'); // Provide a reason for cancellation
    };
  }, [isArchive]);

  return (
    <Box className={classes.calendar}>
      {isFetching && <LinearProgress/>}
      <FullCalendar
        eventColor="#6191EF"
        dayMaxEvents={3}
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        timeZone="Asia/Almaty"
        locale={ruLocale}
        height={"auto"}
        eventTimeFormat={{ hour: "numeric", minute: "2-digit" }}
        headerToolbar={{
          start: "prev,next",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay"
        }}
        initialView="dayGridMonth"
        events={calendar}
      />
    </Box>
  );
};

export default Calendar;

const useStyles = makeStyles({
  calendar: {
    "& .fc .fc-daygrid-day-frame": {
      minHeight: 190
    },
    "& .fc-daygrid-day-events": {
      padding: 5
    },
    "& .fc-button-group": {
      alignItems: "center",
      gap: 10
    },
    "& .fc-prev-button, .fc-next-button": {
      borderRadius: "50% !important",
      width: 25,
      height: 25,
      padding: 0,
      transition: "all .1s linear",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#6191EF !important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    "& .fc .fc-button-primary": {
      color: "#2C2C2C",
      background: "none",
      border: "none",
      outline: "none",
      boxShadow: "none !important",

      "&:active": {
        color: "#2c2c2c",
        background: "none !important"
      }
    },
    "& .fc .fc-button-primary:not(:disabled).fc-button-active": {
      background: "none",
      color: "#000",
      borderBottom: "3px solid #6191EF",
      borderRadius: 0,

      "&:active": {
        backgroundColor: "#6191EF"
      }
    },
    "& .fc-toolbar-title": {
      fontWeight: 500
    },
    "& .fc .fc-daygrid-day.fc-day-today": {
      backgroundColor: "rgba(97, 145, 239, 0.5)"
    },
    "& a.fc-event": {
      fontSize: 10,
      fontWeight: 400,
      color: "#2C2C2C",
      backgroundColor: "#F5F5F7",
      height: 30,
      padding: "0 10px",
      marginBottom: 5,

      "&:focus": {
        backgroundColor: "#fff"
      }
    }
  }
});
