import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleSidebar} from "../../services/redux/actions/app-actions";
import {Link, useLocation} from "react-router-dom";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Box, Drawer, IconButton} from "@material-ui/core";
import MultipleLinkComponent from "./links/multipleLink";
import SidebarUserbox from "./sidebarUserBox";
import Navbar from "../navbar/Navbar";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import {ReactComponent as BurgerMenuLogo} from "../../assets/images/icons/burgerMenuLogo.svg";
import LinkComponent from "./links/singleLink";
import {Logo} from "../UI/Logo";
import cookiesService from "../../services/cookiesService";
import {Redirect} from "react-router";
import {PAGES} from "../../constants";

const drawerWidth = 330;

export const activePage = (link, location) => {
  if (location.pathname === `/${link}`) return "#FF4754";
  return "";
};
const cookiesServiceInstance = cookiesService.getService();

export const LeftSidebar = ({children, sidebarUserbox = true}) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const {isOpenSidebar} = useSelector((state) => state.appState);
  const {isUserAuthenticated} = useSelector(state => state.appState);
  const {position_code} = cookiesServiceInstance.getUserData();

  const handleDrawer = () => {
    dispatch(toggleSidebar());
  };

  const isDealPage = location.pathname.split("/").find((el) => el === "deal");
  const isClientPage = location.pathname
    .split("/")
    .find((el) => el === "client");

  const routers = useMemo(() => {
    return getRouters(position_code).filter(item => !!item.positionCode)
  }, [position_code])

  if (!isUserAuthenticated) return <Redirect to={PAGES.login}/>;
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpenSidebar,
          [classes.drawerClose]: !isOpenSidebar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpenSidebar,
            [classes.drawerClose]: !isOpenSidebar,
          }),
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            className={clsx(classes.toolbar, {
              [classes.toolbarOpen]: isOpenSidebar,
              [classes.toolbarClose]: !isOpenSidebar,
            })}
          >
            <Link to={PAGES.main}>
              <Logo size={'small'} alt=""/>
            </Link>
            <IconButton
              onClick={handleDrawer}
              className={classes.menuButton}
            >
              {theme.direction === "rtl" ? null : (
                <Box>
                  <BurgerMenuLogo/>
                </Box>
              )}
            </IconButton>
          </Box>

          {sidebarUserbox && (
            <div className={!isOpenSidebar ? classes.hide : ""}>
              <SidebarUserbox/>
            </div>
          )}

          <PerfectScrollbar options={{wheelPropagation: true}}>
            <Box
              className={classes.menu}
              sx={{padding: isOpenSidebar ? "20px" : "0"}}
            >
              {routers.map((el, i) => {
                if (el.children)
                  return <MultipleLinkComponent key={i} {...el} />;
                else return <LinkComponent key={i} {...el} />;
              })}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Drawer>

      {isDealPage || isClientPage ? null : (
        <Navbar/>
      )}

      <main className={classes.content}>{children}</main>
    </div>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    padding: 0,
    marginTop: 15
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#659DF1",
      borderRadius: 4,
    },
  },
  dropdown_block: {
    position: "fixed",
    background: "white",
    padding: "1em",
    borderRadius: "5px",
    zIndex: 1,
    top: "0",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 16,
    },
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#659DF1",
      borderRadius: 4,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    transition: theme.transitions.create("display", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  toolbarOpen: {
    flexDirection: "row",
  },
  toolbarClose: {
    flexDirection: "column",
    padding: "20px 0",
  },
  listItem: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.04em",
    color: "#9194A9",
    textDecoration: "none",
  },
  toolbarContent: {
    height: "45px",
    background: "#FFFFFF",
    borderBottom: "1px solid #D2D3D9",
    boxShadow: " 0px 5px 20px rgba(108, 108, 108, 0.15)",
    display: "flex",
    padding: "0 40px",
    alignItems: "center",
  },
  sidebar_links: {
    color: "#9194A9",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    padding: "8px 0",
    cursor: "pointer",
    "& p": {
      fontSize: 16,
    },

    "&:hover": {
      "& p": {
        color: "#FF4754",
        transition: "all 0.3s ease",
      },

      "& svg": {
        color: "#FF4754",
        transition: "all 0.3s ease",
      },
    },
  },

  sidebar_links__min: {
    flexDirection: "column",
    justifyContent: "center",

    "& p": {
      fontSize: 10,
      whiteSpace: "normal",
      textAlign: "center",
    },
  },
  sidebar_links__max: {
    "&:last-child": {
      marginBottom: 0,
    },
    marginBottom: 15,
    marginLeft: "0!important",
  },

  sidebar_icon: {
    color: "#9194A9",
  },

  sidebar_icon__active: {
    color: "#FF4754",
  },

  sidebar_text: {
    color: "#9194A9",
    margin: "0 10px",
  },

  sidebar_text__active: {
    color: "#FF4754",
  },
  menu: {
    display: 'flex',
    flexDirection: "column",
  },
}));
const getRouters = (position_code) => {
  //  positionCode - набор условий для отображения элемента в меню(По умолчанию true)
  return [
    {
      title: "Сделки",
      path: "/deals",
      page: "deals",
      Icon: "WorkOutlineOutlinedIcon",
      positionCode: true,
    },
    {
      title: "Задачи",
      path: "/tasks",
      page: "tasks",
      Icon: "AssignmentOutlinedIcon",
      positionCode: true,
    },
    {
      title: "Клиенты",
      path: "/clients",
      page: "clients",
      Icon: "PeopleAltOutlinedIcon",
      positionCode:
        position_code === "SALE_HEAD" ||
        position_code === "SALE_HEAD_HEAD" ||
        position_code === "CALL_CENTER_HEAD" ||
        position_code === "SALE_ARCHIVE",
    },
    {
      title: "Отчеты",
      Icon: "FolderOutlinedIcon",
      positionCode:
        position_code !== "SELLER" && position_code !== "SALE_ARCHIVE",
      children: [
        {
          title: "Конверсия",
          path: "/reports",
          page: "reports",
          Icon: "AssessmentOutlinedIcon",
          positionCode: true,
        },
        {
          title: "Сумма продаж",
          path: "/contract",
          page: "contract",
          Icon: "DescriptionOutlined",
          positionCode:
            position_code === "SALE_HEAD" || position_code === "SALE_HEAD_HEAD",
        },
        {
          title: "Отчет по скидкам",
          path: "/discount",
          page: "discount",
          Icon: "LoyaltyIcon",
          positionCode:
            position_code === "SALE_HEAD" ||
            position_code === "SALE_HEAD_HEAD" ||
            position_code === "CALL_CENTER_HEAD",
        },
        {
          title: "Задачи КЦ",
          path: "/cc-task",
          page: "ccTask",
          Icon: "LoyaltyIcon",
          positionCode:
            position_code === "SALE_HEAD" ||
            position_code === "SALE_HEAD_HEAD" ||
            position_code === "CALL_CENTER_HEAD",
        },
      ],
    },
    {
      title: "План ОП",
      path: "/sales_dept_plan",
      page: "sales_dept_plan",
      Icon: "EventNoteOutlinedIcon",
      positionCode: position_code === "SALE_HEAD_HEAD",
    },
    {
      title: "План Хантеров",
      path: "/hunters_plan",
      page: "hunters_plan",
      Icon: "EventNoteOutlinedIcon",
      positionCode:
        position_code !== "SELLER" &&
        position_code !== "CALL_CENTER_HEAD" &&
        position_code !== "CALL_CENTER" &&
        position_code !== "SALE_AUDITOR" &&
        position_code !== "SALE_ARCHIVE",
    },
    {
      title: "KPI хантеров",
      path: "/hunters_kpi",
      page: "hunters_kpi",
      Icon: "TrendingUpOutlinedIcon",
      positionCode:
        position_code !== "CALL_CENTER_HEAD" &&
        position_code !== "CALL_CENTER" &&
        position_code !== "SALE_ARCHIVE",
    },
    {
      title: "Справочники",
      Icon: "FolderOutlinedIcon",
      positionCode: position_code === "SALE_HEAD_HEAD",
      children: [
        {
          title: "Коэффициенты",
          path: "/coefficient",
          page: "coefficient",
          Icon: "BarChartOutlinedIcon",
          positionCode: true,
        },
      ],
    },
    {
      title: "Поиск оп номеру телефона",
      path: "/search-by-phone",
      page: "search-by-phone",
      Icon: "SearchIcon",
      positionCode: position_code !== "SELLER"
                    && position_code !== "SALE_ARCHIVE"
                    && position_code !== "SALE_HEAD",
    },
  ];
}


