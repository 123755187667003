import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,} from "@material-ui/core";

import {getHunterDetails} from "../api";
import Layout from "../../../components/layout";
import Filters from "./Filters";
import TableRowComp from "./TableRowComp";
import styleConstants from "../../../constants/styleConstants";
import EmptyOrShow from "../../../components/EmptyOrShow";
import {useDispatch} from "react-redux";
import {setDealCount} from "../../../services/redux/actions/app-actions";
import NumberFormat from "react-number-format";
import {LOCALSTORAGEFIELDS} from "../../../constants";

const HunterDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const monthFromLocal = localStorage.getItem(LOCALSTORAGEFIELDS.month);

  const [hunterDetails, setHunterDetails] = useState([]);
  const [stateSum, setStateSum] = useState({});
  const [state, setState] = useState({
    month: monthFromLocal
      ? monthFromLocal
      : (new Date().getMonth() + 1).toString(),
    year: new Date().getFullYear().toString(),
    with_contract: 0,
  });
  const [hunterId, setHunterId] = useState(id);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    setLoading(true);
    getHunterDetails(hunterId, state)
      .then((res) => {
        setHunterDetails(res);
        setStateSum({
          contract_sum: res?.reduce((a, b) => a + b.contract_sum, 0),
          hunter_contract_sum: res?.reduce((a, b) => a + b.hunter_contract_sum, 0),
          fact_sum: res?.reduce((a, b) => a + b.fact_sum, 0),
        });
        dispatch(setDealCount(res.length));
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state, dispatch, hunterId])

  useEffect(() => {
    getData()
  }, [getData])

  const handleChangeHunterId = (e) => {
    const newId = e.target.value
    window.history.pushState(
      "",
      "",
      `/hunter/${newId || hunterId}`
    );
    setHunterId(prev => e.target.value || prev);
  };

  if(!hunterId || isNaN(hunterId))
    return null

  return (
    <Layout title="Хантер">
      <Box sx={{padding: "45px 20px 20px"}}>
        <Filters
          state={state}
          setState={setState}
          handleChangeHunterId={handleChangeHunterId}
          hunterId={hunterId}
        />
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className={classes.table_head}>
                <TableRow>
                  {headTitle.map((el) => {
                    return <TableCell key={el}>{el}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table_body}>
                {hunterDetails?.map((el) => {
                  return <TableRowComp key={el.crm_deal_id} {...el} />;
                })}
              </TableBody>
              <TableFooter className={classes.table_footer}>
                <TableRow>
                  <TableCell style={{fontWeight: "bold"}}>Итого</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <NumberFormat
                      value={stateSum.contract_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell/>
                  <TableCell>
                    <NumberFormat
                      value={stateSum.hunter_contract_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={stateSum.fact_sum}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Layout>
  );
};

export default HunterDetails;

const headTitle = [
  "Номер сделки",
  "Номер заявки",
  "ЖК",
  "КВ",
  "Тип ремонта",
  "Номер договора",
  "Дата договора",
  "Сумма договора",
  "Кол-во хантеров",
  "Сумма договора(хантер)",
  "Заработок",
];

const useStyles = makeStyles({
  table_container: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    height: "calc(100vh - 160px)",

    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius,
    },
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc !important",
    },
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e",
      },
    },
    "& td": {
      borderTop: "none !important",
      borderBottom: "1px solid #ccc !important",
      borderRight: "none !important",
    },
    "& td span": {
      whiteSpace: "nowrap",
    },
  },
  table_footer: {
    "& td": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc !important",
    },
    "& td span": {
      whiteSpace: "nowrap",
    },
  },
});