import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";

import Task from "../tasks/view/Task";
import {getTasksArchive, getTasksCurrent} from "./api";
import {getTaskTypes} from "../deals/view/api";
import {fetchStatuses} from "../deals/list/api";
import styleConstants from "../../constants/styleConstants";
import Search from "../../components/UI/Search";
import EmptyOrShow from "../../components/EmptyOrShow";
import AutoSuggest from "../../components/UI/AutoSuggest";
import CustomDatePicker from "../../components/UI/DatePicker";
import Layout from "../../components/layout";
import Calendar from "./view/Calendar";
import {NotFound} from "../../components/helpers/text";

const initialState = {
  crm_status_id: null,
  date_begin: null,
  date_end: null,
  crm_task_type_id: null
};

export const Tasks = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [taskType, setTaskType] = useState([]);
  const [data, setData] = useState([]);
  const [cnt, setCnt] = useState([]);
  const [filters, setFilters] = useState(initialState);
  const [viewName] = useState("listView");

  const {isArchive} = useSelector(state => state.appState);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const getTasks = useCallback(async () => {
    setLoading(true);
    if (isArchive) {
      getTasksArchive(filters)
        .then(res => {
          setData(res?.tasks || []);
          setCnt(res?.cnt || []);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getTasksCurrent(filters)
        .then(res => {
          setData(res?.tasks || []);
          setCnt(res?.cnt || []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filters, isArchive])

  useEffect(() => {
    getTasks()
  }, [getTasks]);

  useEffect(() => {
    fetchStatuses().then(res => {
      setStatus(res);
    });
  }, []);

  useEffect(() => {
    getTaskTypes().then(res => {
      setTaskType(res);
    });
  }, []);

  const handleChange = (e, v, name) => {
    if (name === "date_begin" || name === "date_end") {
      setFilters({...filters, [name]: v});
    } else {
      setFilters({...filters, [e.target.name]: e.target.value});
    }
  };

  const handleClearDate = name => {
    setFilters({...filters, [name]: null});
  };

  const filterTasks = useMemo(() => {
    if (!data?.length) return []
    if (!search) return data
    return data?.filter(el => {
      return el.client_name?.toLowerCase().includes(search.toLowerCase()) ||
        el.crm_task_type_name?.toLowerCase().includes(search.toLowerCase()) ||
        el.date_finish?.toLowerCase().includes(search.toLowerCase()) ||
        el.crm_deal_id?.toString().includes(search.toLowerCase()) ||
        el.crm_status_name?.toLowerCase().includes(search.toLowerCase()) ||
        el.phone_number?.includes(search.toLowerCase()) ||
        el.resident_name?.toLowerCase().includes(search.toLowerCase()) ||
        el.hunter_fio?.toLowerCase().includes(search.toLowerCase()) ||
        el.task_text?.toLowerCase().includes(search.toLowerCase()) ||
        el.fio?.toLowerCase().includes(search.toLowerCase())
    })
  }, [data, search]);

  return (
    <Layout title="Список задач">
      <Box className={classes.main_block}>
        <Box className={classes.controls_wrapper}>
          {viewName === "listView" ? (
            <Box className={classes.controls}>
              <Box className={classes.search}>
                <Search placeholder={"Поиск"} handleChange={handleSearch}/>
              </Box>
              <Box className={classes.select_type}>
                <AutoSuggest
                  name="crm_status_id"
                  label="Статус"
                  placeholder="Все статусы"
                  list={status}
                  valueKey="crm_status_id"
                  showKey="crm_status_name"
                  selected="crm_status_id"
                  onChange={handleChange}
                />
              </Box>
              <Box className={classes.select_date}>
                <CustomDatePicker
                  name="date_begin"
                  label={"Дата с"}
                  placeholder="Введите дату"
                  value={filters["date_begin"]}
                  handleChange={handleChange}
                  handleClearDate={handleClearDate}
                />
              </Box>
              <Box className={classes.select_date}>
                <CustomDatePicker
                  name="date_end"
                  label={"Дата по"}
                  placeholder="Введите дату"
                  value={filters["date_end"]}
                  handleChange={handleChange}
                  handleClearDate={handleClearDate}
                />
              </Box>
              <Box className={classes.select_type}>
                <AutoSuggest
                  name="crm_task_type_id"
                  label="Тип задачи"
                  placeholder="Все типы"
                  list={taskType}
                  valueKey="crm_task_type_id"
                  showKey="crm_task_type_name"
                  onChange={handleChange}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        {viewName === "gridView" ? (
          <Calendar/>
        ) : (
          loading
            ? <EmptyOrShow loading={loading}/>
            : (!cnt?.length && !data?.length)
              ? <NotFound/>
              : <>

                <Box mb={2} display="flex">
                  {cnt?.map(el => (
                    <Typography
                      className={classes.cnt}
                      key={el.crm_task_type_name}
                    >
                      {el.crm_task_type_name} - {el.cnt}
                    </Typography>
                  ))}
                </Box>
                <Box className={classes.board}>
                  {filterTasks?.map(el => {
                    return <Task key={el.crm_deal_task_id} {...el} getTasks={getTasks}/>;
                  })}
                </Box>
              </>
        )}
      </Box>
    </Layout>
  );
};

const useStyles = makeStyles({
  main_block: {
    padding: "45px 20px 20px"
  },
  controls_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "20px 0"
  },
  controls: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: 20,
    minHeight: 50
  },
  btn_add: {
    width: 150
  },
  search: {
    width: 400
  },
  select_type: {
    width: 200
  },
  select_date: {
    width: 200,

    "& button": {
      padding: 0
    }
  },
  board: {
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      height: 4
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  cnt: {
    fontWeight: 600,
    marginRight: 20
  },
  tasks_view: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: 50
  },
  tasks_view_inner: {
    display: "flex",
    alignItems: "center"
  },
  tasks_view_title: {
    fontWeight: 500,
    color: "#C2C4CE",
    marginRight: 10
  },
  tasks_view_buttons: {
    backgroundColor: "#eee",
    borderRadius: 50,
    display: "flex",

    "& button": {
      outline: "none",
      border: "none",
      fontSize: 16,
      color: "#C2C4CE",
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      padding: 10
    }
  },
  active_view: {
    color: "#fff !important",
    backgroundColor: styleConstants.primaryColor
  }
});
