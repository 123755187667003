import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MAIN_URL } from "../../../constants";

const useStyles = makeStyles({
  info_text: {
    display: "flex",
    flexDirection: "column",
    minWidth: 100
  },
  info_text_label: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194a9",
    display: "flex",
    alignItems: "center"
  },
  info_text_value: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "15px",
    color: "#494c62",
    marginTop: 6
  }
});

export default function InfoTxt({ label, value, imgLink = null }) {
  const classes = useStyles();

  return (
    <div className={classes.info_text}>
      <div className={classes.info_text_label}>{label}</div>
      <div className={classes.info_text_value}>
        {imgLink ? (
          <a target="_blank" href={MAIN_URL + imgLink}>
            {value}
          </a>
        ) : (
          value
        )}
      </div>
    </div>
  );
}
