export const monthListForFilters = [
  {
    id: 1,
    month: "Январь"
  },
  {
    id: 2,
    month: "Февраль"
  },
  {
    id: 3,
    month: "Март"
  },
  {
    id: 4,
    month: "Апрель"
  },
  {
    id: 5,
    month: "Май"
  },
  {
    id: 6,
    month: "Июнь"
  },
  {
    id: 7,
    month: "Июль"
  },
  {
    id: 8,
    month: "Август"
  },
  {
    id: 9,
    month: "Сентябрь"
  },
  {
    id: 10,
    month: "Октябрь"
  },
  {
    id: 11,
    month: "Ноябрь"
  },
  {
    id: 12,
    month: "Декабрь"
  }
];

export const monthList = [
  {
    id: 1,
    month: "Январь",
    monthNumber: "01"
  },
  {
    id: 2,
    month: "Февраль",
    monthNumber: "02"
  },
  {
    id: 3,
    month: "Март",
    monthNumber: "03"
  },
  {
    id: 4,
    month: "Апрель",
    monthNumber: "04"
  },
  {
    id: 5,
    month: "Май",
    monthNumber: "05"
  },
  {
    id: 6,
    month: "Июнь",
    monthNumber: "06"
  },
  {
    id: 7,
    month: "Июль",
    monthNumber: "07"
  },
  {
    id: 8,
    month: "Август",
    monthNumber: "08"
  },
  {
    id: 9,
    month: "Сентябрь",
    monthNumber: "09"
  },
  {
    id: 10,
    month: "Октябрь",
    monthNumber: "10"
  },
  {
    id: 11,
    month: "Ноябрь",
    monthNumber: "11"
  },
  {
    id: 12,
    month: "Декабрь",
    monthNumber: "12"
  }
];

const currentYear = new Date().getFullYear();
// Создаем список годов, начиная с 2022 и заканчивая следующим годом после текущего
const generatedYearList = [];
for (let year = 2022; year <= currentYear + 1; year++) {
  generatedYearList.push({
    id: year - 2021, // id начинается с 1 для 2022 года и увеличивается на 1 каждый год
    year: year.toString()
  })
}
export const yearList = generatedYearList;
