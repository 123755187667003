import instance from "../../api";

export const getHuntersPlan = (year, month, sale_id) => {
  return instance(true)
    .get(`/crm/kpi/hunters_month_plan/?year=${year}&month=${month}&sale_department_id=${sale_id}`).then(res => {
      return res?.data;
    })
};

export const getHuntersPlanInfo = state => {
  return instance(true)
    .post(`/crm/kpi/sd_plan_info/`, state)
    .then(res => {
      return res?.data;
    })
};

export const changeSum = (id, year, month, params) => {
  return instance(true)
    .put(
      `/crm/kpi/hunters_month_plan/${id}/?year=${year}&month=${month}`,
      params
    )
    .then(res => {
      return res?.data;
    })
};

export const addHunter = (year, month, params) => {
  return instance(true)
    .post(`/crm/kpi/hunters_month_plan/?year=${year}&month=${month}`, params)
    .then(res => {
      return res?.data;
    })
};

export const getPenaltyList = (id, year, month) => {
  return instance(true)
    .get(
      `/crm/kpi/hunters_month_plan/${id}/penalties/?year=${year}&month=${month}`
    )
    .then(res => {
      return res?.data;
    })
};

export const addPenalty = (id, year, month, params) => {
  return instance(true)
    .post(
      `/crm/kpi/hunters_month_plan/${id}/penalties/?year=${year}&month=${month}`,
      params
    )
    .then(res => {
      return res?.data;
    })
};

export const deleteHunter = (id, year, month) => {
  return instance(true)
    .delete(`/crm/kpi/hunters_month_plan/${id}/?year=${year}&month=${month}`)
    .then(res => {
      return res?.data;
    })
};

export const deletePenalty = (id, penaltyId, year, month) => {
  return instance(true)
    .delete(
      `/crm/kpi/hunters_month_plan/${id}/penalties/${penaltyId}/?year=${year}&month=${month}`
    )
    .then(res => {
      return res?.data;
    })
};

export const getHuntersListForHead = id => {
  return instance(true)
    .get(`/crm/sd_hunters/?sale_department_id=${id}`)
    .then(res => {
      return res?.data;
    });
};
