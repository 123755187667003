import {COUNTRY_CODE, HEADER_FIELDS, SYSTEM_COUNTRY_CODE} from "../constants";

export function isArrayEmpty(array = []) {
  return Boolean(array.length);
}

export const getDataUrlOfFile = (files = []) => {
  const arrayOfDataUrl = [];

  return Promise.all(
    Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const { result } = e.target;
          arrayOfDataUrl.push({ photo: result, name: file.name });
          resolve();
        };
        reader.readAsDataURL(file);
      });
    })
  ).then(() => arrayOfDataUrl);
};

// this func need fix
export function saveImage(
  setRenderPhotos,
  setFieldValue,
  name,
  values,
  stateUpdater = () => {},
  event
) {
  const { files } = event.target;
  stateUpdater(name, [...values[name], ...files]);
  setFieldValue(name, [...values[name], ...files]);

  if (files && files.length) {
    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imgElement = document.createElement("img");
        imgElement.src = e.target.result;
        setRenderPhotos((prev) => [...prev, e.target.result]);
      };
      reader.readAsDataURL(file);
    });
  }
}

export function detectTabbar(headerFieldsInState) {
  return !!headerFieldsInState[HEADER_FIELDS.isTaskList];
}

export function checker(value) {
  return value < 10 ? "0" + value : value;
}

export function doObjectsArray(array = []) {
  return array.map((item) => ({
    photo: item,
  }));
}

export function convertHoursToDays(numberOfHours) {
  const days = Math.floor(numberOfHours / 24);
  const hours = Math.floor(numberOfHours % 24);
  return { days, hours };
}

export const getPhoneFormat = () => {
  switch (SYSTEM_COUNTRY_CODE) {
    case COUNTRY_CODE.UZB: {
      return "+### (##) ###-####";
    }
    case COUNTRY_CODE.KZ: {
      return "+# (###) ###-####";
    }
    default: {
      return "+# (###) ###-####";
    }
  }
};
export const getPhoneLength = () => {
  switch (SYSTEM_COUNTRY_CODE) {
    case COUNTRY_CODE.UZB: {
      return 12;
    }
    case COUNTRY_CODE.KZ: {
      return 11;
    }
    default: {
      return 11;
    }
  }
};

export const removeHashFromUrl = (url = '') => {
  if(!url) return ''
  try {
    if(url.includes('/#'))
      return url.replace('/#', '')
  } catch (e) {
    return '/'
  }
  return url
}

export const formatSum = sum => {
  return new Intl.NumberFormat("ru-RU").format(sum);
};

export const getDateCalendar = day => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  if (day) dd = "01";

  today = dd + "." + mm + "." + yyyy;
  return today;
};

export const getBgColor = (crm_status_id, is_archive, leadCardMode = false) => {
  if (is_archive)
    return "#e1e1e1"
  switch (crm_status_id) {
    case 1:
      return leadCardMode ? "#FFF" : "#F3F3FB";
    case 2:
      return leadCardMode ? "#FFF" : "#F3F3FB";
    case 3:
      return "#E4E7FF";
    case 4:
      return "#F1E9FA";
    case 5:
      return "#F9F3D3";
    case 6:
      return "#E9FAF5";
    case 7:
      return "#FFEFF4";
    default:
      return "#FAFAFE";
  }
};