import React, {useState} from "react";

import {Box} from "@material-ui/core";
import CustomButton from "../../UI/Button";
import {changeSum} from "../../../pages/huntersPlan/api";
import {setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import NumberFormatInput from "../../UI/NumberFormatInput";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const ChangeSum = ({
                     state,
                     setHuntersPlan,
                     hunter_month_plan_sum,
                     hunter_month_plan_id,
                     hunter_salary,
                     fio
                   }) => {
  const dispatch = useDispatch();
  const [hunterChangeState, setHunterChangeState] = useState({
    hunter_month_plan_sum: hunter_month_plan_sum,
    hunter_salary: hunter_salary
  });
  const {addDefaultSnack} = useCustomSnackbar();

  const handleClick = () => {
    changeSum(hunter_month_plan_id, state.year, state.month, hunterChangeState)
      .then(res => {
        setHuntersPlan(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно изменен");
      })
      .catch(() => {
      });
  };

  const handleChange = e => {
    setHunterChangeState({
      ...hunterChangeState,
      [e.target.name]: +e.target.value
    });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{margin: "20px 0"}}>
        <Box sx={{marginBottom: 20}}>Хантер - {fio}</Box>
        <Box>
          <NumberFormatInput
            name="hunter_month_plan_sum"
            label="План"
            shrink={true}
            value={hunterChangeState.hunter_month_plan_sum || ""}
            handleChange={handleChange}
          />
        </Box>
        <Box>
          <NumberFormatInput
            name="hunter_salary"
            label="Оклад"
            shrink={true}
            value={hunterChangeState.hunter_salary || ""}
            handleChange={handleChange}
          />
        </Box>
      </Box>
      <Box sx={{width: 200, marginLeft: "auto"}}>
        <CustomButton btnText="Изменить" handleClick={handleClick}/>
      </Box>
    </Box>
  );
};

export default ChangeSum;
