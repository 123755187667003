import React from "react";
import {InputLabel, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";

const useStyles = makeStyles({
  label: {
    fontSize: 15,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3
  }
});

const CustomTimePicker = ({ label, name, value, handleChange }) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <TextField
        id="time"
        type="time"
        name={name}
        // defaultValue="00:00"
        value={value}
        fullWidth
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 300
        }}
      />
    </>
  );
};

export default CustomTimePicker;
