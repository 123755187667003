import React from 'react'
import { useSnackbar } from 'notistack'
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const addDefaultSnack = (
    message = 'Успешно',
    status = 'success',
    duration = 3000,
  ) => {
    enqueueSnackbar(message, {
      variant: status,
      autoHideDuration: duration,
    })
  }
  const addCloseSnack = (
    message = 'Ошибка',
    status = 'error',
    duration = 10000,
  ) => {
    const key = enqueueSnackbar(message, {
      variant: status,
      autoHideDuration: duration,
      action: () => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
    })
  }
  return { addCloseSnack, addDefaultSnack }
}
