import React, {useEffect, useState} from "react";
import {Box, Drawer, Tabs, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {getDealHistory} from "./api";
import Tab from '@material-ui/core/Tab';
import clsx from "clsx";

const HistoryDrawer = ({open, handleClose, crm_deal_id}) => {
  const classes = useStyles();
  const [history, setHistory] = useState([]);
  const [denies, setDenies] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (crm_deal_id && open) {
      getDealHistory(crm_deal_id).then(res => {
        if (!res) return
        const {statuses, denies} = res
        setHistory(statuses);
        setDenies(denies);
      });
    }
  }, [crm_deal_id, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      BackdropProps={{invisible: true}}
      classes={{
        paper: classes.drawer
      }}
    >
      <Box className={classes.sidebar}>
        <Box className={classes.header}>
          <Typography className={classes.title}>История</Typography>
          <CloseIcon className={classes.icon} onClick={handleClose}/>
        </Box>
        <Box className={classes.content}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="full width tabs example"
          >
            <Tab label="Общее" {...a11yProps(0)} style={{textTransform: "initial"}}/>
            <Tab disabled={!denies?.length} label="По отказам" {...a11yProps(1)} style={{textTransform: "initial"}}/>
          </Tabs>
          <TabPanel value={value} index={0}>
            {history?.map(el => {
              return (
                <HistoryItem key={el.crm_status_history_id} title={el.fio} date={el.rowversion} action={el.action} />
              );
            })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {denies?.map(el => {
              return (
                <HistoryItem key={el.crm_deal_deny_id} title={el.fio} date={el.rowversion} description={el.comments} action={el.deny_reason_name} />
              );
            })}
          </TabPanel>
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryDrawer;

const HistoryItem = ({title, description, date, action}) => {
  const classes = useStyles();

  return <Box mb={1} className={classes.item}>
    <Typography className={classes.name}>
      {title}
      <Box component="span" className={clsx([classes.date, {[classes.onlyDate]: !title}])}>
        {date}
      </Box>
    </Typography>
    <Typography className={classes.action}>{action}</Typography>
    {!!description && <Typography className={classes.comment}>{description}</Typography>}
  </Box>
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={'15px'}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  drawer: {
    backgroundColor: "#F8FAFB",
    border: "1px solid #D2D3D9",
    boxShadow: "none",
    overflow: "hidden"
  },
  sidebar: {
    width: 330,
    height: "100vh",
    overflow: "auto"
  },
  header: {
    width: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottom: "1px solid #D2D3D9",
    padding: "0 22px"
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    color: "#494C62"
  },
  icon: {
    marginLeft: "auto",
    color: "#6191EF",
    cursor: "pointer"
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  },
  date: {
    fontSize: 11,
    fontWeight: 400,
    color: "#9194A9",
    paddingLeft: 9
  },
  onlyDate: {
    paddingLeft: 0
  },
  action: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000"
  },
  comment: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000",
    fontStyle: 'italic'
  },
  content: {
    padding: '0 12px 12px 12px',
  },
  item: {
    borderBottom: '1px solid #afafaf',
    paddingBottom: '8px'
  }
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}