import React, {useEffect, useState} from "react";
import {getSaleDeptId} from "pages/salesDeptPlan/api";
import {Box, FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import CustomButton from "../../components/UI/Button";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../services/redux/actions/app-actions";
import {MODALS} from "../../constants";
import cookiesService from "../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();
const Filters = ({ state, handleChange, type }) => {
  const { position_code } = cookiesServiceInstance.getUserData();
  const [saleDept, setSaleDept] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getSaleDeptId().then((res) => {
      setSaleDept(res);
    });
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: "20px",
        margin: "20px 0",
      }}
    >
      <Box sx={{ minWidth: 200 }}>
        <CustomButton
          btnText="Добавить коэффициент"
          handleClick={() => {
            dispatch(
              setShowModal(true, MODALS.addCoeffModal, {
                title: "Добавить коэффициент",
                state: state,
                type: type,
                saleDept: saleDept,
              })
            );
          }}
        />
      </Box>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel htmlFor="ceof-select" shrink>
            Тип коэффициента
          </InputLabel>
          <NativeSelect
            onChange={handleChange}
            inputProps={{
              name: "coeff",
              id: "ceof-select",
            }}
          >
            {state.map((el) => {
              return (
                <option value={el.sale_koef_type_id} key={el.sale_koef_type_id}>
                  {el.sale_koef_type_name}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </Box>

      {position_code === "SALE_HEAD_HEAD" ||
      position_code === "SALE_AUDITOR" ? (
        <FormControl style={{ minWidth: "200px" }}>
          <InputLabel htmlFor="select-sd" shrink>
            ОП
          </InputLabel>
          <NativeSelect
            onChange={handleChange}
            inputProps={{
              name: "sale_department_id",
              id: "select-sd",
            }}
          >
            {saleDept.map((el) => {
              return (
                <option
                  value={el.sale_department_id}
                  key={el.sale_department_id}
                >
                  {el.sale_department_name}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      ) : null}
    </Box>
  );
};

export default Filters;
