import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { setShowModal } from "../../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
const useStyles = makeStyles({
  wrapper: {
    maxWidth: 550
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10
  },
  button: {
    width: "100%",
    height: 38,
    padding: 10,
    borderRadius: 4,
    marginBottom: 10
  },
  primary: {
    color: "#fff",
    backgroundColor: "rgb(97, 145, 239)",

    "&:hover": {
      backgroundColor: "rgb(97, 145, 239)"
    }
  },
  cancel: {
    borderColor: "#6191EF",
    color: "#6191EF"
  }
});
const CloseMonthModal = ({ clickHandle, titleModal, text }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.wrapper}>
      <h1>
        {titleModal ? titleModal : "Вы действительно хотите закрыть месяц"}
      </h1>
      <Box className={classes.buttons}>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.cancel)}
          onClick={() => dispatch(setShowModal(false))}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.primary)}
          onClick={clickHandle}
        >
          {text ? text : "Закрыть месяц"}
        </Button>
      </Box>
    </Box>
  );
};

export default CloseMonthModal;
