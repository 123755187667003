import {Box, CircularProgress, Popover} from "@material-ui/core";
import React from "react";
import {RiArrowDownSLine} from "react-icons/ri";

export default function SelectPopover({
                                        list = [],
                                        valueKey,
                                        showKey,
                                        onClick,
                                        margin = true,
                                        dropdownMode,
                                        label = "",
                                        loading,
                                      }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "preset-popover" : undefined;

  const getLabel = () => {
    const currStatus = list?.find(el => el.is_current === true);
    if (currStatus) return currStatus["crm_status_name"];
    return "";
  };

  const getActiveClass = item => {
    const currStatus = list?.find(el => el.is_current === true);
    if (item[valueKey] === currStatus?.crm_status_id) return " _active ";
    return "";
  };

  const isItemAvail = item => {
    if (item["is_transferable"]) return " _transferable";
  };

  return (
    <div className={open ? "select_popover _active" : "select_popover"}>
      <div className={`select_popover__component ${margin ? 'select_popover__component_margin' : ''}`} onClick={handleClick}>
        <span>{dropdownMode ? label || "" : getLabel()}</span>
        <RiArrowDownSLine/>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className="select_popover__content">
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={178}
              height={236}
            >
              <CircularProgress/>
            </Box>
          ) : (
            <>
              {(list?.length ? list : [{[showKey]: "Нет данных", [valueKey]: "NOT_FOUND"}]).map(i => (
                <div
                  className={"_item " + getActiveClass(i) + isItemAvail(i)}
                  key={i[valueKey]}
                  onClick={() => i[valueKey] !== "NOT_FOUND" && onClick(i, handleClose)}
                >
                  {i[showKey]}
                </div>
              ))}
            </>
          )}
        </div>
      </Popover>
    </div>
  );
}
