import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDealCreateData, setModalData, setShowModal} from "../../../services/redux/actions/app-actions";

import {changeShowConstructorPrice, getBankData, getClientData, getContactData, getDocData} from "./api";
import {Box, Button, FormControlLabel, Switch, Table, TableBody, TableContainer} from "@material-ui/core";

import * as Yup from "yup";
import {clientTypes, MODALS} from "../../../constants";
import InputText from "components/Form/InputText";
import {PlusIcon} from "../../../components/UI/Icons";
import TableHeadComp from "./TableHeadComp";
import ContactDataRowComp from "./ContactDataRowComp";
import styleConstants from "../../../constants/styleConstants";
import {FaUserTie, RiVipCrown2Fill} from "react-icons/all";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import PhoneInput from "react-phone-number-input";
import ru from "react-phone-number-input/locale/ru.json";
import cookiesService from "../../../services/cookiesService";
import {ShowPhone} from "../../../components/helpers/showPhone";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {LoaderData} from "../../../components/loaderData";

const contactTitle = ["ФИО", "Телефон", "Тип", "Действия"];

export const validate = Yup.object().shape({
  password: Yup.string().required("Обязательное поле"),
  phone_number: Yup.string().required("Обязательное поле")
});

const cookiesServiceInstance = cookiesService.getService();

export default function DealHeadForm({crm_deal_id, isClient}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const {...values} = useSelector(state => state.appState.dealCreateData);
  const {docData, bankData, contactData} = useSelector(
    state => state.appState.modalData
  );
  const {position_code} = cookiesServiceInstance.getUserData();
  const {addCloseSnack} = useCustomSnackbar();

  const [showPhone, setShowPhone] = useState(!crm_deal_id)
  const [showConstructorPrice, setShowConstructorPrice] = useState(undefined)
  const [showConstructorPriceLoading, setShowConstructorPriceLoading] = useState(undefined)

  useEffect(() => {
    if (showConstructorPrice === undefined) {
      setShowConstructorPrice(values.is_constructor_price_show)
    }
  }, [values, showConstructorPrice])

  const handleChange = e => {
    if (e.target.name === "iin" && e.target.value?.length > 12) return;
    dispatch(
      setDealCreateData({
        [e.target.name]: e.target.value ? e.target.value : ""
      })
    );
    if (e.target.name === "iin" && e.target.value?.length === 12) {
      let iin = e.target.value;
      const timeout = setTimeout(() => {
        getClientData({...values, iin})
          .then(res => {
            if (res.client_id) {
              dispatch(
                setShowModal(true, MODALS.checkDocument, {
                  title: "Подтвердите действия",
                  handleCancel: () => handleCancelDocuments(),
                  handleAccept: () => handleAcceptDocuments(res),
                  clearIin: true
                })
              );
            } else {
              dispatch(setDealCreateData(res));
            }
          })
          .catch(() => {
          });
      }, 700);
      return () => clearTimeout(timeout);
    }
  };

  useEffect(() => {
    if (values?.client_id) {
      Promise.all([
        getDocData(values.client_id, values.crm_deal_id),
        getBankData(values.client_id),
        getContactData(values.client_id)
      ])
        .then(res => {
          dispatch(
            setModalData({
              docData: res[0] || {},
              bankData: res[1],
              contactData: res[2]
            })
          );
        })
        .catch(() => {
        });
    } else {
      dispatch(
        setModalData({
          docData: {},
          bankData: {},
          contactData: {}
        })
      );
    }
  }, [values?.client_id]);

  const handleClickDocData = () => {
    if (!values?.iin) {
      return addCloseSnack("Заполните поле ИИН и сохраните", "info");
    }
    dispatch(
      setShowModal(true, MODALS.docData, {
        title: "Данные документа",
        client_id: values.client_id,
        crm_deal_id: values.crm_deal_id
      })
    );
  };

  const handleClickContacts = () => {
    if (!values?.iin) {
      return addCloseSnack("Заполните поля ИИН", "info");
    }
    dispatch(
      setShowModal(true, MODALS.contactData, {
        title: "Контактное лицо",
        client_id: values.client_id
      })
    );
  };

  const handleCancelDocuments = () => {
    dispatch(setShowModal(false));
    dispatch(setDealCreateData({iin: null}));
  };

  const handleAcceptDocuments = data => {
    dispatch(setShowModal(false));
    dispatch(setDealCreateData({...values, ...data}));
  };

  const disable = crm_status_id => {
    if (
      (crm_status_id === 1 || crm_status_id === 2 || crm_status_id === 3) &&
      position_code === "SALE_AUDITOR"
    ) {
      return `${globalClasses.disable_block}`;
    } else {
      return "";
    }
  };

  const phoneValue = useMemo(() => {
    if (!values.phone_number) return ''
    if (!values.phone_number.includes('+'))
      return `+${values.phone_number}`
    return values.phone_number
  }, [values.phone_number])

  const handleClickPhone = async (e, phoneRes) => {
    if (!phoneRes) return
    dispatch(setDealCreateData({phone_number: phoneRes}));
    setShowPhone(true)
  }

  const handleChangeShowConstructorPrice = async () => {
    if (showConstructorPriceLoading) return
    setShowConstructorPriceLoading(true)
    const res = await changeShowConstructorPrice(crm_deal_id, {is_constructor_price_show: !showConstructorPrice}, isClient ? 1 : 0)
    setShowConstructorPriceLoading(false)
    if (!res) return
    dispatch(setDealCreateData(res, res));
    setShowConstructorPrice(res.is_constructor_price_show)
  }
  return (
    <Box className={classes.client_info}>
      <Box className={clsx(classes.info_item, disable(values.crm_status_id))}>
        <h4>Личная информация</h4>
        <Box className={classes.personal_info}>
          <Box className={classes.personal_info_item}>
            <InputText
              name="client_fio"
              label={
                <div>
                  {values?.client_type_code === clientTypes.LEGAL_ENTITIES
                    ? "Наименование компании"
                    : "ФИО"}{" "}
                  <span>*</span>
                </div>
              }
              value={values.client_fio || ""}
              onChange={handleChange}
              className={classes.input}
              fullWidth={false}
              disabled={Boolean(values?.client_id)}
            />
            <InputText
              name="iin"
              label={
                <div>
                  {values?.client_type_code === clientTypes.LEGAL_ENTITIES
                    ? "БИН"
                    : "ИИН"}{" "}
                  <span>*</span>
                </div>
              }
              type="number"
              value={values.iin?.replace(/\s/g, "") || ""}
              onChange={handleChange}
              fullWidth={false}
              className={classes.input}
              disabled={
                values.crm_status_id === 5 ||
                values.crm_status_id === 6 ||
                values.crm_status_id === 7 ||
                (position_code === "SELLER" && values.crm_status_id >= 4)
              }
            />
            <InputText
              name="address"
              label={
                <div>
                  Адрес проживания <span>*</span>
                </div>
              }
              value={values?.address || ""}
              onChange={handleChange}
              fullWidth={false}
              className={classes.input}
              disabled={Boolean(values?.client_id)}
            />
            <Box sx={{position: "relative", width: "62%"}}>
              <button
                tabIndex={-1}
                className={clsx(classes.deals_btn, {
                  [globalClasses.btn_green]: docData?.all_filled === true,
                  [globalClasses.btn_grey]: Boolean(docData?.all_filled) === false
                })}
                style={{width: 170}}
                onClick={handleClickDocData}
              >
                Паспортные данные
              </button>
              <Box
                sx={{
                  fontSize: 16,
                  color: "red",
                  position: "absolute",
                  top: 0,
                  right: 17,
                  width: 10,
                  height: 10
                }}
              >
                *
              </Box>
            </Box>
          </Box>
          <div className={classes.personal_info_item}>
            <InputText
              name="email"
              label={
                <div>
                  Email <span>*</span>
                </div>
              }
              type="email"
              value={values.email || ""}
              onChange={handleChange}
              fullWidth={false}
              className={classes.input}
              disabled={Boolean(values?.client_id)}
            />

            {
              showPhone
                ? <PhoneInput
                  className={classes.input_phone}
                  international
                  defaultCountry="KZ"
                  labels={ru}
                  value={phoneValue || ""}
                  disabled={position_code === "SELLER" && values.crm_status_id >= 4}
                  onChange={phone => {
                    const e = {
                      target: {
                        name: "phone_number",
                        value: phone
                      }
                    };
                    handleChange(e);
                  }}
                />
                : !!crm_deal_id && <ShowPhone onClick={handleClickPhone}
                             body={{crm_deal_id: crm_deal_id || null}}/>
            }
          </div>
        </Box>
      </Box>
      <Box className={classes.info_item}>
        <h4>Дополнительная информация</h4>
        <Box className={classes.additional_info}>
          <Box className={classes.additional_info_item}
               style={{width: "40%"}}
          >
            <Box className={classes.additional_info_title}>
              <RiVipCrown2Fill
                name="is_vip"
                size={24}
                color={values.is_vip ? "#EFC761" : "#dadada"}
                style={{marginRight: 10, cursor: "pointer"}}
                onClick={() =>
                  handleChange({
                    target: {
                      name: "is_vip",
                      value: !values.is_vip
                    }
                  })
                }
              />
              VIP клиент
            </Box>
            <Box className={classes.additional_info_title}>
              <FaUserTie
                size={24}
                color={values.is_investor ? "#AA82D3" : "#dadada"}
                style={{marginRight: 10}}
              />
              Инвестор
            </Box>
            <button
              tabIndex={-1}
              className={clsx(classes.deals_btn, {
                [globalClasses.btn_green]: bankData?.all_filled === true,
                [globalClasses.btn_grey]:
                Boolean(bankData?.all_filled) === false,
                [globalClasses.btn_disable]:
                !values.crm_deal_id || !values.client_id
              })}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.bankData, {
                    title: "Реквизиты банка",
                    client_id: values.client_id
                  })
                )
              }
            >
              Реквизиты банка
            </button>


            {showConstructorPrice !== undefined && <Box
              style={{marginTop: 10, display: 'flex', alignItems: 'center', gap: 5, minHeight: 40}}>
              {showConstructorPriceLoading ? <LoaderData small allWidth={false}/> : <FormControlLabel
                label={''}
                control={
                  <Switch
                    checked={!!showConstructorPrice}
                    onChange={handleChangeShowConstructorPrice}
                    color="primary"
                  />
                }
                style={{marginRight: 0}}
              />}
              <span style={{fontSize: 13, lineHeight: '14px'}}>Показывать цену в конструкторе</span>
            </Box>}
          </Box>


          <Box
            className={classes.additional_info_item}
            style={{width: "60%"}}
          >
            <Button
              className={classes.btn_contact}
              endIcon={<PlusIcon/>}
              onClick={handleClickContacts}
            >
              Контактное лицо
            </Button>
            {contactData?.phones?.length ? (
              <Box className={classes.table_wrapper}>
                <TableContainer className={classes.table_container}>
                  <Table aria-label="a dense table" className={classes.table}>
                    <TableHeadComp data={contactTitle}/>
                    <TableBody>
                      {contactData?.phones.map(el => {
                        return (
                          !!values?.client_id && <ContactDataRowComp
                            key={el.client_phone_id}
                            {...el}
                            clientId={values?.client_id}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export const useStyles = makeStyles(theme => ({
  client_info: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,

    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: "1fr"
    }
  },
  info_item: {
    backgroundColor: "#fff",
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    width: "100%",
    minHeight: 210,
    padding: 20,

    "& h4": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "17px",
      color: "#494C62",
      margin: "0 0 15px 0"
    }
  },
  personal_info: {
    display: "flex",
    flexWrap: "wrap",
    gap: 15,

    "& .MuiFormControl-root": {
      width: 300
    }
  },
  personal_info_item: {
    display: "flex",
    flexDirection: "column",
    gap: 20,

    "& .Mui-disabled": {
      color: "rgba(0,0,0,0.87)",
      cursor: "not-allowed"
    }
  },
  number_block: {
    maxWidth: 340
  },
  number_icon: {
    position: "absolute",
    top: 15,
    right: -40
  },
  numbers_block: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20
  },
  numbers_block_inner: {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  numbers: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "10px"
  },
  passport: {
    width: 170
  },
  additional_info: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start"
  },
  additional_info_item: {
    width: "50%"
  },
  additional_info_title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#9194A9",
    display: "flex",
    alignItems: "center",
    marginBottom: 20,

    "& svg": {
      marginRight: 10
    }
  },
  btn_contact: {
    fontSize: 14,
    fontWeight: 600,
    color: styleConstants.primaryColor,
    textAlign: "left",
    marginBottom: 10
  },
  contact_number: {
    backgroundColor: "#F5F5F9",
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    gap: "10px",
    padding: 10
  },
  contact_number_inner: {
    display: "flex",
    flexDirection: "column"
  },
  contact_icon: {
    display: "inline-flex",
    cursor: "pointer"
  },
  table_wrapper: {
    border: "1px solid #D2D3D9",
    borderRadius: 4,
    marginTop: 10
  },
  table_container: {
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      height: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table: {
    "& .MuiTableCell-root": {
      fontSize: 12,
      whiteSpace: "nowrap",
      padding: "7px 10px"
    }
  },
  deals_btn: {
    fontWeight: 600,
    color: "#fff",
    border: "none",
    borderRadius: styleConstants.borderRadius,
    padding: "8px 10px",
    cursor: "pointer"
  },
  input_phone: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    height: "45.63px",
    alignItems: "flex-end",

    "& .PhoneInputCountry": {
      alignSelf: "auto",
      height: 18,
      marginBottom: 8
    },

    "& input": {
      border: "none",
      outline: "none",
      background: "none",
      marginBottom: 8
    }
  },
  input: {
    "& label": {
      fontSize: 12,
      fontWeight: 600,
      transform: "scale(1)",

      "& span": {
        color: "red"
      }
    }
  }
}));