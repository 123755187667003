import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, InputLabel} from "@material-ui/core";

import Search from "../../../components/UI/Search";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import ClientsRow from "./ClientsRow";
import {getClients} from "./api";
import EmptyOrShow from "../../../components/EmptyOrShow";
import Layout from "../../../components/layout";
import InputText from "../../../components/Form/InputText";
import cookiesService from "../../../services/cookiesService";
import InputPhone from "../../../components/Form/InputPhone";
import {getPhoneLength} from "../../../utils";

const headTitle = [
  "ID",
  "ФИО",
  "Телефон",
  "Email",
  "ИИН",
  "Статус",
  "Сделки",
  "Активный"
];

const cookiesServiceInstance = cookiesService.getService();
export const Clients = () => {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const {position_code} = cookiesServiceInstance.getUserData();

  const [state, setState] = useState({
    email: null,
    client_iin: null,
    client_status: null,
    phone_number: null
  });

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      getClients(state)
        .then(res => {
          res && setClients(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 700);
    return () => clearTimeout(timeout);
  }, [state]);

  const handleChange = e => {
    if (e.target.name === "client_iin" && e.target.value?.length > 12) return;
    if (e.target.name === "phone_number" && (e.target.value?.length !== getPhoneLength() && e.target.value?.length !== 0)) return;

    setState({
      ...state,
      [e.target.name]: e.target.value ? e.target.value : null
    });
  };

  return (
    <Layout title="Список клиентов">
      {position_code === "SALE_HEAD" ||
      position_code === "SALE_HEAD_HEAD" ||
      position_code === "CALL_CENTER_HEAD" ||
      position_code === "SALE_ARCHIVE" ? (
        <Box className={classes.main_block}>
          <Box className={classes.control_block}>
            <Box className={classes.search}>
              <Search
                placeholder={"Поиск"}
                handleChange={handleSearch}
                value={search}
              />
            </Box>
            <FormControl style={{minWidth: "200px"}}>
              <InputLabel id="status" shrink={true}>
                Статус
              </InputLabel>
              <Select
                name="client_status"
                native
                labelId="status"
                value={state?.client_status || null}
                onChange={handleChange}
              >
                <option value={""}>Все статусы</option>
                <option value={1}>VIP</option>
                <option value={2}>Инвестор</option>
              </Select>
            </FormControl>
            <InputText
              name="email"
              label="Email"
              placeholder="Введите email"
              value={state?.email || null}
              onChange={handleChange}
              fullWidth={false}
              className={classes.input_email}
            />
            <InputText
              name="client_iin"
              label="ИИН"
              placeholder="Введите ИИН"
              type="number"
              value={state?.client_iin || null}
              onChange={handleChange}
              fullWidth={false}
              className={classes.input_iin}
            />
            <InputPhone
              name="phone_number"
              type="number"
              label="Телефон"
              placeholder="Номер телефона"
              value={state?.phone_number || ""}
              onChange={handleChange}
              fullWidth={false}
            />
          </Box>
          <EmptyOrShow loading={loading}>
            <Box>
              <Box className={classes.table_head}>
                {headTitle.map(el => {
                  return (
                    <Box key={el} textAlign="left">
                      {el}
                    </Box>
                  );
                })}
              </Box>
              <ClientsRow search={search} clients={clients}/>
            </Box>
          </EmptyOrShow>
        </Box>
      ) : (
        <Box className={classes.empty_message}>
          <h1>Список клиентов доступен только РОП</h1>
        </Box>
      )}
    </Layout>
  );
};
const useStyles = makeStyles({
  main_block: {
    backgroundColor: "#F5F5F9",
    height: "100vh",
    padding: "45px 20px 0 20px"
  },
  table_head: {
    backgroundColor: "#F5F5F9",
    display: "grid",
    gridTemplateColumns: "100px 1fr 200px 1fr 200px 150px 110px 110px",
    width: "100%",

    "& div": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#9194A9",
      padding: 10,
      width: "100%"
    }
  },
  control_block: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: 20,
    padding: "20px 0",

    "& .react-tel-input .selected-flag:hover": {
      backgroundColor: "transparent !important"
    }
  },
  search: {
    minWidth: 300
  },
  empty_message: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  input_phone: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",

    "& input": {
      border: "none",
      outline: "none",
      background: "none",
      padding: "6px 0"
    }
  },
  input_iin: {
    width: 200
  },
  input_email: {
    width: 200
  }
});
