import instance from "api";
import { handleError } from "api/helpers";

export const getClients = params => {
  return instance(true)
    .post(`/crm/client/list/`, params)
    .then(res => {
      return res?.data;
    })
    .catch((err) => {
      return handleError(err);
    });
};
