import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import {getDocs} from "../../../pages/deals/view/api";
import EmptyOrShow from "../../EmptyOrShow";
import styleConstants from "../../../constants/styleConstants";
import AdditionalDocsRowComp from "../../../pages/deals/view/AdditionalDocsRowComp";

const useStyles = makeStyles({
  addDocs: {
    width: 1200,
    maxWidth: "90vw"
  },
  table_container: {
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      height: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table: {
    "& td": {
      padding: "5px 10px !important"
    }
  },
  block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4
  },
  documentName: {
    whiteSpace: "nowrap",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default"
  },
  name: {
    whiteSpace: "nowrap"
  },
  date: {
    whiteSpace: "nowrap"
  }
});

const titleArray = [
  "ID",
  "Наименование",
  "Тип",
  "Сумма ДС",
  "Сумма ДС по сверке",
  "Подписан клиентом",
  "Подписан подписантом",
  "Добавил",
  "Дата обновления",
  "Действия"
];

function AdditionalDocsModal({ crm_deal_id }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getDocs(crm_deal_id)
      .then(res => {
        setData(res.docs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crm_deal_id]);

  return (
    <Box className={classes.addDocs}>
      <Box className={classes.block}>
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table aria-label="a dense table" className={classes.table}>
              <TableHeadComp data={titleArray} />
              <TableBody>
                {data?.map(el => (
                  <AdditionalDocsRowComp
                    key={el.client_request_document_id}
                    {...el}
                    setData={setData}
                    crm_deal_id={crm_deal_id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Box>
  );
}

export default AdditionalDocsModal;
