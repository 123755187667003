import instance from "api";

export const getFormInfo = (id, is_client_form) => {
  if (!id) return
  return instance(true)
    .get(`/crm/crm_deals/form/${id}/?is_client_form=${is_client_form}`)
    .then(res => {
      return res?.data;
    })
};

export const getCities = () => {
  return instance(true)
    .get(`/crm/cities/`)
    .then(res => {
      return res?.data;
    })
};

export const getResidents = params => {
  return instance(true)
    .post(`/crm/residents/`, params)
    .then(res => {
      return res?.data;
    })
};

export const getCrmStatuses = () => {
  return instance(true)
    .get(`/crm/crm_statuses/`)
    .then(res => {
      return res?.data;
    })
};

export const getOrigins = (id = 1) => {
  return instance(true)
    .get(`/crm/origins/?report=${id}`)
    .then(res => {
      return res?.data;
    })
};

export const getClientTypes = () => {
  return instance(true)
    .get(`/crm/client_types/`)
    .then(res => {
      return res?.data;
    })
};

export const getHunters = id => {
  return instance(true)
    .get(`/crm/hunters/${id ? `?sale_department_id=${id}` : ""}`)
    .then(res => {
      return res?.data;
    })
};

export const getRemontTypes = () => {
  return instance(true)
    .get(`/crm/remont_types/`)
    .then(res => {
      return res?.data;
    })
};

export const getRemontKeys = () => {
  return instance(true)
    .get(`/crm/remont_keys/`)
    .then(res => {
      return res?.data;
    })
};

export const getBigEmployees = () => {
  return instance(true)
    .get(`/crm/big_employees/`)
    .then(res => {
      return res?.data;
    })
};

export const getSalePoints = id => {
  return instance(true)
    .get(`/crm/sale_points/${id ? `?sale_department_id=${id}` : ""}`)
    .then(res => {
      return res?.data;
    })
};

export const updateDeal = (id, is_client_form, params) => {
  return instance(true)
    .put(`/crm/crm_deals/form/${id}/?is_client_form=${is_client_form}`, params)
    .then(res => {
      return res?.data;
    });
};

export const createDeal = (is_client_form, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/?is_client_form=${is_client_form}`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDealFields = (id, client_type_id = "") => {
  return instance(true)
    .get(`/crm/crm_deals/form/${id}/fields/?client_type_id=${client_type_id}`)
    .then(res => {
      return res?.data;
    })
};

export const updateDealFields = (id, params, client_type_id) => {
  return instance(true)
    .put(
      `/crm/crm_deals/form/${id}/fields/?client_type_id=${client_type_id}`,
      params
    )
    .then(res => {
      return res?.data;
    })
};

export const getDocData = (client_id, deal_id) => {
  if (!client_id || !deal_id) return
  return instance(true)
    .get(`/crm/client/${client_id}/identification/${deal_id}/`)
    .then(res => {
      return res?.data;
    })
};

export const changeDocData = (client_id, deal_id, params) => {
  return instance(true)
    .post(`/crm/client/${client_id}/identification/${deal_id}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getBankData = client_id => {
  return instance(true)
    .get(`/crm/client/${client_id}/bank_details/`)
    .then(res => {
      return res?.data;
    })
};

export const changeBankData = (client_id, params) => {
  return instance(true)
    .post(`/crm/client/${client_id}/bank_details/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getContactData = client_id => {
  return instance(true)
    .get(`/crm/client/${client_id}/contact/`)
    .then(res => {
      return res?.data;
    })
};

export const deleteContactData = (client_id, contact_id, params) => {
  return instance(true)
    .delete(`/crm/client/${client_id}/contact/${contact_id}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const changeContactData = (client_id, params) => {
  return instance(true)
    .post(`/crm/client/${client_id}/contact/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getTransfer = id => {
  return instance(true)
    .get(`/crm/crm_deals/${id}/modals/key_receives/`)
    .then(res => {
      return res?.data;
    })
};

export const addTransfer = (id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${id}/modals/key_receives/`, params)
    .then(res => {
      return res?.data;
    });
};

export const changeTransfer = (deal_id, receive_id, params) => {
  return instance(true)
    .put(`/crm/crm_deals/${deal_id}/modals/key_receives/${receive_id}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const deleteTransfer = (deal_id, receive_id) => {
  return instance(true)
    .delete(`/crm/crm_deals/${deal_id}/modals/key_receives/${receive_id}/`)
    .then(res => {
      return res?.data;
    });
};

export const getKeys = deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${deal_id}/modals/keys/`)
    .then(res => {
      return res?.data;
    })
};

export const changeKeys = (deal_id, params) => {
  return instance(true)
    .put(`/crm/crm_deals/${deal_id}/modals/keys/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDocs = deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${deal_id}/modals/documents/`)
    .then(res => {
      return res?.data;
    });
};

export const addDocs = (id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${id}/modals/documents/`, params)
    .then(res => {
      return res?.data;
    });
};

export const deleteDocs = (id, docId) => {
  return instance(true)
    .delete(`/crm/crm_deals/${id}/modals/documents/${docId}`)
    .then(res => {
      return res?.data;
    });
};

export const getDealTasks = id => {
  return instance(true)
    .get(`/crm/crm_deals/${id}/tasks/`)
    .then(res => {
      return res?.data;
    })
};

export const createDealTask = (id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${id}/tasks/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getTaskTypes = () => {
  return instance(true)
    .get(`/crm/task_types/`)
    .then(res => {
      return res?.data;
    })
};

export const changeDealTask = (crmId, taskId, params) => {
  return instance(true)
    .put(`/crm/crm_deals/${crmId}/tasks/${taskId}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const deleteDealTask = (crmId, taskId, params) => {
  return instance(true)
    .delete(`/crm/crm_deals/${crmId}/tasks/${taskId}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDealComments = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/comments/`)
    .then(res => {
      return res?.data;
    })
};

export const postComment = (id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${id}/comments/`, params)
    .then(res => {
      return res?.data;
    });
};
export const changeComment = (id, commentId, params) => {
  return instance(true)
    .put(`/crm/crm_deals/${id}/comments/${commentId}/`, params)
    .then(res => {
      return res?.data;
    });
};
export const deleteComment = (id, commentId, params) => {
  return instance(true)
    .delete(`/crm/crm_deals/${id}/comments/${commentId}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDealHistory = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/history/`)
    .then(res => {
      return res?.data;
    })
};

export const getPayments = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/payments/`)
    .then(res => {
      return res?.data;
    })
};

export const addPayments = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/payments/`, params)
    .then(res => {
      return res?.data;
    });
};

export const changePayments = (crm_deal_id, payment_id, params) => {
  return instance(true)
    .put(`/crm/crm_deals/${crm_deal_id}/modals/payments/${payment_id}/`, params)
    .then(res => {
      return res?.data;
    });
};

export const deletePayments = (crm_deal_id, payment_id, params) => {
  return instance(true)
    .post(
      `/crm/crm_deals/${crm_deal_id}/modals/payments/${payment_id}/`,
      params
    )
    .then(res => {
      return res?.data;
    });
};

export const getTranches = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/tranches/`)
    .then(res => {
      return res?.data;
    })
};

export const getTechnics = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/techniques/`)
    .then(res => {
      return res?.data;
    })
};

export const endDealTask = (deal_id, task_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${deal_id}/tasks/${task_id}/complete/`, params)
    .then(res => {
      return res?.data;
    });
};
export const getEndTaskQuestions = async (deal_id, task_id) => {
  try {
    return await instance(true)
      .get(`/crm/crm_deals/${deal_id}/tasks/${task_id}/questions/`)
      .then(res => {
        return res?.data?.questions;
      });
  } catch (e) {
  }
};

export const callComplete = (deal_id, task_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${deal_id}/tasks/${task_id}/complete_call/`, params)
    .then(res => {
      return res?.data;
    });
};
export const callCompleteData = async (deal_id, task_id, body) => {
  try {
    return await callComplete(deal_id, task_id, body)
  } catch (e) {}
};

export const getKitchenInfo = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/kitchen/`)
    .then(res => {
      return res?.data;
    })
};

export const getTechFeature = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/tech_feature/`)
    .then(res => {
      return res?.data;
    })
};

export const changeTechFeature = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/tech_feature/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDocTypes = () => {
  return instance(true)
    .get(`/crm/document_types/`)
    .then(res => {
      return res?.data;
    })
};
export const getApproveTypes = () => {
  return instance(true)
    .get(`/crm/approve_document_types/`)
    .then(res => {
      return res?.data;
    })
};
export const getDsTypes = () => {
  return instance(true)
    .get(`/crm/ds_types/`)
    .then(res => {
      return res?.data;
    })
};
export const getUnsignedXml = document_id => {
  return instance(true)
    .get(`/crm/documents/${document_id}/xml/`)
    .then(res => {
      return res?.data;
    })
};

export const signVerifiedXml = (deal_id, document_id, params) => {
  return instance(true)
    .post(
      `/crm/crm_deals/${deal_id}/modals/documents/${document_id}/sign/`,
      params
    )
    .then(res => {
      return res?.data;
    });
};

export const getSaleTypes = () => {
  return instance(true)
    .get(`/crm/sale_types/`)
    .then(res => {
      return res?.data;
    })
};

export const getDealSales = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/sales/`)
    .then(res => {
      return res?.data;
    })
};

export const getSales = (city_id = 1, params = {}) => {
  return instance(true)
    .post(`/crm/cities/${city_id}/sales/`, params)
    .then(res => {
      return res?.data;
    });
};

export const changeSale = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/sales/`, params)
    .then(res => {
      return res?.data;
    });
};
export const changeContractStatus = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/contract/`, params)
    .then(res => {
      return res?.data;
    });
};
export const getContractStatus = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/contract/`)
    .then(res => {
      return res?.data;
    });
};
export const getAuditorStatus = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/auditor/`)
    .then(res => {
      return res?.data;
    });
};
export const changeAuditorStatus = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/auditor/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDealStatuses = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/statuses/`)
    .then(res => {
      return res?.data;
    })
};
export const changeDealStatus = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/statuses/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getReportTemplates = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/report_templates/`)
    .then(res => {
      return res?.data;
    })
};

export const checkReportTemplates = (crm_deal_id, params) => {
  return instance(true)
    .post(
      `/crm/crm_deals/${crm_deal_id}/modals/report_templates/check/`,
      params
    )
    .then(res => {
      return res?.data;
    });
};

export const getBtnStatuses = (crm_deal_id, client_type_id = "") => {
  return instance(true)
    .get(
      `/crm/crm_deals/form/${crm_deal_id}/btns_identifier/${
        client_type_id ? `?client_type_id=${client_type_id}` : ""
      }`
    )
    .then(res => {
      return res?.data;
    });
};

export const getDenyReasons = () => {
  return instance(true)
    .get(`/crm/deny_reasons`)
    .then(res => {
      return res?.data;
    });
};

export const getMainDocs = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/documents/`)
    .then(res => {
      return res?.data;
    })
};

export const changeMainDocs = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/documents/`, params)
    .then(res => {
      return res?.data;
    });
};

export const deleteMainDocs = (crm_deal_id, doc_id) => {
  return instance(true)
    .delete(`/crm/crm_deals/form/${crm_deal_id}/documents/${doc_id}/`)
    .then(res => {
      return res?.data;
    });
};

export const changeHunter = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/hunters/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getDealHunters = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/hunters/`)
    .then(res => {
      return res?.data;
    })
};

export const deleteHunter = (crm_deal_id, hunter_id) => {
  return instance(true)
    .delete(`/crm/crm_deals/${crm_deal_id}/modals/hunters/${hunter_id}/`)
    .then(res => {
      return res?.data;
    });
};

export const getPresets = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/presets/`)
    .then(res => {
      return res?.data;
    })
};
export const changeMainPreset = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/presets/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getContractNum = () => {
  return instance(true)
    .get(`/crm/prop_number/`)
    .then(res => {
      return res?.data;
    })
};

export const getDiscounts = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/discounts`)
    .then(res => {
      return res?.data;
    })
};
export const getBanks = (sale_type_id) => {
  if(!sale_type_id) return new Promise((resolve) => resolve([]))
  return instance(true)
    .get(`/crm/banks/${sale_type_id}`)
    .then(res => {
      return res?.data;
    })
};
export const getDealAllSales = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/crm_sales/`)
    .then(res => {
      return res?.data;
    })
};

export const addSale = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/crm_sales/`, params)
    .then(res => {
      return res?.data;
    });
};

export const removeSale = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/crm_sales/del/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getGisLink = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/gis_link/`)
    .then(res => {
      return res?.data;
    })
};

export const changeGisLink = (crm_deal_id, params) => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/modals/gis_link/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getClientData = params => {
  return instance(true)
    .post(`/crm/client/iin/`, params)
    .then(res => {
      return res?.data;
    });
};
export const changeShowConstructorPrice = (crm_deal_id, body, is_client_form) => {
  try {
    return instance(true)
      .put(`/crm/crm_deals/form/${crm_deal_id}/constructor_price/`, body, {params: {is_client_form}})
      .then(res => {
        return res?.data;
      });
  } catch (e) {}
};

export const getResidentsTable = client_id => {
  return instance(true)
    .get(`/crm/client/residents/?client_id=${client_id}`)
    .then(res => {
      return res?.data;
    });
};
export const saveResidentsTable = params => {
  return instance(true)
    .post(`/crm/client/residents/`, params)
    .then(res => {
      return res?.data;
    });
};
export const getResidentsTableByIin = iin => {
  return instance(true)
    .get(`/crm/client/residents/?iin=${iin}`)
    .then(res => {
      return res?.data;
    })
};
export const getRepair = crm_deal_id => {
  if (!crm_deal_id) return
  return instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/remont/`)
    .then(res => {
      return res?.data;
    })
};
export const getSaleDepart = () => {
  return instance(true)
    .get(`/crm/sale_departments/`)
    .then(res => {
      return res?.data;
    })
};
export const changeSaleDepart = (crm_deal_id, is_client_form, params) => {
  return instance(true)
    .post(
      `/crm/crm_deals/form/${crm_deal_id}/sale_department/?is_client_form=${is_client_form}`,
      params
    )
    .then(res => {
      return res?.data;
    });
};
export const getSaleDepartment = crm_deal_id => {
  return instance(true)
    .get(`/crm/crm_deals/form/${crm_deal_id}/sale_departments_view/`)
    .then(res => {
      return res?.data;
    });
};
export const setSaleDepartment = (crm_deal_id, sale_department_id) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/sale_departments_view/`, {
      sale_department_id
    })
    .then(res => {
      return res?.data;
    });
};
export const resign = (crm_deal_id, sign_id) => {
  return instance(true)
    .post(`/crm/crm_deals/form/${crm_deal_id}/resign/`, {
      sign_id
    })
    .then(res => {
      return res?.data;
    });
};
export const recount = async (crm_deal_id) => {
  return await instance(true)
    .get(`/crm/crm_deals/${crm_deal_id}/modals/recalculate/`).then(res => res?.data);
};
