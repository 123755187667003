import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import EmptyOrShow from "../../../components/EmptyOrShow";
import { MAIN_URL } from "../../../constants";
import {
  DownloadIcon,
  PlusDefIcon,
  WordIcon
} from "../../../components/UI/Icons";
import {
  AiOutlineFile,
  BsFileEarmarkExcel,
  BsFileEarmarkPdf
} from "react-icons/all";

const useStyles = makeStyles({
  wrapper: {
    position: "relative"
  },
  dropzone: {
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: "transparent",
    padding: 5
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194a9",
    margin: "0 0 10px 0"
  },
  content: {
    borderBottom: "1px solid #f0f2f6",
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "100%",
    paddingBottom: 5,
    cursor: "pointer"
  },
  content_title: {
    fontSize: 14,
    lineHeight: "15px",
    color: "#6191ef",
    display: "flex",
    alignItems: "center",
    flex: 1,
    height: 20,
    margin: "0 25px 0 0",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  button_download: {
    backgroundColor: "#fff",
    width: 20,
    height: 20,
    position: "absolute",
    top: "50%",
    right: 35
  }
});

const DownloadDoc = ({
  document_name,
  document_url,
  title,
  name,
  ext,
  isUploading = false,
  onChange,
  getRootProps,
  getInputProps,
  isDragAccept
}) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (ext) {
      case ".pdf":
        return <BsFileEarmarkPdf size={20} color="#FF4754" />;
      case ".docx":
        return <WordIcon />;
      case ".xlsx":
        return <BsFileEarmarkExcel size={20} color="#1D6F42" />;
      default:
        return <AiOutlineFile size={20} color="#6191EF" />;
    }
  };

  const acceptStyle = {
    borderColor: "#6191EF"
  };

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {})
    }),
    [isDragAccept]
  );

  return (
    <Box className={classes.wrapper}>
      <Box {...getRootProps({ style })} className={classes.dropzone}>
        <input
          {...getInputProps()}
          name={name}
          id={"upload-file-" + name}
          onChange={e =>
            onChange({
              target: { name, value: e.target.files }
            })
          }
        />
        <Box className={classes.label}>{title}</Box>
        <Box className={classes.content}>
          {getIcon()}
          <Box className={classes.content_title} title={document_name}>
            {document_name}
          </Box>
          <PlusDefIcon width={20} height={20} style={{ color: "#6A6A6A" }} />
        </Box>
        {isUploading ? (
          <Box>
            <EmptyOrShow loading={isUploading} />
          </Box>
        ) : null}
      </Box>
      <a
        target="_blank" rel="noopener noreferrer"
        href={MAIN_URL + document_url}
        className={classes.button_download}
      >
        <DownloadIcon />
      </a>
    </Box>
  );
};

export default DownloadDoc;
