import React, {useEffect, useState} from "react";
import CustomButton from "../../UI/Button";
import styleConstants from "../../../constants/styleConstants";
import {Box, TextareaAutosize} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {callCompleteData, getDealStatuses, getEndTaskQuestions} from "../../../pages/deals/view/api";
import CustomSelect from "../../UI/Select";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {setShowModal, setStatusInfo, setTasks} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import EmptyOrShow from "../../EmptyOrShow";

export const EndTaskModal = ({deal_id, task_id, afterEndTask, dealMode}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {addCloseSnack, addDefaultSnack} = useCustomSnackbar();
  const [questions, setQuestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true)
      const res = await getEndTaskQuestions(deal_id, task_id)
      setIsFetching(false)
      if (!res) return
      setQuestions(res)
    }
    getData()
  }, [deal_id, task_id])

  const handleChange = (id, key, value) => {
    setQuestions(prev => prev.map(item => {
      if (item.crm_task_type_question_id === id)
        return {...item, [key]: value}
      return item
    }))
  };

  const handleSubmit = async () => {
    const isRequiredFieldEmpty = questions.some(item => {
      if (item.required_type === 3) {
        return !item.crm_task_type_answer_id || !item.comments
      }
      if (item.required_type === 1) {
        return !item.crm_task_type_answer_id
      }
      if (item.required_type === 2) {
        return !item.comments
      }
      return false
    })
    if (isRequiredFieldEmpty)
      return addCloseSnack('Заполните обязательные поля!')
    const answers = questions.map(item => ({
      crm_task_type_question_id: item.crm_task_type_question_id,
      crm_task_type_answer_id: item.crm_task_type_answer_id,
      comments: item.comments
    }))
    setLoading(true)
    const res = await callCompleteData(deal_id, task_id, {answers})
    setLoading(false)
    if (!res) return
    addDefaultSnack("Задача успешно завершена");
    dispatch(setShowModal(false));
    if(afterEndTask)
      afterEndTask()
    if(dealMode) {
      dispatch(setTasks(res));
      getDealStatuses(deal_id).then(res => {
        dispatch(setStatusInfo(res));
      });
    }
  }

  return (
    <Box className={classes.wrapper}>
      <EmptyOrShow loading={isFetching}/>
      <Box className={classes.rowsWrapper}>
        {
          questions?.map((item, index) => {
            return <div key={item.crm_task_type_question_id} className={classes.row}>
              <div className={classes.title}>{index + 1}) {item.crm_task_type_question}</div>
              <div className={classes.inputs}>
                <CustomSelect
                  name="crm_task_type_answer_id"
                  label={"Ответ"}
                  required={item.required_type === 1 || item.required_type === 3}
                  options={item.answers}
                  optionLabel={"crm_task_type_answer"}
                  placeholder="Выберите ответ"
                  selected={item.crm_task_type_answer_id}
                  valueKey={"crm_task_type_answer_id"}
                  handleChange={(e, v) => handleChange(item.crm_task_type_question_id, 'crm_task_type_answer_id', v?.crm_task_type_answer_id)}
                />
                <TextareaAutosize
                  className={classes.textArea}
                  minRows={2.5} maxRows={5}
                  placeholder="Введите комментарий"
                  autoFocus
                  value={item.comments || ''}
                  onChange={e => handleChange(item.crm_task_type_question_id, 'comments', e.target.value)}
                />
              </div>
            </div>
          })
        }
      </Box>
      <Box className={classes.footer}>
        <CustomButton
          disabled={isFetching || loading}
          btnText={"Отмена"}
          bgColor={styleConstants.errorColor}
          onClick={() => dispatch(setShowModal(false))} fullWidth={false}
        />
        <CustomButton
          loading={loading} disabled={isFetching}
          btnText={"Завершить"}
          bgColor={styleConstants.primaryColor}
          onClick={handleSubmit} fullWidth={false}
        />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    minWidth: "730px",
    [theme.breakpoints.down(768)]: {
      minWidth: "calc(100vw - 70px)",
    },
  },
  rowsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: "15px",
    marginBottom: '15px'
  },
  row: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderBottom: '1px solid #c1c1c1',
    paddingBottom: '15px',
    [theme.breakpoints.down(768)]: {
      display: "flex",
    },
    '& > div': {
      flexGrow: "1",
    },
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0
    },
  },
  inputs: {
    display: "grid",
    gridTemplateColumns: "4fr 8fr",
    gap: "15px",
  },
  title: {
    fontWeight: '700',
    color: '#152e44',
    lineHeight: "18px"
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: "10px"
  },
  textArea: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#f5f5f9",
    fontSize: 14,
    color: "#494C62",
    outline: "none",
    letterSpacing: "0.01em",
    whiteSpace: "pre-wrap",
    resize: "vertical",
    maxHeight: "120px",
    minHeight: "17px"
  }
}));
