import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Checkbox, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import { RiArrowDownSLine } from "react-icons/ri";
import { isEmpty } from "components/helpers";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default function AutoSuggest({
  label = "",
  name = "",
  selected = "",
  onChange,
  list = [],
  showKey = "name",
  valueKey = "id",
  placeholder,
  disabled,
  className = "",
  error = "",
  noOptionsText = "Ничего не найдено",
  disableClearable = false,
  isMultiple = false,
  isMultipleCheckbox = false,
  controlledMode = false,
}) {
  const [value, setValue] = useState("");
  const [multipleValue, setMultipleValue] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (isMultiple) {
      let sel = list?.filter((i) =>
        selected?.length ? selected?.some((e) => i[valueKey] === e) : false
      );
      if (sel) {
        setMultipleValue(sel);
      } else {
        setMultipleValue([]);
      }
    } else {
      let sel = list?.filter((i) => i[valueKey] === selected)[0];
      if (sel) {
        setValue(sel);
      } else {
        setValue(null);
      }
    }
  }, [selected, list, isMultiple, valueKey]);

  function handleChange(_, newValue, reason) {
    let e = { target: { name: name, value: "" } };
    if (isMultiple) {
      e.target.value = newValue?.map((el) => el[valueKey]) || null;
      onChange(e);
      setMultipleValue(newValue);
    } else {
      if (newValue || newValue === null) {
        if (!isEmpty(newValue)) e.target.value = newValue[valueKey];
        else e.target.value = newValue;
        setValue(newValue);
      }
      onChange(e, newValue, reason);
    }
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const OptionR = (item, { selected }) => {
    return (
      <Fragment>
        {isMultipleCheckbox && (
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
        )}
        <span className={classes.option} value={item[valueKey]}>
          {item[showKey]}
        </span>
      </Fragment>
    );
  };

  const inputValue = useMemo(() => {
    if (controlledMode) {
      if (isMultiple) {
        const valueObj = list?.filter((i) =>
          selected?.length ? selected?.some((e) => i[valueKey] === e) : false
        );
        return valueObj || [];
      }
      const valueObj = list?.filter((i) => i[valueKey] === selected)[0];
      return valueObj || null;
    }
    return isMultiple ? (multipleValue ? multipleValue : []) : value;
  }, [controlledMode, isMultiple, value, multipleValue, selected, list]);

  return (
    <div className={className}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Autocomplete
        multiple={isMultiple}
        disableClearable={disableClearable}
        id={name}
        name={name}
        options={list}
        getOptionLabel={(option) => option[showKey] || ""}
        value={inputValue}
        onChange={handleChange}
        className={classes.select}
        popupIcon={<RiArrowDownSLine className={classes.icon} />}
        onInputChange={(event, newInputValue) => {
          if (!newInputValue) handleChange(event);
        }}
        noOptionsText={noOptionsText}
        disableCloseOnSelect={isMultipleCheckbox}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            variant="standard"
            placeholder={placeholder}
            helperText={error}
            error={Boolean(error)}
          />
        )}
        renderOption={OptionR}
        disabled={disabled}
      />
    </div>
  );
}

const useStyles = makeStyles({
  select: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3,
  },
  icon: {
    color: styleConstants.primaryColor,
  },
});
