import React from "react";
import {useDispatch} from "react-redux";
import {setShowModal, setStatusInfo} from "../../../services/redux/actions/app-actions";
import {changeDealTask, deleteDealTask, getDealStatuses} from "./api";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";

import Task from "../../deals/view/Task";
import CustomIconButton from "../../../components/UI/CustomIconButton";
import styleConstants from "../../../constants/styleConstants";
import {MODALS} from "../../../constants";
import {Delete, Edit} from "react-iconly";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {EndTaskButton} from "../../tasks/view/EndTaskButton";

const TaskTabItem = ({
                       fio,
                       crm_deal_id,
                       crm_task_type_id,
                       crm_deal_task_id,
                       crm_task_type_name,
                       date_create,
                       date_finish,
                       date_complete,
                       task_text,
                       time_left,
                       is_complete,
                       is_question,
                       setTasks,
                       is_asap,
                       crm_task_type_code,
                       deny_reason_name,
                       deny_comment
                     }) => {
  const classes = useStyles();
  const {addDefaultSnack} = useCustomSnackbar();
  const dispatch = useDispatch();

  const handleSave = params => {
    changeDealTask(crm_deal_id, crm_deal_task_id, params)
      .then(res => {
        dispatch(setTasks(res));
        addDefaultSnack("Задача успешно изменена");
        getDealStatuses(crm_deal_id)
          .then(res => {
            dispatch(setStatusInfo(res));
          })
          .catch(() => {
          });
        dispatch(setShowModal(false));
      })
      .catch(() => {
      });
  };

  const handleDelete = () => {
    deleteDealTask(crm_deal_id, crm_deal_task_id)
      .then(res => {
        dispatch(setTasks(res));
        addDefaultSnack("Задача успешно удалена");
        dispatch(setShowModal(false));
      })
      .catch(() => {
      });
  };

  return (
    <Box className={classes.wrapper}>
      <Task
        title={crm_task_type_name}
        date={date_create}
        time={time_left}
        taskId={crm_deal_task_id}
        is_asap={is_asap}
        crm_task_type_code={crm_task_type_code}
        deny_reason_name={deny_reason_name}
        deny_comment={deny_comment}
      />
      <Box className={classes.info}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.title}>Исполнитель:</Typography>
          <Typography className={classes.desc}>{fio}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title}>Дедлайн:</Typography>
          <Typography className={classes.desc}>{date_finish}</Typography>
        </Box>
        <Typography className={classes.title}>Комментарий:</Typography>
        <Typography className={classes.desc}>{task_text}</Typography>
        {deny_reason_name ? (
          <Box className={classes.deny_class}>
            <Typography className={classes.title}>Причина отказа: <b>{deny_reason_name}</b></Typography>
            <Typography className={classes.title}>Комментарий отказа: <b>{deny_comment}</b></Typography>
          </Box>
        ) : null}
        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          style={{gap: 10}}
        >
          <Box width={260} flex={1}>
            <EndTaskButton crm_deal_id={crm_deal_id} crm_deal_task_id={crm_deal_task_id}
                           crm_task_type_name={crm_task_type_name} is_complete={is_complete}
                           date_complete={date_complete}
                           is_question={is_question} dealMode />
          </Box>
          {!is_complete && !is_question ? (
            <CustomIconButton
              width={38}
              height={38}
              color={styleConstants.primaryColor}
              handleClick={() => {
                dispatch(
                  setShowModal(true, MODALS.addTask, {
                    title: "Изменить задачу",
                    initialValues: {
                      crm_task_type_id,
                      task_text,
                      date:
                        date_finish === null
                          ? ""
                          : date_finish.split(",")[1].trim(),
                      time:
                        date_finish === null
                          ? "00:00"
                          : date_finish.split(",")[0]
                    },
                    handleSave
                  })
                );
              }}
            >
              <Edit set="light" primaryColor={styleConstants.primaryColor}/>
            </CustomIconButton>
          ) : null}
          {!is_question ? (
            <CustomIconButton
              width={38}
              height={38}
              color="#FF6262"
              handleClick={() => {
                dispatch(
                  setShowModal(true, MODALS.confirmDelete, {
                    title: "Удаление задачи",
                    handleDelete
                  })
                );
              }}
            >
              <Delete set="light" primaryColor="#FF6262"/>
            </CustomIconButton>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskTabItem;

const useStyles = makeStyles({
  wrapper: {
    border: styleConstants.border,
    borderRadius: styleConstants.borderRadius,
    backgroundColor: "#fff",
    marginBottom: 10
  },
  info: {
    padding: 10,
    position: "relative"
  },
  title: {
    fontSize: 12,
    color: styleConstants.darkGray
  },
  desc: {
    fontSize: 12
  },
  btn_disable: {
    pointerEvents: "none",
    opacity: "0.4"
  },
  deny_class: {
    border: "2px solid #E2E3ED",
    padding: "4px 6px",
    marginTop: "10px",
    borderRadius: "4px"
  }
});
