import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {CloseIcon} from "../../../components/UI/Icons";
import styleConstants from "../../../constants/styleConstants";
import LaunchIcon from '@material-ui/icons/Launch';
import {formatSum} from "../../../utils";

const SaleItem = ({
                    sale_type_name,
                    sale_name,
                    handleRemoveSale,
                    material_area_discount,
                    credit_fio,
                    detailView = false,
                    onClickDetail = () => {} ,
                    credit_sum
                  }) => {
  const classes = useStyles();

  return (
    <Box className={classes.item}>
      <Box className={classes.item_inner}>
        <Box className={classes.item_title}>
          <span className={classes.title}>{sale_type_name}</span>
          <span className={classes.subtitle}>{sale_name}</span>
          <span className={classes.subtitle}>{credit_fio}</span>
          {credit_sum ? (
            <span className={classes.subtitle}>
              {formatSum(credit_sum)} тг
            </span>
          ) : null}
          {material_area_discount ? (
            <span className={classes.subtitle}>
              {formatSum(material_area_discount)} тг
            </span>
          ) : null}
        </Box>
        <Box className={classes.delete_sale} >
          {detailView && <LaunchIcon onClick={onClickDetail} className={classes.openIcon}/>}
          <CloseIcon onClick={handleRemoveSale} className={classes.closeIcon}/>
        </Box>
      </Box>
    </Box>
  );
};

export default SaleItem;

const useStyles = makeStyles({
  item: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 10
  },
  item_inner: {
    backgroundColor: "#F5F5F9",
    borderRadius: styleConstants.borderRadius,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    padding: 10
  },
  item_title: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: "#494C62",
    marginBottom: 5
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: "#9194A9"
  },
  openIcon: {
    color: "#6191EF"
  },
  closeIcon: {
    marginLeft: 10,
    '& line': {
      stroke: "#ff4a4a"
    }
  },
  delete_sale: {
    display: "inline-flex",
    cursor: "pointer",
    alignItems: 'center'
  }
});
