import instance from "api";

export const getKpi = (year, sd_id) => {
  let data = { year };
  if (sd_id) data.sale_department_id = sd_id;
  return instance(true)
    .get(`/crm/kpi/sd_month_plan/`, { params: data })
    .then(res => {
      return res?.data;
    })
};

export const getSaleDeptId = () => {
  return instance(true)
    .get(`/crm/sale_departments`)
    .then(res => {
      return res?.data;
    })
};

export const addMonth = (year, sd_id, params) => {
  return instance(true)
    .post(
      `/crm/kpi/sd_month_plan/?year=${year}&sale_department_id=${sd_id}`,
      params
    )
    .then(res => {
      return res?.data;
    })
};

export const changeKpi = (id, year, sd_id, params) => {
  return instance(true)
    .put(
      `/crm/kpi/sd_month_plan/${id}/?year=${year}&sale_department_id=${sd_id}`,
      params
    )
    .then(res => {
      return res?.data;
    })
};
