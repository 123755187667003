import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setShowModal, setStatusInfo } from "../../../services/redux/actions/app-actions";

import { makeStyles } from "@material-ui/core/styles";
import { TextareaAutosize } from "@material-ui/core";

import { changeDealStatus, getDenyReasons } from "../../../pages/deals/view/api";
import AutoSuggest from "../../UI/AutoSuggest";
import CustomButton from "../../UI/Button";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  denial: {
    width: 400
  },
  denial_comment: {
    color: "#494C62",
    border: "none",
    borderRadius: 4,
    outline: "none",
    resize: "none",
    width: "100%",
    marginBottom: 10,
    padding: 10
  }
});

const DeniedContract = ({ crm_deal_id }) => {
  const classes = useStyles();
  const [reasons, setReasons] = useState([]);
  const [reasonId, setReasonId] = useState(null);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getDenyReasons()
      .then(res => {
        setReasons(res);
      })
      .catch(() => {
      });
  }, []);

  const handleChange = e => {
    setReasonId(e.target.value);
  };

  const handleClick = () => {
    changeDealStatus(crm_deal_id, {
      deny_reason_id: reasonId,
      is_denied: true,
      comments: comment
    })
      .then(res => {
        dispatch(setStatusInfo(res));
        enqueueSnackbar("Успешно", {
          variant: "success"
        });
        dispatch(setShowModal(false));
        window.location.reload();
      })
      .catch(err => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  };

  return (
    <div className={classes.denial}>
      <AutoSuggest
        label="Причина"
        placeholder="Выберите причину отказа"
        name="deny_reason_id"
        list={reasons}
        valueKey="deny_reason_id"
        showKey="deny_reason_name"
        onChange={handleChange}
      />
      <TextareaAutosize
        className={classes.denial_comment}
        minRows={5}
        placeholder="Введите комментарий"
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
      <CustomButton btnText="Сохранить" handleClick={handleClick} />
    </div>
  );
};

export default DeniedContract;
