import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  addSale,
  getBanks,
  getDealSales,
  getDiscounts,
  getSaleTypes,
} from "../../../pages/deals/view/api";
import CustomButton from "../../UI/Button";
import AutoSuggest from "../../UI/AutoSuggest";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../EmptyOrShow";
import { isEmpty } from "../../helpers";
import { setShowModal } from "../../../services/redux/actions/app-actions";
import InputText from "../../Form/InputText";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import uuid4 from "uuid4";
import { formatSum } from "../../../utils";

const SalesModal = ({ crmDealId, setDealSaleInfo = () => {}, body = null }) => {
  const dispatch = useDispatch();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const [sales, setSales] = useState([]);
  const [banks, setBanks] = useState([]);
  const [state, setState] = useState(body || {});
  const [loading, setLoading] = useState(false);
  const [saleTypes, setSaleTypes] = useState([]);
  const [inputState, setInputState] = useState({});
  const [amounts, setAmounts] = useState(
    body?.sums?.map((item) => ({
      ...item,
      fio: item.credit_fio,
      iin: item.credit_iin,
      sum: item.credit_sum,
      id: uuid4(),
    })) || [{ id: uuid4(), fio: "", sum: "", bank_id: "" }]
  );
  const classes = useStyles({ isBank: state?.is_bank });

  useEffect(() => {
    getSaleTypes().then((res) => {
      setSaleTypes(res);
    });
  }, []);

  const isFilledAndNotice = () => {
    return amounts.every(
      (item) => !!item.fio && !!item.sum && !!item.bank_id && !!item.bank_id
    );
  };

  const handleSubmit = () => {
    const body = { ...state };
    if (inputState?.sale_id) {
      body.sale_id = inputState.sale_id;
    }
    if (state?.is_bank) {
      if (!isFilledAndNotice())
        return addCloseSnack("Заполните пустые поля", "error", 1000);
      body.sums = amounts.map((item) => ({
        sum: +item.sum,
        fio: item.fio,
        bank_id: item.bank_id,
        credit_iin: item.credit_iin,
      }));
      const isIinsCorrect = body.sums?.every(
        (item) => item.credit_iin?.length === 12
      );
      if (!isIinsCorrect)
        return addCloseSnack("Длина ИИН должна быть 12 символов");
    }
    setLoading(true);
    addSale(crmDealId, body)
      .then((res) => {
        addDefaultSnack();
        setDealSaleInfo(res);
        dispatch(setShowModal(false));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getContent = useCallback(() => {
    setLoading(true);
    if (state.is_bank) {
      getBanks(state.sale_type_id)
        .then((res) => {
          setBanks(res || []);
        })
        .finally(() => setLoading(false));
      return;
    }
    switch (state?.sale_type_code) {
      case "DISCOUNT":
        getDiscounts(crmDealId).then((res) => {
          setSales(res);
        });
        return setLoading(false);
      case "STOCK":
        getDealSales(crmDealId).then((res) => {
          setSales(res);
        });
        return setLoading(false);
      default:
        return setLoading(false);
    }
  }, [state.sale_type_code, state.is_bank, state.sale_type_id, crmDealId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const handleChange = (e, newValue) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    if (newValue?.is_input_sum === 1) {
      setInputState(newValue);
    }
    if (newValue?.discount_percent) {
      setState({
        sale_type_code: state.sale_type_code,
        discount_id: e.target.value,
        discount_amount: newValue.discount_percent,
      });
    }
  };

  const onSumChange = (id, key, value) => {
    setAmounts((prev) =>
      prev?.map((item) => {
        if (item.id === id) return { ...item, [key]: value };
        return item;
      })
    );
  };
  const addSum = () => {
    if (!isFilledAndNotice())
      return addCloseSnack("Заполните пустые поля", "error", 1000);
    const newSum = {
      id: uuid4(),
      fio: "",
      sum: "",
      bank_id: "",
      credit_iin: "",
    };
    setAmounts((prev) => [...prev, newSum]);
  };
  const removeSum = (id) => {
    setAmounts((prev) => prev?.filter((item) => item.id !== id));
  };
  const handleTypeCodeChange = (e, obj) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      is_bank: obj?.is_bank,
      sale_type_id: obj?.sale_type_id,
    }));
    setInputState({});
  };

  const banksList = useMemo(() => {
    if (!!body) {
      return [
        {
          bank_id: 1,
          bank_name: body.bank_name || "",
        },
      ];
    }
    return banks;
  }, [banks, body]);

  const isDisabled = !!body;
  return (
    <EmptyOrShow loading={loading}>
      <Box className={classes.wrapper}>
        <Box>
          <AutoSuggest
            label="Тип"
            name={"sale_type_code"}
            list={saleTypes}
            selected={state.sale_type_code}
            showKey="sale_type_name"
            valueKey="sale_type_code"
            disabled={isDisabled}
            placeholder="Выберите тип акции"
            onChange={handleTypeCodeChange}
          />
        </Box>

        {state.is_bank ? (
          <Box>
            {amounts.map((item, idx) => {
              return (
                <div key={item.id} className={classes.sum}>
                  <AutoSuggest
                    list={banksList}
                    selected={body ? 1 : item.bank_id}
                    label="Банк"
                    name={"bank_id"}
                    showKey="bank_name"
                    valueKey="bank_id"
                    disabled={isDisabled}
                    placeholder="Банк"
                    onChange={(e) =>
                      onSumChange(item.id, "bank_id", e.target.value)
                    }
                  />
                  <InputText
                    name="credit_fio"
                    label="ФИО"
                    placeholder="Введите ФИО"
                    value={item?.fio}
                    disabled={isDisabled}
                    onChange={(e) =>
                      onSumChange(item.id, "fio", e.target.value)
                    }
                  />
                  <InputText
                    name="credit_iin"
                    label="ИИН"
                    numberText
                    placeholder="Введите ИИН"
                    disabled={isDisabled}
                    value={item?.credit_iin}
                    onChange={(e) =>
                      onSumChange(item.id, "credit_iin", e.target.value)
                    }
                  />
                  <div className={classes.flex}>
                    <InputText
                      name="credit_sum"
                      label="Сумма"
                      placeholder="Сумма"
                      disabled={isDisabled}
                      value={item?.sum}
                      onChange={(e) =>
                        onSumChange(item.id, "sum", e.target.value)
                      }
                    />
                    <div className={classes.actions}>
                      {amounts.length > 1 && !isDisabled ? (
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={() => removeSum(item.id)}
                        >
                          <CloseIcon
                            className={classes.actionIcon}
                            fontSize="small"
                            style={{ fill: "red" }}
                          />
                        </IconButton>
                      ) : (
                        <span />
                      )}
                      {idx === amounts.length - 1 && !isDisabled ? (
                        <IconButton
                          size="small"
                          aria-label="add"
                          color="inherit"
                          onClick={addSum}
                        >
                          <AddIcon
                            fontSize="medium"
                            className={classes.actionIcon}
                            style={{ fill: "green" }}
                          />
                        </IconButton>
                      ) : (
                        <span />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Box>
        ) : (
          <Box width={300}>
            <AutoSuggest
              list={sales}
              label="Скидка"
              selected={
                state?.sale_type_code === "DISCOUNT"
                  ? state?.discount_id
                  : state?.sale_id
              }
              name={
                state?.sale_type_code === "DISCOUNT" ? "discount_id" : "sale_id"
              }
              showKey={
                state?.sale_type_code === "DISCOUNT"
                  ? "discount_name"
                  : "sale_name"
              }
              valueKey={
                state?.sale_type_code === "DISCOUNT" ? "discount_id" : "sale_id"
              }
              placeholder="Выберите скидку"
              onChange={handleChange}
            />
            {inputState?.is_input_sum === 1 ? (
              <InputText
                name="discount_amount"
                label="Значение"
                value={
                  inputState?.discount_percent
                    ? inputState?.discount_percent
                    : state?.discount_amount
                }
                disabled={Boolean(inputState?.discount_percent)}
                onChange={handleChange}
              />
            ) : null}
          </Box>
        )}

        {state?.credit_sum ? (
          <p className={classes.creditSum}>
            Общая сумма: {formatSum(state.credit_sum)} тг
          </p>
        ) : null}

        {!isDisabled && (
          <Box display="flex" justifyContent="flex-end">
            <Box width={111}>
              <CustomButton
                btnText="Сохранить"
                handleClick={handleSubmit}
                disabled={isEmpty(state)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </EmptyOrShow>
  );
};

export default SalesModal;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: (props) => (props?.isBank ? 950 : 300),
    [theme.breakpoints.down(991)]: {
      width: "100%",
    },
  },
  sum: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "3fr 4fr 120px 2fr",
    gap: 10,
    marginTop: 10,
  },
  creditSum: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 18,
    margin: 0,
    fontWeight: 600,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  },
  actions: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "6fr 6fr",
    gap: 10,
    width: 100,
  },
  actionIcon: {
    fontSize: 26,
  },
}));
