import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Box, FormControl, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import clsx from "clsx";
import {getCities} from "./api";
import {useDispatch, useSelector} from "react-redux";
import {logout, setMainCity, toggleTasksPage} from "../../services/redux/actions/app-actions";
import {LOCALSTORAGEFIELDS} from "../../constants";
import cookiesService from "../../services/cookiesService";
import LocalStorageService from "../../api/localStorage";
import {useHistory, useLocation} from "react-router-dom";
import {SellerSide} from "./SellerSide";

const localStorageService = LocalStorageService.getService();
const cookiesServiceInstance = cookiesService.getService();

const defaultCity = localStorage.getItem(LOCALSTORAGEFIELDS.city);

function Navbar() {
  const location = useLocation();
  const classes = useNavStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [city, setCity] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCity, setSelectedCity] = useState(defaultCity);
  const {isArchive, dealCount, isOpenSidebar} = useSelector(state => state.appState);

  const userData = cookiesServiceInstance.getUserData();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getCities().then(res => {
      setCity(res);
      if (defaultCity && defaultCity !== 'null') {
        dispatch(setMainCity(defaultCity));
        localStorageService.setCity(defaultCity);
      } else {
        dispatch(setMainCity(null));
        localStorageService.setCity(null);
      }
    });
  }, [dispatch]);

  const handleChange = e => {
    setSelectedCity(e.target.value);
    dispatch(setMainCity(e.target.value));
    localStorage.setItem(LOCALSTORAGEFIELDS.city, e.target.value);
  };

  const handleLogout = () => {
    dispatch(logout(cookiesServiceInstance, history.push))
  }

  const pageName = useMemo(() => {
    return getPageName(location, dealCount)
  }, [location, dealCount])

  const isTasksPage = useMemo(() => pageName === "Задачи", [pageName])
  return (
    <Box
      className={
        !isOpenSidebar
          ? clsx(classes.closeSidebar, classes.navbar)
          : clsx(classes.openSidebar, classes.navbar)
      }
    >
      <Box className={classes.navbar_inner}>
        <Box display="flex" alignItems="center" sx={{gap: "10px"}}>
          <Box
            className={clsx(classes.pageName, {
              [classes.task_archive_button]: isTasksPage,
              [classes.active]: !isArchive,
            })}
            onClick={() => isTasksPage && dispatch(toggleTasksPage(false))}
          >
            {pageName}
          </Box>
          <SellerSide/>
          {isTasksPage && (
            <Typography
              style={{marginLeft: 10}}
              className={clsx(classes.pageName, {
                [classes.task_archive_button]: isTasksPage,
                [classes.active]: isArchive
              })}
              onClick={() => {
                dispatch(toggleTasksPage(true));
              }}
            >
              Архив
            </Typography>
          ) }
        </Box>
        <Box display="flex" alignItems="center">
          <FormControl className={classes.selectCity_block}>
            <Select
              value={selectedCity ? selectedCity : ""}
              native
              onChange={handleChange}
            >
              <option value="">Все города</option>
              {city?.map(el => {
                return (
                  <option value={el.city_id} key={el.city_id}>
                    {el.city_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <Box className={classes.notification_block}>
            <IconButton>
              <NotificationsIcon/>
            </IconButton>
          </Box>
          <Box className={classes.account_block}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-beetwen",
                alignItems: "center"
              }}
            >
              <IconButton>
                <AccountCircleIcon fontSize="medium"/>
              </IconButton>
              <Typography
                variant="h6"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{cursor: "pointer"}}
              >
                {userData?.fio}
              </Typography>
              <ExpandMoreIcon/>
            </div>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menu_block}
            >
              <MenuItem>
                <a href={"/profile"}>Личный кабинет</a>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Выход</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;

const getPageName = (location, dealCount) => {
  switch (location.pathname) {
    case "/deals": {
      return "Сделки";
    }
    case "/tasks": {
      return "Задачи";
    }
    case "/clients": {
      return "Список клиентов";
    }
    case "/reports": {
      return "Конверсия";
    }
    case "/sales_dept_plan": {
      return "План ОП";
    }
    case "/hunters_plan": {
      return "План Хантеров";
    }
    case "/hunters_kpi": {
      return "KPI хантеров";
    }
    case "/coefficient": {
      return "Коэффициенты";
    }
    case "/contract": {
      return "Сумма продаж";
    }
    case `/discount`: {
      return `Отчет по скидкам`;
    }
    case `/cc-task`: {
      return `Задачи КЦ`;
    }
    case `/search-by-phone`: {
      return `Поиск по номеру телефона`;
    }

    default:
      if (location.pathname.includes("/hunter/"))
        return `Детализация хантера (${dealCount})`;
      else return "";
  }
};
export const useNavStyles = makeStyles({
  navbar: {
    color: "#72768F",
    backgroundColor: "#fff",
    border: "none",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1)",
    position: "absolute",
    top: 0,
    right: 0,
    transition: "all .2s linear"
  },
  navbar_inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 45,
    padding: "0 30px"
  },
  menu_block: {
    "& div": {
      transform: "translateY(30px)!important",
      "& a": {
        color: "black",
        textDecoration: "none"
      }
    }
  },
  closeSidebar: {
    width: "calc(100% - 96px)"
  },
  openSidebar: {
    width: "calc(100% - 330px)"
  },
  selectCity_block: {
    "& select": {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& .MuiInput-underline": {
      "&::before": {
        content: "none",
        borderBottom: "none"
      },
      "&::after": {
        content: "none",
        borderBottom: "none"
      }
    }
  },
  notification_block: {
    textAlign: "center",

    "& button": {
      color: "#6191EF",
      padding: 5
    }
  },
  account_block: {
    display: "flex",
    alignItems: "center",

    "& button": {
      padding: 5
    }
  },
  pageName: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#9194A9",
  },
  task_archive_button: {
    cursor: "pointer"
  },
  active: {
    color: "#659df1"
  },
  kpi_info: {
    display: "flex",
    gap: "10px",

    "& div": {
      color: "#111",
      lineHeight: "22px",
      display: "flex",
      gap: "5px"
    },

    "& p": {
      margin: 0
    }
  }
});
