import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setStatusInfo } from "../../../services/redux/actions/app-actions";
import { changeMainDocs, deleteMainDocs, getDealStatuses } from "./api";
import UploadFile from "../../../components/UI/UploadFile";
import DownloadDoc from "./DownloadDoc";
import { useDropzone } from "react-dropzone";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const DocsItem = ({
  name = "",
  title = "",
  file = {},
  setDocs,
  crm_deal_id,
}) => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const { addDefaultSnack } = useCustomSnackbar();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
  } = useDropzone();

  const updateDocs = async (files = []) => {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append(name, files[0]);
    setIsUploading(true);
    changeMainDocs(crm_deal_id, formData)
      .then((res) => {
        setDocs(res);
        addDefaultSnack("Файл успешно добавлен");

        getDealStatuses(crm_deal_id)
          .then((res) => {
            dispatch(setStatusInfo(res));
          })
          .catch(() => {});
      })
      .catch(() => {})
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleChange = (e) => {
    updateDocs(e.target.value);
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      updateDocs(acceptedFiles);
    }
  }, [acceptedFiles, crm_deal_id, name]);

  const handleDeleteClick = (doc_id) => {
    setIsUploading(true);
    deleteMainDocs(crm_deal_id, doc_id)
      .then((res) => {
        setDocs(res);
        addDefaultSnack("Файл успешно удален");
        getDealStatuses(crm_deal_id).then((res) => {
          dispatch(setStatusInfo(res));
        });
      })
      .catch(() => {})
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <>
      {file?.document_name ? (
        <DownloadDoc
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragAccept={isDragAccept}
          {...file}
          handleDeleteClick={handleDeleteClick}
          name={name}
          title={title}
          onChange={handleChange}
          isUploading={isUploading}
        />
      ) : (
        <UploadFile
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragAccept={isDragAccept}
          name={name}
          fileName={file?.document_name}
          title={title}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default DocsItem;
