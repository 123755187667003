import {format} from "date-fns";

export const arr = (array = []) => {
  if (!isEmpty(array)) return array;
  else return [];
};

export const obj = obj => {
  if (!isEmpty(obj)) return obj;
  else return {};
};

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export const getShortTxt = (str = "", length = 80) => {
  let string = "";
  if (!str) return "";
  if (str.length > length) {
    string = str.slice(0, length) + " ...";
  } else {
    string = str;
  }
  return string;
};

export const toShowDate = (dateStr = "") => {
  try {
    let date = "";
    if (dateStr) {
      date = format(new Date(dateStr), "dd.MM.yyyy");
    }
    return date;
  } catch (e) {
    return ''
  }
};

export const parseDate = (dateStr = "", format = null) => {
  let date = "";
  if (dateStr) {
    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    date = new Date(dateStr.replace(pattern, "$3-$2-$1"));
  }
  try {
    if (format && date) return date.toISOString().slice(0, 10);
  } catch (e) {}
  return date;
};

export const compareByValue = (first, second) => {
  switch (typeof first) {
    case "number":
      return first === Number(second);
    case "string":
      return first === second + "";
    case "boolean":
      return first === second;
    default:
      break;
  }
};

export async function downloadFromInet(url = "", fileName = null) {
  fetch(url)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      if (fileName) a.download = fileName;
      else a.download = new Date().getTime() + "." + detectFileExtention(url);
      document.body.append(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert("Не удалось скачать файл!"));
}

export function detectFileExtention(name = "") {
  const array = name.split(".");
  return array[array.length - 1];
}

export const shortName = name => {
  return name
    ?.trim()
    .split(/\s+/)
    .map((n, i) => (i ? n.substring(0, 1).toUpperCase() + "." : n))
    .join(" ");
};
