import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

import {deletePenalty, getPenaltyList} from "../../../pages/huntersPlan/api";
import EmptyOrShow from "../../EmptyOrShow";
import {Delete} from "react-iconly";
import {setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import NumberFormat from "react-number-format";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const headTitle = [
  "ID",
  "ФИО",
  "Сумма",
  "Комменатарий",
  "Дата создания",
  "Действия"
];

const useStyles = makeStyles({
  table_container: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px"
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#9194a9",
      borderBottom: "none !important"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "1px solid #ccc !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important"
    }
  }
});

const PenaltyList = ({id, state, getHuntersFunc, isClosed, fio}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [penaltyList, setPenaltyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const {addDefaultSnack} = useCustomSnackbar();

  useEffect(() => {
    setLoading(true);
    getPenaltyList(id, state.year, state.month)
      .then(res => {
        setPenaltyList(res);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, state.month, state.year]);

  const handleDelete = (id, penaltyId) => {
    deletePenalty(id, penaltyId, state.year, state.month)
      .then(res => {
        setPenaltyList(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно удален");
        getHuntersFunc();
      })
      .catch(() => {
      });
  };

  return (
    <Box sx={{minWidth: 800}}>
      <Box sx={{margin: "20px 0"}}>
        <Box sx={{marginBottom: 20}}>Хантер - {fio}</Box>
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className={classes.table_head}>
                <TableRow>
                  {headTitle.map(el => {
                    return <TableCell key={el}>{el}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table_body}>
                {penaltyList.map(el => {
                  return (
                    <TableRow key={el.hunter_penalty_id}>
                      <TableCell align="left">{el.hunter_penalty_id}</TableCell>
                      <TableCell align="left">{el.fio}</TableCell>
                      <TableCell align="left">
                        <NumberFormat
                          value={el.penalty_sum}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell align="left">{el.comments}</TableCell>
                      <TableCell align="left">{el.rowversion}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          size="small"
                          disabled={isClosed}
                          onClick={() => handleDelete(id, el.hunter_penalty_id)}
                        >
                          <Delete
                            set="light"
                            primaryColor={isClosed ? "#ccc" : "#FF6262"}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Box>
  );
};

export default PenaltyList;
