import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import {Box} from "@material-ui/core";
import {Person} from "@material-ui/icons";

import {getDealComments, postComment} from "./api";
import SingleComment from "./SingleComment";
import CommentField from "../../../components/UI/CommentField";
import InputText from "../../../components/Form/InputText";
import {useSnackbar} from "notistack";
import {setDealCreateData} from "../../../services/redux/actions/app-actions";

const useStyles = makeStyles(theme => ({
  comments: {
    background: "#fff",
    border: "1px solid #e2e3ed",
    borderRadius: 4,
    width: "100%",
    height: "300px !important",
    padding: 20,
    gridArea: "2 / 1 / 3 / 4",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#6191EF",
      borderRadius: 4
    },

    "& .MuiFormControl-root": {
      width: 250
    },

    [theme.breakpoints.down(1300)]: {
      gridArea: "2 / 1 / 3 / 2",
      height: "571px !important"
    },

    [theme.breakpoints.down(850)]: {
      gridArea: "auto"
    }
  },
  comment_icon: {
    color: "#fff",
    background: "#9194A9",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30
  }
}));

const DealComments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalClasses = globalUseStyles();
  const {enqueueSnackbar} = useSnackbar();

  const {crm_deal_id} = useSelector(state => state.appState.dealCreateData);
  const {dealCreateData} = useSelector(state => state.appState);
  const [loading, setLoading] = useState(false);
  const [isCommentClicked, setIsCommentClicked] = useState(false);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");

  const handleCommentClick = () => {
    setIsCommentClicked(true);
  };

  const handleClickAway = () => {
    setIsCommentClicked(false);
    setComment("");
  };

  const handlePostComment = () => {
    setLoading(true)
    postComment(crm_deal_id, {comments: comment})
      .then(res => {
        setLoading(false)
        setIsCommentClicked(false);
        setComment("");
        setComments(res);
        enqueueSnackbar("Комментарий успешно добавлен", {
          variant: "success"
        });
      })
      .catch(() => {
        setLoading(false)
      });
  };

  useEffect(() => {
    if (crm_deal_id) {
      getDealComments(crm_deal_id).then(res => {
        setComments(res);
      });
    }
  }, [crm_deal_id]);

  const handleChange = e => {
    if (!crm_deal_id) {
      dispatch(
        setDealCreateData({...dealCreateData, comments: e.target.value})
      );
    } else {
      setComment(e.target.value);
    }
  };

  return (
    <Box className={classes.comments}>
      <h4 className={globalClasses.deals_main_title}>Комментарии</h4>
      <Box>
        <Box display="grid" gridTemplateColumns="45px 1fr">
          <Box className={classes.comment_icon}>
            <Person/>
          </Box>
          {isCommentClicked ? (
            <CommentField
              handleClickAway={handleClickAway}
              handleClick={handlePostComment}
              loading={loading}
              value={!crm_deal_id ? dealCreateData?.comments : comment}
              handleChange={handleChange}
              handleClear={handleClickAway}
            />
          ) : (
            <Box onClick={handleCommentClick}>
              <InputText
                name="comment"
                placeholder="Введите комментарий..."
                fullWidth={false}
              />
            </Box>
          )}
        </Box>
        {comments?.map(el => {
          return (
            <SingleComment
              key={el.crm_deal_comment_id}
              {...el}
              crm_deal_id={crm_deal_id}
              setComments={setComments}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default DealComments;
