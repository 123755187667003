import instance from "../../api";

export const createTask = params => {
  return instance(true)
    .post(`/crm/crm_deals/42/tasks/`, params)
    .then(res => {
      return res?.data;
    });
};

export const getTasksCurrent = params => {
  return instance(true)
    .post("/crm/tasks/list/current/", params)
    .then(res => {
      return res?.data;
    });
};

export const getTasksArchive = params => {
  return instance(true)
    .post("/crm/tasks/list/archive/", params)
    .then(res => {
      return res?.data;
    });
};

export const getCalendar = (params, reqParams) => {
  return instance(true, null, reqParams)
    .post("/crm/tasks/calendar/", params)
    .then(res => {
      return res?.data;
    });
};
