import React from "react";
import { styled } from "@material-ui/core";

const NotFoundText = styled("p")(() => ({
  display: "block",
  color: "#77787a",
  fontSize: "16px",
  width: "100%",
  textAlign: "center",
  margin: "3px 0",
  minWidth: "150px"
}));

export const NotFound = ({ children }) => {
  return <NotFoundText>{children || "Ничего не найдено"}</NotFoundText>;
};
