import React from "react";

import { Box } from "@material-ui/core";

import Layout from "../../components/layout";
import ReportCharts from "./ReportCharts";

const ReportsPage = () => {
  return (
    <Layout title="Конверсия">
      <Box
        sx={{
          padding: "45px 20px 20px"
        }}
      >
        <ReportCharts />
      </Box>
    </Layout>
  );
};

export default ReportsPage;
