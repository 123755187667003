import React from "react";

import { TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { Tooltip } from "@material-ui/core/index";
import { useTasksStyles } from ".";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 11,
    backgroundColor: "#383838",
    padding: "5px 10px",
  },
}))(Tooltip);

const TableRowComp = ({
  crm_deal_task_id,
  crm_deal_id,
  crm_task_type_name,
  questions_answers = [],
  fio,
  date_create,
  date_finish,
  date_complete,
  is_complete,
}) => {
  const classes = useTasksStyles();
  return (
    <TableRow>
      <TableCell
        style={{
          borderLeft: is_complete ? "0.25rem solid green" : "none",
        }}
      >
        {crm_deal_task_id}
      </TableCell>
      <TableCell>
        <CustomTooltip title={`Перейти к сделке №${crm_deal_id}`}>
          <a
            href={`/deal/${crm_deal_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {crm_deal_id}
          </a>
        </CustomTooltip>
      </TableCell>
      <TableCell>{crm_task_type_name}</TableCell>
      <TableCell>
        {questions_answers?.map((item, i) => {
          return (
            <div key={i} className={classes.questionWrapper}>
              <p className={classes.question}>{item.crm_task_type_question}</p>
              <span className={classes.questionAnswer}>
                Ответ: {item.crm_task_type_answer}
              </span>
              <p className={classes.questionComment}>
                Комментарии: {item.comments}
              </p>
            </div>
          );
        })}
      </TableCell>
      <TableCell>{fio}</TableCell>
      <TableCell>{date_create}</TableCell>
      <TableCell>{date_finish}</TableCell>
      <TableCell>{date_complete}</TableCell>
    </TableRow>
  );
};

export default TableRowComp;
