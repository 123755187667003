import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import {LoaderData} from "../../loaderData";
import clsx from "clsx";

const CustomButton = ({
  btnText,
  bgColor = "#6191EF",
  txtColor = "#fff",
  startIcon = null,
  className = '',
  endIcon = null,
  disabled = false,
  loading = false,
  fullWidth = true,
  small = false,
  handleClick, children,
  ...props
}) => {
  const btnProps = {
    backgroundColor: bgColor,
    color: txtColor, small, fullWidth
  };
  const classes = useStyles(btnProps);
  return (
    <Button
      disableElevation
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled || loading}
      variant="contained"
      onClick={handleClick}
      {...props}
      className={clsx(classes.btn, className)}
    >
      {loading ? <LoaderData small /> : (btnText || children)}
    </Button>
  );
};

export default CustomButton;

const useStyles = makeStyles({
  btn: {
    backgroundColor: props => props.backgroundColor || '#6191EF',
    color: props => props.color,
    width: props => props.small ? 'auto' : props.fullWidth ? "100%" : 'auto',
    height: props => props.small ? 'auto' : 38,
    borderRadius: styleConstants.borderRadius,
    fontSize: 14,
    lineHeight: "17px",
    textAlign: "center",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: props => props.backgroundColor
    },
    textTransform: "none"
  }
});