import React, {useEffect, useState} from "react";
import InputText from "components/Form/InputText";
import CustomButton from "components/UI/Button";
import CustomDatePicker from "../../UI/DatePicker";
import CustomTimePicker from "../../UI/TimePicker";
import CustomSelect from "../../UI/Select";
import {makeStyles} from "@material-ui/core/styles";
import {getTaskTypes} from "../../../pages/deals/view/api";

const useStyles = makeStyles({
  form: {
    marginTop: "0 !important"
  },
  inputs: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: 30
  },
  select_type: {
    gridArea: "1/1/2/7"
  },
  datePicker: {
    gridArea: "2/1/3/4",

    "& button": {
      padding: "2px !important"
    }
  },
  timePicker: {
    gridArea: "2/4/3/7"
  },
  comment: {
    gridArea: "3/1/4/7",

    "& label": {
      fontSize: 15,
      fontWeight: 600,
      color: "#9194A9",
      transform: "unset !important"
    }
  },
  btn_save: {
    marginTop: 30,
    textAlign: "right",

    "& button": {
      marginTop: "0 !important"
    }
  }
});

export default function AddTaskModal({ handleSave, initialValues = {} }) {
  const classes = useStyles();
  const [newTask, setNewTask] = useState(initialValues);
  const [taskTypes, setTaskTypes] = useState([]);

  const handleChange = (e, v, name) => {
    if (v === undefined) {
      setNewTask({
        ...newTask,
        id: Date.now(),
        [name ? name : e.target.name]: e.target.value
      });
    } else {
      setNewTask({
        ...newTask,
        id: Date.now(),
        [name ? name : e.target.name]: v?.crm_task_type_id
          ? v.crm_task_type_id
          : v
      });
    }
  };

  const handleClearDate = name => {
    setNewTask({ ...newTask, [name]: null });
  };

  useEffect(() => {
    getTaskTypes().then(res => {
      setTaskTypes(res);
    });
  }, []);

  const params = {
    crm_task_type_id: newTask?.crm_task_type_id,
    task_text: newTask?.task_text,
    date_finish:
      !newTask?.time && !newTask?.date
        ? null
        : `${newTask?.time ? newTask?.time : null}, ${
            newTask?.date ? newTask?.date : null
          }`
  };

  return (
    <form className={classes.form}>
      <div className={classes.inputs}>
        <div className={classes.select_type}>
          <CustomSelect
            name="crm_task_type_id"
            label={"Тип задачи"}
            options={taskTypes}
            optionLabel={"crm_task_type_name"}
            placeholder="Выберите тип"
            selected={newTask?.crm_task_type_id}
            valueKey={"crm_task_type_id"}
            handleChange={handleChange}
          />
        </div>
        <div className={classes.datePicker}>
          <CustomDatePicker
            name="date"
            label={"Дата"}
            placeholder="Выберите дату"
            value={newTask["date"]}
            handleChange={handleChange}
            handleClearDate={handleClearDate}
          />
        </div>
        <div className={classes.timePicker}>
          <CustomTimePicker
            name="time"
            label={"Время"}
            placeholder="Выберите время"
            value={newTask["time"]}
            handleChange={handleChange}
          />
        </div>
        <InputText
          name="task_text"
          label="Комментарий"
          placeholder="Введите комментарий"
          value={newTask["task_text"] || ""}
          className={classes.comment}
          onChange={handleChange}
        />
      </div>
      <div className={classes.btn_save}>
        <CustomButton
          btnText="Сохранить"
          handleClick={() => handleSave(params)}
        />
      </div>
    </form>
  );
}
