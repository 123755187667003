import instance from "../../api";
import { handleError } from "../../api/helpers";

export const getUserProfileInfo = () => {
  return instance(true)
    .get("/user-info/")
    .then((res = {}) => {
      return res;
    })
    .catch(err => {
      return handleError(err);
    });
};

export const acceptChangesInUserSettings = params => {
  return instance(true)
    .put("/auth/change-details/", params)
    .catch(err => {
      return handleError(err);
    });
};
export const PutPassword = params => {
  return instance(true).put("/auth/change_password/", params);
};
