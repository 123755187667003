import React, {useEffect, useState} from "react";

import {Box, Checkbox, FormControl, FormControlLabel, InputLabel, NativeSelect} from "@material-ui/core";
import {monthListForFilters, yearList} from "../../../utils/dates";
import {getHuntersList} from "../../deals/list/api";
import AutoSuggest from "../../../components/UI/AutoSuggest";
import cookiesService from "../../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();
const Filters = ({state, setState, handleChangeHunterId, hunterId}) => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const [huntersArr, setHuntersArr] = useState([]);

  useEffect(() => {
    getHuntersList()
      .then(res => {
        setHuntersArr(res);
      })
      .catch(() => {
      });
  }, []);

  const handleChange = e => {
    setState({...state, [e.target.name]: e.target.value});
    if (e.target.name === "with_contract") {
      setState({...state, [e.target.name]: e.target.checked ? 1 : 0});
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        gap: "20px",
        padding: "20px 0"
      }}
    >
      {position_code === "SALE_HEAD_HEAD" || position_code === "SALE_HEAD" ? (
        <Box sx={{minWidth: 300}}>
          <AutoSuggest
            name="employee_id"
            label="Хантер"
            list={huntersArr}
            valueKey="employee_id"
            showKey="fio"
            selected={+hunterId}
            onChange={handleChangeHunterId}
            disableClearable={true}
          />
        </Box>
      ) : null}
      <FormControl style={{minWidth: "200px"}}>
        <InputLabel htmlFor="year-select" shrink>
          Год
        </InputLabel>
        <NativeSelect
          value={state.year}
          onChange={handleChange}
          inputProps={{
            name: "year",
            id: "year-select"
          }}
        >
          {yearList.map(el => {
            return (
              <option value={el.year} key={el.id}>
                {el.year}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
      <FormControl style={{minWidth: "200px"}}>
        <InputLabel htmlFor="month-select" shrink>
          Месяц
        </InputLabel>
        <NativeSelect
          value={state.month}
          onChange={handleChange}
          inputProps={{
            name: "month",
            id: "month-select"
          }}
        >
          {monthListForFilters.map(el => {
            return (
              <option value={el.id} key={el.id}>
                {el.month}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            name="with_contract"
            value={state.is_contract}
            onChange={handleChange}
            color="primary"
            style={{padding: 5}}
          />
        }
        label="+ договор"
      />
    </Box>
  );
};

export default Filters;
