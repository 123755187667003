import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Radio } from "@material-ui/core";
import { MAIN_URL } from "../../../constants";
import { PlusIcon } from "../../UI/Icons";
import CustomButton from "../../UI/Button";
import { changeMainPreset, getPresets } from "../../../pages/deals/view/api";
import { isEmpty } from "../../helpers";
import {
  setDealCreateData,
  setShowModal,
} from "../../../services/redux/actions/app-actions";
import { makeStyles } from "@material-ui/core/styles";
import CustomIconButton from "../../UI/CustomIconButton";
import { CaretRight } from "react-iconly";
import cookiesService from "../../../services/cookiesService";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import styles from "../modal.module.scss";

export const useStyles = makeStyles({
  radio: {
    color: "#6191EF",
  },
  checked: {
    color: "#6191EF !important",
  },
});

const cookiesServiceInstance = cookiesService.getService();

const PresetChoiceModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();

  const { dealCreateData, statusInfo } = useSelector((state) => state.appState);
  const [isBtnLock, setIsBtnLock] = useState(false);
  const [presets, setPresets] = useState([]);
  const { position_code, employee_id } = cookiesServiceInstance.getUserData();

  const [selectedValue, setSelectedValue] = useState(
    dealCreateData.preset?.client_request_main_id
  );

  const handleChange = (e) => {
    if (e.target.value === null) setSelectedValue(null);
    setSelectedValue(Number(e.target.value));
  };

  const handleClick = () => {
    setIsBtnLock(true);
    changeMainPreset(dealCreateData?.crm_deal_id, {
      client_request_main_id: selectedValue,
    })
      .then((res) => {
        const chosenPreset = res.find((el) => el.is_checked === true);
        dispatch(
          setDealCreateData({ ...dealCreateData, preset: chosenPreset })
        );
        dispatch(setShowModal(false));
        addDefaultSnack("Пресет успешно выбран");
        window.location.reload();
      })
      .catch(() => {})
      .finally(() => {
        setIsBtnLock(false);
      });
  };
  useEffect(() => {
    getPresets(dealCreateData.crm_deal_id).then((res) => {
      setPresets(res);
      const selected = res.find((el) => el.is_checked === true);
      if (!isEmpty(selected)) {
        setSelectedValue(selected.client_request_main_id);
      }
    });
  }, [dealCreateData.crm_deal_id]);

  const goConstructor = () => {
    if (statusInfo?.crm_status_code === "DENIED") {
      addCloseSnack(
        "Сделка в статусе ОТКАЗАНО, невозможно создать выбор клиента"
      );
    } else {
      window.open(
        `${MAIN_URL}/request/constructor-pick/employee_id/${employee_id}/crm_deal_id/${dealCreateData?.crm_deal_id}/`,
        "_blank"
      );
    }
  };
  return (
    <div className={styles.modal}>
      <div className="d-flex align-items-center">
        <span className="constuctor-link"> Переход в конструктор</span>
        <span onClick={goConstructor}>
          <IconButton>
            <PlusIcon />
          </IconButton>
        </span>
      </div>
      {presets?.map((el) => {
        return (
          <div
            className="d-flex align-items-center "
            key={el.client_request_main_id}
          >
            <Radio
              color="primary"
              className={classes.radio}
              classes={{
                checked: classes.checked,
              }}
              value={el.client_request_main_id}
              checked={selectedValue === el.client_request_main_id}
              onChange={handleChange}
            />
            <a
              className="modal-preset"
              style={{ marginBottom: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href={`${MAIN_URL}/request/constructor-pick/employee_id/${employee_id}/crm_deal_id/${dealCreateData?.crm_deal_id}/client_request_id/${el.client_request_main_id}/`}
            >
              {el.preset_info}
            </a>
            {position_code === "SALE_HEAD" ||
            position_code === "SALE_HEAD_HEAD" ||
            position_code === "SALE_AUDITOR" ? (
              <CustomIconButton
                width={30}
                height={30}
                color="#6191EF"
                style={{ marginLeft: 10 }}
              >
                <a
                  style={{ display: "inline-flex", color: "#6191EF" }}
                  title="Переход на заявку"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${MAIN_URL}/dict/client-request-detail-list/client_request_id/${el.client_request_main_id}`}
                >
                  <CaretRight set="light" />
                </a>
              </CustomIconButton>
            ) : null}
          </div>
        );
      })}
      <div className="modal-btn">
        <CustomButton
          btnText="Сохранить"
          handleClick={handleClick}
          disabled={isBtnLock}
        />
      </div>
    </div>
  );
};

export default PresetChoiceModal;
