import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {sendEmail} from "./api";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Logo} from "../../components/UI/Logo";
import {useLoginStyles} from "./index";
import {LoaderData} from "../../components/loaderData";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";

const ResetPassword = () => {
  const classes = useLoginStyles();
  const history = useHistory();
  const location = useLocation();
  const {addDefaultSnack} = useCustomSnackbar();

  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.email) setEmail(location.state?.email);
  }, [location.state]);

  const singIn = async e => {
    e.preventDefault();
    setLoading(true);
    await sendEmail(email)
      .then(() => {
        setLoading(false);
        addDefaultSnack("Новый пароль выслан на указанную почту", 'success', 7000)
        history.push("/login");
      })
      .catch(() => {
        setLoading(false);
      })
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{backgroundColor: "#fff", textAlign: "center"}}
    >
      <div className={classes.paper}>
        <div style={{width: 269, marginBottom: 50}}>
          <Logo alt="" size={'large'} className={classes.logo}/>
        </div>

        <form className={classes.form} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            style={{color: "#818181", fontSize: 18}}
            onChange={e => setEmail(e.target.value)}
          />

          <div
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{marginBottom: "10px", lineHeight: "1"}}
              onClick={singIn}
              disabled={loading}
            >
              {loading ? <LoaderData small/> : 'Отправить'}
            </Button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Link
              to={"/login"} className={classes.forget}
            >
              Авторизоваться
            </Link>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default ResetPassword;
