import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../services/redux/actions/app-actions";

import {Box, FormControl, InputLabel, NativeSelect} from "@material-ui/core";

import Search from "../../components/UI/Search";
import CustomButton from "../../components/UI/Button";
import {MODALS} from "../../constants";
import {yearList} from "../../utils/dates";
import {getSaleDeptId} from "./api";
import cookiesService from "../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();
const Filters = ({handleChange, state, setKpiList, search, setSearch}) => {
  const dispatch = useDispatch();
  const {position_code} = cookiesServiceInstance.getUserData();
  const [saleDept, setSaleDept] = useState([]);

  useEffect(() => {
    getSaleDeptId().then((res) => {
      setSaleDept(res);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        gap: "20px",
        padding: "20px 0",
      }}
    >
      <Box sx={{minWidth: 200}}>
        <CustomButton
          btnText="Добавить месяц"
          handleClick={() => {
            dispatch(
              setShowModal(true, MODALS.addMonthModal, {
                title: "Добавить месяц",
                state: state,
                setKpiList: setKpiList,
                saleDept: saleDept,
              })
            );
          }}
        />
      </Box>
      <Box sx={{minWidth: 300}}>
        <Search
          placeholder="Поиск"
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      {position_code === "SALE_HEAD_HEAD" ||
      position_code === "SALE_AUDITOR" ? (
        <Box sx={{minWidth: 200}}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sd-select" shrink>
              ОП
            </InputLabel>
            <NativeSelect
              onChange={handleChange}
              inputProps={{
                name: "id",
                id: "sd-select",
              }}
            >
              <option value="">Все ОП</option>
              {saleDept.map((el) => {
                return (
                  <option
                    value={el.sale_department_id}
                    key={el.sale_department_id}
                  >
                    {el.sale_department_name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
      ) : null}
      <Box sx={{width: 200}}>
        <FormControl fullWidth>
          <InputLabel htmlFor="year-select" shrink>
            Год
          </InputLabel>
          <NativeSelect
            value={state.year}
            onChange={handleChange}
            inputProps={{
              name: "year",
              id: "year-select",
            }}
          >
            {yearList.map((el) => {
              return (
                <option value={el.year} key={el.id}>
                  {el.year}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Filters;
