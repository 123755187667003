import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  icon: {
    width: 30,
    height: 30,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 4,
    padding: 5,
    backgroundColor: props => props.bg,
    margin: props => props.margin
  }
});

const IndicatorIcon = ({ children, bg = "#D2D3D9", handleClick, margin }) => {
  const classes = useStyles({ bg, margin });
  return (
    <Box className={classes.icon} onClick={handleClick}>
      {children}
    </Box>
  );
};

export default IndicatorIcon;
