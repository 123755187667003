import React from "react";
import {Route} from "react-router-dom";
import {PAGES} from "../../constants";
import DealRoot from "../../pages/deals/dealRoot/DealRoot";
import {Clients, Deals, Tasks} from "../../pages";
import Client from "../../pages/clients/view/Client";
import ReportsPage from "../../pages/reports";
import salesDeptPlan from "../../pages/salesDeptPlan";
import huntersPlan from "../../pages/huntersPlan";
import coefficient from "../../pages/coefficient";
import huntersKpi from "../../pages/huntersKpi";
import hunterDetails from "../../pages/huntersKpi/HunterDetails";
import profile from "../../pages/profile";
import contract from "../../pages/reports/Contract";
import discount from "../../pages/reports/Discount";
import CcTask from "../../pages/reports/CcTask";
import {SearchByPhonePage} from "../../pages/searchByPhone";

export const Content = () => {
  return <>
    <Route path={PAGES.deals} component={Deals}/>
    <Route path={PAGES.dealView + "/:id?"} component={DealRoot}/>
    <Route path={PAGES.tasks} component={Tasks}/>
    <Route path={PAGES.clients} component={Clients}/>
    <Route path={PAGES.client + "/:id?"} component={Client}/>
    <Route path={PAGES.reports} component={ReportsPage}/>
    <Route path={PAGES.salesDeptPlan} component={salesDeptPlan}/>
    <Route path={PAGES.huntersPlan} component={huntersPlan}/>
    <Route path={PAGES.coefficient} component={coefficient}/>
    <Route path={PAGES.huntersKpi} component={huntersKpi}/>
    <Route path={PAGES.hunter + "/:id"} component={hunterDetails}/>
    <Route path={PAGES.profile} component={profile}/>
    <Route path={PAGES.contract} component={contract}/>
    <Route path={PAGES.discount} component={discount}/>
    <Route path={PAGES.ccTask} component={CcTask}/>
    <Route path={PAGES.searchByPhone} component={SearchByPhonePage}/>
  </>
}