import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";

import {contractSum} from "../api";
import Layout from "../../../components/layout";
import Filters from "./Filters";
import EmptyOrShow from "../../../components/EmptyOrShow";
import TableRowComp from "./TableRowComp";
import NumberFormat from "react-number-format";
import {getStatusData} from "./services";
import clsx from "clsx";
import {TableDetailRowComp} from "./TableDetailRowComp";
import {getDateCalendar} from "../../../utils";

const headTitle = ["Статус", "Факт", "Кол-во сделок"];
const headDetailTitle = ["ID сделки", "ФИО", "Хантер", "Сумма сделки", "ЖК", "№КВ", "Дата договора"];

const detailInitial = {id: null, data: [], sum: 0}
const Contract = () => {
  const classes = useStyles();
  const [contractArr, setContractArr] = useState([]);
  const [statusDetail, setStatusDetail] = useState(detailInitial);
  const [stateSum, setStateSum] = useState({});
  const [filters, setFilters] = useState({
    sale_department_id: null,
    crm_status_ids: null,
    date_begin: getDateCalendar(1),
    date_end: getDateCalendar()
  });
  const [loading, setLoading] = useState(true);
  const [detailLoading, setDetailLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setStatusDetail(detailInitial)
    contractSum(filters)
      .then(res => {
        setContractArr(res);
        setStateSum({
          fact_sum: res?.reduce((a, b) => a + b.fact_sum, 0)
        });
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  const onStatusClick = async (crm_status_id, deal_ids) => {
    if (!deal_ids?.length) return setStatusDetail({id: crm_status_id, data: undefined})
    setDetailLoading(true)
    const res = await getStatusData(deal_ids)
    const {data, sum} = res
    setDetailLoading(false)
    setStatusDetail({id: crm_status_id, data: data || [], sum: sum || 0})
  }

  return (
    <Layout title="Сумма продаж">
      <Box sx={{padding: "45px 20px 20px"}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "20px",
            padding: "20px 0"
          }}
        >
          <Filters state={filters} setState={setFilters}/>
        </Box>
        <Box className={classes.tableContainer}>
          <EmptyOrShow loading={loading}>
            <TableContainer className={clsx(classes.table_container, classes.tableDetailContainer)}>
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead className={classes.table_head}>
                  <TableRow>
                    {headTitle.map(el => {
                      return <TableCell key={el}>{el}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.table_body}>
                  {contractArr?.map(el => {
                    return <TableRowComp isActive={el.crm_status_id === statusDetail.id}
                                         onStatusClick={() => onStatusClick(el.crm_status_id, el.deal_ids)}
                                         key={el.crm_status_id} {...el} />;
                  })}
                </TableBody>
                <TableFooter className={classes.table_footer}>
                  <TableRow>
                    <TableCell style={{fontWeight: "bold"}}>Итого</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={stateSum?.fact_sum}
                        displayType="text"
                        thousandSeparator={" "}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </EmptyOrShow>
          {statusDetail?.data?.length ? <EmptyOrShow loading={detailLoading}>
              <TableContainer className={clsx(classes.table_container, classes.table_container__detail)}>
                <Table
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                  className={classes.table}
                >
                  <TableHead className={classes.table_head}>
                    <TableRow>
                      {headDetailTitle.map(el => {
                        return <TableCell key={el}>{el}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.table_body}>
                    {
                      statusDetail?.data?.map(el => {
                        return <TableDetailRowComp key={el.crm_deal_id} {...el} />
                      })
                    }
                  </TableBody>
                  <TableFooter className={classes.table_footer}>
                    <TableRow>
                      <TableCell style={{fontWeight: "bold"}}>Итого</TableCell>
                      <TableCell style={{fontWeight: "bold"}}></TableCell>
                      <TableCell style={{fontWeight: "bold"}}></TableCell>
                      <TableCell>
                        <NumberFormat
                          value={statusDetail.sum}
                          displayType="text"
                          thousandSeparator={" "}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </EmptyOrShow>
            : statusDetail?.data === undefined ?
              <div className={classes.noData}>Нет данных для отображения...</div> : ''}
        </Box>
      </Box>
    </Layout>
  );
};

export default Contract;

export const useStyles = makeStyles({
  table_container: {
    maxHeight: "calc(100vh - 150px)",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",

    "&::-webkit-scrollbar": {
      width: 8
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    },
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc !important"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "none !important",
      borderBottom: "1px solid #ccc !important",
      borderLeft: "none !important"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  },
  table_footer: {
    "& td": {
      fontSize: 14,
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#fff",
      borderTop: "1px solid #f0f2f6 !important"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  },
  tableContainer: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "420px 12fr",
    gap: 20
  },
  tableDetailContainer: {},
  status: {
    cursor: 'pointer',
    transition: ".4s all",
    "&:hover": {
      color: "#5babef"
    },
  },
  statusActive: {
    background: "#d0daff"
  },
  noData: {
    padding: 15,
    border: '1px solid #f2f2f2',
    boxShadow: '0 0 2px #ccc',
    borderRadius: 4,
    background: '#e3e6f1',
  }
});