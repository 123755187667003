import React, {useEffect, useState} from "react";

import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import styleConstants from "../../../constants/styleConstants";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import InputText from "../../Form/InputText";
import CustomButton from "../../UI/Button";
import KeyRowComp from "../../../pages/deals/view/KeyRowComp";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {addTransfer, changeGisLink, changeKeys, getGisLink, getKeys, getTransfer} from "../../../pages/deals/view/api";
import {format} from "date-fns";
import clsx from "clsx";
import CheckboxR from "../../Form/CheckboxR";
import EmptyOrShow from "../../EmptyOrShow";
import {setBtnStatuses} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import {ReactComponent as CopyIcon} from "../../../assets/images/icons/copy_icon.svg";
import {TickSquare} from "react-iconly";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  key_wrapper: {
    width: 930,
    maxWidth: "90vw",
    maxHeight: "80vh",
    overflow: "auto",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",
    paddingRight: 10,

    "&::-webkit-scrollbar": {
      width: 5
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  main_title: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    marginBottom: 10
  },
  block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4
  },
  table: {
    "& td": {
      whiteSpace: "nowrap",
      padding: "5px !important"
    }
  },
  inputs_wrapper: {
    display: "grid",
    gridTemplateColumns: "177px 1fr",
    gap: "15px",
    marginBottom: 10
  },
  inputs_block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4,
    display: "grid",
    gap: "10px",
    alignItems: "flex-end",
    padding: "10px 15px"
  },
  inputs_first: {
    backgroundColor: "#fff",
    gridTemplateColumns: "repeat(3, 1fr)",

    "& input": {
      textAlign: "center",

      "&[type=number]": {
        "-moz-appearance": "textfield"
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      }
    }
  },
  inputs_second: {
    backgroundColor: "#fff",
    gridTemplateColumns: "repeat(5, 1fr)"
  },
  input_block: {
    marginBottom: "0 !important",

    "& div": {
      marginBottom: "0 !important"
    },

    "& label": {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15px",
      color: "#9194A9",
      margin: 0
    },

    "& input": {
      fontSize: 14
    }
  },
  checkbox_block: {
    "& label": {
      margin: "0 !important"
    }
  },
  gis_block: {
    display: "flex",
    width: 372
  },
  gis_form: {
    width: "100%",
    margin: "0 !important",

    "& input": {
      outline: "none",
      fontWeight: 500,
      color: "#6191EF",
      border: "1px solid #E2E3ED",
      borderRadius: 4,
      width: "100%",
      height: "100%",
      padding: "8px 10px"
    }
  },
  gis_save: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10px",
    cursor: "pointer",

    "& svg": {
      width: 30,
      height: 30
    }
  },
  gis_copy: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  }
});

const titleArray = [
  "ID",
  "Дата передачи",
  "Кто передал",
  "Кто принял",
  "Комментарии",
  "Кем добавлен",
  "Действия"
];

export default function KeyTransferModal({ crm_deal_id, btnStatuses }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState({});
  const [keys, setKeys] = useState({});
  const [data, setData] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [gisValue, setGisValue] = useState({});
  const [loading, setLoading] = useState(true);
  const { addDefaultSnack } = useCustomSnackbar();

  useEffect(() => {
    setLoading(true);
    getTransfer(crm_deal_id)
      .then(res => {
        setData(res.keys);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crm_deal_id]);

  useEffect(() => {
    getKeys(crm_deal_id).then(res => {
      setKeys(res);
      setIsCheck(Boolean(res?.key_cnt && res?.trinket_cnt));
    });
  }, [crm_deal_id]);

  useEffect(() => {
    getGisLink(crm_deal_id).then(res => {
      setGisValue(res);
    });
  }, [crm_deal_id]);

  const handleChange = (e, v, name) => {
    setValue({
      ...value,
      [name ? name : e.target.name]: v ? v : e.target.value
    });
  };

  const handleChangeKeys = e => {
    setKeys({
      ...keys,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = async () => {
    try {
      const resp = await addTransfer(crm_deal_id, {
        ...value,
        receive_date: value["receive_date"]
          ? format(new Date(value["receive_date"]), "dd.MM.yyyy")
          : null
      });
      setData(resp.keys);
      dispatch(setBtnStatuses({ ...btnStatuses, keys_btn: resp.keys_btn }));
      setValue({
        key_from: "",
        receive_date: "",
        key_to: "",
        comments: ""
      });
      addDefaultSnack();
    } catch (err) {}
  };

  const handleSaveKeys = e => {
    if (e.target.checked) {
      setIsCheck(true);
      keys.key_cnt = +keys.key_cnt;
      keys.trinket_cnt = +keys.trinket_cnt;

      changeKeys(crm_deal_id, keys)
        .then(res => {
          setKeys(res);
          dispatch(setBtnStatuses({ ...btnStatuses, keys_btn: res.keys_btn }));
          addDefaultSnack();
        })
        .catch(() => {
          setIsCheck(false);
          setKeys({});
        });
    }
  };

  const handleSaveGis = () => {
    changeGisLink(crm_deal_id, gisValue)
      .then(res => {
        setGisValue(res);
        addDefaultSnack();
      })
      .catch(() => {});
  };

  const handleChangeGis = e => {
    setGisValue({
      ...gisValue,
      [e.target.name]: e.target.value
    });
  };

  const handleCopyGisLink = () => {
    navigator.clipboard.writeText(gisValue?.gis_link).then(function() {
      addDefaultSnack("Скопировано в буфер обмена");
    });
  };

  return (
    <Box className={classes.key_wrapper}>
      <Box className={classes.inputs_wrapper}>
        <Box className={clsx(classes.inputs_block, classes.inputs_first)}>
          <InputText
            name="key_cnt"
            type="number"
            label="Ключи"
            disabled={isCheck}
            value={keys?.key_cnt === null ? "" : keys?.key_cnt}
            onChange={handleChangeKeys}
            className={classes.input_block}
          />
          <InputText
            name="trinket_cnt"
            type="number"
            label="Брелоки"
            disabled={isCheck}
            value={keys?.trinket_cnt === null ? "" : keys?.trinket_cnt}
            onChange={handleChangeKeys}
            className={classes.input_block}
          />
          <Box className={classes.checkbox_block}>
            <CheckboxR
              name="check"
              onChange={handleSaveKeys}
              disabled={isCheck}
              value={isCheck}
            />
          </Box>
        </Box>
        <Box className={clsx(classes.inputs_block, classes.inputs_second)}>
          <InputText
            name="key_from"
            label="Кто передал"
            value={value["key_from"]}
            onChange={handleChange}
            className={classes.input_block}
          />
          <InputText
            name="receive_date"
            type="date"
            label="Дата передачи"
            value={value["receive_date"]}
            onChange={handleChange}
            shrink={true}
            className={classes.input_block}
          />
          <InputText
            name="key_to"
            label="Кто принял"
            value={value["key_to"]}
            onChange={handleChange}
            className={classes.input_block}
          />
          <InputText
            name="comments"
            label="Комментарии"
            value={value["comments"]}
            onChange={handleChange}
            className={classes.input_block}
          />
          <Box>
            <CustomButton
              btnText={"Сохранить"}
              bgColor={styleConstants.primaryColor}
              onClick={handleAdd}
            />
          </Box>
        </Box>
        <Box className={classes.gis_block}>
          <form className={classes.gis_form}>
            <input
              name="gis_link"
              placeholder="Ссылка в 2GIS"
              type="text"
              value={gisValue?.gis_link || ""}
              onChange={handleChangeGis}
            />
          </form>
          <Box
            className={classes.gis_save}
            onClick={handleSaveGis}
            style={{ pointerEvents: gisValue?.gis_link ? "auto" : "none" }}
          >
            <TickSquare set="bold" primaryColor="#77BF6C" />
          </Box>
          <Box
            className={classes.gis_copy}
            onClick={handleCopyGisLink}
            style={{ pointerEvents: gisValue?.gis_link ? "auto" : "none" }}
          >
            <CopyIcon />
          </Box>
        </Box>
      </Box>
      <Box className={classes.block}>
        <EmptyOrShow loading={loading}>
          <TableContainer>
            <Table aria-label="a dense table" className={classes.table}>
              <TableHeadComp data={titleArray} />
              <TableBody>
                {data?.map(el => (
                  <KeyRowComp
                    key={el.key_receive_id}
                    {...el}
                    setData={setData}
                    crmDealId={crm_deal_id}
                    btnStatuses={btnStatuses}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Box>
  );
}
