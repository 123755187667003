import React, {useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {CloseSquare, Delete, Edit, TickSquare} from "react-iconly";

import InputText from "../../../components/Form/InputText";
import {changePayments, getDealStatuses} from "./api";
import {setStatusInfo} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import {formatSum} from "../../../utils";

const useStyles = makeStyles({
  input: {
    marginBottom: "0 !important",

    "& .MuiInput-underline::before": {
      content: "none"
    },

    "& .MuiInput-underline::after": {
      content: "none"
    },

    "& input": {
      fontSize: "0.875rem",
      textAlign: "center",
      border: "1px solid #E2E3ED !important",
      borderRadius: 5,
      padding: "5px !important"
    }
  },
  btn_block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      cursor: "pointer",
      width: 25,
      height: 25,
      margin: "0 5px"
    },

    "& svg": {
      width: "100%",
      height: "100%"
    }
  },
  short: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
});

export default function PaymentRowComp({
                                         client_request_payment_id,
                                         payment_amount,
                                         payment_date,
                                         comments,
                                         is_payed,
                                         is_last,
                                         client_type_name,
                                         pay_url,
                                         cp_transaction_id,
                                         crmDealId,
                                         setPaymentData,
                                         status
                                       }) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [copyState, setCopyState] = useState({});
  const dispatch = useDispatch();
  const [editState, setEditState] = useState({
    payment_amount,
    payment_date,
    comments,
    is_payed,
    is_last
  });

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setCopyState(editState);
  };

  const handleSave = () => {
    setIsEditing(false);
    changePayments(crmDealId, client_request_payment_id, {
      ...editState,
      payment_amount: +editState.payment_amount
    })
      .then(res => {
        setPaymentData(res.payments);
        getDealStatuses(crmDealId)
          .then(res => {
            dispatch(setStatusInfo(res));
          })
          .catch(() => {
          });
      })
      .catch(() => {
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditState(copyState);
  };

  const handleChange = e => {
    setEditState({
      ...editState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">{client_request_payment_id}</TableCell>
        {isEditing ? (
          <TableCell align="center">
            <InputText
              name="payment_amount"
              value={editState?.payment_amount}
              onChange={handleChange}
              className={classes.input}
            />
          </TableCell>
        ) : (
          <TableCell align="center">
            {formatSum(payment_amount)}
          </TableCell>
        )}
        {isEditing ? (
          <TableCell align="center">
            <InputText
              name="payment_date"
              value={editState?.payment_date}
              onChange={handleChange}
              className={classes.input}
            />
          </TableCell>
        ) : (
          <TableCell align="center">{payment_date}</TableCell>
        )}
        <TableCell align="center">Номер счета</TableCell>
        {isEditing ? (
          <TableCell>
            <InputText
              name="comments"
              value={editState?.comments}
              onChange={handleChange}
              className={classes.input}
            />
          </TableCell>
        ) : (
          <TableCell align="center" className={classes.short} title={comments}>
            {comments}
          </TableCell>
        )}
        <TableCell align="center">{status}</TableCell>
        <TableCell align="center">{client_type_name}</TableCell>
        <TableCell align="center">{cp_transaction_id}</TableCell>
        <TableCell align="center" className={classes.short}>
          <a href={pay_url} target="_blank" rel="noopener noreferrer">
            {pay_url}
          </a>
        </TableCell>
        <TableCell align="center">
          <Box className={classes.btn_block}>
            {isEditing ? (
              <span onClick={handleSave}>
                <TickSquare set="bold" primaryColor="#77BF6C"/>
              </span>
            ) : (
              <span onClick={handleEdit}>
                <Edit set="light" primaryColor="#6191EF"/>
              </span>
            )}
            {isEditing ? (
              <span onClick={handleCancel}>
                <CloseSquare set="bold" primaryColor="#FF4754"/>
              </span>
            ) : (
              <span>
                <Delete set="light" primaryColor="#FF6262"/>
              </span>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
