import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TableBody, TableCell, TableRow } from "@material-ui/core";
import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

import { getKitchenInfo } from "../../../pages/deals/view/api";
import { MAIN_URL } from "../../../constants";
import EmptyOrShow from "../../EmptyOrShow";

const titleArray = ["ID", "Фото", "Наименование"];

const useStyles = makeStyles({
  kitchen: {
    width: 530
  },
  block: {
    border: "1px solid #D2D3D9",
    borderRadius: 4,

    "&:not(:last-child)": {
      marginBottom: 25
    }
  },
  table_img: {
    display: "block",
    maxWidth: 100,
    margin: "0 auto",
    objectFit: "contain"
  },
  cell: {
    border: "1px solid #D2D3D9",
    textAlign: "center",
    padding: "5px !important",
    "&:first-child": {
      borderLeft: "none"
    },
    "&:last-child": {
      borderRight: "none"
    }
  }
});

export default function KitchenModal({ crm_deal_id }) {
  const classes = useStyles();
  const [kitchenInfo, setKitchenInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (crm_deal_id) {
      setLoading(true);
      getKitchenInfo(crm_deal_id)
        .then(res => {
          setKitchenInfo(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [crm_deal_id]);

  const { material_id, material_url, material_name } = kitchenInfo;


  const getImgSrc = () => {
    if (material_url?.includes("http")) {
      return material_url;
    }
    return MAIN_URL + material_url;
  };

  return (
    <Box className={classes.kitchen}>
      <Box className={classes.block}>
        <EmptyOrShow loading={loading}>
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHeadComp data={titleArray} />
              <TableBody>
                {kitchenInfo ? (
                  <TableRow>
                    <TableCell className={classes.cell}>
                      {material_id}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {material_url ? (
                        <a href={getImgSrc()} target="_blank" rel="noopener noreferrer">
                          <img
                            src={getImgSrc()}
                            className={classes.table_img}
                            alt="kitchen_photo"
                          />
                        </a>
                      ) : null}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {material_name}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Box>
  );
}
