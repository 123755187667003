import React, {useState} from "react";
import Layout from "../../components/layout";
import {Box, Grid, makeStyles, Paper} from "@material-ui/core";
import InputText from "../../components/Form/InputText";
import CustomButton from "../../components/UI/Button";
import {setShowModal} from "../../services/redux/actions/app-actions";
import {MODALS} from "../../constants";
import {useDispatch} from "react-redux";

import {PutPassword} from "./api";
import cookiesService from "../../services/cookiesService";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  info: {
    padding: "1em",
    "& h2": {
      marginTop: 0
    }
  }
}));
const cookiesServiceInstance = cookiesService.getService();
const Profile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {addDefaultSnack} = useCustomSnackbar();
  const state = cookiesServiceInstance.getUserData();

  const [password, setPassword] = useState({
    password: "",
    new_password: "",
    new_password_repeat: ""
  });
  const handleChange = e => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value
    });
  };
  const changePassword = () => {
    PutPassword(password)
      .then(() => {
        setPassword({
          password: "",
          new_password: "",
          new_password_repeat: ""
        });
        addDefaultSnack("Пароль успешно изменен");
      })
      .catch(() => {
      })
      .finally(() => {
        dispatch(setShowModal(false));
      });
  };
  return (
    <Layout title={"Профиль"}>
      <Box sx={{padding: "45px 20px 20px"}}>
        <Box
          style={{
            marginTop: "20px"
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper style={{height: "100%"}}>
                <div className={classes.info}>
                  <h2>Информация</h2>
                  <p>
                    <b>Почта</b> - {state.email}
                  </p>
                  <p>
                    <b>ФИО</b> - {state.fio}
                  </p>
                  <p>
                    <b>Номер телефона</b> - {state.phone}
                  </p>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <div className={classes.info}>
                  <h2>Смена пароля</h2>
                  <InputText
                    name="password"
                    label="Старый пароль"
                    shrink={false}
                    type="text"
                    value={password.password}
                    onChange={handleChange}
                    fullWidth={true}
                  />
                  <InputText
                    name="new_password"
                    label="Новый пароль"
                    shrink={false}
                    type="text"
                    value={password.new_password}
                    onChange={handleChange}
                    fullWidth={true}
                  />
                  <InputText
                    name="new_password_repeat"
                    label="Повторите новый пароль"
                    shrink={false}
                    type="text"
                    value={password.new_password_repeat}
                    onChange={handleChange}
                    fullWidth={true}
                  />
                  <CustomButton
                    style={{marginTop: "15px"}}
                    btnText="Изменить пароль"
                    disabled={
                      !(
                        password.password &&
                        password.new_password &&
                        password.new_password_repeat
                      )
                    }
                    handleClick={() =>
                      dispatch(
                        setShowModal(true, MODALS.closeMonthModal, {
                          title: "Изменение пароля",
                          titleModal:
                            "Вы действительно хотите изменить пароль?",
                          text: "Изменить пароль",
                          clickHandle: changePassword
                        })
                      )
                    }
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default Profile;
