import React, {useState} from "react";

import {Box} from "@material-ui/core";
import {ColorBox} from "material-ui-color";
import CustomButton from "../Button";

const popover = {
  position: "absolute",
  zIndex: "2",
  top: "-3%",
  right: "-50%",
  marginTop: "10px",
  background: "white",
  padding: "1em",
  borderRadius: "4px",
  border: "1px solid lightgray",
};
const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};
const ColorPicker = ({ comColor, setComColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(comColor);
  const handleClick = (val) => {
    setDisplayColorPicker(true);
  };
  const handleChange = () => {
    setComColor(color);
    setDisplayColorPicker(false);
  };
  const handleChangeColor = (color) => {
    setColor(color);
  };
  const handleClose = () => {
    setColor(0);
    setDisplayColorPicker(false);
  };
  return (
    <Box>
      <Box component="div" style={{ display: "flex" }}>
        <CustomButton
          style={{ maxWidth: "200px", marginRight: "15px" }}
          btnText="Изменить цвет"
          handleClick={handleClick}
        />
        <Box
          component="div"
          style={{
            background: color?.hex ? `#${color?.hex}` : comColor,
            width: "50px",

            borderRadius: "4px",
          }}
        ></Box>
      </Box>

      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} />
          <ColorBox value={color} onChange={handleChangeColor} />
          <Box
            component="div"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              btnText="Изменить"
              style={{
                marginRight: "15px",
              }}
              handleClick={handleChange}
            />
            <CustomButton
              btnText="Закрыть"
              bgColor="#dc3545"
              handleClick={handleClose}
            />
          </Box>
        </div>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
