import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestTypesData,
  setActiveHunter,
  setBtnStatuses,
  setDealCreateData,
  setShowModal,
  setStatusInfo,
  setTasks,
} from "../../../services/redux/actions/app-actions";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import { Box, Divider, LinearProgress } from "@material-ui/core";

import {
  changeSaleDepart,
  getBtnStatuses,
  getSaleDepartment,
  getSalePoints,
  setSaleDepartment,
} from "./api";
import InputText from "components/Form/InputText";
import AutoSuggest from "components/UI/AutoSuggest";
import InfoTxt from "components/UI/InfoTxt";
import { isEmpty } from "../../../components/helpers";
import { MODALS } from "../../../constants";
import union from "../../../assets/images/union.svg";
import key from "../../../assets/images/key.svg";
import styleConstants from "../../../constants/styleConstants";
import clsx from "clsx";
import CustomButton from "../../../components/UI/Button";
import EmptyOrShow from "../../../components/EmptyOrShow";
import cookiesService from "../../../services/cookiesService";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

export const validate = Yup.object().shape({
  password: Yup.string().required("Обязательное поле"),
  phone_number: Yup.string().required("Обязательное поле"),
});

const useStyles = makeStyles({
  deals_info: {
    "& h4": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "17px",
      color: "#494c62",
      margin: "0 0 15px 0",
    },
  },
  deals_info_options: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 10,
  },
  option_wrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 10,

    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  option_item: {
    width: 250,
    marginRight: 50,
    marginBottom: 10,

    "& label": {
      fontSize: 12,
      fontWeight: 600,
      transform: "scale(1)",

      "& span": {
        color: "red",
      },
    },

    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  option_item_multiple: {
    "& .MuiFormControl-root": {
      width: "90%!important",
    },
    marginRight: 50,
    marginBottom: 10,

    "& label": {
      fontSize: 12,
      fontWeight: 600,
      transform: "scale(1)",

      "& span": {
        color: "red",
      },
    },

    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  option_btn: {
    fontWeight: 600,
    color: "#fff",
    backgroundColor: "#9194A9",
    border: "none",
    borderRadius: styleConstants.borderRadius,
    width: 30,
    height: 30,
    marginLeft: 20,
    padding: 6,
    cursor: "pointer",

    "& img": {
      width: 19,
      height: 19,
    },
  },
  keys_select: {
    "& label span": {
      color: "red",
    },
  },
  client_select: {
    "& label span": {
      color: "red",
    },
  },
  lead: {
    "& label": {
      fontSize: "12px",
      transform: "scale(1)",
    },
  },
  payments_outer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",

    "& h5": {
      color: styleConstants.darkGray,
      margin: 0,
    },
  },
  payments_inner: {
    display: "flex",
    alignItems: "center",
    width: 300,
    marginTop: 10,

    "& button": {
      textTransform: "uppercase",
      width: "auto",
      marginLeft: 0,
    },
  },
  progress_bar: {
    width: 150,

    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#D2D3D9",
    },
  },
  progress_percent: {
    fontSize: 16,
    lineHeight: "20px",
    color: "#72768F",
    margin: "0 14px 0 14px",
  },
  divider: {
    margin: "20px 0",
  },
  btn_grey: {
    backgroundColor: "#9194A9",
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#9194A9",
    },
  },
});
const cookiesServiceInstance = cookiesService.getService();

export default function DealLeftForm({
  data = {},
  getResidentsData,
  isClient,
  setData,
}) {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const dispatch = useDispatch();
  const {
    dealCreateData,
    updatedDealData,
    btnStatuses,
    requestTypes,
    statusInfo,
  } = useSelector((state) => state.appState);
  const { position_code } = cookiesServiceInstance.getUserData();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const copyState = { ...dealCreateData };
  const {
      formInfo,
      cities,
      residents,
      origins,
      clientTypes,
      remontTypes,
      remontKeys,
      bigEmployees,
      salePoints,
      saleDepart,
    } = data,
    values = dealCreateData;
  const { extra_btn, keys_btn, payments_btn } = btnStatuses;
  const [sales, setSales] = useState([]);
  const [salesFilter, setSalesFilter] = useState([]);

  useEffect(() => {
    dispatch(getRequestTypesData());
  }, [dispatch]);

  const fetchBtnStatuses = () => {
    getBtnStatuses(values.crm_deal_id, +values.client_type_id)
      .then((res) => {
        dispatch(setBtnStatuses(res));
      })
      .catch(() => {});
  };

  const changeData = (id, is_changeable = false) => {
    changeSaleDepart(values.crm_deal_id, isClient ? 1 : 0, {
      sale_department_id: id,
      is_changeable,
    })
      .then((res) => {
        if (res.is_changeable === false) {
          dispatch(
            setShowModal(true, MODALS.changeDataModal, {
              title: "Изменить данные",
              sale_department_id: id,
              changeData,
              copyState,
            })
          );
        } else {
          const { crm_deal, hunters, tasks, checklists, statuses } = res;

          dispatch(setDealCreateData(crm_deal));
          dispatch(setActiveHunter(hunters.active_hunter));
          dispatch(setTasks(tasks));
          dispatch(setStatusInfo({ ...statusInfo, checklists, statuses }));

          getSalePoints(id).then((res) => {
            setData({ ...data, salePoints: res });
          });
        }
      })
      .catch(() => {});
  };

  const handleChange = (e) => {
    if (e.target.name === "city_id") {
      getResidentsData(e.target.value);
      dispatch(
        setDealCreateData({ resident_id: null, city_id: e.target.value })
      );
      return;
    }

    if (e.target.name === "sale_department_id" && values.crm_deal_id) {
      changeData(e.target.value);
    } else if (e.target.name === "sale_department_id" && !values.crm_deal_id) {
      getSalePoints(e.target.value).then((res) => {
        setData({ ...data, salePoints: res });
      });
    }

    dispatch(
      setDealCreateData({
        [e.target.name]: e.target.value,
      })
    );
  };
  const handleChangeDepartment = (e) => {
    let text = "";
    if (salesFilter.length <= e.target.value.length)
      text = "ОП успешно добавлен";
    else text = "ОП успешно удален";

    const diff = function(a1, a2) {
      return a1
        .filter((i) => !a2.includes(i))
        .concat(a2.filter((i) => !a1.includes(i)));
    };
    let sale_id = diff(e.target.value, salesFilter)[0];

    setSaleDepartment(values?.crm_deal_id, sale_id)
      .then(() => {
        addDefaultSnack(text);
      })
      .catch(() => {});
    setSalesFilter(e.target.value);
  };

  useEffect(() => {
    if (values?.crm_deal_id) {
      fetchBtnStatuses();
      getSaleDepartment(values?.crm_deal_id).then((res) => {
        setSales(res);
        setSalesFilter(
          res.filter((x) => x.cnt > 0).map((d) => d.sale_department_id)
        );
      });
    }
  }, [values?.client_type_id]);

  if (isEmpty(data)) {
    return <EmptyOrShow />;
  }

  const handleClick = () => {
    dispatch(
      setShowModal(true, MODALS.residentsModal, {
        title: "Подгрузка ЖК",
        client_id: values?.client_id,
        crm_deal_id: values?.crm_deal_id,
        dealCreateData: values,
      })
    );
  };

  const showAdditionalFieldsModal = () => {
    if (!updatedDealData?.client_type_id)
      return addCloseSnack("Выберите и сохраните тип клиента!");
    dispatch(
      setShowModal(true, MODALS.formBuilder, {
        title: "Дополнительные поля",
        id: values.client_type_id,
        btnStatuses,
      })
    );
  };

  return (
    <Box className={classes.deals_info}>
      <h4>Информация о сделке</h4>
      <Box className={classes.deals_info_options}>
        {values?.crm_deal_id ? (
          <InputText
            name="origin_id"
            label={
              <div>
                Источник лида <span>*</span>
              </div>
            }
            value={values?.origin_name}
            fullWidth={false}
            disabled
            className={clsx(classes.option_item, classes.lead)}
          />
        ) : (
          <AutoSuggest
            label="Источник лида"
            name="origin_id"
            selected={values?.origin_id}
            list={origins}
            valueKey="origin_id"
            showKey="origin_name"
            onChange={handleChange}
            disabled={values?.crm_deal_id}
            className={classes.option_item}
          />
        )}
        <AutoSuggest
          label={
            <div>
              Тип ремонта <span>*</span>
            </div>
          }
          name="remont_type_id"
          selected={values?.remont_type_id}
          list={remontTypes}
          valueKey="remont_type_id"
          showKey="remont_type_name"
          onChange={handleChange}
          className={classes.option_item}
        />
        <AutoSuggest
          label={<div>Тип заявки</div>}
          name="request_type_id"
          selected={values?.request_type_id}
          list={requestTypes}
          valueKey="request_type_id"
          showKey="request_type_name"
          onChange={handleChange}
          className={classes.option_item}
        />
        <AutoSuggest
          label={
            <div>
              Город <span>*</span>
            </div>
          }
          name="city_id"
          selected={values?.city_id}
          list={cities}
          valueKey="city_id"
          showKey="city_name"
          onChange={handleChange}
          className={classes.option_item}
        />
        <Box className={classes.option_wrapper}>
          <AutoSuggest
            label={
              <div>
                Тип клиента <span>*</span>
              </div>
            }
            name="client_type_id"
            selected={values?.client_type_id}
            list={clientTypes}
            valueKey="client_type_id"
            showKey="client_type_name"
            className={classes.client_select}
            onChange={handleChange}
          />
          <Box sx={{ position: "relative" }}>
            <button
              className={`${
                !values?.crm_deal_id
                  ? clsx(classes.option_btn, globalClasses.btn_disable)
                  : classes.option_btn
              } ${extra_btn ? globalClasses.btn_green : ""}`}
              onClick={showAdditionalFieldsModal}
            >
              <img src={union} alt="icon" />
            </button>
            <Box
              sx={{
                fontSize: "16px",
                color: "red",
                position: "absolute",
                top: "-10px",
                right: "-10px",
              }}
            >
              *
            </Box>
          </Box>
        </Box>
        <AutoSuggest
          label={
            <div>
              ЖК <span>*</span>
            </div>
          }
          name="resident_id"
          selected={values?.resident_id}
          list={residents}
          valueKey="resident_id"
          showKey="resident_name"
          onChange={handleChange}
          disabled={!values.city_id}
          className={classes.option_item}
        />
        <Box className={classes.option_wrapper}>
          <AutoSuggest
            label={
              <div>
                Ключи <span>*</span>
              </div>
            }
            name="remont_begin_id"
            selected={values?.remont_begin_id}
            list={remontKeys}
            valueKey="remont_begin_id"
            showKey="remont_begin_name"
            onChange={handleChange}
            className={classes.keys_select}
            disabled={!values?.city_id}
          />
          <button
            className={`${
              !values?.crm_deal_id || !values?.preset?.client_request_main_id
                ? clsx(classes.option_btn, globalClasses.btn_disable)
                : classes.option_btn
            } ${keys_btn ? globalClasses.btn_green : ""} `}
            onClick={() => {
              dispatch(
                setShowModal(true, MODALS.keyTransfer, {
                  title: "Передача ключей",
                  crm_deal_id: dealCreateData.crm_deal_id,
                  btnStatuses,
                })
              );
            }}
          >
            <img src={key} alt="icon" />
          </button>
        </Box>
        <InputText
          name="flat_num"
          label={
            <div>
              КВ <span>*</span>
            </div>
          }
          value={values?.flat_num}
          onChange={handleChange}
          fullWidth={false}
          disabled={!values?.city_id}
          className={classes.option_item}
        />
        <Box className={classes.payments_outer}>
          <h5>Оплачено</h5>
          <Box className={classes.payments_inner}>
            <Box className={classes.progress_bar}>
              <LinearProgress
                variant="determinate"
                value={
                  values?.payed >= 100 ? 100 : values?.payed ? values?.payed : 0
                }
              />
            </Box>
            <Box className={classes.progress_percent}>% {values?.payed}</Box>
            <button
              className={`${
                !values?.crm_deal_id
                  ? clsx(classes.option_btn, globalClasses.btn_disable)
                  : classes.option_btn
              } ${payments_btn ? globalClasses.btn_green : ""}`}
              onClick={() => {
                dispatch(
                  setShowModal(true, MODALS.payment, {
                    title: "Платежи",
                    crm_deal_id: dealCreateData.crm_deal_id,
                    btnStatuses,
                  })
                );
              }}
            >
              Платежи
            </button>
          </Box>
        </Box>
        <AutoSuggest
          label="Сотрудник БИГ"
          name="big_employee_id"
          selected={values?.big_employee_id}
          list={bigEmployees}
          valueKey="big_employee_id"
          showKey="big_employee_fio"
          onChange={handleChange}
          disabled={!values?.city_id}
          className={classes.option_item}
        />
        {position_code === "SALE_HEAD_HEAD" || position_code === "SALE_HEAD" ? (
          <AutoSuggest
            label={<div>Отдел продаж</div>}
            name="sale_department_id"
            selected={values?.sale_department_id}
            list={saleDepart}
            valueKey="sale_department_id"
            showKey="sale_department_name"
            onChange={handleChange}
            className={classes.option_item}
          />
        ) : null}

        <InputText
          name="resident_address"
          label={
            <div>
              Адрес ЖК <span>*</span>
            </div>
          }
          value={values?.resident_address || ""}
          onChange={handleChange}
          fullWidth={false}
          disabled={!values?.city_id}
          className={classes.option_item}
        />
        <AutoSuggest
          label={
            <div>
              Точка продаж <span>*</span>
            </div>
          }
          name="sale_point_id"
          selected={values?.sale_point_id}
          list={salePoints}
          valueKey="sale_point_id"
          showKey="point_name"
          onChange={handleChange}
          className={classes.option_item}
        />
      </Box>
      <Box>
        {position_code === "SALE_HEAD_HEAD" ||
        position_code === "CALL_CENTER" ||
        position_code === "SALE_AUDITOR" ||
        position_code === "CALL_CENTER_HEAD" ? (
          <AutoSuggest
            label={
              <div>
                Передать для ОП <span>*</span>
              </div>
            }
            isMultiple={true}
            selected={salesFilter}
            name="sale_department_id_array"
            list={sales}
            valueKey="sale_department_id"
            showKey="sale_department_name"
            disableClearable={true}
            onChange={handleChangeDepartment}
            className={classes.option_item_multiple}
          />
        ) : null}
      </Box>
      <Box
        sx={{ position: "relative", height: "100%", display: "inline-block" }}
      >
        <CustomButton
          btnText="Подгрузить ЖК"
          handleClick={handleClick}
          className={clsx(
            classes.btn_grey,
            {
              [globalClasses.btn_green]: !isEmpty(values?.json_1c),
            },
            classes.red_star
          )}
        />
        <Box
          sx={{
            fontSize: "16px",
            color: "red",
            position: "absolute",
            top: "0",
            right: "7px",
          }}
        >
          *
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        <InfoTxt label={"Дата сдачи ЖК"} value={formInfo?.delivery_date} />
        <InfoTxt
          label={"Планировка"}
          value={formInfo?.planirovka_name}
          imgLink={formInfo?.planirovka_image_url}
        />
        <InfoTxt label={"Срок работы"} value={formInfo?.prop_term} />
      </Box>
    </Box>
  );
}
