import React from "react";
import NumberFormat from "react-number-format";
import {TextField, withStyles} from "@material-ui/core";
import {getPhoneFormat} from "utils";

const WhiteTextField = withStyles({
  root: {
    color: "#fff !important",
    "& label": {
      color: "#fff !important",
    },
    "& label.Mui-focused": {
      color: "#fff !important",
    },
    "& .MuiInputBase-input": {
      color: "#fff !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important",
      },
      "&:hover fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important",
      },
      "&.Mui-focused fieldset": {
        color: "#fff !important",
        borderColor: "#fff !important",
      },
    },
  },
  input: {
    color: "#fff !important",
    "&:focus": {
      color: "#fff !important",
      borderColor: "#fff !important",
    },
    "&$focused": {
      color: "#fff !important",
      borderColor: "#fff !important",
    },
  },
})(TextField);

export default function InputPhone({
  name,
  label,
  value,
  onChange,
  error,
  className = "",
  variant = "standard",
  isWhite = null,
  fullWidth = true,
  placeholder,
  shrink = true,
  disabled = false,
}) {
  let props = {
    fullWidth: fullWidth,
    label: label,
    value: value,
    onChange: onChange,
    name: name,
    className: className,
    variant: variant,
    placeholder: placeholder ? placeholder : "",
    id: name,
    InputProps: {
      inputComponent: NumberFormatCustom,
    },
    InputLabelProps: {
      shrink: shrink,
    },
    helperText: error,
    error: !!error,
    disabled: disabled,
  };

  if (isWhite) return <WhiteTextField {...props} />;
  else return <TextField {...props} />;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  const phoneFormat = getPhoneFormat();
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={phoneFormat || "+# (###) ###-####"}
      mask="_"
    />
  );
}
