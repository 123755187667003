import InputText from "components/Form/InputText";
import ProgressR from "components/Form/ProgressR";
import CustomButton from "components/UI/Button";
import {format} from "date-fns";
import {Formik} from "formik";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModalData, setShowModal} from "../../../services/redux/actions/app-actions";
import * as Yup from "yup";
import {changeDocData} from "../../../pages/deals/view/api";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

export const validate = Yup.object().shape({
  doc_num: Yup.string()
    .nullable()
    .required("Обязательное поле"),
  issued_by: Yup.string()
    .nullable()
    .required("Обязательное поле"),
  issue_date: Yup.string()
    .nullable()
    .required("Обязательное поле")
});

export default function DocDataModal({client_id, crm_deal_id}) {
  const dispatch = useDispatch();
  const {addDefaultSnack} = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const {docData} = useSelector(state => state.appState.modalData);

  const date = docData?.identification?.issue_date
    ? format(
      new Date(
        docData?.identification?.issue_date
          .split(".")
          .reverse()
          .join("-")
      ),
      "yyyy-MM-dd"
    )
    : "";
  const postData = values => {
    setLoading(true)
    changeDocData(
      client_id,
      crm_deal_id,
      {
        doc_num: values.doc_num,
        issued_by: values.issued_by,
        issue_date: format(new Date(values.issue_date), "dd.MM.yyyy")
      }
    )
      .then(res => {
        dispatch(setModalData({docData: res}));
        dispatch(setShowModal(false));
        setLoading(false)
        addDefaultSnack("Паспортные данные успешно сохранены");
      })
      .catch(() => {
        setLoading(false)
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          ...docData?.identification,
          issue_date: date
        } || {}
      }
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values, {resetForm, setSubmitting}) => {
        postData(values, resetForm, setSubmitting);
      }}
    >
      {props => {

        let {
          values,
          errors,
          handleChange,
          handleSubmit,
        } = props;

        return (
          <form>
            <ProgressR
              width="340px"
              minHeight="50px"
              loading={loading}
            />
            <InputText
              name="doc_num"
              label="Номер документа *"
              value={values.doc_num}
              error={errors.doc_num}
              onChange={handleChange}
              disabled={loading}
            />
            <InputText
              name="issued_by"
              label="Кем выдано *"
              value={values.issued_by || ""}
              error={errors.issued_by}
              onChange={handleChange}
              disabled={loading}
            />
            <InputText
              name="issue_date"
              label="Дата выдачи *"
              type="date"
              value={values.issue_date}
              error={errors.issue_date}
              onChange={handleChange}
              disabled={loading}
              shrink={true}
            />
            <div className="d-flex justify-content-end">
              <CustomButton
                btnText="Сохранить"
                handleClick={handleSubmit}
                disabled={!client_id}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
