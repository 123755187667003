import {dealsSumAPI} from "./api";

export const getStatusData =async (deal_ids) => {
  try {
    const res = await dealsSumAPI.getStatusDetail({deal_ids})
    const details = res?.details
    if(!details) return {}
    const sum = details?.reduce((a, b) => a + b.fact_sum, 0)
    return {data: details, sum }
  } catch (e) {}
}