const webSocket = new WebSocket("wss://127.0.0.1:13579/");

export let signedXml;

webSocket.onclose = function(event) {
  if (event.wasClean) {
    console.log("connection has been closed");
  } else {
    console.log("Connection error");
  }
  console.log("Code: " + event.code + " Reason: " + event.reason);
};

function signXmlBack(result) {
  if (result["code"] === "500") {
    alert(result["message"]);
  } else if (result["code"] === "200") {
    return result["responseObject"];
  }
}

export function signXml(storageName, keyType, xmlToSign, cb, data) {
  const signXml = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "signXml",
    args: [storageName, keyType, xmlToSign, "", ""]
  };
  webSocket.send(JSON.stringify(signXml));

  return new Promise((resolve) => {
    webSocket.onmessage = function(event) {
      const result = JSON.parse(event.data);
      if (result != null) {
        const rw = {
          code: result["code"],
          message: result["message"],
          responseObject: result["responseObject"],
          getResult: function() {
            return this.result;
          },
          getMessage: function() {
            return this.message;
          },
          getResponseObject: function() {
            return this.responseObject;
          },
          getCode: function() {
            return this.code;
          }
        };

        signedXml = signXmlBack(rw);
        resolve({
          unsigned_message: data,
          signed_message: signedXml
        });
      }
    };
  });
}
