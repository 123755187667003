import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import TableHeadComp from "../../../pages/deals/view/TableHeadComp";
import { getRepair } from "../../../pages/deals/view/api";
import CheckboxR from "../../Form/CheckboxR";
import EmptyOrShow from "../../EmptyOrShow";

const AboutRepair = ({ id }) => {
  const classes = useStyles();
  const [repairs, setRepairs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getRepair(id)
      .then((res) => {
        setRepairs(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Box className={classes.repair}>
      <EmptyOrShow loading={loading}>
        <TableContainer className={classes.table_container}>
          <Table size="small" aria-label="a dense table">
            <TableHeadComp data={titleArray} />
            <TableBody>
              <TableRow>
                <TableCell align="center">{repairs?.fio}</TableCell>
                <TableCell align="center" className={classes.phone_cell}>
                  {repairs?.phone}
                </TableCell>
                <TableCell align="center">
                  {repairs?.draft_status_name}
                </TableCell>
                <TableCell align="center">{repairs?.status_name}</TableCell>
                <TableCell align="center" className={classes.checkbox_cell}>
                  <CheckboxR
                    name="is_measure_taken"
                    value={repairs?.is_measure_taken}
                    disabled
                  />
                </TableCell>
                <TableCell align="center">{repairs?.date_mark}</TableCell>
                <TableCell align="left">
                  {repairs?.techproject_employee_fio}
                  <br />
                  <span className={classes.phone_cell}>
                    {repairs?.techproject_employee_phone}
                  </span>
                </TableCell>
                <TableCell align="left">{repairs?.stage_name}</TableCell>
                <TableCell align="center">
                  {repairs?.jur_remont_date_end}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </EmptyOrShow>
    </Box>
  );
};

export default AboutRepair;

const titleArray = [
  "ФИО МП",
  "Телефон",
  "Статус принятия черновой от застройщика",
  "Текущий статус ремонта",
  "Замеры квартир",
  "Дата снятия замеров",
  "Проектировщик",
  "Этап ремонта",
  "Юр. дата окончания ремонта",
];

const useStyles = makeStyles({
  repair: {
    width: 920,
    maxWidth: "90vw",
  },
  table_container: {
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      height: "7px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#659DF1",
      borderRadius: 4,
    },
  },
  phone_cell: {
    whiteSpace: "nowrap",
  },
  checkbox_cell: {
    "& label": {
      margin: "0 !important",
      justifyContent: "center",
    },
  },
});
