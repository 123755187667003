import React, {useEffect, useState} from "react";
import {InputLabel, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import {RiArrowDownSLine} from "react-icons/ri";

const useStyles = makeStyles({
  select: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer"
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3
  },
  icon: {
    color: styleConstants.primaryColor
  }
});

const CustomSelect = ({
                        options = [],
                        optionLabel = "",
                        label = "",
                        placeholder = "",
                        handleChange = () => {
                        },
                        isMultiple = false,
                        required = false,
                        value = [],
                        name,
                        selected = "",
                        valueKey = ""
                      }) => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(null);
  useEffect(() => {
    const defaultValue = options?.find(el => el[valueKey] === selected);
    if (defaultValue) {
      setSelectValue(defaultValue);
    } else {
      setSelectValue(null);
    }
  }, [selected, options, valueKey]);

  return (
    <div className="custom_select">
      <InputLabel className={classes.label}>{`${label}${required ? ' *' : ''}`}</InputLabel>
      <Autocomplete
        className={classes.select}
        name={name}
        multiple={isMultiple}
        noOptionsText="Ничего не найдено"
        blurOnSelect
        value={isMultiple ? value : selectValue}
        options={options}
        getOptionLabel={option => option[optionLabel] || ""}
        onChange={(e, v) => {
          handleChange(e, v, name);
        }}
        popupIcon={<RiArrowDownSLine className={classes.icon}/>}
        renderInput={params => (
          <TextField {...params} variant="standard" placeholder={placeholder}/>
        )}
      />
    </div>
  );
};

export default CustomSelect;
