import NumberFormat from "react-number-format";
import {LoaderData} from "../loaderData";
import React, {useCallback, useMemo, useState} from "react";
import {getPhoneFormat} from "../../utils";
import {makeStyles} from "@material-ui/core/styles";
import {getClientPhone, getClientPhones} from "../../pages/deals/list/api";

export const ShowPhone = ({
                            body = {}, onClick, phonesMode = false, phoneValue = ''
                          }) => {
  const classes = useStyles();
  const phoneFormat = getPhoneFormat();
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClickPhone = useCallback(async (e) => {
    try {
      setLoading(true)
      const res = phonesMode ? await getClientPhones(body) : await getClientPhone(body)
      setLoading(false)
      if (!!res.error) return
      !phonesMode && setPhoneNumber(res.phone)
      onClick && onClick(e, phonesMode ? {phones: res?.phones} : res.phone)
    } catch (e) {
      setLoading(false)
    }
  }, [body, phonesMode])

  const phoneNumberComputed = useMemo(() => {
    if(phonesMode) return phoneValue
    return phoneNumber
  }, [phonesMode, phoneNumber, phoneValue])

  return <>
    {
      !!phoneNumberComputed
        ? <NumberFormat
          value={phoneNumberComputed || ""}
          displayType={"text"}
          format={phoneFormat || "+# (###) ###-####"}
        />
        : phoneNumberComputed === null
          ? 'Телефон не найден'
          : <span className={classes.phoneWrapper} onClick={(e) => !loading && handleClickPhone(e)}>
            <span className={classes.phone} id={"show_phone"}>Показать телефон</span>
            {loading && <LoaderData small allWidth={false}/>}
      </span>
    }
  </>
}
export const useStyles = makeStyles({
  phone: {
    padding: "3px",
    display: "block",
    textDecoration: "underline",
    color: "#646464",
    transition: "all .4s",
    "&:hover": {
      color: "#000",
    }
  },
  phoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: "pointer"
  },
});