import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModalData, setShowModal} from "../../../services/redux/actions/app-actions";
import {deleteContactData} from "./api";

import {TableCell, TableRow} from "@material-ui/core";

import {MODALS} from "../../../constants";
import {Delete} from "react-iconly";
import {ShowPhone} from "../../../components/helpers/showPhone";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

function ContactDataRowComp({
                              contact_fio,
                              is_contact,
                              phone, clientId,
                              client_phone_id,
                            }) {
  const dispatch = useDispatch();
  const {...values} = useSelector((state) => state.appState.dealCreateData);
  const {addDefaultSnack} = useCustomSnackbar();

  const handleContactDelete = (contact_id, isContact) => {
    deleteContactData(values?.client_id, contact_id, {
      phone: null,
      contact_fio: null,
      is_contact: isContact,
    })
      .then((res) => {
        dispatch(setModalData({contactData: res}));
        addDefaultSnack("Контактные данные успешно удалены");
        dispatch(setShowModal(false));
      })
      .catch(() => {
      });
  };

  const handleClickPhone = useCallback(async (e, res = {}) => {
    if (!res) return
    dispatch(setModalData({contactData: res}))
  }, [dispatch])
  return (
    <TableRow>
      <TableCell align="center">{contact_fio}</TableCell>
      <TableCell align="center">
        <ShowPhone onClick={handleClickPhone} phoneValue={phone} body={{client_id: clientId}} phonesMode/>
      </TableCell>
      <TableCell align="center">
        {is_contact ? "Контактное лицо" : "Доп контакт"}
      </TableCell>
      <TableCell
        style={{cursor: "pointer"}}
        align="center"
        onClick={() => {
          dispatch(
            setShowModal(true, MODALS.confirmDelete, {
              title: "Удалить контактное лицо",
              handleDelete: () =>
                handleContactDelete(client_phone_id, is_contact),
            })
          );
        }}
      >
        <Delete set="light" primaryColor="#FF6262"/>
      </TableCell>
    </TableRow>
  );
}

export default ContactDataRowComp;
