import React from "react";
import {Box, ClickAwayListener, TextareaAutosize} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CustomButton from "../Button";
import CloseIcon from "@material-ui/icons/Close";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
  textArea: {
    width: "100%",
    border: "none",
    borderRadius: 4,
    backgroundColor: "#fff",
    fontSize: 14,
    color: "#494C62",
    outline: "none",
    letterSpacing: "0.01em",
    resize: "none",
    whiteSpace: "pre-wrap"
  },
  wrapper: {
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    padding: 10,
    width: "100%",
    marginTop: 10
  },
  close_icon: {
    color: "#6191EF",
    marginLeft: 10
  }
});

const CommentField = ({
                        handleClickAway,
                        value = "",
                        handleChange,
                        handleClick,
                        loading,
                        handleClear
                      }) => {
  const classes = useStyles();
  const {crm_deal_id} = useSelector(state => state.appState.dealCreateData);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={classes.wrapper}
        onKeyDown={event => {
          if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
            handleClick();
          }
        }}
      >
        <TextareaAutosize
          className={classes.textArea}
          minRows={2}
          placeholder="Введите комментарий"
          autoFocus
          value={value}
          onChange={handleChange}
        />
        {crm_deal_id ? (
          <Box display="flex" alignItems="center">
            <Box>
              <CustomButton loading={loading} disabled={loading} btnText="Сохранить" handleClick={handleClick}/>
            </Box>
            <CloseIcon className={classes.close_icon} onClick={handleClear}/>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default CommentField;
