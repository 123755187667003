import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {getHuntersPlan, getHuntersPlanInfo} from "./api";
import Layout from "../../components/layout";
import Filters from "./Filters";
import styleConstants from "../../constants/styleConstants";
import TableRowComp from "./TableRowComp";
import EmptyOrShow from "../../components/EmptyOrShow";
import cookiesService from "../../services/cookiesService";
import {formatSum} from "../../utils";

const cookiesServiceInstance = cookiesService.getService();
const HuntersPlan = () => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const classes = useStyles();
  const [planInfo, setPlanInfo] = useState({});
  const [huntersPlan, setHuntersPlan] = useState([]);
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    sale_department_id: saleDepartmentId(position_code)
  });
  const [loading, setLoading] = useState(true);

  const getHuntersFunc = useCallback(() => {
    setLoading(true);
    getHuntersPlan(filters.year, filters.month, filters.sale_department_id)
      .then(res => {
        setHuntersPlan(res);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters])

  useEffect(() => {
    getHuntersFunc();
  }, [getHuntersFunc]);

  const getHunterInfo = () => {
    getHuntersPlanInfo(filters).then(res => {
      setPlanInfo(res);
    });
  };

  const handleChange = e => {
    setFilters({...filters, [e.target.name]: e.target.value});
  };

  const updateInfo = val => {
    getHunterInfo();
    setHuntersPlan(val);
  };
  return (
    <Layout title="План хантеров">
      <Box sx={{padding: "45px 20px 20px"}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "20px",
            padding: "20px 0"
          }}
        >
          <Filters
            state={filters}
            handleChange={handleChange}
            setHuntersPlan={updateInfo}
          />

          {planInfo.text && filters.sale_department_id !== "" ? (
            <Alert
              variant="filled"
              severity={
                planInfo?.doesnt_exist ? "error" : colors[planInfo.by_plan]
              }
            >
              {planInfo?.doesnt_exist ? textInfo.exist(planInfo.left) : ""}
              {planInfo.by_plan ? textInfo.full(planInfo.left) : ""}
              {planInfo.by_plan === false
                ? textInfo.success(planInfo.left)
                : ""}
              {planInfo.by_plan === null ? textInfo.warning(planInfo.left) : ""}
            </Alert>
          ) : null}
          {planInfo.is_closed ? (
            <Alert variant="filled" severity="info">
              Месяц закрыт
            </Alert>
          ) : null}
        </Box>

        <Box>
          <EmptyOrShow loading={loading}>
            <TableContainer className={classes.table_container}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead className={classes.table_head}>
                  <TableRow>
                    {headTitle.map(el => {
                      return <TableCell key={el}>{el}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.table_body}>
                  {huntersPlan?.map(el => {
                    return (
                      <TableRowComp
                        key={el.hunter_month_plan_id}
                        {...el}
                        state={filters}
                        setHuntersPlan={updateInfo}
                        getHuntersFunc={getHuntersFunc}
                        isClosed={planInfo?.is_closed}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </EmptyOrShow>
        </Box>
      </Box>
    </Layout>
  );
};

export default HuntersPlan;

const headTitle = ["ID", "Хантер", "Оклад", "План", "Штраф", "Действия"];

export const useStyles = makeStyles({
  table_container: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    height: "calc(100vh - 160px)",

    "&::-webkit-scrollbar": {
      width: 8
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "none"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "1px solid #ccc !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  },
  btn_penalty_list: {
    border: "none",
    outline: "none",
    background: "none",
    textDecoration: "underline",
    cursor: "pointer",
    padding: "5px"
  }
});

const colors = {
  false: "error",
  true: "info",
  null: "warning"
};
const saleDepartmentId = (position_code) => {
  if (
    position_code === "SALE_HEAD_HEAD" ||
    position_code === "SALE_AUDITOR"
  ) {
    return "";
  }
  return 1;
}

const textInfo = {
  success: val => {
    val = formatSum(val);
    return `План хантеров превышен на ${val} по отделу продаж`;
  },
  full: () => {
    return `План распределен`;
  },
  warning: val => {
    val = formatSum(val);
    return `Нераспределенный план: ${val}`;
  },
  exist: () => {
    return `Данный месяц не внесен в план ОП`;
  }
};
