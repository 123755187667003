import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, TextareaAutosize} from "@material-ui/core";

import {changeTechFeature, getTechFeature} from "../../../pages/deals/view/api";
import CustomButton from "../../UI/Button";
import styleConstants from "../../../constants/styleConstants";
import EmptyOrShow from "../../EmptyOrShow";
import {setBtnStatuses, setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
  techFeature: {
    width: 582
  },
  textArea: {
    border: "none",
    outline: "none",
    fontSize: 14,
    letterSpacing: "0.01em",
    backgroundColor: "#fff",
    borderRadius: 4,
    color: "#494C62",
    resize: "none",
    whiteSpace: "pre-wrap",
    width: 582,
    height: "150px !important",
    maxHeight: 150,
    padding: 10,
    overflow: "auto !important",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: 5
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  btn_wrapper: {
    width: 111,
    margin: "12px 0 0 auto"
  }
});

export default function TechFeatureModal({crm_deal_id, btnStatuses}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getTechFeature(crm_deal_id)
      .then(res => {
        setValue(res?.tech_feature);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crm_deal_id]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    changeTechFeature(crm_deal_id, {tech_feature: value})
      .then(res => {
        setValue(res?.tech_feature);
        dispatch(
          setBtnStatuses({
            ...btnStatuses,
            tech_feature_btn: res.tech_feature_btn
          })
        );
        dispatch(setShowModal(false));
      })
      .catch(() => {
      });
  };

  return (
    <div className={classes.techFeature}>
      <EmptyOrShow loading={loading}>
        <TextareaAutosize
          className={classes.textArea}
          value={value === null ? "" : value}
          autoFocus
          onChange={handleChange}
        />
        <Box className={classes.btn_wrapper}>
          <CustomButton
            btnText={"Сохранить"}
            bgColor={styleConstants.primaryColor}
            handleClick={handleSave}
            disabled={!value}
          />
        </Box>
      </EmptyOrShow>
    </div>
  );
}
