import React, {useEffect, useState} from "react";
import SingleChart from "./SingleChart";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import HorizontalChart, {anotherColors} from "./HorizontalChart";
import AutoSuggest from "../../components/UI/AutoSuggest";
import CustomDatePicker from "../../components/UI/DatePicker";
import {getCallCenterManagers, getConversionData} from "./api";
import {getCities, getHunters, getOrigins, getSaleDepart, getSalePoints} from "../deals/view/api";
import EmptyOrShow from "../../components/EmptyOrShow";
import {convertHoursToDays} from "../../utils";
import DownloadExcel from "../../components/DownloadExcel";
import {useSelector} from "react-redux";
import cookiesService from "../../services/cookiesService";

const initialState = {
  hunter_id: null,
  city_id: null,
  origin_ids: [],
  sale_point_id: null,
  manager_cc_id: null,
  sale_department_id: null,
  date_begin: "",
  date_end: ""
};

const initialDataState = {
  hunters: [],
  cities: [],
  origins: [],
  salePoints: [],
  callCenterManagers: [],
  saleDepartments: []
};

const cookiesServiceInstance = cookiesService.getService();

const ReportCharts = () => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const classes = useStyles();
  const [filterState, setFilterState] = useState(initialState);
  const [filterData, setFilterData] = useState(initialDataState);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {cityId} = useSelector(state => state.appState);

  const {
    average_day,
    percents,
    time_intervals,
    statuses_info,
    origins,
    deals_cnt
  } = data;

  const fetchData = filters => {
    setLoading(true);
    getConversionData(filters)
      .then(res => {
        setData(res);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e, v, name) => {
    if (name === "date_begin" || name === "date_end") {
      setFilterState({...filterState, [name]: v});
    } else {
      setFilterState({...filterState, [e.target.name]: e.target.value});
    }
  };

  const handleClearDate = name => {
    setFilterState(prevState => ({...prevState, [name]: null}));
  };

  useEffect(() => {
    fetchData(filterState);
  }, [filterState]);

  useEffect(() => {
    Promise.all([
      getHunters(),
      getCities(),
      getOrigins(),
      getSalePoints(),
      getCallCenterManagers(),
      getSaleDepart()
    ])
      .then(res => {
        const [
          hunters,
          cities,
          origins,
          salePoints,
          callCenterManagers,
          saleDepartments
        ] = res;
        setFilterData({
          hunters,
          cities,
          origins,
          salePoints,
          callCenterManagers,
          saleDepartments
        });
      })
      .catch(() => {});
  }, []);

  const originsCount = origins?.map(el => el.crm_deals_cnt);
  const sorted = time_intervals
    ?.filter(el => el !== null)
    ?.map(day => convertHoursToDays(day));
  const convertedDays = sorted?.map(el => el.days);
  const convertedHours = sorted?.map(el => el.hours);
  return (
    <>
      <Box
        display="flex"
        mt={3}
        mb={3}
        style={{gap: 20}}
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box minWidth={200}>
          <AutoSuggest
            label={"Хантер"}
            placeholder="Выберите"
            name="hunter_id"
            value={filterState["hunter_id"]}
            onChange={handleChange}
            list={filterData["hunters"]}
            showKey={"fio"}
            valueKey="employee_id"
          />
        </Box>
        <Box minWidth={145}>
          <AutoSuggest
            label={"Город"}
            placeholder="Выберите"
            name="city_id"
            value={filterState["city_id"]}
            onChange={handleChange}
            list={filterData["cities"]}
            showKey={"city_name"}
            valueKey="city_id"
          />
        </Box>
        <Box minWidth={145}>
          <AutoSuggest
            label={"Источник лида"}
            placeholder="Выберите"
            name="origin_ids"
            value={filterState["origin_ids"]}
            isMultiple
            onChange={handleChange}
            list={filterData["origins"]}
            showKey={"origin_name"}
            valueKey="origin_id"
          />
        </Box>
        {position_code !== "SALE_HEAD" ? (
          <Box minWidth={145}>
            <AutoSuggest
              label={"Отдел продаж"}
              placeholder="Выберите"
              name="sale_department_id"
              value={filterState["sale_department_id"]}
              list={filterData["saleDepartments"]}
              onChange={handleChange}
              showKey={"sale_department_name"}
              valueKey="sale_department_id"
            />
          </Box>
        ) : (
          ""
        )}

        <Box minWidth={145}>
          <AutoSuggest
            label={"Точка продаж"}
            placeholder="Выберите"
            name="sale_point_id"
            value={filterState["sale_point_id"]}
            onChange={handleChange}
            list={filterData["salePoints"]}
            showKey={"point_name"}
            valueKey="sale_point_id"
          />
        </Box>
        {position_code !== "CALL_CENTER" ? (
          <Box minWidth={200}>
            <AutoSuggest
              label={"Менеджер КЦ"}
              placeholder="Выберите"
              name="manager_cc_id"
              value={filterState["manager_cc_id"]}
              onChange={handleChange}
              list={filterData["callCenterManagers"]}
              showKey={"fio"}
              valueKey="employee_id"
            />
          </Box>
        ) : (
          ""
        )}

        <Box minWidth={200} className={classes.select_date}>
          <CustomDatePicker
            name="date_begin"
            label="Дата от"
            placeholder="Введите дату"
            value={filterState["date_begin"]}
            handleChange={handleChange}
            handleClearDate={handleClearDate}
          />
        </Box>
        <Box minWidth={200} className={classes.select_date}>
          <CustomDatePicker
            name="date_end"
            label="Дата по"
            placeholder="Введите дату"
            value={filterState["date_end"]}
            handleChange={handleChange}
            handleClearDate={handleClearDate}
          />
        </Box>
        <Box display="flex" alignItems="flex-end" height={45}>
          <DownloadExcel
            url="crm/reports/conversion/xlsx/"
            params={{
              ...filterState,
              city_id: cityId === "null" || cityId === "" ? null : cityId
            }}
            title="Отчёты"
          />
        </Box>
      </Box>

      <EmptyOrShow loading={loading}>
        <Box style={{backgroundColor: "#fff", padding: 25}}>
          <Box display="flex" className={classes.wrapper}>
            <Box width="100%">
              <Typography className={classes.title}>ЖЦ Сделки</Typography>
              <div>
                {percents?.map((percent, i) => (
                  <SingleChart
                    size={percent}
                    days={convertedDays}
                    averageDay={data.average_day}
                    rowIndex={i}
                    key={i}
                    convertedHours={convertedHours}
                  />
                ))}
              </div>
            </Box>
            <Box width="15%">
              <Typography className={classes.title}>
                Конверсия Сделок
              </Typography>
              <Box>
                {statuses_info?.map(el => {
                  return (
                    <Box key={el.crm_status_id} className={classes.lead_item}>
                      <Typography className={classes.subtitle}>
                        {el.crm_status_name}
                      </Typography>
                      <Typography className={classes.deal_count}>
                        {el.crm_deals_cnt} сделки
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: 20
            }}
          >
            <Box>
              <Typography className={classes.day_count}>
                {average_day}
              </Typography>
              <Typography className={classes.info}>
                Средняя длительность сделки
              </Typography>
            </Box>
            <Box
              sx={{
                color: "#0E1432",
                fontSize: 18,
                fontWeight: 500,
                lineHeight: "1.5"
              }}
            >
              Всего {deals_cnt} сделок
            </Box>
          </Box>
        </Box>

        <Box mt={5} sx={{backgroundColor: "#FFF", padding: 25}}>
          <Typography className={classes.title}>
            Конверсия по источникам лида
          </Typography>
          <HorizontalChart days={originsCount} isDays={false}/>
          <Box mt={3} display="flex" flexWrap="wrap">
            {origins?.map((el, i) => (
              <Box width={110} key={el.origin_id} mr={3} mb={3}>
                <Typography className={classes.conversion_count}>
                  {el.crm_deals_cnt}
                </Typography>
                <Box>
                  <span
                    className={classes.square}
                    style={{backgroundColor: anotherColors[i]}}
                  />
                  <span className={classes.conversion_type}>
                    {el.origin_name}
                  </span>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </EmptyOrShow>
    </>
  );
};

export default ReportCharts;

const useStyles = makeStyles({
  wrapper: {
    gap: 30
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",

    letterSpacing: "0.03em",
    textTransform: "uppercase",
    color: "#494C62",
    marginBottom: 20,
    whiteSpace: "nowrap"
  },
  lead_item: {
    height: 50,
    "&:not(:last-child)": {
      marginBottom: "25px"
    }
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    color: "#9194A9",
    letterSpacing: "1px",
    whiteSpace: "nowrap"
  },
  deal_count: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1px",
    color: "#0E1432"
  },
  day_count: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: "44px",
    color: "#0E1432"
  },
  info: {
    color: "#9194A9",
    fontWeight: 500
  },
  conversion_count: {
    fontSize: 24,
    fontWeight: 500,
    color: "#0E1432"
  },
  conversion_type: {
    color: "#0E1432",
    marginLeft: 10
  },
  square: {
    display: "inline-block",
    width: 10,
    height: 10,
    backgroundColor: "#C4C4C4"
  },
  select_date: {
    "& button": {
      padding: 0
    }
  }
});
