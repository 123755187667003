import React, {useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {CloseSquare, Delete, Edit, TickSquare} from "react-iconly";

import InputText from "../../../components/Form/InputText";
import {changeTransfer, deleteTransfer} from "./api";
import {setBtnStatuses} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  input: {
    marginBottom: "0 !important",

    "& .MuiInput-underline::before": {
      content: "none"
    },

    "& .MuiInput-underline::after": {
      content: "none"
    },

    "& input": {
      fontSize: "0.875rem",
      textAlign: "center",
      border: "1px solid #E2E3ED !important",
      borderRadius: 5,
      padding: "5px !important"
    }
  },
  btn_block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      cursor: "pointer",
      width: 25,
      height: 25,
      margin: "0 5px"
    },

    "& svg": {
      width: "100%",
      height: "100%"
    }
  },
  short: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
});

export default function KeyRowComp({
                                     key_receive_id,
                                     receive_date,
                                     key_from,
                                     key_to,
                                     comments,
                                     employee_fio,
                                     setData,
                                     crmDealId,
                                     btnStatuses
                                   }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [copyState, setCopyState] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [rowState, setRowState] = useState({
    receive_date,
    key_from,
    key_to,
    comments
  });
  const {addDefaultSnack} = useCustomSnackbar();

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setCopyState(rowState);
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmEdit = () => {
    setIsEditing(false);
    changeTransfer(crmDealId, key_receive_id, rowState)
      .then(res => {
        setData(res);
        addDefaultSnack();
      })
      .catch(() => {
      });
  };

  const handleConfirmDelete = () => {
    deleteTransfer(crmDealId, key_receive_id)
      .then(res => {
        setData(res.keys);
        dispatch(setBtnStatuses({...btnStatuses, keys_btn: res.keys_btn}));
        addDefaultSnack();
      })
      .catch(() => {
      });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setRowState(copyState);
  };

  const handleCancelDelete = () => {
    setIsDelete(false);
  };

  const handleChange = e => {
    setRowState({
      ...rowState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <TableRow>
      <TableCell align="center">{key_receive_id}</TableCell>
      {isEditing ? (
        <TableCell align="center">
          <InputText
            name="receive_date"
            value={rowState?.receive_date}
            onChange={handleChange}
            className={classes.input}
          />
        </TableCell>
      ) : (
        <TableCell align="center">{receive_date}</TableCell>
      )}
      {isEditing ? (
        <TableCell align="center">
          <InputText
            name="key_from"
            value={rowState?.key_from}
            onChange={handleChange}
            className={classes.input}
          />
        </TableCell>
      ) : (
        <TableCell align="center">{key_from}</TableCell>
      )}
      {isEditing ? (
        <TableCell align="center">
          <InputText
            name="key_to"
            value={rowState?.key_to}
            onChange={handleChange}
            className={classes.input}
          />
        </TableCell>
      ) : (
        <TableCell align="center">{key_to}</TableCell>
      )}
      {isEditing ? (
        <TableCell>
          <InputText
            name="comments"
            value={rowState?.comments}
            onChange={handleChange}
            className={classes.input}
          />
        </TableCell>
      ) : (
        <TableCell align="center" className={classes.short} title={comments}>
          {comments}
        </TableCell>
      )}
      <TableCell align="center">{employee_fio}</TableCell>
      <TableCell align="center">
        <Box className={classes.btn_block}>
          {isEditing ? (
            <span onClick={handleConfirmEdit}>
              <TickSquare set="bold" primaryColor="#77BF6C"/>
            </span>
          ) : isDelete ? (
            <span onClick={handleConfirmDelete}>
              <TickSquare set="bold" primaryColor="#77BF6C"/>
            </span>
          ) : (
            <span onClick={handleEdit}>
              <Edit set="light" primaryColor="#6191EF"/>
            </span>
          )}
          {isEditing ? (
            <span onClick={handleCancelEdit}>
              <CloseSquare set="bold" primaryColor="#FF4754"/>
            </span>
          ) : isDelete ? (
            <span onClick={handleCancelDelete}>
              <CloseSquare set="bold" primaryColor="#FF4754"/>
            </span>
          ) : (
            <span onClick={handleDelete}>
              <Delete set="light" primaryColor="#FF6262"/>
            </span>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
