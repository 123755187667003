import instance from "../../api";

export const getCoefficients = (state) => {
  let data = {};
  if (state.sale_department_id)
    data.sale_department_id = state.sale_department_id;
  return instance(true)
    .get(`/crm/kpi/types/${state.coeff}/koefs/`, { params: data })
    .then((res) => {
      return res?.data;
    });
};
export const putCoefficient = (state, type) => {
  state.color_code = `#${state.color_code}`;
  return instance(true)
    .put(
      `/crm/kpi/types/${type}/koefs/${state.sale_koef_id}/?sale_department_id=${state.sale_department_id}`,
      state
    )
    .then((res) => {
      return res?.data;
    });
};
export const addCoefficient = (state, type) => {
  state.color_code = `#${state.color_code}`;
  return instance(true)
    .post(
      `/crm/kpi/types/${type}/koefs/?sale_department_id=${state.sale_department_id}`,
      state
    )
    .then((res) => {
      return res?.data;
    });
};
export const deleteCoefficient = (id, type) => {
  return instance(true)
    .delete(`/crm/kpi/types/${type}/koefs/${id}/`)
    .then((res) => {
      return res?.data;
    });
};

export const getCoefficientTypes = () => {
  return instance(true)
    .get(`/crm/kpi/types/`)
    .then((res) => {
      return res?.data;
    });
};
