import React, {Fragment} from "react";
import clsx from "clsx";
import {Avatar, Box} from "@material-ui/core";
import {connect, useDispatch} from "react-redux";
import EmptyOrShow from "components/EmptyOrShow";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {useHistory} from "react-router";
import cookiesService from "../../../services/cookiesService";
import {logout} from "../../../services/redux/actions/app-actions";

const cookiesServiceInstance = cookiesService.getService();
const SidebarUserbox = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {sidebarToggle, sidebarHover} = props;

  const [loading,] = React.useState(false);

  const userData = cookiesServiceInstance.getUserData();
  const handleLogout = () => {
    dispatch(logout(cookiesServiceInstance, history.push))
  }

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover
        })}
      >
        <EmptyOrShow loading={loading}>
          <ExitToAppIcon
            onClick={handleLogout}
            style={{
              transform: "rotate(180deg)",
              color: "black",
              cursor: "pointer"
            }}
            className="profile__icons"
          />
          <Avatar
            alt={userData.fio}
            className="app-sidebar-userbox-avatar"
          />
          <Box className="app-sidebar-userbox-name">
            <Box>
              <b>{userData.fio}</b>
            </Box>
            <Box className="app-sidebar-userbox-position ">
              {userData.bpm_position}
            </Box>
            <Box className="app-sidebar-userbox-description">
              {userData.email}
            </Box>
            <Box className="app-sidebar-userbox-description">
              {userData.phone}
            </Box>
          </Box>
        </EmptyOrShow>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
