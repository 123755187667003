import React from "react";
import { Link } from "react-router-dom";
import { darken, makeStyles } from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import { Box, Tooltip, Typography } from "@material-ui/core";
import {
  BsCheck,
  BsFillTelephoneFill,
  FaCheckCircle,
  FaUserAlt,
  HiOutlineArrowSmRight,
  RiBuilding4Fill,
} from "react-icons/all";
import List from "@material-ui/core/List";
import clsx from "clsx";
import CardListItem from "./CardListItem";
import MinLead from "./MinLead";
import { takeToWork } from "../list/api";
import { ReactComponent as OriginIcon } from "../../../assets/images/icons/origin_icon.svg";
import { ReactComponent as WallIcon } from "../../../assets/images/icons/wall.svg";
import { ReactComponent as PaintBrushIcon } from "../../../assets/images/icons/paint_brush.svg";
import ArchiveIcon from "@material-ui/icons/Archive";
import { getBgColor } from "../../../utils";
import { requestTypeCodes } from "../../../constants";

const LeadCard = ({
  origin_name,
  type = null,
  is_canceled = false,
  crm_deal_id,
  crmStatusId,
  client_fio,
  resident_name,
  flat_num,
  deadline,
  is_minimal,
  is_archive,
  el,
  price,
  take_work,
  current_employee,
  fetchDeals,
  is_auditor_checked,
  virtualRow,
  is_remarks_corrected,
  cc_task_text,
  request_type_code,
  request_type_name,
}) => {
  const props = {
    backgroundColor: getBgColor(crmStatusId, is_archive, true),
  };
  const classes = useStyles(props);

  const handleTakeToWork = () => {
    takeToWork(crm_deal_id)
      .then(() => {
        fetchDeals();
      })
      .catch(() => {});
  };

  if (is_minimal) {
    return <MinLead isCanceled={is_canceled} {...el} />;
  }

  const onLeadClick = (e) => {
    if (e.target.id === "show_phone") return e?.preventDefault();
  };
  return (
    <Box
      className={classes.card}
      style={{
        height: `${virtualRow.size - 15}px`,
        transform: `translateY(${virtualRow.start}px)`,
      }}
    >
      <Box className={classes.wrapper}>
        <Link
          onClick={onLeadClick}
          href="#"
          to={`/deal/${crm_deal_id}`}
          className={classes.link_details}
          target="_blank"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Box className={classes.card_id__wrapper}>
              <Typography className={classes.card_id}>
                №{crm_deal_id}
              </Typography>
              {is_archive && (
                <Tooltip title={"В архиве"}>
                  <ArchiveIcon className={classes.archiveIcon} />
                </Tooltip>
              )}
            </Box>
            <Typography className={classes.header_info}>{deadline}</Typography>
          </Box>
          <List>
            <CardListItem text={client_fio}>
              <FaUserAlt />
            </CardListItem>
            <CardListItem isPhone getPhoneBody={{ crm_deal_id }}>
              <BsFillTelephoneFill className={classes.icon} />
            </CardListItem>
            <CardListItem
              text={`${resident_name} - КВ ${
                flat_num === null ? "" : flat_num
              }`}
            >
              <RiBuilding4Fill className={classes.icon} />
            </CardListItem>
            <CardListItem text={origin_name}>
              <OriginIcon className={classes.icon} />
            </CardListItem>
            {is_remarks_corrected ? (
              <CardListItem text="Замечания исправлены">
                <FaCheckCircle className={classes.icon} />
              </CardListItem>
            ) : null}
            {is_auditor_checked ? (
              <CardListItem
                text="Проверено аудитором"
                isAuditorChecked={is_auditor_checked}
              >
                <BsCheck className={classes.check_icon} />
              </CardListItem>
            ) : null}
            <Box className={classes.tasks_wrapper}>
              {cc_task_text ? (
                <Box display="flex" className={classes.cc_task_text}>
                  <Box display="flex" className={classes.tasks_name}>
                    КЦ: {cc_task_text}
                  </Box>
                </Box>
              ) : null}
              {current_employee ? (
                <Box display="flex" className={classes.tasks_name}>
                  В работе: {current_employee}
                </Box>
              ) : null}
            </Box>
            <CardListItem text={request_type_name}>
              {request_type_code === requestTypeCodes.remont ? (
                <PaintBrushIcon className={classes.localIcon} />
              ) : request_type_code === requestTypeCodes.materials ? (
                <WallIcon className={classes.localIcon} />
              ) : null}
            </CardListItem>
          </List>
        </Link>
        {type ? null : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={clsx(classes.subtitle, classes.text)}>
              {price}
            </Typography>
            {take_work ? (
              <Box
                display="flex"
                alignItems="center"
                className={classes.footer_work}
                onClick={handleTakeToWork}
                component="span"
              >
                <Typography className={clsx(classes.take_work, classes.text)}>
                  Взять в работу
                </Typography>
                <HiOutlineArrowSmRight className={classes.footer_icon} />
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default LeadCard;

export const useStyles = makeStyles({
  card: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  wrapper: {
    backgroundColor: (props) => props.backgroundColor,
    border: styleConstants.border,
    borderRadius: styleConstants.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    cursor: "pointer",
    transition: "all .2s linear",

    "& .MuiList-padding": {
      padding: 0,
    },

    "&:hover": {
      backgroundColor: (props) =>
        props.backgroundColor ? darken(props.backgroundColor, 0.07) : "",
    },
  },
  link_details: {
    textDecoration: "none",
    display: "block",
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  card_id: {
    fontSize: 14,
    color: styleConstants.textColor,
    fontWeight: 600,
  },
  card_id__wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
  },
  archiveIcon: {
    fontSize: "18px",
    color: "#964e0e",
  },
  header_info: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.textColor,
  },
  icon: {
    color: styleConstants.iconColor,
    width: 16,
    height: 16,
  },
  localIcon: {
    "& path": {
      stroke: styleConstants.iconColor,
    },
    color: styleConstants.iconColor,
    width: 16,
    height: 16,
  },
  list_item: {
    padding: 0,
    "&:not(:last-child)": {
      marginBottom: 6,
    },
  },
  list_text: {
    color: styleConstants.textColor,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "15px",
    margin: 0,

    "& span": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  list_icon: {
    minWidth: 25,
  },
  tasks_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tasks: {
    color: "#FF4754",
  },
  tasks_icon: {
    display: "flex",
    alignItems: "center",
    minWidth: 30,
  },
  tasks_name: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15px",
    color: "#9194A9",
  },
  take_work: {
    color: styleConstants.primaryColor,
    marginRight: 8,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "15px",
  },
  subtitle: {
    color: styleConstants.subtitle,
  },
  phone: {
    padding: "3px",
    display: "block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  phoneWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  footer_icon: {
    background: styleConstants.primaryColor,
    color: "#fff",
    borderRadius: "50%",
    width: 24,
    height: 24,
  },
  footer_work: {
    cursor: "pointer",
  },
  check_icon: {
    width: 25,
    height: 25,
    transform: "translateX(-4px)",
    color: "#228B22",
  },
  auditor_checked: {
    color: "#228B22",
    fontWeight: 600,
  },
  cc_task_text: {
    border: "1px solid",
    padding: "6px",
    width: "100%",
  },
});
