import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles({
  table_head: {
    backgroundColor: "#ECEEFD"
  },
  table_title: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194A9"
  }
});

export default function TableHeadComp({ data }) {
  const classes = useStyles();

  return (
    <TableHead className={classes.table_head}>
      <TableRow>
        {data.map(el => (
          <TableCell
            key={el}
            component="th"
            scope="row"
            align="center"
            className={classes.table_title}
          >
            {el}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
