import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBtnStatuses,
  setDealCreateData,
  setDealDocs,
  setShowModal,
} from "../../../services/redux/actions/app-actions";

import { makeStyles } from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";

import {
  checkReportTemplates,
  getBtnStatuses,
  getContractNum,
  getMainDocs,
  getReportTemplates,
} from "./api";
import DocsItem from "./DocsItem";
import { format } from "date-fns";
import { MAIN_URL, MODALS } from "../../../constants";
import CustomIconButton from "../../../components/UI/CustomIconButton";
import InputText from "../../../components/Form/InputText";
import { AiOutlinePlus } from "react-icons/ai";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";
import cookiesService from "../../../services/cookiesService";
import SelectPopover from "../../../components/Form/SelectPopover";

const cookiesServiceInstance = cookiesService.getService();

const ClientDocs = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const { dealCreateData, dealDocs: docs } = useSelector(
    (state) => state.appState
  );
  const {
    crm_deal_id,
    prop_number,
    prop_date,
    preset,
    client_type_id,
    min_remont_date_begin,
    remont_begin_id,
  } = useSelector((state) => state.appState.dealCreateData);
  const { docs_btn, prop_num_btn } = useSelector(
    (state) => state.appState.btnStatuses
  );
  const dispatch = useDispatch();
  const [reportTemp, setReportTemp] = useState([]);
  const { position_code } = cookiesServiceInstance.getUserData();
  const [reportTempId, setReportTempId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (rt) => {
    if (!rt.id) {
      setReportTempId(null);
    } else {
      setReportTempId(rt.id);
      check(rt.id, rt?.link);
    }
  };

  const check = async (id, value) => {
    setLoading(true);
    try {
      const resp = await checkReportTemplates(crm_deal_id, { id });
      setLoading(false);
      if (resp) {
        window.open(MAIN_URL + value);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (crm_deal_id) {
      getMainDocs(crm_deal_id).then((res) => dispatch(setDealDocs(res)));
    }
  }, [crm_deal_id, dispatch]);

  const presetObj = useMemo(() => preset || {}, [preset]);

  useEffect(() => {
    if (crm_deal_id && presetObj?.client_request_main_id !== null) {
      getReportTemplates(crm_deal_id)
        .then((res) => {
          setReportTemp(res);
        })
        .catch(() => {});
    }
  }, [crm_deal_id, presetObj.client_request_main_id]);

  const { client_aggrement, flat_num, identification } = docs;

  const handleClick = () => {
    getContractNum().then((res) => {
      dispatch(setDealCreateData({ ...dealCreateData, prop_number: res }));
      getBtnStatuses(crm_deal_id, +client_type_id)
        .then((res) => {
          dispatch(setBtnStatuses(res));
        })
        .catch(() => {});
    });
  };

  const handlePropPropChange = (e) => {
    dispatch(
      setDealCreateData({ ...dealCreateData, [e.target.name]: e.target.value })
    );
  };

  const setDocs = useCallback((data) => dispatch(setDealDocs(data)), [
    dispatch,
  ]);

  return (
    <Box
      className={`${
        !crm_deal_id
          ? clsx(classes.clientDocs, globalClasses.disable_block)
          : classes.clientDocs
      } ${position_code === "CALL_CENTER" ? globalClasses.disable_block : ""}`}
    >
      <h4>Документы</h4>
      <Box className={classes.clientDocs_inner}>
        <Box className={classes.clientDocs_left}>
          <Box className={classes.dragAndDrop_wrapper}>
            <DocsItem
              title={
                <Box>
                  Квартирный лист <span className={classes.req_star}>*</span>
                </Box>
              }
              name="flat_list"
              file={flat_num}
              setDocs={setDocs}
              crm_deal_id={crm_deal_id}
            />
          </Box>
          <Box className={classes.dragAndDrop_wrapper}>
            <DocsItem
              title="Договор"
              name="client_agreement"
              file={client_aggrement}
              setDocs={setDocs}
              crm_deal_id={crm_deal_id}
            />
          </Box>
          <Box className={classes.dragAndDrop_wrapper}>
            <DocsItem
              title={
                <Box>
                  Удостоверение <span className={classes.req_star}>*</span>
                </Box>
              }
              name="identification"
              file={identification}
              setDocs={setDocs}
              crm_deal_id={crm_deal_id}
            />
          </Box>
          <Box
            className={classes.clientDocs_item}
            display="flex"
            alignItems="flex-end"
          >
            <InputText
              disabled={!prop_number}
              label={
                <Box>
                  Договор клиента <span className={classes.req_star}>*</span>
                </Box>
              }
              name="prop_number"
              onChange={handlePropPropChange}
              value={prop_number}
            />
            <button
              className={clsx(classes.contract_btn, {
                [globalClasses.btn_green]: prop_num_btn,
              })}
              onClick={handleClick}
            >
              #
            </button>
          </Box>
          <Box className={classes.clientDocs_item}>
            <InputText
              name="prop_date"
              type="date"
              label={
                <Box>
                  Дата договора <span className={classes.req_star}>*</span>
                </Box>
              }
              onChange={handlePropPropChange}
              value={prop_date ? format(new Date(prop_date), "yyyy-MM-dd") : ""}
              shrink={true}
            />
          </Box>
          <Box className={classes.clientDocs_item}>
            <InputText
              name="min_remont_date_begin"
              type="date"
              label={
                <Box>
                  Минимальная дата начала ремонта
                  {!!remont_begin_id && remont_begin_id === 2 && (
                    <span className={classes.req_star}>*</span>
                  )}
                </Box>
              }
              onChange={handlePropPropChange}
              value={
                min_remont_date_begin
                  ? format(new Date(min_remont_date_begin), "yyyy-MM-dd")
                  : ""
              }
              shrink={true}
            />
          </Box>
        </Box>
        <Box className={classes.clientDocs_right}>
          <Box className={classes.clientDocs_item}>
            <SelectPopover
              name="id"
              value={reportTempId}
              onClick={handleChange}
              list={reportTemp || []}
              valueKey="id"
              label={"Выгрузка документа"}
              dropdownMode
              margin={false}
              showKey="report_name"
              loading={loading}
            />
          </Box>
          <Box className={classes.clientDocs_btns}>
            <button
              className={`${
                docs_btn
                  ? clsx(classes.additionalDocs_btn, globalClasses.btn_green)
                  : clsx(classes.additionalDocs_btn, globalClasses.btn_grey)
              }`}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.additionalDocs, {
                    title: "Дополнительные документы",
                    crm_deal_id,
                  })
                )
              }
            >
              Доп документы
            </button>
            <CustomIconButton
              width={32}
              height={32}
              color="#6191EF"
              handleClick={() => {
                dispatch(
                  setShowModal(true, MODALS.uploadDocs, {
                    title: "Загрузка документов",
                    crm_deal_id,
                  })
                );
              }}
            >
              <AiOutlinePlus color="#6191EF" />
            </CustomIconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientDocs;

export const useStyles = makeStyles({
  clientDocs: {
    backgroundColor: "#fff",
    border: "1px solid #e2e3ed",
    borderRadius: 4,
    padding: 20,

    "& h4": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "17px",
      color: "#494c62",
      margin: "0 0 15px 0",
    },
  },
  clientDocs_inner: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 50,
  },
  clientDocs_left: {
    width: 300,
  },
  clientDocs_right: {
    width: 300,
  },
  dragAndDrop_wrapper: {
    marginBottom: 15,
  },
  clientDocs_item: {
    marginBottom: 15,

    "& .MuiFormControl-root label": {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15px",
      color: "#9194a9",
      transform: "scale(1)",
    },
  },
  contract_btn: {
    color: "#fff",
    backgroundColor: "#9194A9",
    border: "none",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    cursor: "pointer",
  },
  clientDocs_btns: {
    display: "flex",
    gap: 10,
  },
  additionalDocs_btn: {
    fontWeight: 600,
    color: "#fff",
    border: "none",
    borderRadius: styleConstants.borderRadius,
    padding: "8px 10px",
    cursor: "pointer",
  },
  req_star: {
    fontSize: 14,
    color: "red",
  },
});
