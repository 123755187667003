export const getTokenExpires = token => {
  if (!token) return;
  try {
    const jwt = require("jsonwebtoken");
    const decodedToken = jwt.decode(token, { complete: true });
    return decodedToken.payload.exp;
  } catch (e) {
    return 0;
  }
};

export const getTokenExpiresDuration = token => {
  if (!token) return;
  try {
    const tokenExpiresDate = getTokenExpires(token) * 1000;
    let expires;
    if (tokenExpiresDate && !isNaN(tokenExpiresDate)) {
      expires =
        (new Date(tokenExpiresDate).getTime() - new Date().getTime()) / 1000;
    }
    return expires;
  } catch (e) {
    return 0;
  }
};
