import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";
import { BsFillPersonFill } from "react-icons/bs";
import { FaInstagramSquare, FaPhoneAlt } from "react-icons/all";

const useStyles = makeStyles({
  lead: {
    width: "100%",
    padding: "16px 10px 6px",
    border: styleConstants.border,
    borderRadius: styleConstants.borderRadius,
    marginTop: 10,
    backgroundColor: props => props.backgroundColor
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: styleConstants.textColor,
    marginLeft: 10
  },
  icon_bg: {
    backgroundColor: "#72768F"
  }
});

const MinLead = ({ isCanceled }) => {
  const props = {
    backgroundColor: isCanceled ? "#FFEFF4" : "#FAFAFE"
  };
  const classes = useStyles(props);
  return (
    <Box className={classes.lead}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box display="flex">
          <BsFillPersonFill size={20} color="#72768F" />
          <Typography className={classes.text}>Айтымгуль</Typography>
        </Box>
        <Typography className={classes.text}>14 октября 15:59</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <FaPhoneAlt style={{ height: 14, width: 20 }} color="#72768F" />
          <Typography className={classes.text}>+7 777 123 54 76</Typography>
        </Box>
        <FaInstagramSquare size={20} color="#72768F" />
      </Box>
    </Box>
  );
};

export default MinLead;
