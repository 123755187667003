import React from "react";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../services/redux/actions/app-actions";
import CustomButton from "../../components/UI/Button";
import {Box, IconButton, TableCell, TableRow} from "@material-ui/core";
import {Edit} from "react-iconly";
import {MODALS} from "../../constants";
import {changeKpi} from "./api";
import Progress from "../../components/UI/Progress";
import NumberFormat from "react-number-format";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";

const TableRowComp = ({
                        state,
                        setKpiList,
                        sd_month_plan_id,
                        sd_plan_month,
                        sd_month_plan_sum,
                        sd_month_fact_sum,
                        percentage,
                        taxes_percentage,
                        is_closed,
                        color_code,
                        sale_department_name
                      }) => {
  const dispatch = useDispatch();
  const {addDefaultSnack} = useCustomSnackbar();

  const closeMonth = () => {
    changeKpi(sd_month_plan_id, state.year, state.id, {is_closed: true})
      .then(res => {
        setKpiList(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно изменен");
      })
      .catch(() => {
      });
  };

  const formatDate = date => {
    const today = new Date(date); // yyyy-mm-dd
    return today.toLocaleString('ru', {month: 'long'});
  };

  return (
    <TableRow>
      <TableCell align="left">
        <NumberFormat
          value={sd_month_plan_id}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left">{sale_department_name}</TableCell>
      <TableCell align="left">{formatDate(sd_plan_month)}</TableCell>
      <TableCell align="left">
        <NumberFormat
          value={sd_month_plan_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left">
        <NumberFormat
          value={taxes_percentage}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left">
        <NumberFormat
          value={sd_month_fact_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left">
        <Progress progress={percentage} color={color_code}/>
      </TableCell>
      <TableCell align="left">
        <Box sx={{width: 150}}>
          <CustomButton
            btnText={is_closed ? "Месяц закрыт" : "Закрыть месяц"}
            disabled={is_closed}
            handleClick={() =>
              dispatch(
                setShowModal(true, MODALS.closeMonthModal, {
                  title: "Закрытие месяца",
                  clickHandle: closeMonth
                })
              )
            }
          />
        </Box>
      </TableCell>
      <TableCell align="left">
        <IconButton
          size="small"
          disabled={is_closed}
          onClick={() =>
            dispatch(
              setShowModal(true, MODALS.changeKpiModal, {
                title: "Изменение",
                state: state,
                setKpiList: setKpiList,
                sd_month_plan_id: sd_month_plan_id,
                sd_month_plan_sum: sd_month_plan_sum,
                taxes_percentage: taxes_percentage
              })
            )
          }
        >
          <Edit set="light" primaryColor={is_closed ? "#ccc" : "#6191EF"}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
