import React, {useCallback, useEffect, useMemo, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

import {getKpi} from "./api";
import Layout from "../../components/layout";
import Filters from "./Filters";
import TableRowComp from "./TableRowComp";
import styleConstants from "../../constants/styleConstants";
import EmptyOrShow from "../../components/EmptyOrShow";
import cookiesService from "../../services/cookiesService";

const cookiesServiceInstance = cookiesService.getService();
const SalesDeptPlan = () => {
  const {position_code} = cookiesServiceInstance.getUserData();
  const classes = useStyles();
  const [kpiList, setKpiList] = useState([]);
  const [state, setState] = useState({
    id: 0,
    year: new Date().getFullYear()
  });
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const filtersArray = useMemo(() => {
    if (!kpiList?.length) return []

    if (!search) return kpiList?.map(el => {
      return {...el, plansList: el.plans};
    })

    return kpiList?.map(el => {
      return {
        ...el, plansList: el.plans.filter(item => {
          return el.sale_department_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          item.sd_month_fact_sum?.toString().includes(search) ||
          item.sd_month_plan_id?.toString().includes(search) ||
          item.sd_month_plan_sum?.toString().includes(search) ||
          item.sd_plan_month?.toString().includes(search) ||
          item.taxes_percentage?.toString().includes(search)
        })
      };
    })
  }, [kpiList, search]);

  const getKpiList = useCallback(() => {
    setLoading(true);
    getKpi(state.year, state.id)
      .then(res => {
        setKpiList(res);
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [state.id, state.year])

  useEffect(() => {
    getKpiList();
  }, [getKpiList]);

  const handleChange = e => {
    setState({...state, [e.target.name]: e.target.value});
  };

  return (
    <>
      {position_code === "SALE_HEAD_HEAD" ? (
        <Layout title="План ОП">
          <Box sx={{padding: "45px 20px 20px"}}>
            <Filters
              handleChange={handleChange}
              state={state}
              setKpiList={setKpiList}
              search={search}
              setSearch={setSearch}
            />

            <Box>
              <EmptyOrShow loading={loading}>
                <TableContainer className={classes.table_container}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead className={classes.table_head}>
                      <TableRow>
                        {headTitle.map(el => {
                          return <TableCell key={el}>{el}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.table_body}>
                      {filtersArray?.map(el => {
                        return el.plansList.map(item => {
                          return (
                            <TableRowComp
                              key={item.sd_month_plan_id}
                              {...item}
                              {...el}
                              state={state}
                              setKpiList={getKpiList}
                            />
                          );
                        });
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </EmptyOrShow>
            </Box>
          </Box>
        </Layout>
      ) : (
        <Box className={classes.empty_message}>
          <h1>План ОП доступен только Руководителю РОП</h1>
        </Box>
      )}
    </>
  );
};

export default SalesDeptPlan;

const headTitle = [
  "ID",
  "ОП",
  "Месяц",
  "План",
  "Налог %",
  "Факт",
  "% выполнения плана",
  "Закрытие месяца",
  "Действия"
];

const useStyles = makeStyles({
  table_wrapper: {},
  table_container: {
    backgroundColor: "#fff",
    border: "1px solid #CCC",
    borderRadius: "4px",
    height: "calc(100vh - 160px)",

    "&::-webkit-scrollbar": {
      width: 8
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "none"
    }
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e"
      }
    },
    "& td": {
      borderTop: "1px solid #ccc !important",
      borderBottom: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important"
    },
    "& td span": {
      whiteSpace: "nowrap"
    }
  },
  empty_message: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }
});
