import {LOCALSTORAGEFIELDS} from "../constants";

const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setCity(id) {
    localStorage.setItem(LOCALSTORAGEFIELDS.city, id);
  }

  return {
    getService: _getService,
    setCity: _setCity
  };
})();
export default LocalStorageService;
