import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import {InputAdornment, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  search: {
    "& .MuiInputBase-root": {
      height: 35,
      borderRadius: 4,
      fontSize: "14px !important",
      padding: 8
    }
  },
  icon: {
    width: 24,
    height: 24,
    color: "#C4C4C4"
  }
});

const Search = ({label, placeholder, handleChange, value}) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.search}
      onChange={handleChange}
      value={value}
      label={label}
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon}/>
          </InputAdornment>
        )
      }}
      variant="outlined"
    />
  );
};

export default Search;
