import React, {useState} from 'react';
import Layout from "../../components/layout";
import InputPhone from "../../components/Form/InputPhone";
import {getPhoneLength} from "../../utils";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useHuntersStyles} from "../huntersKpi";
import {makeStyles} from "@material-ui/core/styles";
import {searchByPhone} from "./services";
import CustomButton from "../../components/UI/Button";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";
import clsx from "clsx";
import cookiesService from "../../services/cookiesService";
import {useHistory} from "react-router-dom";

const cookiesServiceInstance = cookiesService.getService();
export const SearchByPhonePage = () => {
  const history = useHistory();
  const {position_code} = cookiesServiceInstance.getUserData();

  if(!position_code || position_code === "SELLER"
    || position_code === "SALE_ARCHIVE"
    || position_code === "SALE_HEAD") {
    history.push('/')
    return <div></div>
  }
  return (
    <SearchByPhone />
  );
};

const SearchByPhone = () => {
  const styles = useStyles();
  const classes = useHuntersStyles({hover: false});
  const {addCloseSnack} = useCustomSnackbar();
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const handleChange = e => {
    if (e.target.name === "phone_number" && (e.target.value?.length !== getPhoneLength() && e.target.value?.length !== 0)) return;
    setPhoneNumber(e.target.value);
  };

  const handleSearchByPhone = async () => {
    if (!phoneNumber || phoneNumber?.length !== getPhoneLength()) return addCloseSnack('Введите корректный номер телефона')
    setLoading(true)
    const res = await searchByPhone(phoneNumber)
    setLoading(false)
    if (!res) return
    setData(res)
  }
  return (
    <Layout title="Поиск по номеру телефона">
      <Box sx={{padding: "50px 12px 12px"}}>
        <Box
          sx={{marginBottom: "20px", border: "1px solid #ccc", borderRadius: 4, marginTop: 16, padding: "15px"}}
        >
          <Box className={styles.header}>
            <InputPhone
              name="phone_number"
              type="number"
              label="Телефон"
              placeholder="Номер телефона"
              value={phoneNumber || ""}
              onChange={handleChange}
              fullWidth={false}
            />
            <CustomButton
              className={classes.button} small
              onClick={handleSearchByPhone} loading={loading}
            >
              Поиск
            </CustomButton>
          </Box>
          <TableContainer
            className={classes.table_container}
            style={{maxHeight: 500}}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className={classes.table_head}>
                <TableRow>
                  {headTitle.map(el => {
                    return (
                      <TableCell
                        key={el}
                        style={{color: "black", fontWeight: "bold", lineHeight: "16px", padding: '5px'}}
                      >
                        {el}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table_body}>
                {data.map((e, i) => {
                  return (
                    <TableRow
                      key={i} title={e.is_contact_number ? 'Контактный телефон' : ""}
                      className={clsx([styles.tableRow, {[styles.tableContactNumber]: e.is_contact_number}])}
                    >
                      <TableCell align="center">
                        {e.is_follow ? <a
                          href={`/deal/${e.crm_deal_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {e.crm_deal_id}
                        </a> : <span>{e.crm_deal_id}</span>}
                      </TableCell>
                      <TableCell align="left">
                        {e.client_fio}
                      </TableCell>
                      <TableCell align="left">
                        {e.crm_status_name}
                      </TableCell>
                      <TableCell align="center">
                        {e.rowversion}
                      </TableCell>
                      <TableCell align="center">
                        {e.hist_rowversion}
                      </TableCell>
                      <TableCell align="left">
                        {e.action}
                      </TableCell>
                      <TableCell align="left">
                        {e.origin_name}
                      </TableCell>
                      <TableCell align="left">
                        {e.sale_department_name}
                      </TableCell>
                      <TableCell align="left">
                        {e.fio}
                      </TableCell>
                      <TableCell align="left">
                        {e.city_name}
                      </TableCell>
                      <TableCell align="left">
                        {e.resident_name}
                      </TableCell>
                      <TableCell align="left">
                        {e.flat_num}
                      </TableCell>
                      <TableCell align="left">
                        {e.preset_kit_name}
                      </TableCell>
                      <TableCell align="center">
                        {e.prop_date}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
};
const headTitle = [
  "ID Сделки",
  "ФИО клиента",
  "Статус сделки",
  "Дата создания сделки",
  "Дата последнего действия(по истории)",
  "Последнее действие(по истории)",
  "Источник",
  "ОП",
  "ФИО хантера",
  "Город",
  "ЖК",
  "№ кв",
  "Название пакета",
  "Дата договора",
];

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: "10px",
    marginBottom: "15px"
  },
  tableContactNumber: {
    backgroundColor: "#a4c99a"
  },
  tableRow: {
    "& td": {
      padding: '5px',
      fontSize: "13px"
    }
  }
})