import instance from "../../../api";
import { handleError } from "../../../api/helpers";

export const fetchAllDeals = params => {
  return instance(true)
    .post(`/crm/crm_deals/`, params)
    .then(res => {
      return res?.data;
    });
};

export const fetchResidents = params => {
  return instance(true)
    .post(`/crm/residents/`, params)
    .then(res => {
      return res?.data;
    });
};

export const fetchStatuses = () => {
  return instance(true)
    .get(`/crm/crm_statuses/`)
    .then(res => {
      return res?.data;
    })
    .catch((err) => {
      return handleError(err);
    });
};

export const takeToWork = crm_deal_id => {
  return instance(true)
    .post(`/crm/crm_deals/${crm_deal_id}/work/`)
    .then(res => {
      return res?.data;
    });
};

export const getHuntersList = () => {
  return instance(true)
    .get(`/crm/crm_hunters/`)
    .then(res => {
      return res?.data;
    });
};
export const getPresetsList = () => {
  return instance(true)
    .get(`/crm/deal_presets/`)
    .then(res => {
      return res?.data;
    });
};
export const getClientPhone = (body) => {
  return instance()
    .post(`/crm/client/phone/`, body)
    .then(res => {
      return res?.data;
    });
};
export const getClientPhones = (body) => {
  return instance()
    .post(`/crm/client/phones/`, body)
    .then(res => {
      return res?.data;
    });
};
