import instance from "../../api";

export const getHuntersReports = filters => {
  return instance(true)
    .post(`/crm/kpi/hunters_report/`, filters)
    .then(res => {
      return res?.data;
    });
};
export const getPlans = filters => {
  return instance(true)
    .get(`/crm/kpi/sd_plans/`, {params: filters})
    .then(res => {
      return res?.data;
    });
};
export const getHunterDetails = (id, params) => {
  if (isNaN(id)) return
  return instance(true)
    .post(`/crm/kpi/hunters_report/${id}/`, params)
    .then(res => {
      return res?.data;
    });
};
