import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import UploadFile from "../../UI/UploadFile";
import styleConstants from "../../../constants/styleConstants";
import CustomButton from "../../UI/Button";
import {addDocs, getApproveTypes, getDocTypes, getDsTypes} from "../../../pages/deals/view/api";
import AutoSuggest from "../../UI/AutoSuggest";
import CheckboxR from "../../Form/CheckboxR";
import InputText from "../../Form/InputText";
import {format} from "date-fns";
import {setBtnStatuses, setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch, useSelector} from "react-redux";
import {useDropzone} from "react-dropzone";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  block: {
    width: 305
  },
  inputs_block: {
    display: "grid",
    gap: "10px"
  },
  select_block: {
    "& .MuiFormControl-root": {
      marginBottom: 0
    },
    "& label": {
      fontSize: 12
    },
    "& input": {
      fontSize: 12
    }
  },
  upload_block: {
    "& .docs-item": {
      marginBottom: 0
    },
    "& .upload_file__inner": {
      borderBottom: "1px solid rgba(0, 0, 0, 1)",
      width: "100%",
      paddingBottom: 5
    }
  },
  btn_block: {
    width: "50%",
    marginLeft: "auto"
  },
  input_block: {
    margin: "15px 0",
    "& label": {
      color: "#9194A9",
      fontWeight: 600,
      fontSize: 16,
      whiteSpace: "nowrap"
    },
    "& .MuiFormControl-root": {
      margin: 0
    }
  },
  cb_block: {
    "& .MuiFormControlLabel-root": {
      margin: 0
    }
  }
});

const AgreementField = ({handleChange}) => {
  const classes = useStyles();
  const [approveTypes, setApproveTypes] = useState([]);

  useEffect(() => {
    getApproveTypes().then(res => {
      setApproveTypes(res);
    });
  }, []);

  return (
    <Box className={classes.select_block}>
      <AutoSuggest
        label="Тип согласования"
        placeholder="Выебире тип согласования"
        name="approve_document_type_id"
        list={approveTypes}
        valueKey="approve_document_type_id"
        showKey="approve_document_type_name"
        onChange={handleChange}
      />
    </Box>
  );
};

const DsField = ({handleChange, state, setState}) => {
  const classes = useStyles();
  const [dsTypes, setDsTypes] = useState([]);

  useEffect(() => {
    getDsTypes().then(res => {
      setDsTypes(res);
      setState({
        ...state,
        is_amount_decrease: 0,
        is_client_return: 0,
        is_individual_condition: 0
      });
    });
  }, []);

  return (
    <Box>
      <Box className={classes.select_block}>
        <AutoSuggest
          label="Тип ДС"
          placeholder="Выебире тип ДС"
          name="ds_type_id"
          list={dsTypes}
          valueKey="ds_type_id"
          showKey="ds_type_name"
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.input_block}>
        <InputText
          name="document_num"
          type="text"
          label="Номер ДС"
          placeholder="Введите номер ДС"
          value={state.document_num}
          shrink={true}
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.input_block}>
        <InputText
          name="document_date"
          type="date"
          label="Дата ДС"
          value={state.document_date}
          onChange={handleChange}
          shrink={true}
        />
      </Box>
      <Box className={classes.input_block}>
        <InputText
          name="client_payment_date"
          type="date"
          label="Дата платежа клиента"
          value={state.client_payment_date}
          onChange={handleChange}
          shrink={true}
        />
      </Box>
      {/*Checkboxes*/}
      <Box className={classes.cb_block}>
        <CheckboxR
          name="is_amount_decrease"
          label="Уменьшение суммы договора"
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.cb_block}>
        <CheckboxR
          name="is_client_return"
          label="Возврат клиенту"
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.cb_block}>
        <CheckboxR
          name="is_individual_condition"
          label="Индивидуальные условия"
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.input_block}>
        <InputText
          name="additional_agreement_amount"
          type="text"
          label="Сумма дополнительного соглашения"
          value={state.additional_agreement_amount}
          shrink={true}
          onChange={handleChange}
          placeholder="Введите сумму доп.соглашения"
        />
      </Box>
    </Box>
  );
};

function UploadDocsModal({crm_deal_id}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {addDefaultSnack} = useCustomSnackbar();

  const [state, setState] = useState({});
  const {btnStatuses} = useSelector(state => state.appState);
  const [docTypes, setDocTypes] = useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept
  } = useDropzone();

  const handleChange = e => {
    if (e.target.name === "document") {
      setState({...state, document: e.target.value[0]});
      return;
    }
    if (e.target.name === "document_type_id") {
      setState({
        document: state.document,
        document_type_id: e.target.value
      });
      return;
    }
    setState({
      ...state,
      [e.target.name]: e.target.value ? e.target.value : +e.target.checked
    });
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      const file = {
        target: {
          name: "document",
          value: acceptedFiles
        }
      };
      handleChange(file);
    }
  }, [acceptedFiles]);

  const handleAdd = async () => {
    try {
      const params = new FormData();
      for (const key in state) {
        if (key === "document_date" || key === "client_payment_date") {
          params.append(key, format(new Date(state[key]), "dd.MM.yyyy"));
        } else {
          params.append(key, state[key]);
        }
      }
      const resp = await addDocs(crm_deal_id, params);
      addDefaultSnack();
      dispatch(setShowModal(false));
      dispatch(setBtnStatuses({...btnStatuses, docs_btn: resp.docs_btn}));
    } catch (err) {
    }
  };

  const renderFields = () => {
    switch (state.document_type_id) {
      case 30:
        return <AgreementField handleChange={handleChange}/>;
      case 9:
        return (
          <DsField
            handleChange={handleChange}
            state={state}
            setState={setState}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getDocTypes().then(res => {
      setDocTypes(res);
    });
  }, []);

  return (
    <>
      <Box className={clsx(classes.inputs_block, classes.block)}>
        <Box className={classes.select_block}>
          <AutoSuggest
            label="Загрузка документов"
            placeholder="Выебире тип документа"
            name="document_type_id"
            list={docTypes}
            valueKey="document_type_id"
            showKey="document_type_name"
            onChange={handleChange}
          />
        </Box>
        {state.document_type_id ? renderFields() : null}
        <Box className={clsx(classes.upload_block, classes.block)}>
          <UploadFile
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragAccept={isDragAccept}
            name={"document"}
            title={"Файл"}
            label={"Файл"}
            onChange={handleChange}
            fileName={state.document?.name}
          />
        </Box>
        <Box className={classes.btn_block}>
          <CustomButton
            btnText="Сохранить"
            bgColor={styleConstants.primaryColor}
            // disabled={true}
            onClick={handleAdd}
          />
        </Box>
      </Box>
    </>
  );
}

export default UploadDocsModal;
