import {COOKIES_FIELDS} from "../constants";
import {getTokenExpiresDuration} from "../utils/auth";
import {parseCookies, setCookie} from "nookies";

const cookiesService = (function() {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setLoginData(res) {
    setCookie(null, COOKIES_FIELDS.accessToken, res.token?.access, {
      path: "/",
      maxAge: getTokenExpiresDuration(res.token?.access)
    });
    setCookie(null, COOKIES_FIELDS.refreshToken, res.token?.refresh, {
      path: "/",
      maxAge: getTokenExpiresDuration(res.token?.refresh)
    });
    setCookie(null, COOKIES_FIELDS.userData, JSON.stringify(res.user), {
      path: "/",
      maxAge: getTokenExpiresDuration(res.token?.access)
    });
  }
  function _setAccessToken(token) {
    try {
      setCookie(null, COOKIES_FIELDS.accessToken, token, {
        path: "/",
        maxAge: getTokenExpiresDuration(token)
      });
    } catch (e) {}
  }
  function _getAccessToken(ctx) {
    try {
      const cookies = parseCookies(ctx || null);
      return cookies[COOKIES_FIELDS.accessToken];
    } catch (e) {
      return "";
    }
  }
  function _getRefreshToken(ctx) {
    try {
      const cookies = parseCookies(ctx || null);
      return cookies[COOKIES_FIELDS.refreshToken];
    } catch (e) {
      return "";
    }
  }
  function _getUserData(ctx) {
    try {
      const cookies = parseCookies(ctx || null);
      return JSON.parse(cookies[COOKIES_FIELDS.userData]);
    } catch (e) {
      return {};
    }
  }
  function _isCredentialsExist(ctx) {
    return !!_getAccessToken(ctx) && !!_getRefreshToken(ctx);
  }
  function _clearToken() {
    setCookie(null, COOKIES_FIELDS.accessToken, null, {
      path: "/",
      maxAge: 0
    });
    setCookie(null, COOKIES_FIELDS.refreshToken, null, {
      path: "/",
      maxAge: 0
    });
    setCookie(null, COOKIES_FIELDS.userData, null, {
      path: "/",
      maxAge: 0
    });
  }
  return {
    setAccessToken: _setAccessToken,
    getService: _getService,
    setLoginData: _setLoginData,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getUserData: _getUserData,
    clearToken: _clearToken,
    isCredentialsExist: _isCredentialsExist
  };
})();

export default cookiesService;
