import React, {useEffect, useState} from "react";
import {Box, Collapse, Menu, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {useStyles} from "../";
import clsx from "clsx";
import IconComponent from "../icons";
import LinkComponent from "./singleLink";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MultipleLinkComponent = ({ Icon, children, title, positionCode }) => {
  const classes = useStyles();
  const { isOpenSidebar } = useSelector(state => state.appState);
  const [dropdown, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dropdownOpen = event => {
    if (!isOpenSidebar) setAnchorEl(event.currentTarget);
    else setDropdownOpen(!dropdown);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isOpenSidebar) setDropdownOpen(false);
  }, [isOpenSidebar]);
  if (!positionCode) return null;

  return (
    <>
      <Box
        className={
          isOpenSidebar
            ? classes.sidebar_links
            : clsx(classes.sidebar_links, classes.sidebar_links__min)
        }
        style={{ position: "relative" }}
        onClick={event => {
          dropdownOpen(event);
        }}
      >
        <Box>
          <IconComponent
            name={Icon}
            fontSize={"medium"}
          />
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={isOpenSidebar ? '100%' : 'auto'}>
          <Typography className={classes.sidebar_text}>{title}</Typography>
          {isOpenSidebar && <ExpandMoreIcon style={{transition: "all .3s", transform: `rotate(${dropdown ? '180deg' : '0'})`}}/>}
        </Box>
      </Box>
      {isOpenSidebar ? (
        <Collapse in={dropdown}>
          {children.map((item, i) => {
            return (
              <Box key={i} className={classes.coef_link}>
                <LinkComponent {...item} child={true} />
              </Box>
            );
          })}
        </Collapse>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          PaperProps={{
            style: {
              padding: "0 1em",
              transform: "translateX(80px)",
              left: "50px!important"
            }
          }}
        >
          {children.map((item, i) => {
            return (
              <Box onClick={handleClose} key={i}>
                <LinkComponent

                  {...item}
                  child={true}
                  horizontalClass={true}
                />
              </Box>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default MultipleLinkComponent;
