import React, { useState } from "react";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";

import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  accordion: {
    borderRadius: "unset !important",
    boxShadow: "none !important",
    margin: "0 !important",

    "& .MuiAccordionSummary-content": {
      alignItems: "center"
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0"
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      padding: "0 30px"
    },
    "& .MuiAccordionDetails-root": {
      padding: "0"
    },
    "& .MuiCollapse-root": {
      padding: "0 10px"
    }
  },
  accordion_title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    color: "#494C62",
    textTransform: "uppercase",
    margin: 0
  },
  expandMore_icon: {
    color: "#6681F7",
    transition: "transform 0.3s ease-out"
  },

  openAccordion: {
    transform: "rotate(180deg)"
  }
});

export default function ClientAccordion({ children }) {
  const classes = useStyles();
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const params = useParams();
  const { client_fio } = useSelector(state => state.appState.dealCreateData);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => setIsOpenAccordion(!isOpenAccordion)}
      >
        <h4 className={classes.accordion_title}>
          {`Информация о клиенте ${client_fio ? client_fio : ""} №${params.id}`}
        </h4>
        <ExpandMoreIcon
          className={
            isOpenAccordion
              ? clsx(classes.expandMore_icon, classes.openAccordion)
              : classes.expandMore_icon
          }
        />
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
