import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { deleteDocs, getUnsignedXml, signVerifiedXml } from "./api";
import { useSnackbar } from "notistack";
import { signXml } from "../../../ecpHelper";
import CustomButton from "../../../components/UI/Button";
import { MAIN_URL } from "../../../constants";
import { CloseSquare, Delete, TickSquare } from "react-iconly";

const useStyles = makeStyles({
  documentName: {
    whiteSpace: "nowrap",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default"
  },
  name: {
    whiteSpace: "nowrap"
  },
  date: {
    whiteSpace: "nowrap"
  },
  btn_block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      cursor: "pointer",
      width: 25,
      height: 25,
      margin: "0 5px"
    },

    "& svg": {
      width: "100%",
      height: "100%"
    }
  }
});

export default function AdditionalDocsRowComp({
  client_request_document_id,
  document_name,
  document_url,
  document_type_name,
  additional_agreement_amount,
  ds_origin_sum,
  type_code,
  dn_name,
  contract_signer_dn_name,
  fio,
  rowversion,
  crm_deal_id,
  setData
}) {
  const classes = useStyles();
  const [isSignProcess, setIsSignProcess] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSignClick = async client_request_document_id => {
    try {
      setIsSignProcess(true);
      const unsignedXml = await getUnsignedXml(client_request_document_id);
      const { unsigned_message, signed_message } = await signXml(
        "PKCS12",
        "SIGNATURE",
        unsignedXml,
        "signXmlBack",
        unsignedXml
      );
      const data = await signVerifiedXml(
        crm_deal_id,
        client_request_document_id,
        { unsigned_message, signed_message }
      );
      setData(data);
      enqueueSnackbar("Успешно", {
        variant: "success"
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.error, {
        variant: "error"
      });
    } finally {
      setIsSignProcess(false);
    }
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const cancelDelete = () => {
    setIsDelete(false);
  };

  const confirmDelete = () => {
    deleteDocs(crm_deal_id, client_request_document_id)
      .then(res => {
        setData(res.docs);
        enqueueSnackbar("Документ успешно удален", {
          variant: "success"
        });
      })
      .catch(err => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error"
        });
      });
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">{client_request_document_id}</TableCell>
        <TableCell
          align="center"
          className={classes.documentName}
          title={document_name}
        >
          <a href={MAIN_URL + document_url} target="_blank">
            {document_name}
          </a>
        </TableCell>
        <TableCell align="center">{document_type_name}</TableCell>
        <TableCell align="center">{additional_agreement_amount}</TableCell>
        <TableCell align="center">{ds_origin_sum}</TableCell>
        <TableCell align="center">{dn_name}</TableCell>
        <TableCell align="center">
          {type_code === "CLIENT_AGREEMENT" && !contract_signer_dn_name ? (
            <Box display="flex">
              <CustomButton
                btnText="Подписать"
                style={{ height: 28, width: 130, marginRight: 5 }}
                handleClick={() => handleSignClick(client_request_document_id)}
                disabled={isSignProcess}
              />
              {isSignProcess ? (
                <CircularProgress size={25} style={{ color: "#6191EF" }} />
              ) : null}
            </Box>
          ) : (
            dn_name
          )}
        </TableCell>

        <TableCell align="center" className={classes.name}>
          {fio}
        </TableCell>
        <TableCell align="center" className={classes.date}>
          {rowversion}
        </TableCell>
        <TableCell align="center">
          <Box className={classes.btn_block}>
            {isDelete ? (
              <>
                <span onClick={confirmDelete}>
                  <TickSquare set="bold" primaryColor="#77BF6C" />
                </span>
                <span onClick={cancelDelete}>
                  <CloseSquare set="bold" primaryColor="#FF4754" />
                </span>
              </>
            ) : (
              <span onClick={handleDelete}>
                <Delete set="light" primaryColor="#FF6262" />
              </span>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
