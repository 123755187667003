import instance from "../../api";

export const getCities = () => {
  return instance(true)
    .get(`/crm/cities/`)
    .then(res => {
      return res?.data;
    })
};

export const getKpiInfo = () => {
  return instance(true)
    .get(`/crm/kpi/info/`)
    .then(res => {
      return res?.data;
    })
};
