import { Card } from "@material-ui/core";
import React from "react";

const InCard = ({
  children,
  inCard = true,
  className = "",
  handleClick = () => {},
}) => {
  return inCard ? (
    <Card className={`card-box-cnt ${className}`} onClick={handleClick}>
      {children}
    </Card>
  ) : (
    children
  );
};

export default InCard;
