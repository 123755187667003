import React from "react";

import {TableCell, TableRow} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {useStyles} from "./index"
import clsx from "clsx";

const TableRowComp = ({crm_status_name, fact_sum, onStatusClick, isActive, deal_cnt}) => {
  const classes = useStyles()
  return (
    <TableRow onClick={onStatusClick} className={clsx(classes.status, {[classes.statusActive]: isActive})}>
      <TableCell>{crm_status_name}</TableCell>
      <TableCell>
        <NumberFormat
          value={fact_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell>
        {deal_cnt}
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
