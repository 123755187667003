import React from "react";
import {setShowModal} from "../../services/redux/actions/app-actions";

import {Box, IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {useStyles} from "./index";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import {MODALS} from "../../constants";
import {withStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {deleteHunter} from "./api";
import {Delete} from "react-iconly";
import NumberFormat from "react-number-format";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 11,
    backgroundColor: "#383838",
    padding: "5px 10px"
  }
}))(Tooltip);

const TableRowComp = ({
  state,
  setHuntersPlan,
  hunter_month_plan_id,
  fio,
  hunter_salary,
  hunter_month_plan_sum,
  penalty_total_sum,
  getHuntersFunc,
  isClosed
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {addDefaultSnack } = useCustomSnackbar();

  const handleDeleteHunter = id => {
    deleteHunter(id, state.year, state.month)
      .then(res => {
        setHuntersPlan(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно удален");
      })
      .catch(() => {});
  };

  return (
    <TableRow>
      <TableCell align="left">{hunter_month_plan_id}</TableCell>
      <TableCell align="left">{fio}</TableCell>
      <TableCell align="left">
        <NumberFormat
          value={hunter_salary}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left">
        <NumberFormat
          value={hunter_month_plan_sum}
          displayType="text"
          thousandSeparator={" "}
        />
      </TableCell>
      <TableCell align="left" className={classes.penalty_cell}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px"
          }}
        >
          <CustomTooltip title="Список штрафов">
            <span>
              <button
                className={classes.btn_penalty_list}
                onClick={() =>
                  dispatch(
                    setShowModal(true, MODALS.penaltyListModal, {
                      title: "Список штрафов",
                      id: hunter_month_plan_id,
                      state: state,

                      getHuntersFunc: getHuntersFunc,
                      isClosed: isClosed,
                      fio: fio
                    })
                  )
                }
              >
                <NumberFormat
                  value={penalty_total_sum}
                  displayType="text"
                  thousandSeparator={" "}
                />
              </button>
            </span>
          </CustomTooltip>
          <CustomTooltip title="Добавить штраф">
            <span>
              <IconButton
                size={"small"}
                disabled={isClosed}
                onClick={() =>
                  dispatch(
                    setShowModal(true, MODALS.addPenaltyModal, {
                      title: "Добавить штраф",
                      id: hunter_month_plan_id,
                      state: state,
                      getHuntersFunc: getHuntersFunc,
                      isClosed: isClosed,
                      fio: fio
                    })
                  )
                }
              >
                <AddIcon style={{ color: isClosed ? "#ccc" : "#6191EF" }} />
              </IconButton>
            </span>
          </CustomTooltip>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Box sx={{ display: "flex", gap: "5px" }}>
          <CustomTooltip title="Изменит план">
            <span>
              <IconButton
                size="small"
                disabled={isClosed}
                onClick={() =>
                  dispatch(
                    setShowModal(true, MODALS.changeSumModal, {
                      title: "Изменение",
                      state: state,
                      setHuntersPlan: setHuntersPlan,
                      hunter_month_plan_id: hunter_month_plan_id,
                      hunter_month_plan_sum: hunter_month_plan_sum,
                      hunter_salary: hunter_salary,
                      fio: fio
                    })
                  )
                }
              >
                <EditIcon style={{ color: isClosed ? "#ccc" : "#6191EF" }} />
              </IconButton>
            </span>
          </CustomTooltip>
          <CustomTooltip title="Удалить хантера">
            <span>
              <IconButton
                size="small"
                disabled={isClosed}
                onClick={() => {
                  dispatch(
                    setShowModal(true, MODALS.confirmDelete, {
                      title: "Удалить хантера",
                      handleDelete: () =>
                        handleDeleteHunter(hunter_month_plan_id),
                      fio: fio
                    })
                  );
                }}
              >
                <Delete
                  set="light"
                  style={{ color: isClosed ? "#ccc" : "#FF6262" }}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
