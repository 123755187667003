import React, {useState} from "react";

import {Box} from "@material-ui/core";
import CustomButton from "../../UI/Button";
import {changeKpi} from "../../../pages/salesDeptPlan/api";
import {setShowModal} from "../../../services/redux/actions/app-actions";
import {useDispatch} from "react-redux";
import NumberFormatInput from "../../UI/NumberFormatInput";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const ChangeKpiModal = ({
                          state,
                          setKpiList,
                          sd_month_plan_id,
                          sd_month_plan_sum,
                          taxes_percentage
                        }) => {
  const dispatch = useDispatch();
  const [changeMonthState, setChangeMonthState] = useState({
    sd_month_plan_sum: sd_month_plan_sum,
    taxes_percentage: taxes_percentage
  });
  const {addDefaultSnack} = useCustomSnackbar();

  const handleClick = () => {
    changeKpi(sd_month_plan_id, state.year, state.id, changeMonthState)
      .then(res => {
        setKpiList(res);
        dispatch(setShowModal(false));
        addDefaultSnack("Успешно изменен");
      })
      .catch(() => {
      });
  };

  const handleChange = e => {
    setChangeMonthState({
      ...changeMonthState,
      [e.target.name]: +e.target.value
    });
  };

  return (
    <Box sx={{width: 400}}>
      <Box sx={{margin: "20px 0"}}>
        <NumberFormatInput
          name="sd_month_plan_sum"
          label="План"
          shrink={true}
          value={changeMonthState.sd_month_plan_sum || ""}
          handleChange={handleChange}
        />
        <NumberFormatInput
          name="taxes_percentage"
          label="Налог %"
          shrink={true}
          value={changeMonthState.taxes_percentage || ""}
          handleChange={handleChange}
        />
      </Box>
      <Box sx={{width: 200, marginLeft: "auto"}}>
        <CustomButton btnText="Изменить" handleClick={handleClick}/>
      </Box>
    </Box>
  );
};

export default ChangeKpiModal;
