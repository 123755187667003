import React from "react";
import {Box, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {useStyles} from "./LeadCard";
import clsx from "clsx";
import {ShowPhone} from "../../../components/helpers/showPhone";

const CardListItem = ({
                        text, getPhoneBody = {},
                        children,
                        isProgress = false,
                        isPhone = false,
                        isAuditorChecked = false,
                      }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.list_item}>
      <ListItemIcon className={classes.list_icon}>{children}</ListItemIcon>
      {isProgress ? (
        <ListItemText className={classes.list_text}>
          Прогресс: <span style={{color: "red"}}>{text}</span>
        </ListItemText>
      ) : (
        <ListItemText className={classes.list_text}>
          {isPhone ? (
            <ShowPhone body={getPhoneBody}/>
          ) : (
            <Box
              component="span"
              className={clsx({
                [classes.auditor_checked]: isAuditorChecked === true,
              })}
            >
              {text}
            </Box>
          )}
        </ListItemText>
      )}
    </ListItem>
  );
};

export default CardListItem;
