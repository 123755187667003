import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { setShowModal } from "../../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import CustomButton from "../../UI/Button";

const ConfirmDeleteModal = ({ handleDelete = () => {}, title, submitText, deleteMode = true  }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    handleDelete && await handleDelete()
    setLoading(false)
  }
  return (
    <Box className={classes.wrapper}>
      <h1>{title || 'Вы действительно хотите удалить?'}</h1>
      <Box
        className={classes.buttons}
      >
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.cancel)}
          onClick={() => dispatch(setShowModal(false))}
        >
          Отмена
        </Button>
        <CustomButton loading={loading} disabled={loading}
          className={clsx(classes.button, deleteMode ? classes.delete : '')}
          handleClick={handleSubmit} btnText={submitText || 'Удалить'}
        />
      </Box>
    </Box>
  );
};

export default ConfirmDeleteModal;
const useStyles = makeStyles({
  wrapper: {
    maxWidth: 550,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10
  },
  button: {
    width: "100%",
    height: 38,
    padding: 10,
    borderRadius: 4,
    marginBottom: 10
  },
  delete: {
    color: "#fff",
    backgroundColor: "#FF6262",

    "&:hover": {
      backgroundColor: "#FF6262"
    }
  },
  cancel: {
    borderColor: "#6191EF",
    color: "#6191EF"
  }
});
