import React from "react";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import checklist from "../../../assets/images/checklist.svg";
import styleConstants from "../../../constants/styleConstants";
import {FaBuilding, FaPhoneAlt, FaUser, RiErrorWarningLine,} from "react-icons/all";
import manager from "../../../assets/images/icons/manager.svg";
import clsx from "clsx";
import {ShowPhone} from "../../../components/helpers/showPhone";
import {getBgColor} from "../../../utils";
import {useHistory} from "react-router";
import {EndTaskButton} from "./EndTaskButton";

const Task = ({
                crm_status_name,
                crm_status_id,
                date_finish,
                date_complete,
                crm_deal_id,
                crm_deal_task_id,
                crm_task_type_name,
                task_text,
                client_name,
                resident_name,
                hunter_fio,
                is_overdue,
                is_follow,
                is_asap,
                is_question,
                deny_reason_name,
                deny_comment,
                getTasks,
              }) => {
  const history = useHistory()
  const classes = useStyles({
    backgroundColor: getBgColor(crm_status_id),
    link: !!is_follow
  });
  const {isArchive} = useSelector((state) => state.appState);

  const onTaskClick = () => {
    if (is_follow)
      return history.push(`deal/${crm_deal_id}`)
  }
  return (
    <Box className={classes.task_wrapper}>
      <Box className={classes.task}>
        <div
          onClick={onTaskClick}
          className={classes.taskLeftBlock_link}
        >
          <Box className={classes.taskLeftBlock}>
            <Box className={classes.taskLeftBlock_icon}>
              <Box className={classes.taskLeftBlock_icon_inner}>
                <img src={checklist} width={20} height={20} alt="icon"/>
              </Box>
            </Box>
            <Box className={classes.taskLeftBlock_info}>
              <h3 className={classes.taskLeftBlock_title}>
                {crm_task_type_name}
              </h3>
              <Typography className={classes.taskLeftBlock_desc}>
                Коментарии: {task_text}
              </Typography>
              <Typography
                className={classes.taskLeftBlock_date}
                style={{color: is_overdue ? "#FF4754" : "#9194A9"}}
              >
                Дедлайн: {date_finish}
              </Typography>
              {isArchive && date_complete ? (
                <Typography
                  className={clsx(
                    classes.taskLeftBlock_date,
                    classes.date_complete
                  )}
                >
                  Дата завершения: {date_complete}
                </Typography>
              ) : null}
              {deny_reason_name ? (
                <Box className={classes.deny_class}>
                  <Typography
                    className={clsx(
                      classes.taskLeftBlock_date,
                      classes.date_complete
                    )}
                  >
                    Причина отказа: <b>{deny_reason_name}</b>
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.taskLeftBlock_date,
                      classes.date_complete
                    )}
                  >
                    Комментарий отказа: <b>{deny_comment}</b>
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box className={classes.taskLeftBlock_admin}>
            Исполнитель:{" "}
            <span style={{width: 200, textAlign: "right"}}>{hunter_fio}</span>
          </Box>
          {is_asap ? (
            <Box className={classes.asap_icon} title="Срочная задача">
              <RiErrorWarningLine size="20px"/>
            </Box>
          ) : null}
        </div>
        <Box className={classes.taskRightBlock}>
          <Box className={classes.taskRightBlock_header}>
            <Box className={classes.taskRightBlock_headerItem}>
              <h3>№{crm_deal_id}</h3>
              <p>Статус: {crm_status_name}</p>
            </Box>
            {!isArchive && <Box width={120}>
              <EndTaskButton crm_deal_id={crm_deal_id} crm_deal_task_id={crm_deal_task_id}
                             crm_task_type_name={crm_task_type_name}
                             is_question={is_question} afterEndTask={getTasks}/>
            </Box>}
          </Box>
          <Box className={classes.taskRightBlock_body}>
            <Box className={classes.taskRightBlock_item}>
              <FaUser/>
              {client_name}
            </Box>
            <Box className={classes.taskRightBlock_item}>
              <FaPhoneAlt/>
              {crm_deal_id && <ShowPhone body={{crm_deal_id}}/>}
            </Box>
            <Box className={classes.taskRightBlock_item}>
              <FaBuilding/>
              {resident_name}
            </Box>
            {hunter_fio ? (
              <Box className={classes.taskRightBlock_item}>
                <img src={manager} alt="manager"/>
                {hunter_fio}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Task;

export const useStyles = makeStyles((theme) => ({
  task_wrapper: {
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    overflow: "hidden",

    "&:not(:last-child)": {
      marginBottom: 15,
    },
  },
  task: {
    display: "grid",
    gridTemplateColumns: "400px 1fr",
    alignItems: "center",
  },
  taskLeftBlock_link: {
    color: "inherit",
    backgroundColor: "#fff",
    textDecoration: "none",
    display: "grid",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 10,
    cursor: props => props.link ? 'pointer' : 'default'
  },
  taskLeftBlock: {
    display: "grid",
    gridTemplateColumns: "20% 80%",
    alignItems: "center",
    height: "100%",
  },
  taskLeftBlock_icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  taskLeftBlock_icon_inner: {
    backgroundColor: "#C9A5EE",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 37,
    height: 37,
  },
  taskLeftBlock_info: {
    "& p": {
      marginTop: 5,
    },
  },
  taskLeftBlock_title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    color: "#000000",
    margin: 0,
  },
  taskLeftBlock_desc: {
    fontSize: 12,
    fontWeight: 500,
    color: styleConstants.darkGray,
  },
  taskLeftBlock_date: {
    fontSize: 12,
    fontWeight: 500,
    color: styleConstants.darkGray,
  },
  taskLeftBlock_admin: {
    fontSize: 14,
    color: "#9194A9",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,

    "& span": {
      color: "#000",
    },
  },
  date_complete: {
    color: "#FF4754",
  },
  taskLeftBlock_time: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: "#FF4754",
    marginLeft: "auto",
  },
  taskRightBlock: {
    backgroundColor: (props) => props.backgroundColor,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: 10,
  },
  taskRightBlock_header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    marginBottom: 10,
  },
  taskRightBlock_headerItem: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& h3": {
      fontWeight: 600,
      color: "#494C62",
      margin: 0,
    },

    "& p": {
      margin: 0,
    },
  },
  taskRightBlock_body: {
    display: "flex",
    gap: "30px",

    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: "1fr 1fr",
    },

    "& svg": {
      color: "#72768F",
      width: "15px",
      height: "15px",
      marginRight: 5,
    },
  },
  taskRightBlock_item: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: 18,
      height: 18,
      marginRight: 5,
    },
  },
  asap_icon: {
    color: "#FF4754",
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 50,
    cursor: "default",
  },
  deny_class: {
    border: "2px solid #E2E3ED",
    padding: "4px 6px",
    marginTop: "10px",
    borderRadius: "4px",
  },
}));
