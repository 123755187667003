import React, { useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import { Box } from "@material-ui/core";
import LeadCard from "./LeadCard";
import { useVirtual } from "react-virtual";

const DealsList = ({ sortedDeals, crmStatusId, fetchDeals }) => {
  const classes = useStyles();
  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: sortedDeals.length,
    parentRef,
    estimateSize: useCallback(() => 255, []),
  });
  return (
    <Box ref={parentRef} className={classes.list}>
      <Box
        className={classes.list_inner}
        style={{
          width: "100%",
          height: `${rowVirtualizer.totalSize}px`,
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((item) => {
          const el = sortedDeals[item.index];
          return (
            <LeadCard
              key={el.crm_deal_id}
              {...el}
              crmStatusId={crmStatusId}
              fetchDeals={fetchDeals}
              virtualRow={item}
              el={el}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default DealsList;
const useStyles = makeStyles({
  list: {
    width: 270,
    height: "calc(100vh - 160px)",
    overflow: "auto",
    paddingRight: 5,
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: 4,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius,
    },
  },
});
